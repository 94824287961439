export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** Cursor for paging through collections */
  ConnectionCursor: { input: any; output: any; }
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: { input: any; output: any; }
  /** The Email scalar type represents a valid email address. */
  EmailAddress: { input: any; output: any; }
  /** The `JSONObject` scalar type represents JSON objects as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSONObject: { input: any; output: any; }
  /** The UUID scalar type represents a UUID. */
  UUID: { input: any; output: any; }
};

export type ActivateAccountInfo = {
  /** Email address of the user. */
  email: Scalars['String']['input'];
  /** First name of the user. */
  firstName?: InputMaybe<Scalars['String']['input']>;
  /** Users preferred language. */
  language?: Language;
  /** Last name of the user. */
  lastName?: InputMaybe<Scalars['String']['input']>;
  password: Scalars['String']['input'];
  passwordCheck: Scalars['String']['input'];
};

export type ActiveCompany = {
  __typename?: 'ActiveCompany';
  /** Country code where the company is located (ISO 3166-1 alpha-2). */
  country: Scalars['String']['output'];
  /** Date time of when the recourse is created. */
  createdAt: Scalars['DateTime']['output'];
  /** Currency the company uses. */
  currency: Currency;
  /** Environment of the company. */
  env: CompanyEnv;
  /** Features for this company. */
  features: ActiveCompanyFeatures;
  /** Primary key of the recourse. */
  id: Scalars['UUID']['output'];
  /** Additional info of the company. */
  info?: Maybe<CompanyInfo>;
  /** Preferred language of the company. */
  language: Language;
  /** Name of the company. */
  name: Scalars['String']['output'];
  /** Slug of the company. */
  slug: Scalars['String']['output'];
  /** Company type, "master", "franchisee" or "client". */
  type: CompanyType;
  /** Date time of when the recourse last updated. */
  updatedAt: Scalars['DateTime']['output'];
};

export type ActiveCompanyEdge = {
  __typename?: 'ActiveCompanyEdge';
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor']['output'];
  /** The node containing the ActiveCompany */
  node: ActiveCompany;
};

export type ActiveCompanyFeatures = {
  __typename?: 'ActiveCompanyFeatures';
  /** Allow this company to import transactions following the ISO 20022 XML format. */
  iso20022TransactionsImport: Scalars['Boolean']['output'];
  /** Allow this company to access overheid.io openkvk search API. */
  openKvkSearch: Scalars['Boolean']['output'];
};

export type Actor = {
  __typename?: 'Actor';
  /** Primary key of the recourse. */
  id: Scalars['UUID']['output'];
  /** Name of the actor. */
  name: Scalars['String']['output'];
};

export type AddLabelsToClientInput = {
  /** The id of the record. */
  id: Scalars['UUID']['input'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['UUID']['input']>;
};

export type AddLabelsToDossierInput = {
  /** The id of the record. */
  id: Scalars['UUID']['input'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['UUID']['input']>;
};

export type AddStepsOfWorkflowToWorkflow = {
  /** ID of the workflow to add the steps to. */
  fromWorkflowId: Scalars['UUID']['input'];
  /** Place new steps on top of workflow. */
  placeTop?: Scalars['Boolean']['input'];
  /** Remove all existing steps. */
  removeExistingSteps?: Scalars['Boolean']['input'];
  /** ID of the workflow to add the steps from. */
  toWorkflowId: Scalars['UUID']['input'];
};

/** Different audit actions. */
export enum AuditingAction {
  Create = 'Create',
  Delete = 'Delete',
  Update = 'Update'
}

export type AuthoriseCompanyIntegration = {
  __typename?: 'AuthoriseCompanyIntegration';
  /** ID of the integration. */
  id: Scalars['UUID']['output'];
  /** Method to use for the URL. */
  method?: Maybe<Scalars['String']['output']>;
  /** URL to redirect or to POST to. */
  url?: Maybe<Scalars['String']['output']>;
};

export type BooleanFieldComparison = {
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
};

export type BusinessActivity = {
  __typename?: 'BusinessActivity';
  /** Date time of when the recourse is created. */
  createdAt: Scalars['DateTime']['output'];
  /** Primary key of the recourse. */
  id: Scalars['UUID']['output'];
  /** The name of the business activity. */
  name: Scalars['String']['output'];
  /** Date time of when the recourse last updated. */
  updatedAt: Scalars['DateTime']['output'];
  /** The values of the business activity. */
  values: Array<BusinessActivityValue>;
};

export type BusinessActivityConnection = {
  __typename?: 'BusinessActivityConnection';
  /** Array of nodes. */
  nodes: Array<BusinessActivity>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type BusinessActivityCreatedAtFilterComparison = {
  between?: InputMaybe<BusinessActivityCreatedAtFilterComparisonBetween>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  notBetween?: InputMaybe<BusinessActivityCreatedAtFilterComparisonBetween>;
};

export type BusinessActivityCreatedAtFilterComparisonBetween = {
  lower: Scalars['DateTime']['input'];
  upper: Scalars['DateTime']['input'];
};

export type BusinessActivityDeleteResponse = {
  __typename?: 'BusinessActivityDeleteResponse';
  /** Date time of when the recourse is created. */
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  /** Primary key of the recourse. */
  id?: Maybe<Scalars['UUID']['output']>;
  /** The name of the business activity. */
  name?: Maybe<Scalars['String']['output']>;
  /** Date time of when the recourse last updated. */
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** The values of the business activity. */
  values?: Maybe<Array<BusinessActivityValue>>;
};

export type BusinessActivityFilter = {
  and?: InputMaybe<Array<BusinessActivityFilter>>;
  createdAt?: InputMaybe<BusinessActivityCreatedAtFilterComparison>;
  id?: InputMaybe<BusinessActivityIdFilterComparison>;
  name?: InputMaybe<StringFieldComparison>;
  or?: InputMaybe<Array<BusinessActivityFilter>>;
  updatedAt?: InputMaybe<BusinessActivityUpdatedAtFilterComparison>;
};

export type BusinessActivityIdFilterComparison = {
  eq?: InputMaybe<Scalars['UUID']['input']>;
  in?: InputMaybe<Array<Scalars['UUID']['input']>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  neq?: InputMaybe<Scalars['UUID']['input']>;
  notIn?: InputMaybe<Array<Scalars['UUID']['input']>>;
};

export type BusinessActivityReference = {
  __typename?: 'BusinessActivityReference';
  /** Primary key of the recourse. */
  id: Scalars['UUID']['output'];
  /** The name of the business activity. */
  name: Scalars['String']['output'];
};

export type BusinessActivitySort = {
  direction: SortDirection;
  field: BusinessActivitySortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum BusinessActivitySortFields {
  CreatedAt = 'createdAt',
  Id = 'id',
  Name = 'name',
  UpdatedAt = 'updatedAt'
}

export type BusinessActivityUpdatedAtFilterComparison = {
  between?: InputMaybe<BusinessActivityUpdatedAtFilterComparisonBetween>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  notBetween?: InputMaybe<BusinessActivityUpdatedAtFilterComparisonBetween>;
};

export type BusinessActivityUpdatedAtFilterComparisonBetween = {
  lower: Scalars['DateTime']['input'];
  upper: Scalars['DateTime']['input'];
};

export type BusinessActivityValue = {
  __typename?: 'BusinessActivityValue';
  language: Language;
  value: Scalars['String']['output'];
};

export type CancelOneContractInput = {
  /** The id of the record to delete. */
  id: Scalars['UUID']['input'];
};

export type Client = {
  __typename?: 'Client';
  /** Business activity of the client. */
  businessActivity?: Maybe<BusinessActivityReference>;
  /** ID of the linked business activity. */
  businessActivityId?: Maybe<Scalars['UUID']['output']>;
  /** Contacts of the company. */
  contacts?: Maybe<Array<CompanyContact>>;
  /** Country code where the company is located (ISO 3166-1 alpha-2). */
  country: Scalars['String']['output'];
  /** Date time of when the recourse is created. */
  createdAt: Scalars['DateTime']['output'];
  /** Creator of the client. */
  creator: Creator;
  /** Currency the company uses. */
  currency: Currency;
  /** Primary key of the recourse. */
  id: Scalars['UUID']['output'];
  /** Additional info of the company. */
  info?: Maybe<CompanyInfo>;
  /** Flag if this client is a client in an dossier. */
  isClient: Scalars['Boolean']['output'];
  /** Flag if this client is a debtor in an dossier. */
  isDebtor: Scalars['Boolean']['output'];
  /** ID of the job that created this client. */
  jobId?: Maybe<Scalars['UUID']['output']>;
  /** Labels of the client */
  labels: Array<Label>;
  /** Preferred language of the company. */
  language: Language;
  /** Manager of this client. */
  manager?: Maybe<Manager>;
  /** Name of the company. */
  name: Scalars['String']['output'];
  /** Should the area check be skippend for this client. */
  skipAreaCheck: Scalars['Boolean']['output'];
  /** Slug of the company. */
  slug: Scalars['String']['output'];
  /** Date time of when the recourse last updated. */
  updatedAt: Scalars['DateTime']['output'];
  /** The variant of the company. */
  variant: CompanyVariant;
};


export type ClientContactsArgs = {
  filter?: CompanyContactFilter;
  sorting?: Array<CompanyContactSort>;
};

export type ClientAggregateFilter = {
  and?: InputMaybe<Array<ClientAggregateFilter>>;
  createdAt?: InputMaybe<ClientCreatedAtFilterComparison>;
  id?: InputMaybe<ClientIdFilterComparison>;
  isClient?: InputMaybe<ClientIsClientFilterComparison>;
  isDebtor?: InputMaybe<ClientIsDebtorFilterComparison>;
  jobId?: InputMaybe<ClientJobIdFilterComparison>;
  labels?: InputMaybe<ClientAggregateFilterLabelAggregateFilter>;
  name?: InputMaybe<ClientNameFilterComparison>;
  or?: InputMaybe<Array<ClientAggregateFilter>>;
  updatedAt?: InputMaybe<ClientUpdatedAtFilterComparison>;
  variant?: InputMaybe<ClientVariantFilterComparison>;
};

export type ClientAggregateFilterLabelAggregateFilter = {
  and?: InputMaybe<Array<ClientAggregateFilterLabelAggregateFilter>>;
  createdAt?: InputMaybe<LabelCreatedAtFilterComparison>;
  id?: InputMaybe<LabelIdFilterComparison>;
  name?: InputMaybe<LabelNameFilterComparison>;
  or?: InputMaybe<Array<ClientAggregateFilterLabelAggregateFilter>>;
  updatedAt?: InputMaybe<LabelUpdatedAtFilterComparison>;
};

export type ClientAggregateGroupBy = {
  __typename?: 'ClientAggregateGroupBy';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['UUID']['output']>;
  isClient?: Maybe<Scalars['Boolean']['output']>;
  isDebtor?: Maybe<Scalars['Boolean']['output']>;
  jobId?: Maybe<Scalars['UUID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  variant?: Maybe<CompanyVariant>;
};


export type ClientAggregateGroupByCreatedAtArgs = {
  by?: GroupBy;
};


export type ClientAggregateGroupByUpdatedAtArgs = {
  by?: GroupBy;
};

export type ClientAggregateResponse = {
  __typename?: 'ClientAggregateResponse';
  count?: Maybe<ClientCountAggregate>;
  groupBy?: Maybe<ClientAggregateGroupBy>;
  max?: Maybe<ClientMaxAggregate>;
  min?: Maybe<ClientMinAggregate>;
};

export type ClientConnection = {
  __typename?: 'ClientConnection';
  /** Array of nodes. */
  nodes: Array<Client>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type ClientContact = {
  __typename?: 'ClientContact';
  /** Company that the contact belongs to. */
  company: CompanyReference;
  /** ID of the company the contact belongs to */
  companyId: Scalars['UUID']['output'];
  /** Date time of when the recourse is created. */
  createdAt: Scalars['DateTime']['output'];
  /** Creator of the contact. */
  creator?: Maybe<Creator>;
  /** Email address of the contact */
  email?: Maybe<Scalars['String']['output']>;
  /** First name of the contact. */
  firstName?: Maybe<Scalars['String']['output']>;
  /** Full name of the contact. */
  fullName: Scalars['String']['output'];
  /** Gender of the contact. */
  gender: Gender;
  /** Hide the users last name in salutation. */
  hideLastNameInSalutation: Scalars['Boolean']['output'];
  /** Primary key of the recourse. */
  id: Scalars['UUID']['output'];
  /** Initials of the contact. */
  initials?: Maybe<Scalars['String']['output']>;
  /** Insertion of the contact. */
  insertion?: Maybe<Scalars['String']['output']>;
  /** Preferred language of the contact. */
  language: Language;
  /** Last name of the contact. */
  lastName: Scalars['String']['output'];
  /** Manager of this contact. */
  manager?: Maybe<Manager>;
  /** Mobile number of the contact. (E.164 format) */
  mobileNr?: Maybe<Scalars['String']['output']>;
  /** Phone number of the contact. (E.164 format) */
  phoneNr?: Maybe<Scalars['String']['output']>;
  /** Date time of when the recourse last updated. */
  updatedAt: Scalars['DateTime']['output'];
  /** When creating invoices use this contact. */
  useForInvoicing: Scalars['Boolean']['output'];
};

export type ClientContactCompanyIdFilterComparison = {
  eq?: InputMaybe<Scalars['UUID']['input']>;
  in?: InputMaybe<Array<Scalars['UUID']['input']>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  neq?: InputMaybe<Scalars['UUID']['input']>;
  notIn?: InputMaybe<Array<Scalars['UUID']['input']>>;
};

export type ClientContactConnection = {
  __typename?: 'ClientContactConnection';
  /** Array of nodes. */
  nodes: Array<ClientContact>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type ClientContactCreatedAtFilterComparison = {
  between?: InputMaybe<ClientContactCreatedAtFilterComparisonBetween>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  notBetween?: InputMaybe<ClientContactCreatedAtFilterComparisonBetween>;
};

export type ClientContactCreatedAtFilterComparisonBetween = {
  lower: Scalars['DateTime']['input'];
  upper: Scalars['DateTime']['input'];
};

export type ClientContactDeleteResponse = {
  __typename?: 'ClientContactDeleteResponse';
  /** ID of the company the contact belongs to */
  companyId?: Maybe<Scalars['UUID']['output']>;
  /** Date time of when the recourse is created. */
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  /** Email address of the contact */
  email?: Maybe<Scalars['String']['output']>;
  /** First name of the contact. */
  firstName?: Maybe<Scalars['String']['output']>;
  /** Full name of the contact. */
  fullName?: Maybe<Scalars['String']['output']>;
  /** Gender of the contact. */
  gender?: Maybe<Gender>;
  /** Hide the users last name in salutation. */
  hideLastNameInSalutation?: Maybe<Scalars['Boolean']['output']>;
  /** Primary key of the recourse. */
  id?: Maybe<Scalars['UUID']['output']>;
  /** Initials of the contact. */
  initials?: Maybe<Scalars['String']['output']>;
  /** Insertion of the contact. */
  insertion?: Maybe<Scalars['String']['output']>;
  /** Preferred language of the contact. */
  language?: Maybe<Language>;
  /** Last name of the contact. */
  lastName?: Maybe<Scalars['String']['output']>;
  /** Manager of this contact. */
  manager?: Maybe<Manager>;
  /** Mobile number of the contact. (E.164 format) */
  mobileNr?: Maybe<Scalars['String']['output']>;
  /** Phone number of the contact. (E.164 format) */
  phoneNr?: Maybe<Scalars['String']['output']>;
  /** Date time of when the recourse last updated. */
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** When creating invoices use this contact. */
  useForInvoicing?: Maybe<Scalars['Boolean']['output']>;
};

export type ClientContactEmailFilterComparison = {
  eq?: InputMaybe<Scalars['String']['input']>;
  like?: InputMaybe<Scalars['String']['input']>;
  neq?: InputMaybe<Scalars['String']['input']>;
  notLike?: InputMaybe<Scalars['String']['input']>;
};

export type ClientContactFilter = {
  and?: InputMaybe<Array<ClientContactFilter>>;
  companyId?: InputMaybe<ClientContactCompanyIdFilterComparison>;
  createdAt?: InputMaybe<ClientContactCreatedAtFilterComparison>;
  email?: InputMaybe<ClientContactEmailFilterComparison>;
  fullName?: InputMaybe<ClientContactFullNameFilterComparison>;
  hideLastNameInSalutation?: InputMaybe<ClientContactHideLastNameInSalutationFilterComparison>;
  id?: InputMaybe<ClientContactIdFilterComparison>;
  lastName?: InputMaybe<ClientContactLastNameFilterComparison>;
  mobileNr?: InputMaybe<ClientContactMobileNrFilterComparison>;
  or?: InputMaybe<Array<ClientContactFilter>>;
  phoneNr?: InputMaybe<ClientContactPhoneNrFilterComparison>;
  updatedAt?: InputMaybe<ClientContactUpdatedAtFilterComparison>;
};

export type ClientContactFullNameFilterComparison = {
  eq?: InputMaybe<Scalars['String']['input']>;
  like?: InputMaybe<Scalars['String']['input']>;
  neq?: InputMaybe<Scalars['String']['input']>;
  notLike?: InputMaybe<Scalars['String']['input']>;
};

export type ClientContactHideLastNameInSalutationFilterComparison = {
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ClientContactIdFilterComparison = {
  eq?: InputMaybe<Scalars['UUID']['input']>;
  in?: InputMaybe<Array<Scalars['UUID']['input']>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  neq?: InputMaybe<Scalars['UUID']['input']>;
  notIn?: InputMaybe<Array<Scalars['UUID']['input']>>;
};

export type ClientContactLastNameFilterComparison = {
  eq?: InputMaybe<Scalars['String']['input']>;
  like?: InputMaybe<Scalars['String']['input']>;
  neq?: InputMaybe<Scalars['String']['input']>;
  notLike?: InputMaybe<Scalars['String']['input']>;
};

export type ClientContactMobileNrFilterComparison = {
  eq?: InputMaybe<Scalars['String']['input']>;
  like?: InputMaybe<Scalars['String']['input']>;
  neq?: InputMaybe<Scalars['String']['input']>;
  notLike?: InputMaybe<Scalars['String']['input']>;
};

export type ClientContactPhoneNrFilterComparison = {
  eq?: InputMaybe<Scalars['String']['input']>;
  like?: InputMaybe<Scalars['String']['input']>;
  neq?: InputMaybe<Scalars['String']['input']>;
  notLike?: InputMaybe<Scalars['String']['input']>;
};

export type ClientContactSort = {
  direction: SortDirection;
  field: ClientContactSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum ClientContactSortFields {
  CompanyId = 'companyId',
  CreatedAt = 'createdAt',
  Email = 'email',
  FullName = 'fullName',
  HideLastNameInSalutation = 'hideLastNameInSalutation',
  Id = 'id',
  LastName = 'lastName',
  MobileNr = 'mobileNr',
  PhoneNr = 'phoneNr',
  UpdatedAt = 'updatedAt'
}

export type ClientContactUpdatedAtFilterComparison = {
  between?: InputMaybe<ClientContactUpdatedAtFilterComparisonBetween>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  notBetween?: InputMaybe<ClientContactUpdatedAtFilterComparisonBetween>;
};

export type ClientContactUpdatedAtFilterComparisonBetween = {
  lower: Scalars['DateTime']['input'];
  upper: Scalars['DateTime']['input'];
};

export type ClientCountAggregate = {
  __typename?: 'ClientCountAggregate';
  createdAt?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  isClient?: Maybe<Scalars['Int']['output']>;
  isDebtor?: Maybe<Scalars['Int']['output']>;
  jobId?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['Int']['output']>;
  variant?: Maybe<Scalars['Int']['output']>;
};

export type ClientCreatedAtFilterComparison = {
  between?: InputMaybe<ClientCreatedAtFilterComparisonBetween>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  notBetween?: InputMaybe<ClientCreatedAtFilterComparisonBetween>;
};

export type ClientCreatedAtFilterComparisonBetween = {
  lower: Scalars['DateTime']['input'];
  upper: Scalars['DateTime']['input'];
};

export type ClientFilter = {
  and?: InputMaybe<Array<ClientFilter>>;
  createdAt?: InputMaybe<ClientCreatedAtFilterComparison>;
  id?: InputMaybe<ClientIdFilterComparison>;
  isClient?: InputMaybe<ClientIsClientFilterComparison>;
  isDebtor?: InputMaybe<ClientIsDebtorFilterComparison>;
  jobId?: InputMaybe<ClientJobIdFilterComparison>;
  labels?: InputMaybe<ClientFilterLabelFilter>;
  name?: InputMaybe<ClientNameFilterComparison>;
  or?: InputMaybe<Array<ClientFilter>>;
  updatedAt?: InputMaybe<ClientUpdatedAtFilterComparison>;
  variant?: InputMaybe<ClientVariantFilterComparison>;
};

export type ClientFilterLabelFilter = {
  and?: InputMaybe<Array<ClientFilterLabelFilter>>;
  createdAt?: InputMaybe<LabelCreatedAtFilterComparison>;
  id?: InputMaybe<LabelIdFilterComparison>;
  name?: InputMaybe<LabelNameFilterComparison>;
  or?: InputMaybe<Array<ClientFilterLabelFilter>>;
  updatedAt?: InputMaybe<LabelUpdatedAtFilterComparison>;
};

export type ClientIdFilterComparison = {
  eq?: InputMaybe<Scalars['UUID']['input']>;
  in?: InputMaybe<Array<Scalars['UUID']['input']>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  neq?: InputMaybe<Scalars['UUID']['input']>;
  notIn?: InputMaybe<Array<Scalars['UUID']['input']>>;
};

export type ClientInfo = {
  __typename?: 'ClientInfo';
  /** Main address of the company. */
  address?: Maybe<CompanyAddress>;
  /** Date time of when the recourse is created. */
  createdAt: Scalars['DateTime']['output'];
  /** Email address of the company, used in letters. */
  email?: Maybe<Scalars['String']['output']>;
  /** Primary key of the recourse. */
  id: Scalars['UUID']['output'];
  /** Invoice address of the company. */
  invoiceAddress?: Maybe<CompanyAddress>;
  /** Is this company subjected to vat. */
  isSubjectedToVat: Scalars['Boolean']['output'];
  /** Legal name of the company. */
  legalName?: Maybe<Scalars['String']['output']>;
  /** Phone number of the company, used in letters. */
  phoneNr?: Maybe<Scalars['String']['output']>;
  /** Postal address of the company. */
  postalAddress?: Maybe<CompanyAddress>;
  /** Companies registration number, for example the kvk number in NL. */
  registrationNr?: Maybe<Scalars['String']['output']>;
  /** Companies vat number, for example the BTW number in NL. */
  taxNr?: Maybe<Scalars['String']['output']>;
  /** Date time of when the recourse last updated. */
  updatedAt: Scalars['DateTime']['output'];
};

export type ClientInfoEdge = {
  __typename?: 'ClientInfoEdge';
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor']['output'];
  /** The node containing the ClientInfo */
  node: ClientInfo;
};

export type ClientIsClientFilterComparison = {
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ClientIsDebtorFilterComparison = {
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ClientJobIdFilterComparison = {
  eq?: InputMaybe<Scalars['UUID']['input']>;
  in?: InputMaybe<Array<Scalars['UUID']['input']>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  neq?: InputMaybe<Scalars['UUID']['input']>;
  notIn?: InputMaybe<Array<Scalars['UUID']['input']>>;
};

export type ClientMaxAggregate = {
  __typename?: 'ClientMaxAggregate';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['UUID']['output']>;
  jobId?: Maybe<Scalars['UUID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  variant?: Maybe<CompanyVariant>;
};

export type ClientMinAggregate = {
  __typename?: 'ClientMinAggregate';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['UUID']['output']>;
  jobId?: Maybe<Scalars['UUID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  variant?: Maybe<CompanyVariant>;
};

export type ClientNameFilterComparison = {
  eq?: InputMaybe<Scalars['String']['input']>;
  like?: InputMaybe<Scalars['String']['input']>;
  neq?: InputMaybe<Scalars['String']['input']>;
  notLike?: InputMaybe<Scalars['String']['input']>;
};

export type ClientSort = {
  direction: SortDirection;
  field: ClientSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum ClientSortFields {
  CreatedAt = 'createdAt',
  Id = 'id',
  IsClient = 'isClient',
  IsDebtor = 'isDebtor',
  JobId = 'jobId',
  Name = 'name',
  UpdatedAt = 'updatedAt',
  Variant = 'variant'
}

export type ClientUpdatedAtFilterComparison = {
  between?: InputMaybe<ClientUpdatedAtFilterComparisonBetween>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  notBetween?: InputMaybe<ClientUpdatedAtFilterComparisonBetween>;
};

export type ClientUpdatedAtFilterComparisonBetween = {
  lower: Scalars['DateTime']['input'];
  upper: Scalars['DateTime']['input'];
};

export type ClientVariantFilterComparison = {
  eq?: InputMaybe<CompanyVariant>;
  in?: InputMaybe<Array<CompanyVariant>>;
  neq?: InputMaybe<CompanyVariant>;
  notIn?: InputMaybe<Array<CompanyVariant>>;
};

export type Company = {
  __typename?: 'Company';
  children?: Maybe<CompanyChildrenConnection>;
  /** Country code where the company is located (ISO 3166-1 alpha-2). */
  country: Scalars['String']['output'];
  /** Date time of when the recourse is created. */
  createdAt: Scalars['DateTime']['output'];
  /** Currency the company uses. */
  currency: Currency;
  /** Environment of the company. */
  env: CompanyEnv;
  /** Primary key of the recourse. */
  id: Scalars['UUID']['output'];
  /** Preferred language of the company. */
  language: Language;
  /** Name of the company. */
  name: Scalars['String']['output'];
  /** Slug of the company. */
  slug: Scalars['String']['output'];
  /** Date time of when the recourse last updated. */
  updatedAt: Scalars['DateTime']['output'];
};


export type CompanyChildrenArgs = {
  filter?: CompanyReferenceFilter;
  paging?: OffsetPaging;
  sorting?: Array<CompanyReferenceSort>;
};

export type CompanyAddress = {
  __typename?: 'CompanyAddress';
  /** City. */
  city: Scalars['String']['output'];
  /** Country (ISO 3166-1 alpha-2). */
  country: Scalars['String']['output'];
  /** Date time of when the recourse is created. */
  createdAt: Scalars['DateTime']['output'];
  /** Primary key of the recourse. */
  id: Scalars['UUID']['output'];
  /** State. */
  state?: Maybe<Scalars['String']['output']>;
  /** Street name + house number. */
  streetName: Scalars['String']['output'];
  /** Date time of when the recourse last updated. */
  updatedAt: Scalars['DateTime']['output'];
  /** Zipcode. */
  zipcode: Scalars['String']['output'];
};

export type CompanyAreaCode = {
  __typename?: 'CompanyAreaCode';
  /** Area code. */
  code: Scalars['String']['output'];
  /** ID of the company the code belongs to. */
  companyId: Scalars['UUID']['output'];
  /** Date time of when the recourse is created. */
  createdAt: Scalars['DateTime']['output'];
  /** Primary key of the recourse. */
  id: Scalars['UUID']['output'];
  /** Date time of when the recourse last updated. */
  updatedAt: Scalars['DateTime']['output'];
};

export type CompanyAreaCodeCompanyIdFilterComparison = {
  eq?: InputMaybe<Scalars['UUID']['input']>;
  in?: InputMaybe<Array<Scalars['UUID']['input']>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  neq?: InputMaybe<Scalars['UUID']['input']>;
  notIn?: InputMaybe<Array<Scalars['UUID']['input']>>;
};

export type CompanyAreaCodeCreatedAtFilterComparison = {
  between?: InputMaybe<CompanyAreaCodeCreatedAtFilterComparisonBetween>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  notBetween?: InputMaybe<CompanyAreaCodeCreatedAtFilterComparisonBetween>;
};

export type CompanyAreaCodeCreatedAtFilterComparisonBetween = {
  lower: Scalars['DateTime']['input'];
  upper: Scalars['DateTime']['input'];
};

export type CompanyAreaCodeDeleteFilter = {
  and?: InputMaybe<Array<CompanyAreaCodeDeleteFilter>>;
  companyId: CompanyAreaCodeCompanyIdFilterComparison;
  createdAt?: InputMaybe<CompanyAreaCodeCreatedAtFilterComparison>;
  id?: InputMaybe<CompanyAreaCodeIdFilterComparison>;
  or?: InputMaybe<Array<CompanyAreaCodeDeleteFilter>>;
  updatedAt?: InputMaybe<CompanyAreaCodeUpdatedAtFilterComparison>;
};

export type CompanyAreaCodeFilter = {
  and?: InputMaybe<Array<CompanyAreaCodeFilter>>;
  companyId: CompanyAreaCodeCompanyIdFilterComparison;
  createdAt?: InputMaybe<CompanyAreaCodeCreatedAtFilterComparison>;
  id?: InputMaybe<CompanyAreaCodeIdFilterComparison>;
  or?: InputMaybe<Array<CompanyAreaCodeFilter>>;
  updatedAt?: InputMaybe<CompanyAreaCodeUpdatedAtFilterComparison>;
};

export type CompanyAreaCodeIdFilterComparison = {
  eq?: InputMaybe<Scalars['UUID']['input']>;
  in?: InputMaybe<Array<Scalars['UUID']['input']>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  neq?: InputMaybe<Scalars['UUID']['input']>;
  notIn?: InputMaybe<Array<Scalars['UUID']['input']>>;
};

export type CompanyAreaCodeSort = {
  direction: SortDirection;
  field: CompanyAreaCodeSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum CompanyAreaCodeSortFields {
  CompanyId = 'companyId',
  CreatedAt = 'createdAt',
  Id = 'id',
  UpdatedAt = 'updatedAt'
}

export type CompanyAreaCodeUpdatedAtFilterComparison = {
  between?: InputMaybe<CompanyAreaCodeUpdatedAtFilterComparisonBetween>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  notBetween?: InputMaybe<CompanyAreaCodeUpdatedAtFilterComparisonBetween>;
};

export type CompanyAreaCodeUpdatedAtFilterComparisonBetween = {
  lower: Scalars['DateTime']['input'];
  upper: Scalars['DateTime']['input'];
};

export type CompanyBankAccount = {
  __typename?: 'CompanyBankAccount';
  /** ID of the company the bank accounts belongs to. */
  companyId: Scalars['UUID']['output'];
  /** Date time of when the recourse is created. */
  createdAt: Scalars['DateTime']['output'];
  /** Creator of the bank account. */
  creator?: Maybe<Creator>;
  /** Currency of the bank account. */
  currency?: Maybe<Currency>;
  /** Use this bank account if currency specifiek does not exist. */
  fallback: Scalars['Boolean']['output'];
  /** IBAN of the account. */
  iban: Scalars['String']['output'];
  /** Primary key of the recourse. */
  id: Scalars['UUID']['output'];
  /** Name of the account. */
  name?: Maybe<Scalars['String']['output']>;
  /** Date time of when the recourse last updated. */
  updatedAt: Scalars['DateTime']['output'];
};

export type CompanyBankAccountAudit = {
  __typename?: 'CompanyBankAccountAudit';
  /** Action that was done on the resource. */
  _action: AuditingAction;
  /** Actor that changed the record. */
  _actor: Actor;
  /** Date time of when the action was executed. */
  _modifiedAt: Scalars['DateTime']['output'];
  /** Primary key of the recourse. */
  _seq: Scalars['UUID']['output'];
  /** ID of the company the bank accounts belongs to. */
  companyId: Scalars['UUID']['output'];
  /** Date time of when the recourse is created. */
  createdAt: Scalars['DateTime']['output'];
  /** Creator of the bank account. */
  creator?: Maybe<Creator>;
  /** Currency of the bank account. */
  currency?: Maybe<Currency>;
  /** Use this bank account if currency specifiek does not exist. */
  fallback: Scalars['Boolean']['output'];
  /** IBAN of the account. */
  iban: Scalars['String']['output'];
  /** Primary key of the recourse. */
  id: Scalars['UUID']['output'];
  /** Name of the account. */
  name?: Maybe<Scalars['String']['output']>;
  /** Date time of when the recourse last updated. */
  updatedAt: Scalars['DateTime']['output'];
};

export type CompanyBankAccountAuditCompanyIdFilterComparison = {
  eq?: InputMaybe<Scalars['UUID']['input']>;
  in?: InputMaybe<Array<Scalars['UUID']['input']>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  neq?: InputMaybe<Scalars['UUID']['input']>;
  notIn?: InputMaybe<Array<Scalars['UUID']['input']>>;
};

export type CompanyBankAccountAuditConnection = {
  __typename?: 'CompanyBankAccountAuditConnection';
  /** Array of nodes. */
  nodes: Array<CompanyBankAccountAudit>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type CompanyBankAccountAuditCreatedAtFilterComparison = {
  between?: InputMaybe<CompanyBankAccountAuditCreatedAtFilterComparisonBetween>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  notBetween?: InputMaybe<CompanyBankAccountAuditCreatedAtFilterComparisonBetween>;
};

export type CompanyBankAccountAuditCreatedAtFilterComparisonBetween = {
  lower: Scalars['DateTime']['input'];
  upper: Scalars['DateTime']['input'];
};

export type CompanyBankAccountAuditFallbackFilterComparison = {
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CompanyBankAccountAuditFilter = {
  _modifiedAt?: InputMaybe<CompanyBankAccountAudit_ModifiedAtFilterComparison>;
  _seq?: InputMaybe<CompanyBankAccountAudit_SeqFilterComparison>;
  and?: InputMaybe<Array<CompanyBankAccountAuditFilter>>;
  companyId: CompanyBankAccountAuditCompanyIdFilterComparison;
  createdAt?: InputMaybe<CompanyBankAccountAuditCreatedAtFilterComparison>;
  fallback?: InputMaybe<CompanyBankAccountAuditFallbackFilterComparison>;
  id?: InputMaybe<CompanyBankAccountAuditIdFilterComparison>;
  name?: InputMaybe<CompanyBankAccountAuditNameFilterComparison>;
  or?: InputMaybe<Array<CompanyBankAccountAuditFilter>>;
  updatedAt?: InputMaybe<CompanyBankAccountAuditUpdatedAtFilterComparison>;
};

export type CompanyBankAccountAuditIdFilterComparison = {
  eq?: InputMaybe<Scalars['UUID']['input']>;
  in?: InputMaybe<Array<Scalars['UUID']['input']>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  neq?: InputMaybe<Scalars['UUID']['input']>;
  notIn?: InputMaybe<Array<Scalars['UUID']['input']>>;
};

export type CompanyBankAccountAuditNameFilterComparison = {
  eq?: InputMaybe<Scalars['String']['input']>;
  like?: InputMaybe<Scalars['String']['input']>;
  neq?: InputMaybe<Scalars['String']['input']>;
  notLike?: InputMaybe<Scalars['String']['input']>;
};

export type CompanyBankAccountAuditSort = {
  direction: SortDirection;
  field: CompanyBankAccountAuditSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum CompanyBankAccountAuditSortFields {
  ModifiedAt = '_modifiedAt',
  Seq = '_seq',
  CompanyId = 'companyId',
  CreatedAt = 'createdAt',
  Fallback = 'fallback',
  Id = 'id',
  Name = 'name',
  UpdatedAt = 'updatedAt'
}

export type CompanyBankAccountAuditUpdatedAtFilterComparison = {
  between?: InputMaybe<CompanyBankAccountAuditUpdatedAtFilterComparisonBetween>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  notBetween?: InputMaybe<CompanyBankAccountAuditUpdatedAtFilterComparisonBetween>;
};

export type CompanyBankAccountAuditUpdatedAtFilterComparisonBetween = {
  lower: Scalars['DateTime']['input'];
  upper: Scalars['DateTime']['input'];
};

export type CompanyBankAccountAudit_ModifiedAtFilterComparison = {
  between?: InputMaybe<CompanyBankAccountAudit_ModifiedAtFilterComparisonBetween>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  notBetween?: InputMaybe<CompanyBankAccountAudit_ModifiedAtFilterComparisonBetween>;
};

export type CompanyBankAccountAudit_ModifiedAtFilterComparisonBetween = {
  lower: Scalars['DateTime']['input'];
  upper: Scalars['DateTime']['input'];
};

export type CompanyBankAccountAudit_SeqFilterComparison = {
  eq?: InputMaybe<Scalars['UUID']['input']>;
  in?: InputMaybe<Array<Scalars['UUID']['input']>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  neq?: InputMaybe<Scalars['UUID']['input']>;
  notIn?: InputMaybe<Array<Scalars['UUID']['input']>>;
};

export type CompanyBankAccountCompanyIdFilterComparison = {
  eq?: InputMaybe<Scalars['UUID']['input']>;
  in?: InputMaybe<Array<Scalars['UUID']['input']>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  neq?: InputMaybe<Scalars['UUID']['input']>;
  notIn?: InputMaybe<Array<Scalars['UUID']['input']>>;
};

export type CompanyBankAccountConnection = {
  __typename?: 'CompanyBankAccountConnection';
  /** Array of nodes. */
  nodes: Array<CompanyBankAccount>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type CompanyBankAccountCreatedAtFilterComparison = {
  between?: InputMaybe<CompanyBankAccountCreatedAtFilterComparisonBetween>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  notBetween?: InputMaybe<CompanyBankAccountCreatedAtFilterComparisonBetween>;
};

export type CompanyBankAccountCreatedAtFilterComparisonBetween = {
  lower: Scalars['DateTime']['input'];
  upper: Scalars['DateTime']['input'];
};

export type CompanyBankAccountDeleteResponse = {
  __typename?: 'CompanyBankAccountDeleteResponse';
  /** ID of the company the bank accounts belongs to. */
  companyId?: Maybe<Scalars['UUID']['output']>;
  /** Date time of when the recourse is created. */
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  /** Currency of the bank account. */
  currency?: Maybe<Currency>;
  /** Use this bank account if currency specifiek does not exist. */
  fallback?: Maybe<Scalars['Boolean']['output']>;
  /** IBAN of the account. */
  iban?: Maybe<Scalars['String']['output']>;
  /** Primary key of the recourse. */
  id?: Maybe<Scalars['UUID']['output']>;
  /** Name of the account. */
  name?: Maybe<Scalars['String']['output']>;
  /** Date time of when the recourse last updated. */
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type CompanyBankAccountFallbackFilterComparison = {
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CompanyBankAccountFilter = {
  and?: InputMaybe<Array<CompanyBankAccountFilter>>;
  companyId: CompanyBankAccountCompanyIdFilterComparison;
  createdAt?: InputMaybe<CompanyBankAccountCreatedAtFilterComparison>;
  fallback?: InputMaybe<CompanyBankAccountFallbackFilterComparison>;
  id?: InputMaybe<CompanyBankAccountIdFilterComparison>;
  name?: InputMaybe<CompanyBankAccountNameFilterComparison>;
  or?: InputMaybe<Array<CompanyBankAccountFilter>>;
  updatedAt?: InputMaybe<CompanyBankAccountUpdatedAtFilterComparison>;
};

export type CompanyBankAccountIdFilterComparison = {
  eq?: InputMaybe<Scalars['UUID']['input']>;
  in?: InputMaybe<Array<Scalars['UUID']['input']>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  neq?: InputMaybe<Scalars['UUID']['input']>;
  notIn?: InputMaybe<Array<Scalars['UUID']['input']>>;
};

export type CompanyBankAccountNameFilterComparison = {
  eq?: InputMaybe<Scalars['String']['input']>;
  like?: InputMaybe<Scalars['String']['input']>;
  neq?: InputMaybe<Scalars['String']['input']>;
  notLike?: InputMaybe<Scalars['String']['input']>;
};

export type CompanyBankAccountSort = {
  direction: SortDirection;
  field: CompanyBankAccountSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum CompanyBankAccountSortFields {
  CompanyId = 'companyId',
  CreatedAt = 'createdAt',
  Fallback = 'fallback',
  Id = 'id',
  Name = 'name',
  UpdatedAt = 'updatedAt'
}

export type CompanyBankAccountUpdatedAtFilterComparison = {
  between?: InputMaybe<CompanyBankAccountUpdatedAtFilterComparisonBetween>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  notBetween?: InputMaybe<CompanyBankAccountUpdatedAtFilterComparisonBetween>;
};

export type CompanyBankAccountUpdatedAtFilterComparisonBetween = {
  lower: Scalars['DateTime']['input'];
  upper: Scalars['DateTime']['input'];
};

export type CompanyChildrenConnection = {
  __typename?: 'CompanyChildrenConnection';
  /** Array of nodes. */
  nodes: Array<CompanyReference>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
};

export type CompanyContact = {
  __typename?: 'CompanyContact';
  /** Company that the contact belongs to. */
  company: CompanyReference;
  /** ID of the company the contact belongs to */
  companyId: Scalars['UUID']['output'];
  /** Date time of when the recourse is created. */
  createdAt: Scalars['DateTime']['output'];
  /** Creator of the contact. */
  creator?: Maybe<Creator>;
  /** Email address of the contact */
  email?: Maybe<Scalars['String']['output']>;
  /** First name of the contact. */
  firstName?: Maybe<Scalars['String']['output']>;
  /** Full name of the contact. */
  fullName: Scalars['String']['output'];
  /** Gender of the contact. */
  gender: Gender;
  /** Hide the users last name in salutation. */
  hideLastNameInSalutation: Scalars['Boolean']['output'];
  /** Primary key of the recourse. */
  id: Scalars['UUID']['output'];
  /** Initials of the contact. */
  initials?: Maybe<Scalars['String']['output']>;
  /** Insertion of the contact. */
  insertion?: Maybe<Scalars['String']['output']>;
  /** Preferred language of the contact. */
  language: Language;
  /** Last name of the contact. */
  lastName: Scalars['String']['output'];
  /** Manager of this contact. */
  manager?: Maybe<Manager>;
  /** Mobile number of the contact. (E.164 format) */
  mobileNr?: Maybe<Scalars['String']['output']>;
  /** Phone number of the contact. (E.164 format) */
  phoneNr?: Maybe<Scalars['String']['output']>;
  /** Date time of when the recourse last updated. */
  updatedAt: Scalars['DateTime']['output'];
  /** When creating invoices use this contact. */
  useForInvoicing: Scalars['Boolean']['output'];
};

export type CompanyContactCompanyIdFilterComparison = {
  eq?: InputMaybe<Scalars['UUID']['input']>;
  in?: InputMaybe<Array<Scalars['UUID']['input']>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  neq?: InputMaybe<Scalars['UUID']['input']>;
  notIn?: InputMaybe<Array<Scalars['UUID']['input']>>;
};

export type CompanyContactConnection = {
  __typename?: 'CompanyContactConnection';
  /** Array of nodes. */
  nodes: Array<CompanyContact>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type CompanyContactCreatedAtFilterComparison = {
  between?: InputMaybe<CompanyContactCreatedAtFilterComparisonBetween>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  notBetween?: InputMaybe<CompanyContactCreatedAtFilterComparisonBetween>;
};

export type CompanyContactCreatedAtFilterComparisonBetween = {
  lower: Scalars['DateTime']['input'];
  upper: Scalars['DateTime']['input'];
};

export type CompanyContactDeleteResponse = {
  __typename?: 'CompanyContactDeleteResponse';
  /** ID of the company the contact belongs to */
  companyId?: Maybe<Scalars['UUID']['output']>;
  /** Date time of when the recourse is created. */
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  /** Email address of the contact */
  email?: Maybe<Scalars['String']['output']>;
  /** First name of the contact. */
  firstName?: Maybe<Scalars['String']['output']>;
  /** Full name of the contact. */
  fullName?: Maybe<Scalars['String']['output']>;
  /** Gender of the contact. */
  gender?: Maybe<Gender>;
  /** Hide the users last name in salutation. */
  hideLastNameInSalutation?: Maybe<Scalars['Boolean']['output']>;
  /** Primary key of the recourse. */
  id?: Maybe<Scalars['UUID']['output']>;
  /** Initials of the contact. */
  initials?: Maybe<Scalars['String']['output']>;
  /** Insertion of the contact. */
  insertion?: Maybe<Scalars['String']['output']>;
  /** Preferred language of the contact. */
  language?: Maybe<Language>;
  /** Last name of the contact. */
  lastName?: Maybe<Scalars['String']['output']>;
  /** Manager of this contact. */
  manager?: Maybe<Manager>;
  /** Mobile number of the contact. (E.164 format) */
  mobileNr?: Maybe<Scalars['String']['output']>;
  /** Phone number of the contact. (E.164 format) */
  phoneNr?: Maybe<Scalars['String']['output']>;
  /** Date time of when the recourse last updated. */
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** When creating invoices use this contact. */
  useForInvoicing?: Maybe<Scalars['Boolean']['output']>;
};

export type CompanyContactEmailFilterComparison = {
  eq?: InputMaybe<Scalars['String']['input']>;
  like?: InputMaybe<Scalars['String']['input']>;
  neq?: InputMaybe<Scalars['String']['input']>;
  notLike?: InputMaybe<Scalars['String']['input']>;
};

export type CompanyContactFilter = {
  and?: InputMaybe<Array<CompanyContactFilter>>;
  companyId?: InputMaybe<CompanyContactCompanyIdFilterComparison>;
  createdAt?: InputMaybe<CompanyContactCreatedAtFilterComparison>;
  email?: InputMaybe<CompanyContactEmailFilterComparison>;
  fullName?: InputMaybe<CompanyContactFullNameFilterComparison>;
  hideLastNameInSalutation?: InputMaybe<CompanyContactHideLastNameInSalutationFilterComparison>;
  id?: InputMaybe<CompanyContactIdFilterComparison>;
  lastName?: InputMaybe<CompanyContactLastNameFilterComparison>;
  mobileNr?: InputMaybe<CompanyContactMobileNrFilterComparison>;
  or?: InputMaybe<Array<CompanyContactFilter>>;
  phoneNr?: InputMaybe<CompanyContactPhoneNrFilterComparison>;
  updatedAt?: InputMaybe<CompanyContactUpdatedAtFilterComparison>;
};

export type CompanyContactFullNameFilterComparison = {
  eq?: InputMaybe<Scalars['String']['input']>;
  like?: InputMaybe<Scalars['String']['input']>;
  neq?: InputMaybe<Scalars['String']['input']>;
  notLike?: InputMaybe<Scalars['String']['input']>;
};

export type CompanyContactHideLastNameInSalutationFilterComparison = {
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CompanyContactIdFilterComparison = {
  eq?: InputMaybe<Scalars['UUID']['input']>;
  in?: InputMaybe<Array<Scalars['UUID']['input']>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  neq?: InputMaybe<Scalars['UUID']['input']>;
  notIn?: InputMaybe<Array<Scalars['UUID']['input']>>;
};

export type CompanyContactLastNameFilterComparison = {
  eq?: InputMaybe<Scalars['String']['input']>;
  like?: InputMaybe<Scalars['String']['input']>;
  neq?: InputMaybe<Scalars['String']['input']>;
  notLike?: InputMaybe<Scalars['String']['input']>;
};

export type CompanyContactMobileNrFilterComparison = {
  eq?: InputMaybe<Scalars['String']['input']>;
  like?: InputMaybe<Scalars['String']['input']>;
  neq?: InputMaybe<Scalars['String']['input']>;
  notLike?: InputMaybe<Scalars['String']['input']>;
};

export type CompanyContactPhoneNrFilterComparison = {
  eq?: InputMaybe<Scalars['String']['input']>;
  like?: InputMaybe<Scalars['String']['input']>;
  neq?: InputMaybe<Scalars['String']['input']>;
  notLike?: InputMaybe<Scalars['String']['input']>;
};

export type CompanyContactSort = {
  direction: SortDirection;
  field: CompanyContactSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum CompanyContactSortFields {
  CompanyId = 'companyId',
  CreatedAt = 'createdAt',
  Email = 'email',
  FullName = 'fullName',
  HideLastNameInSalutation = 'hideLastNameInSalutation',
  Id = 'id',
  LastName = 'lastName',
  MobileNr = 'mobileNr',
  PhoneNr = 'phoneNr',
  UpdatedAt = 'updatedAt'
}

export type CompanyContactUpdatedAtFilterComparison = {
  between?: InputMaybe<CompanyContactUpdatedAtFilterComparisonBetween>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  notBetween?: InputMaybe<CompanyContactUpdatedAtFilterComparisonBetween>;
};

export type CompanyContactUpdatedAtFilterComparisonBetween = {
  lower: Scalars['DateTime']['input'];
  upper: Scalars['DateTime']['input'];
};

export type CompanyContract = {
  __typename?: 'CompanyContract';
  costs: Array<CompanyContractCost>;
  /** Date time of when the recourse is created. */
  createdAt: Scalars['DateTime']['output'];
  /** Flag if the contract can still be edited. */
  editable: Scalars['Boolean']['output'];
  /** Primary key of the recourse. */
  id: Scalars['UUID']['output'];
  /** Name of the contract. */
  name: Scalars['String']['output'];
  /** The amount of weeks / months. */
  period: Scalars['Float']['output'];
  /** Type of the period. */
  periodType: CompanyContractPeriodType;
  /** Date time of when the recourse last updated. */
  updatedAt: Scalars['DateTime']['output'];
};


export type CompanyContractCostsArgs = {
  sorting?: Array<CompanyContractCostSort>;
};

export type CompanyContractCompanyIdFilterComparison = {
  eq?: InputMaybe<Scalars['UUID']['input']>;
  in?: InputMaybe<Array<Scalars['UUID']['input']>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  neq?: InputMaybe<Scalars['UUID']['input']>;
  notIn?: InputMaybe<Array<Scalars['UUID']['input']>>;
};

export type CompanyContractConnection = {
  __typename?: 'CompanyContractConnection';
  /** Array of nodes. */
  nodes: Array<CompanyContract>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type CompanyContractCost = {
  __typename?: 'CompanyContractCost';
  /** Categories this cost apply to. */
  categories: Array<CostCategoryReference>;
  /** Category of this costs. */
  category: CostCategoryReference;
  /** Id of the category the pricing of this rule should be added. */
  categoryId: Scalars['UUID']['output'];
  /** Fixed amount to always apply. */
  centAmount?: Maybe<Scalars['Float']['output']>;
  /** Id of the contract this costs apply to. */
  contractId: Scalars['UUID']['output'];
  /** Date time of when the recourse is created. */
  createdAt: Scalars['DateTime']['output'];
  /** Currency of the costs. */
  currency?: Maybe<Currency>;
  /** Primary key of the recourse. */
  id: Scalars['UUID']['output'];
  /** Minimum amount that needs to be paid when using %. */
  minimumCentAmount?: Maybe<Scalars['Float']['output']>;
  /** Fixed amount to always apply (when client zipcode is not in area codes list) */
  outerAreaCentAmount?: Maybe<Scalars['Float']['output']>;
  /** Percentage to calculate over de connected costs (when client zipcode is not in area codes list). */
  outerAreaPercentage?: Maybe<Scalars['Float']['output']>;
  /** Percentage to calculate over de connected costs. */
  percentage?: Maybe<Scalars['Float']['output']>;
  /** Date time of when the recourse last updated. */
  updatedAt: Scalars['DateTime']['output'];
};


export type CompanyContractCostCategoriesArgs = {
  sorting?: Array<CostCategoryReferenceSort>;
};

export type CompanyContractCostCreatedAtFilterComparison = {
  between?: InputMaybe<CompanyContractCostCreatedAtFilterComparisonBetween>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  notBetween?: InputMaybe<CompanyContractCostCreatedAtFilterComparisonBetween>;
};

export type CompanyContractCostCreatedAtFilterComparisonBetween = {
  lower: Scalars['DateTime']['input'];
  upper: Scalars['DateTime']['input'];
};

export type CompanyContractCostDeleteFilter = {
  and?: InputMaybe<Array<CompanyContractCostDeleteFilter>>;
  createdAt?: InputMaybe<CompanyContractCostCreatedAtFilterComparison>;
  id?: InputMaybe<CompanyContractCostIdFilterComparison>;
  or?: InputMaybe<Array<CompanyContractCostDeleteFilter>>;
  updatedAt?: InputMaybe<CompanyContractCostUpdatedAtFilterComparison>;
};

export type CompanyContractCostEdge = {
  __typename?: 'CompanyContractCostEdge';
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor']['output'];
  /** The node containing the CompanyContractCost */
  node: CompanyContractCost;
};

export type CompanyContractCostIdFilterComparison = {
  eq?: InputMaybe<Scalars['UUID']['input']>;
  in?: InputMaybe<Array<Scalars['UUID']['input']>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  neq?: InputMaybe<Scalars['UUID']['input']>;
  notIn?: InputMaybe<Array<Scalars['UUID']['input']>>;
};

export type CompanyContractCostSort = {
  direction: SortDirection;
  field: CompanyContractCostSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum CompanyContractCostSortFields {
  CreatedAt = 'createdAt',
  Id = 'id',
  UpdatedAt = 'updatedAt'
}

export type CompanyContractCostUpdatedAtFilterComparison = {
  between?: InputMaybe<CompanyContractCostUpdatedAtFilterComparisonBetween>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  notBetween?: InputMaybe<CompanyContractCostUpdatedAtFilterComparisonBetween>;
};

export type CompanyContractCostUpdatedAtFilterComparisonBetween = {
  lower: Scalars['DateTime']['input'];
  upper: Scalars['DateTime']['input'];
};

export type CompanyContractCreatedAtFilterComparison = {
  between?: InputMaybe<CompanyContractCreatedAtFilterComparisonBetween>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  notBetween?: InputMaybe<CompanyContractCreatedAtFilterComparisonBetween>;
};

export type CompanyContractCreatedAtFilterComparisonBetween = {
  lower: Scalars['DateTime']['input'];
  upper: Scalars['DateTime']['input'];
};

export type CompanyContractFilter = {
  and?: InputMaybe<Array<CompanyContractFilter>>;
  companyId?: InputMaybe<CompanyContractCompanyIdFilterComparison>;
  createdAt?: InputMaybe<CompanyContractCreatedAtFilterComparison>;
  id?: InputMaybe<CompanyContractIdFilterComparison>;
  name?: InputMaybe<CompanyContractNameFilterComparison>;
  or?: InputMaybe<Array<CompanyContractFilter>>;
  updatedAt?: InputMaybe<CompanyContractUpdatedAtFilterComparison>;
};

export type CompanyContractIdFilterComparison = {
  eq?: InputMaybe<Scalars['UUID']['input']>;
  in?: InputMaybe<Array<Scalars['UUID']['input']>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  neq?: InputMaybe<Scalars['UUID']['input']>;
  notIn?: InputMaybe<Array<Scalars['UUID']['input']>>;
};

export type CompanyContractNameFilterComparison = {
  eq?: InputMaybe<Scalars['String']['input']>;
  like?: InputMaybe<Scalars['String']['input']>;
  neq?: InputMaybe<Scalars['String']['input']>;
  notLike?: InputMaybe<Scalars['String']['input']>;
};

/** The type of the company contract period. */
export enum CompanyContractPeriodType {
  Months = 'Months',
  Weeks = 'Weeks',
  Years = 'Years'
}

export type CompanyContractSort = {
  direction: SortDirection;
  field: CompanyContractSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum CompanyContractSortFields {
  CompanyId = 'companyId',
  CreatedAt = 'createdAt',
  Id = 'id',
  Name = 'name',
  UpdatedAt = 'updatedAt'
}

export type CompanyContractUpdatedAtFilterComparison = {
  between?: InputMaybe<CompanyContractUpdatedAtFilterComparisonBetween>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  notBetween?: InputMaybe<CompanyContractUpdatedAtFilterComparisonBetween>;
};

export type CompanyContractUpdatedAtFilterComparisonBetween = {
  lower: Scalars['DateTime']['input'];
  upper: Scalars['DateTime']['input'];
};

export type CompanyCreatedAtFilterComparison = {
  between?: InputMaybe<CompanyCreatedAtFilterComparisonBetween>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  notBetween?: InputMaybe<CompanyCreatedAtFilterComparisonBetween>;
};

export type CompanyCreatedAtFilterComparisonBetween = {
  lower: Scalars['DateTime']['input'];
  upper: Scalars['DateTime']['input'];
};

/** Type of invoice to apply the debtor management fee too. */
export enum CompanyDebtorManagementInvoiceFeeType {
  AllInvoices = 'AllInvoices',
  ExpiredInvoices = 'ExpiredInvoices'
}

export type CompanyEdge = {
  __typename?: 'CompanyEdge';
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor']['output'];
  /** The node containing the Company */
  node: Company;
};

/** The env of this company. */
export enum CompanyEnv {
  Prod = 'Prod',
  Test = 'Test'
}

export type CompanyFilter = {
  and?: InputMaybe<Array<CompanyFilter>>;
  createdAt?: InputMaybe<CompanyCreatedAtFilterComparison>;
  id?: InputMaybe<CompanyIdFilterComparison>;
  name?: InputMaybe<CompanyNameFilterComparison>;
  or?: InputMaybe<Array<CompanyFilter>>;
  parentId?: InputMaybe<CompanyParentIdFilterComparison>;
  updatedAt?: InputMaybe<CompanyUpdatedAtFilterComparison>;
};

export type CompanyIdFilterComparison = {
  eq?: InputMaybe<Scalars['UUID']['input']>;
  in?: InputMaybe<Array<Scalars['UUID']['input']>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  neq?: InputMaybe<Scalars['UUID']['input']>;
  notIn?: InputMaybe<Array<Scalars['UUID']['input']>>;
};

export type CompanyInfo = {
  __typename?: 'CompanyInfo';
  /** Main address of the company. */
  address?: Maybe<CompanyAddress>;
  /** Date time of when the recourse is created. */
  createdAt: Scalars['DateTime']['output'];
  /** Email address of the company, used in letters. */
  email?: Maybe<Scalars['String']['output']>;
  /** Primary key of the recourse. */
  id: Scalars['UUID']['output'];
  /** Invoice address of the company. */
  invoiceAddress?: Maybe<CompanyAddress>;
  /** Is this company subjected to vat. */
  isSubjectedToVat: Scalars['Boolean']['output'];
  /** Legal name of the company. */
  legalName?: Maybe<Scalars['String']['output']>;
  /** Phone number of the company, used in letters. */
  phoneNr?: Maybe<Scalars['String']['output']>;
  /** Postal address of the company. */
  postalAddress?: Maybe<CompanyAddress>;
  /** Companies registration number, for example the kvk number in NL. */
  registrationNr?: Maybe<Scalars['String']['output']>;
  /** Companies vat number, for example the BTW number in NL. */
  taxNr?: Maybe<Scalars['String']['output']>;
  /** Date time of when the recourse last updated. */
  updatedAt: Scalars['DateTime']['output'];
};

export type CompanyInfoEdge = {
  __typename?: 'CompanyInfoEdge';
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor']['output'];
  /** The node containing the CompanyInfo */
  node: CompanyInfo;
};

export type CompanyIntegration = {
  __typename?: 'CompanyIntegration';
  /** Date time of when the recourse is created. */
  createdAt: Scalars['DateTime']['output'];
  /** Primary key of the recourse. */
  id: Scalars['UUID']['output'];
  /** Date this integration last ran. */
  lastRunAt?: Maybe<Scalars['DateTime']['output']>;
  /** Date the next time the integration will run. */
  nextRunAt?: Maybe<Scalars['DateTime']['output']>;
  /** Provider of this integration. */
  provider: CompanyIntegrationProvider;
  /** Settings of this integration. */
  settings?: Maybe<Scalars['JSONObject']['output']>;
  /** Status of the integration. */
  status: CompanyIntegrationStatus;
  /** Type of this integration. */
  type: CompanyIntegrationType;
  /** Date time of when the recourse last updated. */
  updatedAt: Scalars['DateTime']['output'];
};

export type CompanyIntegrationCompanyIdFilterComparison = {
  eq?: InputMaybe<Scalars['UUID']['input']>;
  in?: InputMaybe<Array<Scalars['UUID']['input']>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  neq?: InputMaybe<Scalars['UUID']['input']>;
  notIn?: InputMaybe<Array<Scalars['UUID']['input']>>;
};

export type CompanyIntegrationConnection = {
  __typename?: 'CompanyIntegrationConnection';
  /** Array of nodes. */
  nodes: Array<CompanyIntegration>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type CompanyIntegrationCreatedAtFilterComparison = {
  between?: InputMaybe<CompanyIntegrationCreatedAtFilterComparisonBetween>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  notBetween?: InputMaybe<CompanyIntegrationCreatedAtFilterComparisonBetween>;
};

export type CompanyIntegrationCreatedAtFilterComparisonBetween = {
  lower: Scalars['DateTime']['input'];
  upper: Scalars['DateTime']['input'];
};

export type CompanyIntegrationDeleteResponse = {
  __typename?: 'CompanyIntegrationDeleteResponse';
  /** Date time of when the recourse is created. */
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  /** Primary key of the recourse. */
  id?: Maybe<Scalars['UUID']['output']>;
  /** Date this integration last ran. */
  lastRunAt?: Maybe<Scalars['DateTime']['output']>;
  /** Date the next time the integration will run. */
  nextRunAt?: Maybe<Scalars['DateTime']['output']>;
  /** Provider of this integration. */
  provider?: Maybe<CompanyIntegrationProvider>;
  /** Settings of this integration. */
  settings?: Maybe<Scalars['JSONObject']['output']>;
  /** Status of the integration. */
  status?: Maybe<CompanyIntegrationStatus>;
  /** Type of this integration. */
  type?: Maybe<CompanyIntegrationType>;
  /** Date time of when the recourse last updated. */
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type CompanyIntegrationFilter = {
  and?: InputMaybe<Array<CompanyIntegrationFilter>>;
  companyId: CompanyIntegrationCompanyIdFilterComparison;
  createdAt?: InputMaybe<CompanyIntegrationCreatedAtFilterComparison>;
  id?: InputMaybe<CompanyIntegrationIdFilterComparison>;
  or?: InputMaybe<Array<CompanyIntegrationFilter>>;
  updatedAt?: InputMaybe<CompanyIntegrationUpdatedAtFilterComparison>;
};

export type CompanyIntegrationIdFilterComparison = {
  eq?: InputMaybe<Scalars['UUID']['input']>;
  in?: InputMaybe<Array<Scalars['UUID']['input']>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  neq?: InputMaybe<Scalars['UUID']['input']>;
  notIn?: InputMaybe<Array<Scalars['UUID']['input']>>;
};

/** Provider of a company integration. */
export enum CompanyIntegrationProvider {
  Afas = 'Afas',
  Billit = 'Billit',
  Carfac = 'Carfac',
  DeFactuur = 'DeFactuur',
  Eboekhouden = 'Eboekhouden',
  ExactOnline = 'ExactOnline',
  ExactOnlineBe = 'ExactOnlineBe',
  FactuurSturen = 'FactuurSturen',
  Legalsense = 'Legalsense',
  Moneybird = 'Moneybird',
  Nordigen = 'Nordigen',
  Snelstart = 'Snelstart',
  Sportlink = 'Sportlink',
  Straetus = 'Straetus',
  Teamleader = 'Teamleader',
  Twinfield = 'Twinfield',
  WeFact = 'WeFact',
  WiverSoft = 'WiverSoft',
  Yuki = 'Yuki'
}

export type CompanyIntegrationSort = {
  direction: SortDirection;
  field: CompanyIntegrationSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum CompanyIntegrationSortFields {
  CompanyId = 'companyId',
  CreatedAt = 'createdAt',
  Id = 'id',
  UpdatedAt = 'updatedAt'
}

/** Status of a company integration. */
export enum CompanyIntegrationStatus {
  Authorising = 'Authorising',
  Deleted = 'Deleted',
  Disabled = 'Disabled',
  Enabled = 'Enabled',
  Expired = 'Expired',
  SelectAccount = 'SelectAccount'
}

/** Type of a company integration. */
export enum CompanyIntegrationType {
  ImportDossiersEmail = 'ImportDossiersEmail',
  SyncBankTransactions = 'SyncBankTransactions',
  SyncCollection = 'SyncCollection',
  SyncDebtorManagement = 'SyncDebtorManagement'
}

export type CompanyIntegrationUpdatedAtFilterComparison = {
  between?: InputMaybe<CompanyIntegrationUpdatedAtFilterComparisonBetween>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  notBetween?: InputMaybe<CompanyIntegrationUpdatedAtFilterComparisonBetween>;
};

export type CompanyIntegrationUpdatedAtFilterComparisonBetween = {
  lower: Scalars['DateTime']['input'];
  upper: Scalars['DateTime']['input'];
};

export type CompanyNameFilterComparison = {
  eq?: InputMaybe<Scalars['String']['input']>;
  like?: InputMaybe<Scalars['String']['input']>;
  neq?: InputMaybe<Scalars['String']['input']>;
  notLike?: InputMaybe<Scalars['String']['input']>;
};

export type CompanyOffsetConnection = {
  __typename?: 'CompanyOffsetConnection';
  /** Array of nodes. */
  nodes: Array<Company>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type CompanyParentIdFilterComparison = {
  eq?: InputMaybe<Scalars['UUID']['input']>;
  in?: InputMaybe<Array<Scalars['UUID']['input']>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  neq?: InputMaybe<Scalars['UUID']['input']>;
  notIn?: InputMaybe<Array<Scalars['UUID']['input']>>;
};

export type CompanyReference = {
  __typename?: 'CompanyReference';
  /** Primary key of the recourse. */
  id: Scalars['UUID']['output'];
  /** Name of the company. */
  name: Scalars['String']['output'];
  /** Slug of the company. */
  slug: Scalars['String']['output'];
  /** Company type, "master", "franchisee" or "client". */
  type: Scalars['String']['output'];
};

export type CompanyReferenceFilter = {
  and?: InputMaybe<Array<CompanyReferenceFilter>>;
  id?: InputMaybe<CompanyReferenceIdFilterComparison>;
  name?: InputMaybe<CompanyReferenceNameFilterComparison>;
  or?: InputMaybe<Array<CompanyReferenceFilter>>;
};

export type CompanyReferenceIdFilterComparison = {
  eq?: InputMaybe<Scalars['UUID']['input']>;
  in?: InputMaybe<Array<Scalars['UUID']['input']>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  neq?: InputMaybe<Scalars['UUID']['input']>;
  notIn?: InputMaybe<Array<Scalars['UUID']['input']>>;
};

export type CompanyReferenceNameFilterComparison = {
  eq?: InputMaybe<Scalars['String']['input']>;
  like?: InputMaybe<Scalars['String']['input']>;
  neq?: InputMaybe<Scalars['String']['input']>;
  notLike?: InputMaybe<Scalars['String']['input']>;
};

export type CompanyReferenceSort = {
  direction: SortDirection;
  field: CompanyReferenceSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum CompanyReferenceSortFields {
  Id = 'id',
  Name = 'name'
}

export type CompanySettings = {
  __typename?: 'CompanySettings';
  /** Workflow that is used when importing collection dossiers and this client is the debtor. */
  collectionDebtorWorkflow?: Maybe<WorkflowReference>;
  /** ID of the workflow that is used when importing collection dossiers and this client is the debtor. */
  collectionDebtorWorkflowId?: Maybe<Scalars['UUID']['output']>;
  /** Workflow that is used when importing collection dossiers for this client. */
  collectionWorkflow?: Maybe<WorkflowReference>;
  /** ID of the workflow that is used when importing collection dossiers for this client. */
  collectionWorkflowId?: Maybe<Scalars['UUID']['output']>;
  /** Date time of when the recourse is created. */
  createdAt: Scalars['DateTime']['output'];
  /** When enabled invoice lines generated towards client in debtor management dossier will be billed when billing contract */
  debtorManagementBillWithContract?: Maybe<Scalars['Boolean']['output']>;
  /** File that is used as logo in debtor management letters for this client. */
  debtorManagementCompanyLogo?: Maybe<FileReference>;
  /** ID of the file to use as logo for debtor management. */
  debtorManagementCompanyLogoId?: Maybe<Scalars['UUID']['output']>;
  /** Workflow that is used when importing debtor management dossiers and this client is the debtor. */
  debtorManagementDebtorWorkflow?: Maybe<WorkflowReference>;
  /** ID of the workflow that is used when importing debtor management dossiers and this client is the debtor. */
  debtorManagementDebtorWorkflowId?: Maybe<Scalars['UUID']['output']>;
  /** File that is used as logo in debtor management emails for this client. */
  debtorManagementEmailLogo?: Maybe<FileReference>;
  /** ID of the file to use as logo for debtor management emails. */
  debtorManagementEmailLogoId?: Maybe<Scalars['UUID']['output']>;
  /** Signature to use in debtor management emails. */
  debtorManagementEmailSignature?: Maybe<Scalars['String']['output']>;
  /** Custom email address to use when sending emails in debtor management, setting this will enable friendly mode. */
  debtorManagementFromEmail?: Maybe<Scalars['String']['output']>;
  /** Category of this costs. */
  debtorManagementInvoiceFeeCategory?: Maybe<CostCategoryReference>;
  /** ID of the cost category for the fee. */
  debtorManagementInvoiceFeeCategoryId?: Maybe<Scalars['UUID']['output']>;
  /** Amount in cents of the fee. */
  debtorManagementInvoiceFeeCentAmount?: Maybe<Scalars['Float']['output']>;
  /** Currency of the invoice fee. */
  debtorManagementInvoiceFeeCurrency?: Maybe<Currency>;
  /** Should a fee be applied for each imported dossier invoice.. */
  debtorManagementInvoiceFeeEnabled?: Maybe<Scalars['Boolean']['output']>;
  /** Type of dossier invoices to apply the fee on. */
  debtorManagementInvoiceFeeType?: Maybe<CompanyDebtorManagementInvoiceFeeType>;
  /** When enabled the client info will be used when sending letters and from email when sending emails in debtor management. */
  debtorManagementUseClientInfo?: Maybe<Scalars['Boolean']['output']>;
  /** Workflow that is used when importing debtor management dossiers for this client. */
  debtorManagementWorkflow?: Maybe<WorkflowReference>;
  /** ID of the workflow that is used when importing debtor management dossiers for this client. */
  debtorManagementWorkflowId?: Maybe<Scalars['UUID']['output']>;
  /** Category of dossier interest. */
  dossierInterestCategory?: Maybe<CostCategoryReference>;
  /** ID of the category to use for "interestCategory", goes together with "dossierInterestForContractor". */
  dossierInterestCategoryId?: Maybe<Scalars['String']['output']>;
  /** When enabled, creating dossiers for this client will default have "interestForContractor" on. */
  dossierInterestForContractor?: Maybe<Scalars['Boolean']['output']>;
  /** When enabled, creating dossiers for this client will default have "interestType" set to "CLIENT". */
  dossierInterestTypeClient?: Maybe<Scalars['Boolean']['output']>;
  /** When enabled, creating dossiers for this client will default have "payoutOnComplete" on. */
  dossierPayoutOnComplete?: Maybe<Scalars['Boolean']['output']>;
  /** Primary key of the recourse. */
  id: Scalars['UUID']['output'];
  /** Workflow that is used for invoices created by contracts. */
  invoiceContractWorkflow?: Maybe<WorkflowReference>;
  /** ID of the workflow to use for invoices generated by contracts. */
  invoiceContractWorkflowId?: Maybe<Scalars['UUID']['output']>;
  /** Workflow that is used for invoices created by invoicing. */
  invoiceInvoicingWorkflow?: Maybe<WorkflowReference>;
  /** ID of the workflow to use for invoices generated by the invoicing process. */
  invoiceInvoicingWorkflowId?: Maybe<Scalars['UUID']['output']>;
  /** Workflow that is used for manual created invoices. */
  invoiceManualWorkflow?: Maybe<WorkflowReference>;
  /** ID of the workflow to use for manual created invoices. */
  invoiceManualWorkflowId?: Maybe<Scalars['UUID']['output']>;
  /** Fallback payment term when importing could not determine end invoice end date. */
  paymentTerm?: Maybe<Scalars['Float']['output']>;
  /** Fallback payment term type when importing could not determine end invoice end date. */
  paymentTermType?: Maybe<InvoicePaymentTerm>;
  /** Date time of when the recourse last updated. */
  updatedAt: Scalars['DateTime']['output'];
};

export type CompanySettingsEdge = {
  __typename?: 'CompanySettingsEdge';
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor']['output'];
  /** The node containing the CompanySettings */
  node: CompanySettings;
};

export type CompanySort = {
  direction: SortDirection;
  field: CompanySortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum CompanySortFields {
  CreatedAt = 'createdAt',
  Id = 'id',
  Name = 'name',
  ParentId = 'parentId',
  UpdatedAt = 'updatedAt'
}

/** The type of this company. */
export enum CompanyType {
  Client = 'Client',
  Franchisee = 'Franchisee',
  International = 'International',
  Master = 'Master'
}

export type CompanyUpdatedAtFilterComparison = {
  between?: InputMaybe<CompanyUpdatedAtFilterComparisonBetween>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  notBetween?: InputMaybe<CompanyUpdatedAtFilterComparisonBetween>;
};

export type CompanyUpdatedAtFilterComparisonBetween = {
  lower: Scalars['DateTime']['input'];
  upper: Scalars['DateTime']['input'];
};

/** Possible variants of the company. */
export enum CompanyVariant {
  Company = 'Company',
  Person = 'Person'
}

export type Contract = {
  __typename?: 'Contract';
  /** Creator of the contract. */
  company: CompanyReference;
  /** ID of the owner company. */
  companyId: Scalars['UUID']['output'];
  /** The contract. */
  contract: CompanyContract;
  /** ID of the contract. */
  contractId: Scalars['UUID']['output'];
  /** Date time of when the recourse is created. */
  createdAt: Scalars['DateTime']['output'];
  /** Debtor of the contract. */
  debtor: CompanyReference;
  /** ID of the debtor company. */
  debtorId: Scalars['UUID']['output'];
  /** End date of the contract. */
  endDate: Scalars['DateTime']['output'];
  /** Primary key of the recourse. */
  id: Scalars['UUID']['output'];
  /** Date the next invoice will be generated. */
  nextInvoiceDate?: Maybe<Scalars['DateTime']['output']>;
  /** Start date of the contract. */
  startDate: Scalars['DateTime']['output'];
  /** Date time of when the recourse last updated. */
  updatedAt: Scalars['DateTime']['output'];
};

export type ContractAggregateFilter = {
  and?: InputMaybe<Array<ContractAggregateFilter>>;
  companyId?: InputMaybe<ContractCompanyIdFilterComparison>;
  contractId?: InputMaybe<ContractContractIdFilterComparison>;
  createdAt?: InputMaybe<ContractCreatedAtFilterComparison>;
  debtorId?: InputMaybe<ContractDebtorIdFilterComparison>;
  endDate?: InputMaybe<ContractEndDateFilterComparison>;
  id?: InputMaybe<ContractIdFilterComparison>;
  nextInvoiceDate?: InputMaybe<ContractNextInvoiceDateFilterComparison>;
  or?: InputMaybe<Array<ContractAggregateFilter>>;
  startDate?: InputMaybe<ContractStartDateFilterComparison>;
  updatedAt?: InputMaybe<ContractUpdatedAtFilterComparison>;
};

export type ContractAggregateGroupBy = {
  __typename?: 'ContractAggregateGroupBy';
  companyId?: Maybe<Scalars['UUID']['output']>;
  contractId?: Maybe<Scalars['UUID']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  debtorId?: Maybe<Scalars['UUID']['output']>;
  endDate?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['UUID']['output']>;
  nextInvoiceDate?: Maybe<Scalars['DateTime']['output']>;
  startDate?: Maybe<Scalars['DateTime']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};


export type ContractAggregateGroupByCreatedAtArgs = {
  by?: GroupBy;
};


export type ContractAggregateGroupByEndDateArgs = {
  by?: GroupBy;
};


export type ContractAggregateGroupByNextInvoiceDateArgs = {
  by?: GroupBy;
};


export type ContractAggregateGroupByStartDateArgs = {
  by?: GroupBy;
};


export type ContractAggregateGroupByUpdatedAtArgs = {
  by?: GroupBy;
};

export type ContractAggregateResponse = {
  __typename?: 'ContractAggregateResponse';
  count?: Maybe<ContractCountAggregate>;
  groupBy?: Maybe<ContractAggregateGroupBy>;
  max?: Maybe<ContractMaxAggregate>;
  min?: Maybe<ContractMinAggregate>;
};

export type ContractCompanyIdFilterComparison = {
  eq?: InputMaybe<Scalars['UUID']['input']>;
  in?: InputMaybe<Array<Scalars['UUID']['input']>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  neq?: InputMaybe<Scalars['UUID']['input']>;
  notIn?: InputMaybe<Array<Scalars['UUID']['input']>>;
};

export type ContractConnection = {
  __typename?: 'ContractConnection';
  /** Array of nodes. */
  nodes: Array<Contract>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type ContractContractIdFilterComparison = {
  eq?: InputMaybe<Scalars['UUID']['input']>;
  in?: InputMaybe<Array<Scalars['UUID']['input']>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  neq?: InputMaybe<Scalars['UUID']['input']>;
  notIn?: InputMaybe<Array<Scalars['UUID']['input']>>;
};

export type ContractCountAggregate = {
  __typename?: 'ContractCountAggregate';
  companyId?: Maybe<Scalars['Int']['output']>;
  contractId?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['Int']['output']>;
  debtorId?: Maybe<Scalars['Int']['output']>;
  endDate?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  nextInvoiceDate?: Maybe<Scalars['Int']['output']>;
  startDate?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['Int']['output']>;
};

export type ContractCreatedAtFilterComparison = {
  between?: InputMaybe<ContractCreatedAtFilterComparisonBetween>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  notBetween?: InputMaybe<ContractCreatedAtFilterComparisonBetween>;
};

export type ContractCreatedAtFilterComparisonBetween = {
  lower: Scalars['DateTime']['input'];
  upper: Scalars['DateTime']['input'];
};

export type ContractDebtorIdFilterComparison = {
  eq?: InputMaybe<Scalars['UUID']['input']>;
  in?: InputMaybe<Array<Scalars['UUID']['input']>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  neq?: InputMaybe<Scalars['UUID']['input']>;
  notIn?: InputMaybe<Array<Scalars['UUID']['input']>>;
};

export type ContractDeleteResponse = {
  __typename?: 'ContractDeleteResponse';
  /** ID of the owner company. */
  companyId?: Maybe<Scalars['UUID']['output']>;
  /** ID of the contract. */
  contractId?: Maybe<Scalars['UUID']['output']>;
  /** Date time of when the recourse is created. */
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  /** ID of the debtor company. */
  debtorId?: Maybe<Scalars['UUID']['output']>;
  /** End date of the contract. */
  endDate?: Maybe<Scalars['DateTime']['output']>;
  /** Primary key of the recourse. */
  id?: Maybe<Scalars['UUID']['output']>;
  /** Date the next invoice will be generated. */
  nextInvoiceDate?: Maybe<Scalars['DateTime']['output']>;
  /** Start date of the contract. */
  startDate?: Maybe<Scalars['DateTime']['output']>;
  /** Date time of when the recourse last updated. */
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type ContractEndDateFilterComparison = {
  between?: InputMaybe<ContractEndDateFilterComparisonBetween>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  notBetween?: InputMaybe<ContractEndDateFilterComparisonBetween>;
};

export type ContractEndDateFilterComparisonBetween = {
  lower: Scalars['DateTime']['input'];
  upper: Scalars['DateTime']['input'];
};

export type ContractFilter = {
  and?: InputMaybe<Array<ContractFilter>>;
  companyId?: InputMaybe<ContractCompanyIdFilterComparison>;
  contractId?: InputMaybe<ContractContractIdFilterComparison>;
  createdAt?: InputMaybe<ContractCreatedAtFilterComparison>;
  debtorId?: InputMaybe<ContractDebtorIdFilterComparison>;
  endDate?: InputMaybe<ContractEndDateFilterComparison>;
  id?: InputMaybe<ContractIdFilterComparison>;
  nextInvoiceDate?: InputMaybe<ContractNextInvoiceDateFilterComparison>;
  or?: InputMaybe<Array<ContractFilter>>;
  startDate?: InputMaybe<ContractStartDateFilterComparison>;
  updatedAt?: InputMaybe<ContractUpdatedAtFilterComparison>;
};

export type ContractIdFilterComparison = {
  eq?: InputMaybe<Scalars['UUID']['input']>;
  in?: InputMaybe<Array<Scalars['UUID']['input']>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  neq?: InputMaybe<Scalars['UUID']['input']>;
  notIn?: InputMaybe<Array<Scalars['UUID']['input']>>;
};

export type ContractMaxAggregate = {
  __typename?: 'ContractMaxAggregate';
  companyId?: Maybe<Scalars['UUID']['output']>;
  contractId?: Maybe<Scalars['UUID']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  debtorId?: Maybe<Scalars['UUID']['output']>;
  endDate?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['UUID']['output']>;
  nextInvoiceDate?: Maybe<Scalars['DateTime']['output']>;
  startDate?: Maybe<Scalars['DateTime']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type ContractMinAggregate = {
  __typename?: 'ContractMinAggregate';
  companyId?: Maybe<Scalars['UUID']['output']>;
  contractId?: Maybe<Scalars['UUID']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  debtorId?: Maybe<Scalars['UUID']['output']>;
  endDate?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['UUID']['output']>;
  nextInvoiceDate?: Maybe<Scalars['DateTime']['output']>;
  startDate?: Maybe<Scalars['DateTime']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type ContractNextInvoiceDateFilterComparison = {
  between?: InputMaybe<ContractNextInvoiceDateFilterComparisonBetween>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  notBetween?: InputMaybe<ContractNextInvoiceDateFilterComparisonBetween>;
};

export type ContractNextInvoiceDateFilterComparisonBetween = {
  lower: Scalars['DateTime']['input'];
  upper: Scalars['DateTime']['input'];
};

export type ContractSort = {
  direction: SortDirection;
  field: ContractSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum ContractSortFields {
  CompanyId = 'companyId',
  ContractId = 'contractId',
  CreatedAt = 'createdAt',
  DebtorId = 'debtorId',
  EndDate = 'endDate',
  Id = 'id',
  NextInvoiceDate = 'nextInvoiceDate',
  StartDate = 'startDate',
  UpdatedAt = 'updatedAt'
}

export type ContractStartDateFilterComparison = {
  between?: InputMaybe<ContractStartDateFilterComparisonBetween>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  notBetween?: InputMaybe<ContractStartDateFilterComparisonBetween>;
};

export type ContractStartDateFilterComparisonBetween = {
  lower: Scalars['DateTime']['input'];
  upper: Scalars['DateTime']['input'];
};

export type ContractUpdatedAtFilterComparison = {
  between?: InputMaybe<ContractUpdatedAtFilterComparisonBetween>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  notBetween?: InputMaybe<ContractUpdatedAtFilterComparisonBetween>;
};

export type ContractUpdatedAtFilterComparisonBetween = {
  lower: Scalars['DateTime']['input'];
  upper: Scalars['DateTime']['input'];
};

export type ConvertDossierToCollection = {
  /** ID of the dossier to update. */
  id: Scalars['UUID']['input'];
};

export type CostCategory = {
  __typename?: 'CostCategory';
  /** Should vat be applied to this cost category. */
  applyVat: Scalars['Boolean']['output'];
  /** Company that created this cost category. */
  company: CompanyReference;
  /** ID of the company that this cost category belongs to. */
  companyId: Scalars['UUID']['output'];
  /** Date time of when the recourse is created. */
  createdAt: Scalars['DateTime']['output'];
  /** Primary key of the recourse. */
  id: Scalars['UUID']['output'];
  /** Name of the cost category. */
  name: Scalars['String']['output'];
  /** Type of the cost category, determines who can use it. */
  type: CostCategoryType;
  /** Date time of when the recourse last updated. */
  updatedAt: Scalars['DateTime']['output'];
};

export type CostCategoryCompanyIdFilterComparison = {
  eq?: InputMaybe<Scalars['UUID']['input']>;
  in?: InputMaybe<Array<Scalars['UUID']['input']>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  neq?: InputMaybe<Scalars['UUID']['input']>;
  notIn?: InputMaybe<Array<Scalars['UUID']['input']>>;
};

export type CostCategoryConnection = {
  __typename?: 'CostCategoryConnection';
  /** Array of nodes. */
  nodes: Array<CostCategory>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type CostCategoryCreatedAtFilterComparison = {
  between?: InputMaybe<CostCategoryCreatedAtFilterComparisonBetween>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  notBetween?: InputMaybe<CostCategoryCreatedAtFilterComparisonBetween>;
};

export type CostCategoryCreatedAtFilterComparisonBetween = {
  lower: Scalars['DateTime']['input'];
  upper: Scalars['DateTime']['input'];
};

export type CostCategoryDeleteResponse = {
  __typename?: 'CostCategoryDeleteResponse';
  /** Should vat be applied to this cost category. */
  applyVat?: Maybe<Scalars['Boolean']['output']>;
  /** ID of the company that this cost category belongs to. */
  companyId?: Maybe<Scalars['UUID']['output']>;
  /** Date time of when the recourse is created. */
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  /** Primary key of the recourse. */
  id?: Maybe<Scalars['UUID']['output']>;
  /** Name of the cost category. */
  name?: Maybe<Scalars['String']['output']>;
  /** Type of the cost category, determines who can use it. */
  type?: Maybe<CostCategoryType>;
  /** Date time of when the recourse last updated. */
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type CostCategoryFilter = {
  and?: InputMaybe<Array<CostCategoryFilter>>;
  applyVat?: InputMaybe<BooleanFieldComparison>;
  companyId?: InputMaybe<CostCategoryCompanyIdFilterComparison>;
  createdAt?: InputMaybe<CostCategoryCreatedAtFilterComparison>;
  id?: InputMaybe<CostCategoryIdFilterComparison>;
  name?: InputMaybe<CostCategoryNameFilterComparison>;
  or?: InputMaybe<Array<CostCategoryFilter>>;
  type?: InputMaybe<CostCategoryTypeFilterComparison>;
  updatedAt?: InputMaybe<CostCategoryUpdatedAtFilterComparison>;
};

export type CostCategoryIdFilterComparison = {
  eq?: InputMaybe<Scalars['UUID']['input']>;
  in?: InputMaybe<Array<Scalars['UUID']['input']>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  neq?: InputMaybe<Scalars['UUID']['input']>;
  notIn?: InputMaybe<Array<Scalars['UUID']['input']>>;
};

export type CostCategoryNameFilterComparison = {
  eq?: InputMaybe<Scalars['String']['input']>;
  like?: InputMaybe<Scalars['String']['input']>;
  neq?: InputMaybe<Scalars['String']['input']>;
  notLike?: InputMaybe<Scalars['String']['input']>;
};

export type CostCategoryReference = {
  __typename?: 'CostCategoryReference';
  /** Should vat be applied to this cost category. */
  applyVat: Scalars['Boolean']['output'];
  /** Primary key of the recourse. */
  id: Scalars['UUID']['output'];
  /** Name of the cost category. */
  name: Scalars['String']['output'];
  /** Type of the cost category, determines who can use it. */
  type: CostCategoryType;
};

export type CostCategoryReferenceSort = {
  direction: SortDirection;
  field: CostCategoryReferenceSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum CostCategoryReferenceSortFields {
  Name = 'name'
}

export type CostCategorySort = {
  direction: SortDirection;
  field: CostCategorySortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum CostCategorySortFields {
  ApplyVat = 'applyVat',
  CompanyId = 'companyId',
  CreatedAt = 'createdAt',
  Id = 'id',
  Name = 'name',
  Type = 'type',
  UpdatedAt = 'updatedAt'
}

/** The type of an cost category. */
export enum CostCategoryType {
  Franchisee = 'Franchisee',
  Master = 'Master'
}

export type CostCategoryTypeFilterComparison = {
  eq?: InputMaybe<CostCategoryType>;
  in?: InputMaybe<Array<CostCategoryType>>;
  neq?: InputMaybe<CostCategoryType>;
  notIn?: InputMaybe<Array<CostCategoryType>>;
};

export type CostCategoryUpdatedAtFilterComparison = {
  between?: InputMaybe<CostCategoryUpdatedAtFilterComparisonBetween>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  notBetween?: InputMaybe<CostCategoryUpdatedAtFilterComparisonBetween>;
};

export type CostCategoryUpdatedAtFilterComparisonBetween = {
  lower: Scalars['DateTime']['input'];
  upper: Scalars['DateTime']['input'];
};

export type CreateBusinessActivity = {
  /** The name of the business activity. */
  name: Scalars['String']['input'];
  /** The values of the business activity. */
  values: Array<CreateBusinessActivityValue>;
};

export type CreateBusinessActivityValue = {
  language: Language;
  value: Scalars['String']['input'];
};

export type CreateClient = {
  /** ID of the linked business activity. */
  businessActivityId?: InputMaybe<Scalars['UUID']['input']>;
  /** Country code where the company is located (ISO 3166-1 alpha-2). */
  country: Scalars['String']['input'];
  /** Preferred language of the company. */
  language?: Language;
  /** Name of the company. */
  name: Scalars['String']['input'];
  /** The variant of the company. */
  variant?: CompanyVariant;
};

export type CreateClientContact = {
  /** ID of the company to add the contact to. */
  companyId: Scalars['UUID']['input'];
  /** Email address of the contact */
  email?: InputMaybe<Scalars['String']['input']>;
  /** First name of the contact. */
  firstName?: InputMaybe<Scalars['String']['input']>;
  /** Gender of the contact. */
  gender?: Gender;
  /** Hide the users last name in salutation. */
  hideLastNameInSalutation?: Scalars['Boolean']['input'];
  /** Initials of the contact. */
  initials?: InputMaybe<Scalars['String']['input']>;
  /** Insertion of the contact. */
  insertion?: InputMaybe<Scalars['String']['input']>;
  /** Preferred language of the contact. */
  language?: Language;
  /** Last name of the contact. */
  lastName: Scalars['String']['input'];
  /** Mobile number of the contact. (E.164 format) */
  mobileNr?: InputMaybe<Scalars['String']['input']>;
  /** Phone number of the contact. (E.164 format) */
  phoneNr?: InputMaybe<Scalars['String']['input']>;
  /** When creating invoices use this contact. */
  useForInvoicing?: Scalars['Boolean']['input'];
};

export type CreateCompanyAreaCode = {
  /** Area code. */
  code: Scalars['String']['input'];
  /** ID of the company the code belongs to. */
  companyId: Scalars['UUID']['input'];
};

export type CreateCompanyBankAccount = {
  /** ID of the company the code belongs to. */
  companyId: Scalars['UUID']['input'];
  /** Currency of the bank account. */
  currency?: InputMaybe<Currency>;
  /** Use this bank account if currency specifiek does not exist. */
  fallback?: Scalars['Boolean']['input'];
  /** IBAN of the account. */
  iban: Scalars['String']['input'];
  /** Name of the account. */
  name?: InputMaybe<Scalars['String']['input']>;
};

export type CreateCompanyContact = {
  /** ID of the company to add the contact to. */
  companyId: Scalars['UUID']['input'];
  /** Email address of the contact */
  email?: InputMaybe<Scalars['String']['input']>;
  /** First name of the contact. */
  firstName?: InputMaybe<Scalars['String']['input']>;
  /** Gender of the contact. */
  gender?: Gender;
  /** Hide the users last name in salutation. */
  hideLastNameInSalutation?: Scalars['Boolean']['input'];
  /** Initials of the contact. */
  initials?: InputMaybe<Scalars['String']['input']>;
  /** Insertion of the contact. */
  insertion?: InputMaybe<Scalars['String']['input']>;
  /** Preferred language of the contact. */
  language?: Language;
  /** Last name of the contact. */
  lastName: Scalars['String']['input'];
  /** Mobile number of the contact. (E.164 format) */
  mobileNr?: InputMaybe<Scalars['String']['input']>;
  /** Phone number of the contact. (E.164 format) */
  phoneNr?: InputMaybe<Scalars['String']['input']>;
  /** When creating invoices use this contact. */
  useForInvoicing?: Scalars['Boolean']['input'];
};

export type CreateCompanyContract = {
  /** Name of the contract. */
  name: Scalars['String']['input'];
  /** The amount of weeks / months. */
  period: Scalars['Float']['input'];
  /** Type of the period. */
  periodType: CompanyContractPeriodType;
};

export type CreateCompanyContractCost = {
  /** Categories to add to costs. */
  categories?: InputMaybe<Array<Scalars['UUID']['input']>>;
  /** Id of the category the pricing of this rule should be added. */
  categoryId: Scalars['UUID']['input'];
  /** Fixed amount to always apply. */
  centAmount?: InputMaybe<Scalars['Float']['input']>;
  /** Id of the contract this costs apply to. */
  contractId: Scalars['UUID']['input'];
  /** Currency of the costs. */
  currency?: InputMaybe<Currency>;
  /** Minimum amount that needs to be paid when using %. */
  minimumCentAmount?: InputMaybe<Scalars['Float']['input']>;
  /** Fixed amount to always apply (when client zipcode is not in area codes list) */
  outerAreaCentAmount?: InputMaybe<Scalars['Float']['input']>;
  /** Percentage to calculate over de connected costs (when client zipcode is not in area codes list). */
  outerAreaPercentage?: InputMaybe<Scalars['Float']['input']>;
  /** Percentage to calculate over de connected costs. */
  percentage?: InputMaybe<Scalars['Float']['input']>;
};

export type CreateCompanyIntegration = {
  /** ID of the company to create the integration for. */
  companyId: Scalars['UUID']['input'];
  /** Provider to integration. */
  provider: CompanyIntegrationProvider;
  /** URL to redirect the user back to when integration is authorised. */
  redirectUrl?: InputMaybe<Scalars['String']['input']>;
  /** Settings for the integration. */
  settings?: InputMaybe<CreateCompanyIntegrationSettings>;
  /** Type of the integration. */
  type: CompanyIntegrationType;
};

export type CreateCompanyIntegrationSettings = {
  /** Type of email import (only used when type is `IMPORT_DOSSIERS_EMAIL`). */
  importType?: InputMaybe<Scalars['String']['input']>;
  /** Whitelist of email addresses (only used when type is `IMPORT_DOSSIERS_EMAIL`). */
  whitelist?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type CreateContract = {
  /** ID of the contract. */
  contractId: Scalars['UUID']['input'];
  /** ID of the debtor company. */
  debtorId: Scalars['UUID']['input'];
  /** End date of the contract. */
  endDate: Scalars['DateTime']['input'];
  /** Start date of the contract. */
  startDate: Scalars['DateTime']['input'];
};

export type CreateCostCategory = {
  /** Should vat be applied to this cost category. */
  applyVat?: Scalars['Boolean']['input'];
  /** Company the cost category belongs to. */
  companyId?: InputMaybe<Scalars['UUID']['input']>;
  /** Name of the cost category. */
  name: Scalars['String']['input'];
  /** Type of the cost category, determines who can use it. */
  type: CostCategoryType;
};

export type CreateDossier = {
  /** Reference for the bailiff. */
  bailiffReference?: InputMaybe<Scalars['String']['input']>;
  /** ID of the client the dossier comes from. */
  clientId: Scalars['UUID']['input'];
  /** ID of the contractor. */
  contractorId?: InputMaybe<Scalars['UUID']['input']>;
  /** Currency of the dossier. */
  currency: Currency;
  /** ID of the client that is in dept. */
  debtorId: Scalars['UUID']['input'];
  /** Reference of the debtor. */
  debtorReference?: InputMaybe<Scalars['String']['input']>;
  /** Type of interest. */
  interestType?: DossierInterestType;
  /** Should the dossier be payout when its completed. */
  payoutOnComplete?: Scalars['Boolean']['input'];
  /** Type of the dossier. */
  type: DossierType;
  /** ID of the workflow to use. */
  workflowId?: InputMaybe<Scalars['UUID']['input']>;
};

export type CreateDossierCost = {
  /** ID of the cost category the cost belongs to. */
  categoryId: Scalars['UUID']['input'];
  /** Amount in cents of cost. */
  centAmount: Scalars['Float']['input'];
  /** Currency of the costs. */
  currency: Currency;
  /** Description of the cost. */
  description: Scalars['String']['input'];
  /** For who the costs are. */
  direction: WorkflowStepCostsDirection;
  /** ID of the dossier. */
  dossierId: Scalars['UUID']['input'];
  /** Is this costs success based. */
  successBased?: Scalars['Boolean']['input'];
};

export type CreateDossierInvoice = {
  /** Amount in cents of the invoice. */
  centAmount: Scalars['Float']['input'];
  /** Amount in cents of the invoice that have been paid (Only used in debtor management). */
  centAmountPaid?: Scalars['Float']['input'];
  /** Currency of the invoice. */
  currency: Currency;
  /** Date of the invoice. */
  date: Scalars['DateTime']['input'];
  /** ID of the dossier. */
  dossierId: Scalars['UUID']['input'];
  /** ID of associated file. */
  fileId?: InputMaybe<Scalars['UUID']['input']>;
  /** Type of interest. */
  interest?: InputMaybe<DossierInvoiceInterest>;
  /** Date to calculate interest from. (Expire date of the invoice) */
  interestFromDate?: InputMaybe<Scalars['DateTime']['input']>;
  /** Amount of days / work-days. */
  paymentTerm: Scalars['Float']['input'];
  /** Amount of days / work-days. */
  paymentTermType: InvoicePaymentTerm;
  /** Reference of the invoice. This needs to be unique! */
  reference: Scalars['String']['input'];
  /** Status of the invoice. (Only used in debtor management) */
  status?: DossierInvoiceStatus;
};

export type CreateFranchisee = {
  /** Preferred language of the company. */
  language?: Language;
  /** Location number of the company. */
  locationNr?: InputMaybe<Scalars['String']['input']>;
  /** Name of the company. */
  name: Scalars['String']['input'];
  /** Only accepted by international users. */
  parentId?: InputMaybe<Scalars['UUID']['input']>;
};

export type CreateInterestRate = {
  /** Amount in % the tax is. */
  amount: Scalars['Float']['input'];
  /** ID of the company the interest rate belongs to. */
  companyId?: InputMaybe<Scalars['UUID']['input']>;
  /** Country of the tax. */
  country?: InputMaybe<Scalars['String']['input']>;
  /** ID of the dossier the interest rate belongs to. */
  dossierId?: InputMaybe<Scalars['UUID']['input']>;
  /** From when the tax is applied. */
  from: Scalars['DateTime']['input'];
  /** Until when the tax is applied. */
  until?: InputMaybe<Scalars['DateTime']['input']>;
  /** Type of the interest rate. */
  variant: InterestRateType;
};

export type CreateInvoice = {
  /** Currency of invoice. */
  currency: Currency;
  /** Id of the debtor the invoiced is to. */
  debtorId: Scalars['UUID']['input'];
  /** Payment term of the invoice. */
  paymentTerm: Scalars['Float']['input'];
  /** Type of the invoice payment term. */
  paymentTermType: InvoicePaymentTerm;
  /** Subject of the invoice. */
  subject?: InputMaybe<Scalars['String']['input']>;
  /** ID of the workflow to use. */
  workflowId?: InputMaybe<Scalars['UUID']['input']>;
};

export type CreateInvoiceLine = {
  /** ID of the category of this line item. */
  categoryId?: InputMaybe<Scalars['UUID']['input']>;
  /** Amount in cents of one line item. */
  centAmount: Scalars['Float']['input'];
  /** Currency of line item. */
  currency: Currency;
  /** ID of the debtor the line item is invoiced to. */
  debtorId: Scalars['UUID']['input'];
  /** Description of the line item. */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Process line item at next invoicing. */
  inInvoicing?: Scalars['Boolean']['input'];
  /** ID of the invoice the line item is invoiced to. */
  invoiceId?: InputMaybe<Scalars['UUID']['input']>;
  /** Note of the line item. */
  note?: InputMaybe<Scalars['String']['input']>;
  /** Quantity of line item. */
  quantity?: Scalars['Float']['input'];
};

export type CreateLabel = {
  /** Name of the label */
  name: Scalars['String']['input'];
};

export type CreateManyCompanyAreaCodesInput = {
  /** Array of records to create */
  companyAreaCodes: Array<CreateCompanyAreaCode>;
};

export type CreateManyCompanyContractCostsInput = {
  /** Array of records to create */
  companyContractCosts: Array<CreateCompanyContractCost>;
};

export type CreateManyWorkflowSteps = {
  /** Place new steps on top of workflow. */
  placeTop?: Scalars['Boolean']['input'];
  /** Remove all existing steps. */
  removeExistingSteps?: Scalars['Boolean']['input'];
  /** Array of records to create */
  steps: Array<CreateWorkflowStep>;
  /** Workflow to add the steps to. */
  workflowId: Scalars['UUID']['input'];
};

export type CreateMaster = {
  /** Country code where the company is located (ISO 3166-1 alpha-2). */
  country: Scalars['String']['input'];
  /** Currency the company uses. */
  currency?: Currency;
  /** Preferred language of the company. */
  language?: Language;
  /** Location number of the company. */
  locationNr?: InputMaybe<Scalars['String']['input']>;
  /** Name of the company. */
  name: Scalars['String']['input'];
};

/** Create new note, at-least "invoiceId", "dossierId" or "companyId" needs to be provided! */
export type CreateNote = {
  /** Company the note belongs to. */
  companyId?: InputMaybe<Scalars['UUID']['input']>;
  /** Content of the note. */
  content: Scalars['String']['input'];
  /** Dossier the note belongs to. */
  dossierId?: InputMaybe<Scalars['UUID']['input']>;
  /** Is it a sticky note. */
  sticky?: Scalars['Boolean']['input'];
  /** Is the note visible for the debtor. */
  visibleForDebtor?: Scalars['Boolean']['input'];
};

export type CreateOneBusinessActivityInput = {
  /** The record to create */
  businessActivity: CreateBusinessActivity;
};

export type CreateOneClientContactInput = {
  /** The record to create */
  clientContact: CreateClientContact;
};

export type CreateOneClientInput = {
  /** The record to create */
  client: CreateClient;
};

export type CreateOneCompanyBankAccountInput = {
  /** The record to create */
  companyBankAccount: CreateCompanyBankAccount;
};

export type CreateOneCompanyContactInput = {
  /** The record to create */
  companyContact: CreateCompanyContact;
};

export type CreateOneCompanyContractInput = {
  /** The record to create */
  companyContract: CreateCompanyContract;
};

export type CreateOneContractInput = {
  /** The record to create */
  contract: CreateContract;
};

export type CreateOneCostCategoryInput = {
  /** The record to create */
  costCategory: CreateCostCategory;
};

export type CreateOneDossierCostInput = {
  /** The record to create */
  dossierCost: CreateDossierCost;
};

export type CreateOneDossierInput = {
  /** The record to create */
  dossier: CreateDossier;
};

export type CreateOneDossierInvoiceInput = {
  /** The record to create */
  dossierInvoice: CreateDossierInvoice;
};

export type CreateOneFranchiseeInput = {
  /** The record to create */
  franchisee: CreateFranchisee;
};

export type CreateOneInterestRateInput = {
  /** The record to create */
  interestRate: CreateInterestRate;
};

export type CreateOneInvoiceInput = {
  /** The record to create */
  invoice: CreateInvoice;
};

export type CreateOneInvoiceLineInput = {
  /** The record to create */
  invoiceLine: CreateInvoiceLine;
};

export type CreateOneLabelInput = {
  /** The record to create */
  label: CreateLabel;
};

export type CreateOneMasterInput = {
  /** The record to create */
  master: CreateMaster;
};

export type CreateOneNoteInput = {
  /** The record to create */
  note: CreateNote;
};

export type CreateOneRepeatingInvoiceLineInput = {
  /** The record to create */
  repeatingInvoiceLine: CreateRepeatingInvoiceLine;
};

export type CreateOneTaxRateInput = {
  /** The record to create */
  taxRate: CreateTaxRate;
};

export type CreateOneTransactionInput = {
  /** The record to create */
  transaction: CreateTransaction;
};

export type CreateOneUserGroupInput = {
  /** The record to create */
  userGroup: CreateUserGroup;
};

export type CreateOneVoipInternalNumberInput = {
  /** The record to create */
  voipInternalNumber: CreateVoipInternalNumber;
};

export type CreateOneWorkflowInput = {
  /** The record to create */
  workflow: CreateWorkflow;
};

export type CreatePaymentArrangement = {
  /** Amount of terms for this arrangement. */
  amountOfTerms?: InputMaybe<Scalars['Float']['input']>;
  /** Amount in cents to pay for each term. */
  centAmountEachTerm?: InputMaybe<Scalars['Float']['input']>;
  /** Actions for the confirmation of the arrangement. */
  confirmationActions: Array<CreateWorkflowAction>;
  /** Currency of the arrangement. */
  currency?: Currency;
  /** Actions for when the arrangement fails. */
  failedToMeetActions: Array<CreateWorkflowAction>;
  /** Date the first term expires. */
  firstTermExpiresAt: Scalars['DateTime']['input'];
  /** Actions for when the day of the agreed term is reached. */
  termActions?: InputMaybe<Array<CreateWorkflowAction>>;
  /** Amount of days after each term to check if the term is paid. */
  termCheckDays?: Scalars['Float']['input'];
  /** Type of the termCheckDays field. */
  termCheckDaysType?: WorkflowStepDaysType;
  /** Amount of days/weeks/months of one term. Required if "centAmountEachTerm" is provided! */
  termDays: Scalars['Float']['input'];
  /** Type of the termDays field. Required if "centAmountEachTerm" is provided! */
  termDaysType: WorkflowStepDaysType;
  /** Actions for the reminder steps of the arrangement. */
  termReminderActions: Array<CreateWorkflowAction>;
  /** Amount of days before each term to send the reminder at. */
  termReminderDays: Scalars['Float']['input'];
  /** Type of the termReminderDays field. */
  termReminderDaysType?: WorkflowStepDaysType;
  /** ID of the workflow to add the arrangement to. */
  workflowId: Scalars['UUID']['input'];
};

export type CreatePaymentCommitment = {
  /** Amount in cents of the commitment. */
  centAmount: Scalars['Float']['input'];
  /** Actions for the confirmation of the commitment. */
  confirmationActions: Array<CreateWorkflowAction>;
  /** Currency of the commitment. */
  currency?: Currency;
  /** Date of the commitment. */
  date: Scalars['DateTime']['input'];
  /** Actions for when the commitment fails. */
  failedToMeetActions: Array<CreateWorkflowAction>;
  /** Actions for when the day of the agreed term is reached. */
  termActions?: InputMaybe<Array<CreateWorkflowAction>>;
  /** Amount of days after the term to check if the term is paid. */
  termCheckDays?: Scalars['Float']['input'];
  /** Type of the termCheckDays field. */
  termCheckDaysType?: WorkflowStepDaysType;
  /** ID of the workflow to add the commitment to. */
  workflowId: Scalars['UUID']['input'];
};

export type CreateRepeatingInvoiceLine = {
  /** ID of the category of this line item. */
  categoryId?: InputMaybe<Scalars['UUID']['input']>;
  /** Amount in cents of one line item. */
  centAmount: Scalars['Float']['input'];
  /** Currency of line item. */
  currency: Currency;
  /** ID of the debtor the line item is invoiced to. */
  debtorId: Scalars['UUID']['input'];
  /** Description of the line item. */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Process line item at next invoicing. */
  inInvoicing?: Scalars['Boolean']['input'];
  /** Note of the line item. */
  note?: InputMaybe<Scalars['String']['input']>;
  /** The amount of (working) days / weeks / months. */
  period: Scalars['Float']['input'];
  /** Type of the period. */
  periodType: RepeatingInvoiceLinePeriodType;
  /** Quantity of line item. */
  quantity?: Scalars['Float']['input'];
};

export type CreateScope = {
  /** The action. */
  action: ScopeAction;
  /** The claim. */
  claim: ScopeClaim;
};

export type CreateTaxRate = {
  /** Amount in % the tax is. */
  amount: Scalars['Float']['input'];
  /** Country of the tax. */
  country?: InputMaybe<Scalars['String']['input']>;
  /** From when the tax is applied. */
  from: Scalars['DateTime']['input'];
  /** Until when the tax is applied. */
  until?: InputMaybe<Scalars['DateTime']['input']>;
};

export type CreateTransaction = {
  /** Amount in cents of the transaction. */
  centAmount: Scalars['Float']['input'];
  /** Currency of the transaction. */
  currency: Currency;
  /** ID of the dossier the transaction is connected to. */
  dossierId?: InputMaybe<Scalars['UUID']['input']>;
  /** ID of the dossier invoice the transaction is connected to. */
  dossierInvoiceId?: InputMaybe<Scalars['UUID']['input']>;
  /** ID of the invoice the transaction is connected to. */
  invoiceId?: InputMaybe<Scalars['UUID']['input']>;
  /** Date time of when transaction took place. */
  receivedOn: Scalars['DateTime']['input'];
  /** Reference of the transaction. */
  reference?: InputMaybe<Scalars['String']['input']>;
  /** Source of the transaction. */
  source?: CreateTransactionSource;
  /** Subtract the amount of this transaction from the initial dossier amount. */
  subtract?: Scalars['Boolean']['input'];
  /** The type of transaction. */
  type: CreateTransactionType;
};

/** Sources of the transaction that can be set when creating one. */
export enum CreateTransactionSource {
  Bank = 'Bank',
  Manual = 'Manual'
}

/** Types of the transaction that can be set when creating one. */
export enum CreateTransactionType {
  BankTransfer = 'BankTransfer',
  Settlement = 'Settlement'
}

export type CreateUserGroup = {
  /** Name of the user group. */
  name: Scalars['String']['input'];
  /** Scopes of the user group. */
  scopes: Array<CreateScope>;
};

export type CreateVoipInternalNumber = {
  /** The internal number. */
  number: Scalars['String']['input'];
  /** ID of the user this internal number belongs to. */
  userId: Scalars['UUID']['input'];
};

export type CreateWorkflow = {
  /** Name of the workflow. */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Workflow variant. */
  type: WorkflowType;
};

export type CreateWorkflowAction = {
  /** If `true` files generated by this action will be added as attachment to same step email actions. */
  attachFilesToStepEmailActions?: Scalars['Boolean']['input'];
  /** To who the action should be focused on. */
  direction?: WorkflowActionDirection;
  /** ID of the global action to clone, if provided all other fields can be skipped!. */
  id?: InputMaybe<Scalars['UUID']['input']>;
  /** Workflow action message. Required if no "id" is provided! */
  message?: InputMaybe<Scalars['String']['input']>;
  /** Name of the action. Required if no "id" is provided! */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Workflow action subject. Required if no "id" is provided and type is "email" or "letter"! */
  subject?: InputMaybe<Scalars['String']['input']>;
  /** Type of the action. Required if no "id" is provided! */
  type?: InputMaybe<WorkflowActionType>;
};

export type CreateWorkflowStep = {
  /** Actions to create for this workflow step. Required if no "id" is provided! */
  actions?: InputMaybe<Array<CreateWorkflowAction>>;
  /** Id of the client cost category. Required if "clientCosts" are provided! */
  clientCostCategoryId?: InputMaybe<Scalars['UUID']['input']>;
  /** Currency of the client costs. Required if "clientCosts" are provided! */
  clientCostCurrency?: InputMaybe<Currency>;
  /** Costs for the client */
  clientCosts?: InputMaybe<Array<CreateWorkflowStepCost>>;
  /** Are the client costs for each invoice. */
  clientCostsForEachInvoice?: Scalars['Boolean']['input'];
  /** Are the client costs success based. */
  clientCostsSuccessBased?: Scalars['Boolean']['input'];
  /** Amount of days / work-days. */
  days?: Scalars['Float']['input'];
  /** Amount of days / work-days. */
  daysType?: WorkflowStepDaysType;
  /** Id of the debtor cost category. Required if "debtorCosts" are provided! */
  debtorCostCategoryId?: InputMaybe<Scalars['UUID']['input']>;
  /** Currency of the debtor costs. Required if "debtorCosts" are provided! */
  debtorCostCurrency?: InputMaybe<Currency>;
  /** Costs for the debtor */
  debtorCosts?: InputMaybe<Array<CreateWorkflowStepCost>>;
  /** Are the debtor costs for each invoice. */
  debtorCostsForEachInvoice?: Scalars['Boolean']['input'];
  /** ID of the global step to clone, if provided all other fields can be skipped!. */
  id?: InputMaybe<Scalars['UUID']['input']>;
  /** Name of the step. Required if no "id" is provided! */
  name?: InputMaybe<Scalars['String']['input']>;
};

export type CreateWorkflowStepCost = {
  /** Amount in cents. */
  centAmount?: InputMaybe<Scalars['Float']['input']>;
  /** Until what cent amount this line applies, null if always. */
  centAmountUntil?: InputMaybe<Scalars['Float']['input']>;
  /** Currency of the until amount. */
  currencyUntil: Currency;
  /** Use percentage instead of centAmount. */
  isPercentage?: Scalars['Boolean']['input'];
  /** Amount in percentage. */
  percentage?: InputMaybe<Scalars['Float']['input']>;
};

export type Creator = {
  __typename?: 'Creator';
  /** Primary key of the recourse. */
  id: Scalars['UUID']['output'];
  /** Name of the creator. */
  name: Scalars['String']['output'];
  /** Type of the creator. */
  type: CreatorType;
};

export enum CreatorType {
  Api = 'API',
  Integration = 'Integration',
  System = 'System',
  User = 'User'
}

/** Currencies the app supports. */
export enum Currency {
  Ang = 'Ang',
  Aud = 'Aud',
  Cad = 'Cad',
  Chf = 'Chf',
  Dkk = 'Dkk',
  Eur = 'Eur',
  Gbp = 'Gbp',
  Ils = 'Ils',
  Nad = 'Nad',
  Pln = 'Pln',
  Ron = 'Ron',
  Sek = 'Sek',
  Usd = 'Usd',
  Zar = 'Zar'
}

export type DateFieldComparison = {
  between?: InputMaybe<DateFieldComparisonBetween>;
  eq?: InputMaybe<Scalars['DateTime']['input']>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  neq?: InputMaybe<Scalars['DateTime']['input']>;
  notBetween?: InputMaybe<DateFieldComparisonBetween>;
  notIn?: InputMaybe<Array<Scalars['DateTime']['input']>>;
};

export type DateFieldComparisonBetween = {
  lower: Scalars['DateTime']['input'];
  upper: Scalars['DateTime']['input'];
};

export type DeleteManyCompanyAreaCodesInput = {
  /** Filter to find records to delete */
  filter: CompanyAreaCodeDeleteFilter;
};

export type DeleteManyCompanyContractCostsInput = {
  /** Filter to find records to delete */
  filter: CompanyContractCostDeleteFilter;
};

export type DeleteManyResponse = {
  __typename?: 'DeleteManyResponse';
  /** The number of records deleted. */
  deletedCount: Scalars['Int']['output'];
};

export type DeleteOneBusinessActivityInput = {
  /** The id of the record to delete. */
  id: Scalars['UUID']['input'];
};

export type DeleteOneClientContactInput = {
  /** The id of the record to delete. */
  id: Scalars['UUID']['input'];
};

export type DeleteOneCompanyBankAccountInput = {
  /** The id of the record to delete. */
  id: Scalars['UUID']['input'];
};

export type DeleteOneCompanyContactInput = {
  /** The id of the record to delete. */
  id: Scalars['UUID']['input'];
};

export type DeleteOneCompanyIntegrationInput = {
  /** The id of the record to delete. */
  id: Scalars['UUID']['input'];
};

export type DeleteOneContractInput = {
  /** The id of the record to delete. */
  id: Scalars['UUID']['input'];
};

export type DeleteOneCostCategoryInput = {
  /** The id of the record to delete. */
  id: Scalars['UUID']['input'];
};

export type DeleteOneDossierCostInput = {
  /** The id of the record to delete. */
  id: Scalars['UUID']['input'];
};

export type DeleteOneDossierInput = {
  /** The id of the record to delete. */
  id: Scalars['UUID']['input'];
};

export type DeleteOneDossierInvoiceInput = {
  /** The id of the record to delete. */
  id: Scalars['UUID']['input'];
};

export type DeleteOneFileInput = {
  /** The id of the record to delete. */
  id: Scalars['UUID']['input'];
};

export type DeleteOneInterestRateInput = {
  /** The id of the record to delete. */
  id: Scalars['UUID']['input'];
};

export type DeleteOneInvoiceInput = {
  /** The id of the record to delete. */
  id: Scalars['UUID']['input'];
};

export type DeleteOneInvoiceLineInput = {
  /** The id of the record to delete. */
  id: Scalars['UUID']['input'];
};

export type DeleteOneLabelInput = {
  /** The id of the record to delete. */
  id: Scalars['UUID']['input'];
};

export type DeleteOneNoteInput = {
  /** The id of the record to delete. */
  id: Scalars['UUID']['input'];
};

export type DeleteOneNotificationInput = {
  /** The id of the record to delete. */
  id: Scalars['UUID']['input'];
};

export type DeleteOneRepeatingInvoiceLineInput = {
  /** The id of the record to delete. */
  id: Scalars['UUID']['input'];
};

export type DeleteOneTaxRateInput = {
  /** The id of the record to delete. */
  id: Scalars['UUID']['input'];
};

export type DeleteOneTransactionInput = {
  /** The id of the record to delete. */
  id: Scalars['UUID']['input'];
};

export type DeleteOneUserToCompanyInput = {
  /** The id of the record to delete. */
  id: Scalars['UUID']['input'];
};

export type DeleteOneVoipInternalNumberInput = {
  /** The id of the record to delete. */
  id: Scalars['UUID']['input'];
};

export type DeleteOneWorkflowInput = {
  /** The id of the record to delete. */
  id: Scalars['UUID']['input'];
};

export type DeleteOneWorkflowStepInput = {
  /** The id of the record to delete. */
  id: Scalars['UUID']['input'];
};

export type DeletePaymentArrangement = {
  /** ID of the workflow to remove the arrangement of. */
  id: Scalars['UUID']['input'];
};

export type DeleteTransactionFromWorkflowStep = {
  /** ID of the workflow step to remove the transaction from. */
  id: Scalars['UUID']['input'];
};

export type Dossier = {
  __typename?: 'Dossier';
  /** Reference for the bailiff. */
  bailiffReference?: Maybe<Scalars['String']['output']>;
  /** Amount in cents of the dossier. */
  centAmount: Scalars['Float']['output'];
  /** Amount in cents of the costs for the client. */
  centAmountClientCosts: Scalars['Float']['output'];
  /** Amount in cents of the costs for the debtor. */
  centAmountDebtorCosts: Scalars['Float']['output'];
  /** Amount in cents of the costs for the debtor that are processed. */
  centAmountDebtorCostsProcessed: Scalars['Float']['output'];
  /** Amount in cents of interest that needs to be paid for the dossier. */
  centAmountInterest: Scalars['Float']['output'];
  /** Amount in cents of the interest that have been processed. */
  centAmountInterestProcessed: Scalars['Float']['output'];
  /** Amount in cents of the dossier invoices. */
  centAmountInvoices: Scalars['Float']['output'];
  /** Amount in cents of the dossier invoices that have been processed. */
  centAmountInvoicesProcessed: Scalars['Float']['output'];
  /** Date time the centAmount is last updated. */
  centAmountLastUpdatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Amount in cents of the dossier that still needs to be paid. */
  centAmountOpen: Scalars['Float']['output'];
  /** Amount in cents of the dossier that is paid. */
  centAmountPaid: Scalars['Float']['output'];
  /** Amount in cents of the dossier that is Paid with subtract true. */
  centAmountPaidSubtracted: Scalars['Float']['output'];
  /** Amount in cents of the dossier that have been processed. */
  centAmountProcessed: Scalars['Float']['output'];
  /** True if the centAmounts are being updated. */
  centAmountUpdating: Scalars['Boolean']['output'];
  /** Client of the dossier */
  client: CompanyReference;
  /** ID of the client. */
  clientId: Scalars['UUID']['output'];
  /** Date time of when the dossier was closed. */
  closedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Franchisee who works on this dossier. */
  contractor: CompanyReference;
  /** ID of the contractor. */
  contractorId: Scalars['UUID']['output'];
  /** Date time of when the recourse is created. */
  createdAt: Scalars['DateTime']['output'];
  /** Creator of this this dossier. */
  creator: Creator;
  /** Currency of the dossier. */
  currency: Currency;
  /** Debtor of the dossier. */
  debtor: CompanyReference;
  /** ID of the debtor. */
  debtorId: Scalars['UUID']['output'];
  /** Reference of the debtor. */
  debtorReference?: Maybe<Scalars['String']['output']>;
  /** User who is working on this dossier. */
  executor?: Maybe<UserReference>;
  /** ID of the executor. */
  executorId?: Maybe<Scalars['UUID']['output']>;
  /** Primary key of the recourse. */
  id: Scalars['UUID']['output'];
  /** Cost category that is used for collected interest of contractor. */
  interestCategory?: Maybe<CostCategoryReference>;
  /** ID of the cost category that is used for the collected contractors interest. */
  interestCategoryId?: Maybe<Scalars['UUID']['output']>;
  /** Date time of when the dossier stops calculating interest. */
  interestEndDate?: Maybe<Scalars['DateTime']['output']>;
  /** Should the collected interest of this dossier be for the contractor. */
  interestForContractor: Scalars['Boolean']['output'];
  /** Type of interest. */
  interestType: DossierInterestType;
  /** ID of the job that created this dossier. */
  jobId?: Maybe<Scalars['UUID']['output']>;
  /** Labels of the dossier */
  labels: Array<Label>;
  /** Date time of when the dossier was last processed. */
  lastProcessedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Manager of the dossier. */
  manager?: Maybe<Manager>;
  /** Number of the dossier. */
  number: Scalars['String']['output'];
  /** OCR number of the dossier (Only used in Sweden). */
  ocr?: Maybe<Scalars['String']['output']>;
  /** Should the dossier be payout when its completed. */
  payoutOnComplete: Scalars['Boolean']['output'];
  /** Source of the dossier. */
  source: DossierSource;
  /** Date time of when the dossier was started. */
  startAt?: Maybe<Scalars['DateTime']['output']>;
  /** Status of the dossier. */
  status: DossierStatus;
  /** Type of the dossier. */
  type: DossierType;
  /** Date time of when the recourse last updated. */
  updatedAt: Scalars['DateTime']['output'];
  /** ID of the workflow that is used in this dossier. */
  workflowId?: Maybe<Scalars['UUID']['output']>;
};

export type DossierAggregateFilter = {
  and?: InputMaybe<Array<DossierAggregateFilter>>;
  centAmount?: InputMaybe<DossierCentAmountFilterComparison>;
  centAmountClientCosts?: InputMaybe<DossierCentAmountClientCostsFilterComparison>;
  centAmountDebtorCosts?: InputMaybe<DossierCentAmountDebtorCostsFilterComparison>;
  centAmountDebtorCostsProcessed?: InputMaybe<DossierCentAmountDebtorCostsProcessedFilterComparison>;
  centAmountInterest?: InputMaybe<DossierCentAmountInterestFilterComparison>;
  centAmountInterestProcessed?: InputMaybe<DossierCentAmountInterestProcessedFilterComparison>;
  centAmountInvoices?: InputMaybe<DossierCentAmountInvoicesFilterComparison>;
  centAmountInvoicesProcessed?: InputMaybe<DossierCentAmountInvoicesProcessedFilterComparison>;
  centAmountOpen?: InputMaybe<DossierCentAmountOpenFilterComparison>;
  centAmountPaid?: InputMaybe<DossierCentAmountPaidFilterComparison>;
  centAmountPaidSubtracted?: InputMaybe<DossierCentAmountPaidSubtractedFilterComparison>;
  centAmountProcessed?: InputMaybe<DossierCentAmountProcessedFilterComparison>;
  client?: InputMaybe<DossierAggregateFilterCompanyReferenceAggregateFilter>;
  clientId?: InputMaybe<DossierClientIdFilterComparison>;
  closedAt?: InputMaybe<DossierClosedAtFilterComparison>;
  createdAt?: InputMaybe<DossierCreatedAtFilterComparison>;
  currency?: InputMaybe<DossierCurrencyFilterComparison>;
  debtor?: InputMaybe<DossierAggregateFilterCompanyReferenceAggregateFilter>;
  debtorId?: InputMaybe<DossierDebtorIdFilterComparison>;
  debtorReference?: InputMaybe<DossierDebtorReferenceFilterComparison>;
  executorId?: InputMaybe<DossierExecutorIdFilterComparison>;
  id?: InputMaybe<DossierIdFilterComparison>;
  jobId?: InputMaybe<DossierJobIdFilterComparison>;
  labels?: InputMaybe<DossierAggregateFilterLabelAggregateFilter>;
  lastProcessedAt?: InputMaybe<DossierLastProcessedAtFilterComparison>;
  manager?: InputMaybe<DossierManagerFilterComparison>;
  number?: InputMaybe<DossierNumberFilterComparison>;
  ocr?: InputMaybe<DossierOcrFilterComparison>;
  or?: InputMaybe<Array<DossierAggregateFilter>>;
  payoutOnComplete?: InputMaybe<DossierPayoutOnCompleteFilterComparison>;
  startAt?: InputMaybe<DossierStartAtFilterComparison>;
  status?: InputMaybe<DossierStatusFilterComparison>;
  type?: InputMaybe<DossierTypeFilterComparison>;
  updatedAt?: InputMaybe<DossierUpdatedAtFilterComparison>;
  workflow?: InputMaybe<DossierAggregateFilterWorkflowAggregateFilter>;
};

export type DossierAggregateFilterCompanyReferenceAggregateFilter = {
  and?: InputMaybe<Array<DossierAggregateFilterCompanyReferenceAggregateFilter>>;
  id?: InputMaybe<CompanyReferenceIdFilterComparison>;
  name?: InputMaybe<CompanyReferenceNameFilterComparison>;
  or?: InputMaybe<Array<DossierAggregateFilterCompanyReferenceAggregateFilter>>;
};

export type DossierAggregateFilterLabelAggregateFilter = {
  and?: InputMaybe<Array<DossierAggregateFilterLabelAggregateFilter>>;
  createdAt?: InputMaybe<LabelCreatedAtFilterComparison>;
  id?: InputMaybe<LabelIdFilterComparison>;
  name?: InputMaybe<LabelNameFilterComparison>;
  or?: InputMaybe<Array<DossierAggregateFilterLabelAggregateFilter>>;
  updatedAt?: InputMaybe<LabelUpdatedAtFilterComparison>;
};

export type DossierAggregateFilterWorkflowAggregateFilter = {
  and?: InputMaybe<Array<DossierAggregateFilterWorkflowAggregateFilter>>;
  companyId?: InputMaybe<WorkflowCompanyIdFilterComparison>;
  createdAt?: InputMaybe<WorkflowCreatedAtFilterComparison>;
  id?: InputMaybe<WorkflowIdFilterComparison>;
  name?: InputMaybe<WorkflowNameFilterComparison>;
  or?: InputMaybe<Array<DossierAggregateFilterWorkflowAggregateFilter>>;
  reachedEnd?: InputMaybe<WorkflowReachedEndFilterComparison>;
  type?: InputMaybe<WorkflowTypeFilterComparison>;
  updatedAt?: InputMaybe<WorkflowUpdatedAtFilterComparison>;
};

export type DossierAggregateGroupBy = {
  __typename?: 'DossierAggregateGroupBy';
  centAmount?: Maybe<Scalars['Float']['output']>;
  centAmountClientCosts?: Maybe<Scalars['Float']['output']>;
  centAmountDebtorCosts?: Maybe<Scalars['Float']['output']>;
  centAmountDebtorCostsProcessed?: Maybe<Scalars['Float']['output']>;
  centAmountInterest?: Maybe<Scalars['Float']['output']>;
  centAmountInterestProcessed?: Maybe<Scalars['Float']['output']>;
  centAmountInvoices?: Maybe<Scalars['Float']['output']>;
  centAmountInvoicesProcessed?: Maybe<Scalars['Float']['output']>;
  centAmountOpen?: Maybe<Scalars['Float']['output']>;
  centAmountPaid?: Maybe<Scalars['Float']['output']>;
  centAmountPaidSubtracted?: Maybe<Scalars['Float']['output']>;
  centAmountProcessed?: Maybe<Scalars['Float']['output']>;
  clientId?: Maybe<Scalars['UUID']['output']>;
  closedAt?: Maybe<Scalars['DateTime']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  currency?: Maybe<Currency>;
  debtorId?: Maybe<Scalars['UUID']['output']>;
  debtorReference?: Maybe<Scalars['String']['output']>;
  executorId?: Maybe<Scalars['UUID']['output']>;
  id?: Maybe<Scalars['UUID']['output']>;
  jobId?: Maybe<Scalars['UUID']['output']>;
  lastProcessedAt?: Maybe<Scalars['DateTime']['output']>;
  manager?: Maybe<Manager>;
  number?: Maybe<Scalars['String']['output']>;
  ocr?: Maybe<Scalars['String']['output']>;
  payoutOnComplete?: Maybe<Scalars['Boolean']['output']>;
  startAt?: Maybe<Scalars['DateTime']['output']>;
  status?: Maybe<DossierStatus>;
  type?: Maybe<DossierType>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};


export type DossierAggregateGroupByClosedAtArgs = {
  by?: GroupBy;
};


export type DossierAggregateGroupByCreatedAtArgs = {
  by?: GroupBy;
};


export type DossierAggregateGroupByLastProcessedAtArgs = {
  by?: GroupBy;
};


export type DossierAggregateGroupByStartAtArgs = {
  by?: GroupBy;
};


export type DossierAggregateGroupByUpdatedAtArgs = {
  by?: GroupBy;
};

export type DossierAggregateResponse = {
  __typename?: 'DossierAggregateResponse';
  avg?: Maybe<DossierAvgAggregate>;
  count?: Maybe<DossierCountAggregate>;
  groupBy?: Maybe<DossierAggregateGroupBy>;
  max?: Maybe<DossierMaxAggregate>;
  min?: Maybe<DossierMinAggregate>;
  sum?: Maybe<DossierSumAggregate>;
};

export type DossierAvgAggregate = {
  __typename?: 'DossierAvgAggregate';
  centAmount?: Maybe<Scalars['Float']['output']>;
  centAmountClientCosts?: Maybe<Scalars['Float']['output']>;
  centAmountDebtorCosts?: Maybe<Scalars['Float']['output']>;
  centAmountDebtorCostsProcessed?: Maybe<Scalars['Float']['output']>;
  centAmountInterest?: Maybe<Scalars['Float']['output']>;
  centAmountInterestProcessed?: Maybe<Scalars['Float']['output']>;
  centAmountInvoices?: Maybe<Scalars['Float']['output']>;
  centAmountInvoicesProcessed?: Maybe<Scalars['Float']['output']>;
  centAmountOpen?: Maybe<Scalars['Float']['output']>;
  centAmountPaid?: Maybe<Scalars['Float']['output']>;
  centAmountPaidSubtracted?: Maybe<Scalars['Float']['output']>;
  centAmountProcessed?: Maybe<Scalars['Float']['output']>;
};

export type DossierCentAmountClientCostsFilterComparison = {
  gt?: InputMaybe<Scalars['Float']['input']>;
  gte?: InputMaybe<Scalars['Float']['input']>;
  lt?: InputMaybe<Scalars['Float']['input']>;
  lte?: InputMaybe<Scalars['Float']['input']>;
};

export type DossierCentAmountDebtorCostsFilterComparison = {
  gt?: InputMaybe<Scalars['Float']['input']>;
  gte?: InputMaybe<Scalars['Float']['input']>;
  lt?: InputMaybe<Scalars['Float']['input']>;
  lte?: InputMaybe<Scalars['Float']['input']>;
};

export type DossierCentAmountDebtorCostsProcessedFilterComparison = {
  gt?: InputMaybe<Scalars['Float']['input']>;
  gte?: InputMaybe<Scalars['Float']['input']>;
  lt?: InputMaybe<Scalars['Float']['input']>;
  lte?: InputMaybe<Scalars['Float']['input']>;
};

export type DossierCentAmountFilterComparison = {
  gt?: InputMaybe<Scalars['Float']['input']>;
  gte?: InputMaybe<Scalars['Float']['input']>;
  lt?: InputMaybe<Scalars['Float']['input']>;
  lte?: InputMaybe<Scalars['Float']['input']>;
};

export type DossierCentAmountInterestFilterComparison = {
  gt?: InputMaybe<Scalars['Float']['input']>;
  gte?: InputMaybe<Scalars['Float']['input']>;
  lt?: InputMaybe<Scalars['Float']['input']>;
  lte?: InputMaybe<Scalars['Float']['input']>;
};

export type DossierCentAmountInterestProcessedFilterComparison = {
  gt?: InputMaybe<Scalars['Float']['input']>;
  gte?: InputMaybe<Scalars['Float']['input']>;
  lt?: InputMaybe<Scalars['Float']['input']>;
  lte?: InputMaybe<Scalars['Float']['input']>;
};

export type DossierCentAmountInvoicesFilterComparison = {
  gt?: InputMaybe<Scalars['Float']['input']>;
  gte?: InputMaybe<Scalars['Float']['input']>;
  lt?: InputMaybe<Scalars['Float']['input']>;
  lte?: InputMaybe<Scalars['Float']['input']>;
};

export type DossierCentAmountInvoicesProcessedFilterComparison = {
  gt?: InputMaybe<Scalars['Float']['input']>;
  gte?: InputMaybe<Scalars['Float']['input']>;
  lt?: InputMaybe<Scalars['Float']['input']>;
  lte?: InputMaybe<Scalars['Float']['input']>;
};

export type DossierCentAmountOpenFilterComparison = {
  gt?: InputMaybe<Scalars['Float']['input']>;
  gte?: InputMaybe<Scalars['Float']['input']>;
  lt?: InputMaybe<Scalars['Float']['input']>;
  lte?: InputMaybe<Scalars['Float']['input']>;
};

export type DossierCentAmountPaidFilterComparison = {
  gt?: InputMaybe<Scalars['Float']['input']>;
  gte?: InputMaybe<Scalars['Float']['input']>;
  lt?: InputMaybe<Scalars['Float']['input']>;
  lte?: InputMaybe<Scalars['Float']['input']>;
};

export type DossierCentAmountPaidSubtractedFilterComparison = {
  gt?: InputMaybe<Scalars['Float']['input']>;
  gte?: InputMaybe<Scalars['Float']['input']>;
  lt?: InputMaybe<Scalars['Float']['input']>;
  lte?: InputMaybe<Scalars['Float']['input']>;
};

export type DossierCentAmountProcessedFilterComparison = {
  gt?: InputMaybe<Scalars['Float']['input']>;
  gte?: InputMaybe<Scalars['Float']['input']>;
  lt?: InputMaybe<Scalars['Float']['input']>;
  lte?: InputMaybe<Scalars['Float']['input']>;
};

export type DossierClientIdFilterComparison = {
  eq?: InputMaybe<Scalars['UUID']['input']>;
  in?: InputMaybe<Array<Scalars['UUID']['input']>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  neq?: InputMaybe<Scalars['UUID']['input']>;
  notIn?: InputMaybe<Array<Scalars['UUID']['input']>>;
};

export type DossierClosedAtFilterComparison = {
  between?: InputMaybe<DossierClosedAtFilterComparisonBetween>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  notBetween?: InputMaybe<DossierClosedAtFilterComparisonBetween>;
};

export type DossierClosedAtFilterComparisonBetween = {
  lower: Scalars['DateTime']['input'];
  upper: Scalars['DateTime']['input'];
};

export type DossierConnection = {
  __typename?: 'DossierConnection';
  /** Array of nodes. */
  nodes: Array<Dossier>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type DossierCost = {
  __typename?: 'DossierCost';
  /** Category of the cost. */
  category: CostCategoryReference;
  /** ID of the cost category the cost belongs to. */
  categoryId: Scalars['UUID']['output'];
  /** Amount in cents of cost. */
  centAmount: Scalars['Float']['output'];
  /** Amount in cents of the cost that have been processed. */
  centAmountProcessed: Scalars['Float']['output'];
  /** Date time of when the recourse is created. */
  createdAt: Scalars['DateTime']['output'];
  /** Currency of the costs. */
  currency: Currency;
  /** Description of the cost. */
  description: Scalars['String']['output'];
  /** For who the costs are. */
  direction: WorkflowStepCostsDirection;
  /** Primary key of the recourse. */
  id: Scalars['UUID']['output'];
  /** Is this costs success based. */
  successBased: Scalars['Boolean']['output'];
  /** Tax rate of the cost. */
  taxRate?: Maybe<TaxRate>;
  /** Date time of when the recourse last updated. */
  updatedAt: Scalars['DateTime']['output'];
};

export type DossierCostCentAmountFilterComparison = {
  gt?: InputMaybe<Scalars['Float']['input']>;
  gte?: InputMaybe<Scalars['Float']['input']>;
  lt?: InputMaybe<Scalars['Float']['input']>;
  lte?: InputMaybe<Scalars['Float']['input']>;
};

export type DossierCostCentAmountProcessedFilterComparison = {
  gt?: InputMaybe<Scalars['Float']['input']>;
  gte?: InputMaybe<Scalars['Float']['input']>;
  lt?: InputMaybe<Scalars['Float']['input']>;
  lte?: InputMaybe<Scalars['Float']['input']>;
};

export type DossierCostConnection = {
  __typename?: 'DossierCostConnection';
  /** Array of nodes. */
  nodes: Array<DossierCost>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type DossierCostCreatedAtFilterComparison = {
  between?: InputMaybe<DossierCostCreatedAtFilterComparisonBetween>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  notBetween?: InputMaybe<DossierCostCreatedAtFilterComparisonBetween>;
};

export type DossierCostCreatedAtFilterComparisonBetween = {
  lower: Scalars['DateTime']['input'];
  upper: Scalars['DateTime']['input'];
};

export type DossierCostDeleteResponse = {
  __typename?: 'DossierCostDeleteResponse';
  /** ID of the cost category the cost belongs to. */
  categoryId?: Maybe<Scalars['UUID']['output']>;
  /** Amount in cents of cost. */
  centAmount?: Maybe<Scalars['Float']['output']>;
  /** Amount in cents of the cost that have been processed. */
  centAmountProcessed?: Maybe<Scalars['Float']['output']>;
  /** Date time of when the recourse is created. */
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  /** Currency of the costs. */
  currency?: Maybe<Currency>;
  /** Description of the cost. */
  description?: Maybe<Scalars['String']['output']>;
  /** For who the costs are. */
  direction?: Maybe<WorkflowStepCostsDirection>;
  /** Primary key of the recourse. */
  id?: Maybe<Scalars['UUID']['output']>;
  /** Is this costs success based. */
  successBased?: Maybe<Scalars['Boolean']['output']>;
  /** Date time of when the recourse last updated. */
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type DossierCostDescriptionFilterComparison = {
  eq?: InputMaybe<Scalars['String']['input']>;
  like?: InputMaybe<Scalars['String']['input']>;
  neq?: InputMaybe<Scalars['String']['input']>;
  notLike?: InputMaybe<Scalars['String']['input']>;
};

export type DossierCostDirectionFilterComparison = {
  eq?: InputMaybe<WorkflowStepCostsDirection>;
  in?: InputMaybe<Array<WorkflowStepCostsDirection>>;
  neq?: InputMaybe<WorkflowStepCostsDirection>;
  notIn?: InputMaybe<Array<WorkflowStepCostsDirection>>;
};

export type DossierCostDossierIdFilterComparison = {
  eq?: InputMaybe<Scalars['UUID']['input']>;
  in?: InputMaybe<Array<Scalars['UUID']['input']>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  neq?: InputMaybe<Scalars['UUID']['input']>;
  notIn?: InputMaybe<Array<Scalars['UUID']['input']>>;
};

export type DossierCostFilter = {
  and?: InputMaybe<Array<DossierCostFilter>>;
  centAmount?: InputMaybe<DossierCostCentAmountFilterComparison>;
  centAmountProcessed?: InputMaybe<DossierCostCentAmountProcessedFilterComparison>;
  createdAt?: InputMaybe<DossierCostCreatedAtFilterComparison>;
  description?: InputMaybe<DossierCostDescriptionFilterComparison>;
  direction?: InputMaybe<DossierCostDirectionFilterComparison>;
  dossierId: DossierCostDossierIdFilterComparison;
  id?: InputMaybe<DossierCostIdFilterComparison>;
  or?: InputMaybe<Array<DossierCostFilter>>;
  successBased?: InputMaybe<DossierCostSuccessBasedFilterComparison>;
  updatedAt?: InputMaybe<DossierCostUpdatedAtFilterComparison>;
};

export type DossierCostIdFilterComparison = {
  eq?: InputMaybe<Scalars['UUID']['input']>;
  in?: InputMaybe<Array<Scalars['UUID']['input']>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  neq?: InputMaybe<Scalars['UUID']['input']>;
  notIn?: InputMaybe<Array<Scalars['UUID']['input']>>;
};

export type DossierCostSort = {
  direction: SortDirection;
  field: DossierCostSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum DossierCostSortFields {
  CentAmount = 'centAmount',
  CentAmountProcessed = 'centAmountProcessed',
  CreatedAt = 'createdAt',
  Description = 'description',
  Direction = 'direction',
  DossierId = 'dossierId',
  Id = 'id',
  SuccessBased = 'successBased',
  UpdatedAt = 'updatedAt'
}

export type DossierCostSuccessBasedFilterComparison = {
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
};

export type DossierCostUpdatedAtFilterComparison = {
  between?: InputMaybe<DossierCostUpdatedAtFilterComparisonBetween>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  notBetween?: InputMaybe<DossierCostUpdatedAtFilterComparisonBetween>;
};

export type DossierCostUpdatedAtFilterComparisonBetween = {
  lower: Scalars['DateTime']['input'];
  upper: Scalars['DateTime']['input'];
};

export type DossierCountAggregate = {
  __typename?: 'DossierCountAggregate';
  centAmount?: Maybe<Scalars['Int']['output']>;
  centAmountClientCosts?: Maybe<Scalars['Int']['output']>;
  centAmountDebtorCosts?: Maybe<Scalars['Int']['output']>;
  centAmountDebtorCostsProcessed?: Maybe<Scalars['Int']['output']>;
  centAmountInterest?: Maybe<Scalars['Int']['output']>;
  centAmountInterestProcessed?: Maybe<Scalars['Int']['output']>;
  centAmountInvoices?: Maybe<Scalars['Int']['output']>;
  centAmountInvoicesProcessed?: Maybe<Scalars['Int']['output']>;
  centAmountOpen?: Maybe<Scalars['Int']['output']>;
  centAmountPaid?: Maybe<Scalars['Int']['output']>;
  centAmountPaidSubtracted?: Maybe<Scalars['Int']['output']>;
  centAmountProcessed?: Maybe<Scalars['Int']['output']>;
  clientId?: Maybe<Scalars['Int']['output']>;
  closedAt?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['Int']['output']>;
  currency?: Maybe<Scalars['Int']['output']>;
  debtorId?: Maybe<Scalars['Int']['output']>;
  debtorReference?: Maybe<Scalars['Int']['output']>;
  executorId?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  jobId?: Maybe<Scalars['Int']['output']>;
  lastProcessedAt?: Maybe<Scalars['Int']['output']>;
  manager?: Maybe<Scalars['Int']['output']>;
  number?: Maybe<Scalars['Int']['output']>;
  ocr?: Maybe<Scalars['Int']['output']>;
  payoutOnComplete?: Maybe<Scalars['Int']['output']>;
  startAt?: Maybe<Scalars['Int']['output']>;
  status?: Maybe<Scalars['Int']['output']>;
  type?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['Int']['output']>;
};

export type DossierCreatedAtFilterComparison = {
  between?: InputMaybe<DossierCreatedAtFilterComparisonBetween>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  notBetween?: InputMaybe<DossierCreatedAtFilterComparisonBetween>;
};

export type DossierCreatedAtFilterComparisonBetween = {
  lower: Scalars['DateTime']['input'];
  upper: Scalars['DateTime']['input'];
};

export type DossierCurrencyFilterComparison = {
  eq?: InputMaybe<Currency>;
  in?: InputMaybe<Array<Currency>>;
  neq?: InputMaybe<Currency>;
  notIn?: InputMaybe<Array<Currency>>;
};

export type DossierDebtorIdFilterComparison = {
  eq?: InputMaybe<Scalars['UUID']['input']>;
  in?: InputMaybe<Array<Scalars['UUID']['input']>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  neq?: InputMaybe<Scalars['UUID']['input']>;
  notIn?: InputMaybe<Array<Scalars['UUID']['input']>>;
};

export type DossierDebtorReferenceFilterComparison = {
  eq?: InputMaybe<Scalars['String']['input']>;
  like?: InputMaybe<Scalars['String']['input']>;
  neq?: InputMaybe<Scalars['String']['input']>;
  notLike?: InputMaybe<Scalars['String']['input']>;
};

export type DossierDeleteResponse = {
  __typename?: 'DossierDeleteResponse';
  /** Reference for the bailiff. */
  bailiffReference?: Maybe<Scalars['String']['output']>;
  /** Amount in cents of the dossier. */
  centAmount?: Maybe<Scalars['Float']['output']>;
  /** Amount in cents of the costs for the client. */
  centAmountClientCosts?: Maybe<Scalars['Float']['output']>;
  /** Amount in cents of the costs for the debtor. */
  centAmountDebtorCosts?: Maybe<Scalars['Float']['output']>;
  /** Amount in cents of the costs for the debtor that are processed. */
  centAmountDebtorCostsProcessed?: Maybe<Scalars['Float']['output']>;
  /** Amount in cents of interest that needs to be paid for the dossier. */
  centAmountInterest?: Maybe<Scalars['Float']['output']>;
  /** Amount in cents of the interest that have been processed. */
  centAmountInterestProcessed?: Maybe<Scalars['Float']['output']>;
  /** Amount in cents of the dossier invoices. */
  centAmountInvoices?: Maybe<Scalars['Float']['output']>;
  /** Amount in cents of the dossier invoices that have been processed. */
  centAmountInvoicesProcessed?: Maybe<Scalars['Float']['output']>;
  /** Date time the centAmount is last updated. */
  centAmountLastUpdatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Amount in cents of the dossier that still needs to be paid. */
  centAmountOpen?: Maybe<Scalars['Float']['output']>;
  /** Amount in cents of the dossier that is paid. */
  centAmountPaid?: Maybe<Scalars['Float']['output']>;
  /** Amount in cents of the dossier that is Paid with subtract true. */
  centAmountPaidSubtracted?: Maybe<Scalars['Float']['output']>;
  /** Amount in cents of the dossier that have been processed. */
  centAmountProcessed?: Maybe<Scalars['Float']['output']>;
  /** True if the centAmounts are being updated. */
  centAmountUpdating?: Maybe<Scalars['Boolean']['output']>;
  /** ID of the client. */
  clientId?: Maybe<Scalars['UUID']['output']>;
  /** Date time of when the dossier was closed. */
  closedAt?: Maybe<Scalars['DateTime']['output']>;
  /** ID of the contractor. */
  contractorId?: Maybe<Scalars['UUID']['output']>;
  /** Date time of when the recourse is created. */
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  /** Currency of the dossier. */
  currency?: Maybe<Currency>;
  /** ID of the debtor. */
  debtorId?: Maybe<Scalars['UUID']['output']>;
  /** Reference of the debtor. */
  debtorReference?: Maybe<Scalars['String']['output']>;
  /** ID of the executor. */
  executorId?: Maybe<Scalars['UUID']['output']>;
  /** Primary key of the recourse. */
  id?: Maybe<Scalars['UUID']['output']>;
  /** ID of the cost category that is used for the collected contractors interest. */
  interestCategoryId?: Maybe<Scalars['UUID']['output']>;
  /** Date time of when the dossier stops calculating interest. */
  interestEndDate?: Maybe<Scalars['DateTime']['output']>;
  /** Should the collected interest of this dossier be for the contractor. */
  interestForContractor?: Maybe<Scalars['Boolean']['output']>;
  /** Type of interest. */
  interestType?: Maybe<DossierInterestType>;
  /** ID of the job that created this dossier. */
  jobId?: Maybe<Scalars['UUID']['output']>;
  /** Date time of when the dossier was last processed. */
  lastProcessedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Manager of the dossier. */
  manager?: Maybe<Manager>;
  /** Number of the dossier. */
  number?: Maybe<Scalars['String']['output']>;
  /** OCR number of the dossier (Only used in Sweden). */
  ocr?: Maybe<Scalars['String']['output']>;
  /** Should the dossier be payout when its completed. */
  payoutOnComplete?: Maybe<Scalars['Boolean']['output']>;
  /** Source of the dossier. */
  source?: Maybe<DossierSource>;
  /** Date time of when the dossier was started. */
  startAt?: Maybe<Scalars['DateTime']['output']>;
  /** Status of the dossier. */
  status?: Maybe<DossierStatus>;
  /** Type of the dossier. */
  type?: Maybe<DossierType>;
  /** Date time of when the recourse last updated. */
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** ID of the workflow that is used in this dossier. */
  workflowId?: Maybe<Scalars['UUID']['output']>;
};

export type DossierExecutorIdFilterComparison = {
  eq?: InputMaybe<Scalars['UUID']['input']>;
  in?: InputMaybe<Array<Scalars['UUID']['input']>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  neq?: InputMaybe<Scalars['UUID']['input']>;
  notIn?: InputMaybe<Array<Scalars['UUID']['input']>>;
};

export type DossierFilter = {
  and?: InputMaybe<Array<DossierFilter>>;
  centAmount?: InputMaybe<DossierCentAmountFilterComparison>;
  centAmountClientCosts?: InputMaybe<DossierCentAmountClientCostsFilterComparison>;
  centAmountDebtorCosts?: InputMaybe<DossierCentAmountDebtorCostsFilterComparison>;
  centAmountDebtorCostsProcessed?: InputMaybe<DossierCentAmountDebtorCostsProcessedFilterComparison>;
  centAmountInterest?: InputMaybe<DossierCentAmountInterestFilterComparison>;
  centAmountInterestProcessed?: InputMaybe<DossierCentAmountInterestProcessedFilterComparison>;
  centAmountInvoices?: InputMaybe<DossierCentAmountInvoicesFilterComparison>;
  centAmountInvoicesProcessed?: InputMaybe<DossierCentAmountInvoicesProcessedFilterComparison>;
  centAmountOpen?: InputMaybe<DossierCentAmountOpenFilterComparison>;
  centAmountPaid?: InputMaybe<DossierCentAmountPaidFilterComparison>;
  centAmountPaidSubtracted?: InputMaybe<DossierCentAmountPaidSubtractedFilterComparison>;
  centAmountProcessed?: InputMaybe<DossierCentAmountProcessedFilterComparison>;
  client?: InputMaybe<DossierFilterCompanyReferenceFilter>;
  clientId?: InputMaybe<DossierClientIdFilterComparison>;
  closedAt?: InputMaybe<DossierClosedAtFilterComparison>;
  createdAt?: InputMaybe<DossierCreatedAtFilterComparison>;
  currency?: InputMaybe<DossierCurrencyFilterComparison>;
  debtor?: InputMaybe<DossierFilterCompanyReferenceFilter>;
  debtorId?: InputMaybe<DossierDebtorIdFilterComparison>;
  debtorReference?: InputMaybe<DossierDebtorReferenceFilterComparison>;
  executorId?: InputMaybe<DossierExecutorIdFilterComparison>;
  id?: InputMaybe<DossierIdFilterComparison>;
  jobId?: InputMaybe<DossierJobIdFilterComparison>;
  labels?: InputMaybe<DossierFilterLabelFilter>;
  lastProcessedAt?: InputMaybe<DossierLastProcessedAtFilterComparison>;
  manager?: InputMaybe<DossierManagerFilterComparison>;
  number?: InputMaybe<DossierNumberFilterComparison>;
  ocr?: InputMaybe<DossierOcrFilterComparison>;
  or?: InputMaybe<Array<DossierFilter>>;
  payoutOnComplete?: InputMaybe<DossierPayoutOnCompleteFilterComparison>;
  startAt?: InputMaybe<DossierStartAtFilterComparison>;
  status?: InputMaybe<DossierStatusFilterComparison>;
  type?: InputMaybe<DossierTypeFilterComparison>;
  updatedAt?: InputMaybe<DossierUpdatedAtFilterComparison>;
  workflow?: InputMaybe<DossierFilterWorkflowFilter>;
};

export type DossierFilterCompanyReferenceFilter = {
  and?: InputMaybe<Array<DossierFilterCompanyReferenceFilter>>;
  id?: InputMaybe<CompanyReferenceIdFilterComparison>;
  name?: InputMaybe<CompanyReferenceNameFilterComparison>;
  or?: InputMaybe<Array<DossierFilterCompanyReferenceFilter>>;
};

export type DossierFilterLabelFilter = {
  and?: InputMaybe<Array<DossierFilterLabelFilter>>;
  createdAt?: InputMaybe<LabelCreatedAtFilterComparison>;
  id?: InputMaybe<LabelIdFilterComparison>;
  name?: InputMaybe<LabelNameFilterComparison>;
  or?: InputMaybe<Array<DossierFilterLabelFilter>>;
  updatedAt?: InputMaybe<LabelUpdatedAtFilterComparison>;
};

export type DossierFilterWorkflowFilter = {
  and?: InputMaybe<Array<DossierFilterWorkflowFilter>>;
  companyId?: InputMaybe<WorkflowCompanyIdFilterComparison>;
  createdAt?: InputMaybe<WorkflowCreatedAtFilterComparison>;
  id?: InputMaybe<WorkflowIdFilterComparison>;
  name?: InputMaybe<WorkflowNameFilterComparison>;
  or?: InputMaybe<Array<DossierFilterWorkflowFilter>>;
  reachedEnd?: InputMaybe<WorkflowReachedEndFilterComparison>;
  type?: InputMaybe<WorkflowTypeFilterComparison>;
  updatedAt?: InputMaybe<WorkflowUpdatedAtFilterComparison>;
};

export type DossierIdFilterComparison = {
  eq?: InputMaybe<Scalars['UUID']['input']>;
  in?: InputMaybe<Array<Scalars['UUID']['input']>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  neq?: InputMaybe<Scalars['UUID']['input']>;
  notIn?: InputMaybe<Array<Scalars['UUID']['input']>>;
};

export enum DossierImportProvider {
  Afas = 'Afas',
  Carfac = 'Carfac',
  Snelstart = 'Snelstart',
  Sportlink = 'Sportlink',
  Straetus = 'Straetus',
  WiverSoft = 'WiverSoft'
}

export enum DossierImportType {
  Collection = 'Collection',
  DebtorManagement = 'DebtorManagement',
  DebtorManagementAddOnly = 'DebtorManagementAddOnly'
}

export type DossierInterestLine = {
  __typename?: 'DossierInterestLine';
  /** Amount in cents of the line interest is calculated over. */
  centAmount: Scalars['Float']['output'];
  /** Amount in cents of the interest of this line. */
  centAmountInterest?: Maybe<Scalars['Float']['output']>;
  /** Date time of when the recourse is created. */
  createdAt: Scalars['DateTime']['output'];
  /** Currency of the interest line. */
  currency: Currency;
  /** Amount of days the interest is calculated with. */
  days?: Maybe<Scalars['Float']['output']>;
  /** Amount of days that year has. */
  daysInYear?: Maybe<Scalars['Float']['output']>;
  /** End date of the interest line. */
  end?: Maybe<Scalars['DateTime']['output']>;
  /** Primary key of the recourse. */
  id: Scalars['UUID']['output'];
  /** Percentage the interest is calculated with. */
  percentage?: Maybe<Scalars['Float']['output']>;
  /** Start date of the interest line. */
  start: Scalars['DateTime']['output'];
  /** Date time of when the recourse last updated. */
  updatedAt: Scalars['DateTime']['output'];
};

export type DossierInterestLineCreatedAtFilterComparison = {
  between?: InputMaybe<DossierInterestLineCreatedAtFilterComparisonBetween>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  notBetween?: InputMaybe<DossierInterestLineCreatedAtFilterComparisonBetween>;
};

export type DossierInterestLineCreatedAtFilterComparisonBetween = {
  lower: Scalars['DateTime']['input'];
  upper: Scalars['DateTime']['input'];
};

export type DossierInterestLineDossierIdFilterComparison = {
  eq?: InputMaybe<Scalars['UUID']['input']>;
  in?: InputMaybe<Array<Scalars['UUID']['input']>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  neq?: InputMaybe<Scalars['UUID']['input']>;
  notIn?: InputMaybe<Array<Scalars['UUID']['input']>>;
};

export type DossierInterestLineFilter = {
  and?: InputMaybe<Array<DossierInterestLineFilter>>;
  createdAt?: InputMaybe<DossierInterestLineCreatedAtFilterComparison>;
  dossierId: DossierInterestLineDossierIdFilterComparison;
  id?: InputMaybe<DossierInterestLineIdFilterComparison>;
  or?: InputMaybe<Array<DossierInterestLineFilter>>;
  updatedAt?: InputMaybe<DossierInterestLineUpdatedAtFilterComparison>;
  weight?: InputMaybe<NumberFieldComparison>;
};

export type DossierInterestLineIdFilterComparison = {
  eq?: InputMaybe<Scalars['UUID']['input']>;
  in?: InputMaybe<Array<Scalars['UUID']['input']>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  neq?: InputMaybe<Scalars['UUID']['input']>;
  notIn?: InputMaybe<Array<Scalars['UUID']['input']>>;
};

export type DossierInterestLineSort = {
  direction: SortDirection;
  field: DossierInterestLineSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum DossierInterestLineSortFields {
  CreatedAt = 'createdAt',
  DossierId = 'dossierId',
  Id = 'id',
  UpdatedAt = 'updatedAt',
  Weight = 'weight'
}

export type DossierInterestLineUpdatedAtFilterComparison = {
  between?: InputMaybe<DossierInterestLineUpdatedAtFilterComparisonBetween>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  notBetween?: InputMaybe<DossierInterestLineUpdatedAtFilterComparisonBetween>;
};

export type DossierInterestLineUpdatedAtFilterComparisonBetween = {
  lower: Scalars['DateTime']['input'];
  upper: Scalars['DateTime']['input'];
};

/** The type of interest of a dossier. */
export enum DossierInterestType {
  Client = 'Client',
  Dossier = 'Dossier',
  Legal = 'Legal'
}

export type DossierInvoice = {
  __typename?: 'DossierInvoice';
  /** Amount in cents of the invoice. */
  centAmount: Scalars['Float']['output'];
  /** Amount in cents of the invoice that is still open (Only used in debtor management). */
  centAmountOpen: Scalars['Float']['output'];
  /** Amount in cents of the invoice that have been paid (Only used in debtor management). */
  centAmountPaid: Scalars['Float']['output'];
  /** Client of the dossier invoice. */
  client: CompanyReference;
  /** ID of the contractor. */
  contractorId: Scalars['UUID']['output'];
  /** Date time of when the recourse is created. */
  createdAt: Scalars['DateTime']['output'];
  /** Creator of the dossier invoice. */
  creator: Creator;
  /** Currency of the invoice. */
  currency: Currency;
  /** Date of the invoice. */
  date: Scalars['DateTime']['output'];
  /** Debtor of the dossier. */
  debtor: CompanyReference;
  /** ID of the debtor. */
  debtorId: Scalars['UUID']['output'];
  /** Dossier this invoice belongs to. */
  dossier: DossierReference;
  /** ID of the dossier. */
  dossierId: Scalars['UUID']['output'];
  /** Days until this invoice expires. */
  expiresInDays: Scalars['Float']['output'];
  /** File connected to the invoice. */
  file?: Maybe<FileReference>;
  /** ID of associated file. */
  fileId?: Maybe<Scalars['UUID']['output']>;
  /** Primary key of the recourse. */
  id: Scalars['UUID']['output'];
  /** Type of interest. */
  interest?: Maybe<DossierInvoiceInterest>;
  /** Date to calculate interest from. (Expire date of the invoice) */
  interestFromDate?: Maybe<Scalars['DateTime']['output']>;
  /** ID of the job that created this dossier invoice. */
  jobId?: Maybe<Scalars['UUID']['output']>;
  /** Amount of days / work-days. */
  paymentTerm: Scalars['Float']['output'];
  /** Amount of days / work-days. */
  paymentTermType: InvoicePaymentTerm;
  /** Reference of the invoice. This needs to be unique! */
  reference: Scalars['String']['output'];
  /** Status of the invoice. (Only used in debtor management) */
  status: DossierInvoiceStatus;
  /** Date time of when the recourse last updated. */
  updatedAt: Scalars['DateTime']['output'];
};

export type DossierInvoiceAggregateFilter = {
  and?: InputMaybe<Array<DossierInvoiceAggregateFilter>>;
  centAmount?: InputMaybe<DossierInvoiceCentAmountFilterComparison>;
  centAmountOpen?: InputMaybe<DossierInvoiceCentAmountOpenFilterComparison>;
  centAmountPaid?: InputMaybe<DossierInvoiceCentAmountPaidFilterComparison>;
  clientId?: InputMaybe<DossierInvoiceClientIdFilterComparison>;
  createdAt?: InputMaybe<DossierInvoiceCreatedAtFilterComparison>;
  currency?: InputMaybe<DossierInvoiceCurrencyFilterComparison>;
  date?: InputMaybe<DossierInvoiceDateFilterComparison>;
  dossier?: InputMaybe<DossierInvoiceAggregateFilterDossierReferenceAggregateFilter>;
  dossierId?: InputMaybe<DossierInvoiceDossierIdFilterComparison>;
  expiresInDays?: InputMaybe<DossierInvoiceExpiresInDaysFilterComparison>;
  id?: InputMaybe<DossierInvoiceIdFilterComparison>;
  interest?: InputMaybe<DossierInvoiceInterestFilterComparison>;
  interestFromDate?: InputMaybe<DossierInvoiceInterestFromDateFilterComparison>;
  jobId?: InputMaybe<DossierInvoiceJobIdFilterComparison>;
  or?: InputMaybe<Array<DossierInvoiceAggregateFilter>>;
  reference?: InputMaybe<DossierInvoiceReferenceFilterComparison>;
  updatedAt?: InputMaybe<DossierInvoiceUpdatedAtFilterComparison>;
};

export type DossierInvoiceAggregateFilterDossierReferenceAggregateFilter = {
  and?: InputMaybe<Array<DossierInvoiceAggregateFilterDossierReferenceAggregateFilter>>;
  id?: InputMaybe<DossierReferenceIdFilterComparison>;
  number?: InputMaybe<DossierReferenceNumberFilterComparison>;
  or?: InputMaybe<Array<DossierInvoiceAggregateFilterDossierReferenceAggregateFilter>>;
  status?: InputMaybe<DossierReferenceStatusFilterComparison>;
  type?: InputMaybe<DossierReferenceTypeFilterComparison>;
};

export type DossierInvoiceAggregateGroupBy = {
  __typename?: 'DossierInvoiceAggregateGroupBy';
  centAmount?: Maybe<Scalars['Float']['output']>;
  centAmountOpen?: Maybe<Scalars['Float']['output']>;
  centAmountPaid?: Maybe<Scalars['Float']['output']>;
  clientId?: Maybe<Scalars['UUID']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  currency?: Maybe<Currency>;
  date?: Maybe<Scalars['DateTime']['output']>;
  dossierId?: Maybe<Scalars['UUID']['output']>;
  expiresInDays?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['UUID']['output']>;
  interest?: Maybe<DossierInvoiceInterest>;
  interestFromDate?: Maybe<Scalars['DateTime']['output']>;
  jobId?: Maybe<Scalars['UUID']['output']>;
  reference?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};


export type DossierInvoiceAggregateGroupByCreatedAtArgs = {
  by?: GroupBy;
};


export type DossierInvoiceAggregateGroupByDateArgs = {
  by?: GroupBy;
};


export type DossierInvoiceAggregateGroupByInterestFromDateArgs = {
  by?: GroupBy;
};


export type DossierInvoiceAggregateGroupByUpdatedAtArgs = {
  by?: GroupBy;
};

export type DossierInvoiceAggregateResponse = {
  __typename?: 'DossierInvoiceAggregateResponse';
  avg?: Maybe<DossierInvoiceAvgAggregate>;
  count?: Maybe<DossierInvoiceCountAggregate>;
  groupBy?: Maybe<DossierInvoiceAggregateGroupBy>;
  max?: Maybe<DossierInvoiceMaxAggregate>;
  min?: Maybe<DossierInvoiceMinAggregate>;
  sum?: Maybe<DossierInvoiceSumAggregate>;
};

export type DossierInvoiceAvgAggregate = {
  __typename?: 'DossierInvoiceAvgAggregate';
  centAmount?: Maybe<Scalars['Float']['output']>;
  centAmountOpen?: Maybe<Scalars['Float']['output']>;
  centAmountPaid?: Maybe<Scalars['Float']['output']>;
  expiresInDays?: Maybe<Scalars['Float']['output']>;
};

export type DossierInvoiceCentAmountFilterComparison = {
  gt?: InputMaybe<Scalars['Float']['input']>;
  gte?: InputMaybe<Scalars['Float']['input']>;
  lt?: InputMaybe<Scalars['Float']['input']>;
  lte?: InputMaybe<Scalars['Float']['input']>;
};

export type DossierInvoiceCentAmountOpenFilterComparison = {
  gt?: InputMaybe<Scalars['Float']['input']>;
  gte?: InputMaybe<Scalars['Float']['input']>;
  lt?: InputMaybe<Scalars['Float']['input']>;
  lte?: InputMaybe<Scalars['Float']['input']>;
};

export type DossierInvoiceCentAmountPaidFilterComparison = {
  gt?: InputMaybe<Scalars['Float']['input']>;
  gte?: InputMaybe<Scalars['Float']['input']>;
  lt?: InputMaybe<Scalars['Float']['input']>;
  lte?: InputMaybe<Scalars['Float']['input']>;
};

export type DossierInvoiceClientIdFilterComparison = {
  eq?: InputMaybe<Scalars['UUID']['input']>;
  in?: InputMaybe<Array<Scalars['UUID']['input']>>;
  neq?: InputMaybe<Scalars['UUID']['input']>;
  notIn?: InputMaybe<Array<Scalars['UUID']['input']>>;
};

export type DossierInvoiceConnection = {
  __typename?: 'DossierInvoiceConnection';
  /** Array of nodes. */
  nodes: Array<DossierInvoice>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type DossierInvoiceCountAggregate = {
  __typename?: 'DossierInvoiceCountAggregate';
  centAmount?: Maybe<Scalars['Int']['output']>;
  centAmountOpen?: Maybe<Scalars['Int']['output']>;
  centAmountPaid?: Maybe<Scalars['Int']['output']>;
  clientId?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['Int']['output']>;
  currency?: Maybe<Scalars['Int']['output']>;
  date?: Maybe<Scalars['Int']['output']>;
  dossierId?: Maybe<Scalars['Int']['output']>;
  expiresInDays?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  interest?: Maybe<Scalars['Int']['output']>;
  interestFromDate?: Maybe<Scalars['Int']['output']>;
  jobId?: Maybe<Scalars['Int']['output']>;
  reference?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['Int']['output']>;
};

export type DossierInvoiceCreatedAtFilterComparison = {
  between?: InputMaybe<DossierInvoiceCreatedAtFilterComparisonBetween>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  notBetween?: InputMaybe<DossierInvoiceCreatedAtFilterComparisonBetween>;
};

export type DossierInvoiceCreatedAtFilterComparisonBetween = {
  lower: Scalars['DateTime']['input'];
  upper: Scalars['DateTime']['input'];
};

export type DossierInvoiceCurrencyFilterComparison = {
  eq?: InputMaybe<Currency>;
  in?: InputMaybe<Array<Currency>>;
  neq?: InputMaybe<Currency>;
  notIn?: InputMaybe<Array<Currency>>;
};

export type DossierInvoiceDateFilterComparison = {
  between?: InputMaybe<DossierInvoiceDateFilterComparisonBetween>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  notBetween?: InputMaybe<DossierInvoiceDateFilterComparisonBetween>;
};

export type DossierInvoiceDateFilterComparisonBetween = {
  lower: Scalars['DateTime']['input'];
  upper: Scalars['DateTime']['input'];
};

export type DossierInvoiceDeleteResponse = {
  __typename?: 'DossierInvoiceDeleteResponse';
  /** Amount in cents of the invoice. */
  centAmount?: Maybe<Scalars['Float']['output']>;
  /** Amount in cents of the invoice that is still open (Only used in debtor management). */
  centAmountOpen?: Maybe<Scalars['Float']['output']>;
  /** Amount in cents of the invoice that have been paid (Only used in debtor management). */
  centAmountPaid?: Maybe<Scalars['Float']['output']>;
  /** ID of the contractor. */
  contractorId?: Maybe<Scalars['UUID']['output']>;
  /** Date time of when the recourse is created. */
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  /** Currency of the invoice. */
  currency?: Maybe<Currency>;
  /** Date of the invoice. */
  date?: Maybe<Scalars['DateTime']['output']>;
  /** ID of the debtor. */
  debtorId?: Maybe<Scalars['UUID']['output']>;
  /** ID of the dossier. */
  dossierId?: Maybe<Scalars['UUID']['output']>;
  /** Days until this invoice expires. */
  expiresInDays?: Maybe<Scalars['Float']['output']>;
  /** ID of associated file. */
  fileId?: Maybe<Scalars['UUID']['output']>;
  /** Primary key of the recourse. */
  id?: Maybe<Scalars['UUID']['output']>;
  /** Type of interest. */
  interest?: Maybe<DossierInvoiceInterest>;
  /** Date to calculate interest from. (Expire date of the invoice) */
  interestFromDate?: Maybe<Scalars['DateTime']['output']>;
  /** ID of the job that created this dossier invoice. */
  jobId?: Maybe<Scalars['UUID']['output']>;
  /** Amount of days / work-days. */
  paymentTerm?: Maybe<Scalars['Float']['output']>;
  /** Amount of days / work-days. */
  paymentTermType?: Maybe<InvoicePaymentTerm>;
  /** Reference of the invoice. This needs to be unique! */
  reference?: Maybe<Scalars['String']['output']>;
  /** Status of the invoice. (Only used in debtor management) */
  status?: Maybe<DossierInvoiceStatus>;
  /** Date time of when the recourse last updated. */
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type DossierInvoiceDossierIdFilterComparison = {
  eq?: InputMaybe<Scalars['UUID']['input']>;
  in?: InputMaybe<Array<Scalars['UUID']['input']>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  neq?: InputMaybe<Scalars['UUID']['input']>;
  notIn?: InputMaybe<Array<Scalars['UUID']['input']>>;
};

export type DossierInvoiceExpiresInDaysFilterComparison = {
  between?: InputMaybe<DossierInvoiceExpiresInDaysFilterComparisonBetween>;
  gt?: InputMaybe<Scalars['Float']['input']>;
  gte?: InputMaybe<Scalars['Float']['input']>;
  lt?: InputMaybe<Scalars['Float']['input']>;
  lte?: InputMaybe<Scalars['Float']['input']>;
  notBetween?: InputMaybe<DossierInvoiceExpiresInDaysFilterComparisonBetween>;
};

export type DossierInvoiceExpiresInDaysFilterComparisonBetween = {
  lower: Scalars['Float']['input'];
  upper: Scalars['Float']['input'];
};

export type DossierInvoiceFilter = {
  and?: InputMaybe<Array<DossierInvoiceFilter>>;
  centAmount?: InputMaybe<DossierInvoiceCentAmountFilterComparison>;
  centAmountOpen?: InputMaybe<DossierInvoiceCentAmountOpenFilterComparison>;
  centAmountPaid?: InputMaybe<DossierInvoiceCentAmountPaidFilterComparison>;
  clientId?: InputMaybe<DossierInvoiceClientIdFilterComparison>;
  createdAt?: InputMaybe<DossierInvoiceCreatedAtFilterComparison>;
  currency?: InputMaybe<DossierInvoiceCurrencyFilterComparison>;
  date?: InputMaybe<DossierInvoiceDateFilterComparison>;
  dossier?: InputMaybe<DossierInvoiceFilterDossierReferenceFilter>;
  dossierId?: InputMaybe<DossierInvoiceDossierIdFilterComparison>;
  expiresInDays?: InputMaybe<DossierInvoiceExpiresInDaysFilterComparison>;
  id?: InputMaybe<DossierInvoiceIdFilterComparison>;
  interest?: InputMaybe<DossierInvoiceInterestFilterComparison>;
  interestFromDate?: InputMaybe<DossierInvoiceInterestFromDateFilterComparison>;
  jobId?: InputMaybe<DossierInvoiceJobIdFilterComparison>;
  or?: InputMaybe<Array<DossierInvoiceFilter>>;
  reference?: InputMaybe<DossierInvoiceReferenceFilterComparison>;
  updatedAt?: InputMaybe<DossierInvoiceUpdatedAtFilterComparison>;
};

export type DossierInvoiceFilterDossierReferenceFilter = {
  and?: InputMaybe<Array<DossierInvoiceFilterDossierReferenceFilter>>;
  id?: InputMaybe<DossierReferenceIdFilterComparison>;
  number?: InputMaybe<DossierReferenceNumberFilterComparison>;
  or?: InputMaybe<Array<DossierInvoiceFilterDossierReferenceFilter>>;
  status?: InputMaybe<DossierReferenceStatusFilterComparison>;
  type?: InputMaybe<DossierReferenceTypeFilterComparison>;
};

export type DossierInvoiceIdFilterComparison = {
  eq?: InputMaybe<Scalars['UUID']['input']>;
  in?: InputMaybe<Array<Scalars['UUID']['input']>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  neq?: InputMaybe<Scalars['UUID']['input']>;
  notIn?: InputMaybe<Array<Scalars['UUID']['input']>>;
};

/** Type of interest of an dossier invoice. */
export enum DossierInvoiceInterest {
  Cumulative = 'Cumulative',
  None = 'None',
  Single = 'Single'
}

export type DossierInvoiceInterestFilterComparison = {
  eq?: InputMaybe<DossierInvoiceInterest>;
  in?: InputMaybe<Array<DossierInvoiceInterest>>;
  neq?: InputMaybe<DossierInvoiceInterest>;
  notIn?: InputMaybe<Array<DossierInvoiceInterest>>;
};

export type DossierInvoiceInterestFromDateFilterComparison = {
  between?: InputMaybe<DossierInvoiceInterestFromDateFilterComparisonBetween>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  notBetween?: InputMaybe<DossierInvoiceInterestFromDateFilterComparisonBetween>;
};

export type DossierInvoiceInterestFromDateFilterComparisonBetween = {
  lower: Scalars['DateTime']['input'];
  upper: Scalars['DateTime']['input'];
};

export type DossierInvoiceJobIdFilterComparison = {
  eq?: InputMaybe<Scalars['UUID']['input']>;
  in?: InputMaybe<Array<Scalars['UUID']['input']>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  neq?: InputMaybe<Scalars['UUID']['input']>;
  notIn?: InputMaybe<Array<Scalars['UUID']['input']>>;
};

export type DossierInvoiceMaxAggregate = {
  __typename?: 'DossierInvoiceMaxAggregate';
  centAmount?: Maybe<Scalars['Float']['output']>;
  centAmountOpen?: Maybe<Scalars['Float']['output']>;
  centAmountPaid?: Maybe<Scalars['Float']['output']>;
  clientId?: Maybe<Scalars['UUID']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  currency?: Maybe<Currency>;
  date?: Maybe<Scalars['DateTime']['output']>;
  dossierId?: Maybe<Scalars['UUID']['output']>;
  expiresInDays?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['UUID']['output']>;
  interest?: Maybe<DossierInvoiceInterest>;
  interestFromDate?: Maybe<Scalars['DateTime']['output']>;
  jobId?: Maybe<Scalars['UUID']['output']>;
  reference?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type DossierInvoiceMinAggregate = {
  __typename?: 'DossierInvoiceMinAggregate';
  centAmount?: Maybe<Scalars['Float']['output']>;
  centAmountOpen?: Maybe<Scalars['Float']['output']>;
  centAmountPaid?: Maybe<Scalars['Float']['output']>;
  clientId?: Maybe<Scalars['UUID']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  currency?: Maybe<Currency>;
  date?: Maybe<Scalars['DateTime']['output']>;
  dossierId?: Maybe<Scalars['UUID']['output']>;
  expiresInDays?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['UUID']['output']>;
  interest?: Maybe<DossierInvoiceInterest>;
  interestFromDate?: Maybe<Scalars['DateTime']['output']>;
  jobId?: Maybe<Scalars['UUID']['output']>;
  reference?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type DossierInvoiceReference = {
  __typename?: 'DossierInvoiceReference';
  /** Amount in cents of the dossier invoice. */
  centAmount: Scalars['Float']['output'];
  /** Amount in cents of the dossier invoice that still needs to be paid. */
  centAmountOpen: Scalars['Float']['output'];
  /** Amount in cents of the dossier invoice that is paid. */
  centAmountPaid: Scalars['Float']['output'];
  /** Primary key of the recourse. */
  id: Scalars['UUID']['output'];
  /** Reference of the dossier invoice. */
  reference: Scalars['String']['output'];
};

export type DossierInvoiceReferenceFilterComparison = {
  eq?: InputMaybe<Scalars['String']['input']>;
  like?: InputMaybe<Scalars['String']['input']>;
  neq?: InputMaybe<Scalars['String']['input']>;
  notLike?: InputMaybe<Scalars['String']['input']>;
};

export type DossierInvoiceSort = {
  direction: SortDirection;
  field: DossierInvoiceSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum DossierInvoiceSortFields {
  CentAmount = 'centAmount',
  CentAmountOpen = 'centAmountOpen',
  CentAmountPaid = 'centAmountPaid',
  ClientId = 'clientId',
  CreatedAt = 'createdAt',
  Currency = 'currency',
  Date = 'date',
  DossierId = 'dossierId',
  ExpiresInDays = 'expiresInDays',
  Id = 'id',
  Interest = 'interest',
  InterestFromDate = 'interestFromDate',
  JobId = 'jobId',
  Reference = 'reference',
  UpdatedAt = 'updatedAt'
}

/** Status of an dossier invoice. */
export enum DossierInvoiceStatus {
  Dispute = 'Dispute',
  Normal = 'Normal'
}

export type DossierInvoiceSumAggregate = {
  __typename?: 'DossierInvoiceSumAggregate';
  centAmount?: Maybe<Scalars['Float']['output']>;
  centAmountOpen?: Maybe<Scalars['Float']['output']>;
  centAmountPaid?: Maybe<Scalars['Float']['output']>;
  expiresInDays?: Maybe<Scalars['Float']['output']>;
};

export type DossierInvoiceUpdatedAtFilterComparison = {
  between?: InputMaybe<DossierInvoiceUpdatedAtFilterComparisonBetween>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  notBetween?: InputMaybe<DossierInvoiceUpdatedAtFilterComparisonBetween>;
};

export type DossierInvoiceUpdatedAtFilterComparisonBetween = {
  lower: Scalars['DateTime']['input'];
  upper: Scalars['DateTime']['input'];
};

export type DossierJobIdFilterComparison = {
  eq?: InputMaybe<Scalars['UUID']['input']>;
  in?: InputMaybe<Array<Scalars['UUID']['input']>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  neq?: InputMaybe<Scalars['UUID']['input']>;
  notIn?: InputMaybe<Array<Scalars['UUID']['input']>>;
};

export type DossierLastProcessedAtFilterComparison = {
  between?: InputMaybe<DossierLastProcessedAtFilterComparisonBetween>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  notBetween?: InputMaybe<DossierLastProcessedAtFilterComparisonBetween>;
};

export type DossierLastProcessedAtFilterComparisonBetween = {
  lower: Scalars['DateTime']['input'];
  upper: Scalars['DateTime']['input'];
};

export type DossierManagerFilterComparison = {
  eq?: InputMaybe<Manager>;
  in?: InputMaybe<Array<Manager>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  neq?: InputMaybe<Manager>;
  notIn?: InputMaybe<Array<Manager>>;
};

export type DossierMaxAggregate = {
  __typename?: 'DossierMaxAggregate';
  centAmount?: Maybe<Scalars['Float']['output']>;
  centAmountClientCosts?: Maybe<Scalars['Float']['output']>;
  centAmountDebtorCosts?: Maybe<Scalars['Float']['output']>;
  centAmountDebtorCostsProcessed?: Maybe<Scalars['Float']['output']>;
  centAmountInterest?: Maybe<Scalars['Float']['output']>;
  centAmountInterestProcessed?: Maybe<Scalars['Float']['output']>;
  centAmountInvoices?: Maybe<Scalars['Float']['output']>;
  centAmountInvoicesProcessed?: Maybe<Scalars['Float']['output']>;
  centAmountOpen?: Maybe<Scalars['Float']['output']>;
  centAmountPaid?: Maybe<Scalars['Float']['output']>;
  centAmountPaidSubtracted?: Maybe<Scalars['Float']['output']>;
  centAmountProcessed?: Maybe<Scalars['Float']['output']>;
  clientId?: Maybe<Scalars['UUID']['output']>;
  closedAt?: Maybe<Scalars['DateTime']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  currency?: Maybe<Currency>;
  debtorId?: Maybe<Scalars['UUID']['output']>;
  debtorReference?: Maybe<Scalars['String']['output']>;
  executorId?: Maybe<Scalars['UUID']['output']>;
  id?: Maybe<Scalars['UUID']['output']>;
  jobId?: Maybe<Scalars['UUID']['output']>;
  lastProcessedAt?: Maybe<Scalars['DateTime']['output']>;
  manager?: Maybe<Manager>;
  number?: Maybe<Scalars['String']['output']>;
  ocr?: Maybe<Scalars['String']['output']>;
  startAt?: Maybe<Scalars['DateTime']['output']>;
  status?: Maybe<DossierStatus>;
  type?: Maybe<DossierType>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type DossierMinAggregate = {
  __typename?: 'DossierMinAggregate';
  centAmount?: Maybe<Scalars['Float']['output']>;
  centAmountClientCosts?: Maybe<Scalars['Float']['output']>;
  centAmountDebtorCosts?: Maybe<Scalars['Float']['output']>;
  centAmountDebtorCostsProcessed?: Maybe<Scalars['Float']['output']>;
  centAmountInterest?: Maybe<Scalars['Float']['output']>;
  centAmountInterestProcessed?: Maybe<Scalars['Float']['output']>;
  centAmountInvoices?: Maybe<Scalars['Float']['output']>;
  centAmountInvoicesProcessed?: Maybe<Scalars['Float']['output']>;
  centAmountOpen?: Maybe<Scalars['Float']['output']>;
  centAmountPaid?: Maybe<Scalars['Float']['output']>;
  centAmountPaidSubtracted?: Maybe<Scalars['Float']['output']>;
  centAmountProcessed?: Maybe<Scalars['Float']['output']>;
  clientId?: Maybe<Scalars['UUID']['output']>;
  closedAt?: Maybe<Scalars['DateTime']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  currency?: Maybe<Currency>;
  debtorId?: Maybe<Scalars['UUID']['output']>;
  debtorReference?: Maybe<Scalars['String']['output']>;
  executorId?: Maybe<Scalars['UUID']['output']>;
  id?: Maybe<Scalars['UUID']['output']>;
  jobId?: Maybe<Scalars['UUID']['output']>;
  lastProcessedAt?: Maybe<Scalars['DateTime']['output']>;
  manager?: Maybe<Manager>;
  number?: Maybe<Scalars['String']['output']>;
  ocr?: Maybe<Scalars['String']['output']>;
  startAt?: Maybe<Scalars['DateTime']['output']>;
  status?: Maybe<DossierStatus>;
  type?: Maybe<DossierType>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type DossierNumberFilterComparison = {
  eq?: InputMaybe<Scalars['String']['input']>;
  like?: InputMaybe<Scalars['String']['input']>;
  neq?: InputMaybe<Scalars['String']['input']>;
  notLike?: InputMaybe<Scalars['String']['input']>;
};

export type DossierOcrFilterComparison = {
  eq?: InputMaybe<Scalars['String']['input']>;
  like?: InputMaybe<Scalars['String']['input']>;
  neq?: InputMaybe<Scalars['String']['input']>;
  notLike?: InputMaybe<Scalars['String']['input']>;
};

export type DossierPayoutOnCompleteFilterComparison = {
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
};

export type DossierReference = {
  __typename?: 'DossierReference';
  /** Amount in cents of the dossier that still needs to be paid. */
  centAmountOpen: Scalars['Float']['output'];
  /** Primary key of the recourse. */
  id: Scalars['UUID']['output'];
  /** ID of the job that created this dossier. */
  jobId?: Maybe<Scalars['UUID']['output']>;
  /** Number of the dossier. */
  number: Scalars['String']['output'];
  /** Status of the dossier. */
  status: DossierStatus;
  /** Type of the dossier. */
  type: DossierType;
};

export type DossierReferenceIdFilterComparison = {
  eq?: InputMaybe<Scalars['UUID']['input']>;
  in?: InputMaybe<Array<Scalars['UUID']['input']>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  neq?: InputMaybe<Scalars['UUID']['input']>;
  notIn?: InputMaybe<Array<Scalars['UUID']['input']>>;
};

export type DossierReferenceNumberFilterComparison = {
  eq?: InputMaybe<Scalars['String']['input']>;
  like?: InputMaybe<Scalars['String']['input']>;
  neq?: InputMaybe<Scalars['String']['input']>;
  notLike?: InputMaybe<Scalars['String']['input']>;
};

export type DossierReferenceStatusFilterComparison = {
  eq?: InputMaybe<DossierStatus>;
  in?: InputMaybe<Array<DossierStatus>>;
  neq?: InputMaybe<DossierStatus>;
  notIn?: InputMaybe<Array<DossierStatus>>;
};

export type DossierReferenceTypeFilterComparison = {
  eq?: InputMaybe<DossierType>;
  in?: InputMaybe<Array<DossierType>>;
  neq?: InputMaybe<DossierType>;
  notIn?: InputMaybe<Array<DossierType>>;
};

export type DossierSort = {
  direction: SortDirection;
  field: DossierSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum DossierSortFields {
  CentAmount = 'centAmount',
  CentAmountClientCosts = 'centAmountClientCosts',
  CentAmountDebtorCosts = 'centAmountDebtorCosts',
  CentAmountDebtorCostsProcessed = 'centAmountDebtorCostsProcessed',
  CentAmountInterest = 'centAmountInterest',
  CentAmountInterestProcessed = 'centAmountInterestProcessed',
  CentAmountInvoices = 'centAmountInvoices',
  CentAmountInvoicesProcessed = 'centAmountInvoicesProcessed',
  CentAmountOpen = 'centAmountOpen',
  CentAmountPaid = 'centAmountPaid',
  CentAmountPaidSubtracted = 'centAmountPaidSubtracted',
  CentAmountProcessed = 'centAmountProcessed',
  ClientId = 'clientId',
  ClosedAt = 'closedAt',
  CreatedAt = 'createdAt',
  Currency = 'currency',
  DebtorId = 'debtorId',
  DebtorReference = 'debtorReference',
  ExecutorId = 'executorId',
  Id = 'id',
  JobId = 'jobId',
  LastProcessedAt = 'lastProcessedAt',
  Manager = 'manager',
  Number = 'number',
  Ocr = 'ocr',
  PayoutOnComplete = 'payoutOnComplete',
  StartAt = 'startAt',
  Status = 'status',
  Type = 'type',
  UpdatedAt = 'updatedAt'
}

/** The source of a dossier. */
export enum DossierSource {
  Import = 'Import',
  Manual = 'Manual'
}

export type DossierStartAtFilterComparison = {
  between?: InputMaybe<DossierStartAtFilterComparisonBetween>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  notBetween?: InputMaybe<DossierStartAtFilterComparisonBetween>;
};

export type DossierStartAtFilterComparisonBetween = {
  lower: Scalars['DateTime']['input'];
  upper: Scalars['DateTime']['input'];
};

/** The statuses of a dossier. */
export enum DossierStatus {
  AutoOpen = 'AutoOpen',
  AutoResume = 'AutoResume',
  Closed = 'Closed',
  Draft = 'Draft',
  Open = 'Open',
  Paid = 'Paid',
  Paused = 'Paused',
  PaymentArrangement = 'PaymentArrangement'
}

export type DossierStatusFilterComparison = {
  eq?: InputMaybe<DossierStatus>;
  in?: InputMaybe<Array<DossierStatus>>;
  neq?: InputMaybe<DossierStatus>;
  notIn?: InputMaybe<Array<DossierStatus>>;
};

export type DossierSumAggregate = {
  __typename?: 'DossierSumAggregate';
  centAmount?: Maybe<Scalars['Float']['output']>;
  centAmountClientCosts?: Maybe<Scalars['Float']['output']>;
  centAmountDebtorCosts?: Maybe<Scalars['Float']['output']>;
  centAmountDebtorCostsProcessed?: Maybe<Scalars['Float']['output']>;
  centAmountInterest?: Maybe<Scalars['Float']['output']>;
  centAmountInterestProcessed?: Maybe<Scalars['Float']['output']>;
  centAmountInvoices?: Maybe<Scalars['Float']['output']>;
  centAmountInvoicesProcessed?: Maybe<Scalars['Float']['output']>;
  centAmountOpen?: Maybe<Scalars['Float']['output']>;
  centAmountPaid?: Maybe<Scalars['Float']['output']>;
  centAmountPaidSubtracted?: Maybe<Scalars['Float']['output']>;
  centAmountProcessed?: Maybe<Scalars['Float']['output']>;
};

export type DossierTimelineItem = {
  __typename?: 'DossierTimelineItem';
  /** Contact name that received this item. */
  contactName?: Maybe<Scalars['String']['output']>;
  /** Date the item is executed. */
  date: Scalars['DateTime']['output'];
  /** Description of the item. */
  description?: Maybe<Scalars['String']['output']>;
  /** Direction of the item. */
  direction: DossierTimelineItemDirection;
  /** Sender of the item. */
  from?: Maybe<Scalars['String']['output']>;
  /** Primary key of the recourse. */
  id: Scalars['UUID']['output'];
  /** Receiver of the item. */
  to?: Maybe<Scalars['String']['output']>;
  /** Type of the item. */
  type: DossierTimelineItemType;
};

export enum DossierTimelineItemDirection {
  Incoming = 'Incoming',
  Neutral = 'Neutral',
  Outgoing = 'Outgoing'
}

export type DossierTimelineItemOffsetConnection = {
  __typename?: 'DossierTimelineItemOffsetConnection';
  /** Array of nodes. */
  nodes: Array<DossierTimelineItem>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export enum DossierTimelineItemType {
  Call = 'Call',
  Email = 'Email',
  Letter = 'Letter',
  Sms = 'SMS'
}

/** The type of a dossier. */
export enum DossierType {
  Collection = 'Collection',
  DebtorManagement = 'DebtorManagement'
}

export type DossierTypeFilterComparison = {
  eq?: InputMaybe<DossierType>;
  in?: InputMaybe<Array<DossierType>>;
  neq?: InputMaybe<DossierType>;
  notIn?: InputMaybe<Array<DossierType>>;
};

export type DossierUpdatedAtFilterComparison = {
  between?: InputMaybe<DossierUpdatedAtFilterComparisonBetween>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  notBetween?: InputMaybe<DossierUpdatedAtFilterComparisonBetween>;
};

export type DossierUpdatedAtFilterComparisonBetween = {
  lower: Scalars['DateTime']['input'];
  upper: Scalars['DateTime']['input'];
};

export type DownloadOneDossierInput = {
  /** Should calls be included in download. */
  includeCalls?: Scalars['Boolean']['input'];
  /** Should emails be included in download. */
  includeEmails?: Scalars['Boolean']['input'];
  /** Should files be included in download. */
  includeFiles?: Scalars['Boolean']['input'];
  /** Should notes be included in download. */
  includeNotes?: Scalars['Boolean']['input'];
  /** Should SMS be included in download. */
  includeSMS?: Scalars['Boolean']['input'];
};

export type Email = {
  __typename?: 'Email';
  /** Attachments that where send with this email. */
  attachments?: Maybe<Array<EmailAttachment>>;
  /** Who where in the bcc of the email. */
  bcc?: Maybe<Array<Scalars['String']['output']>>;
  /** Who where in the cc of the email. */
  cc?: Maybe<Array<Scalars['String']['output']>>;
  /** Date time of when the recourse is created. */
  createdAt: Scalars['DateTime']['output'];
  /** Direction of the email. */
  direction: EmailDirection;
  /** Who send the email. */
  from: Scalars['String']['output'];
  /** Primary key of the recourse. */
  id: Scalars['UUID']['output'];
  /** Uri to read the file. */
  readUri?: Maybe<Scalars['String']['output']>;
  /** Status of the email. */
  status: EmailStatus;
  /** Subject of the email. */
  subject: Scalars['String']['output'];
  /** Who received the email. */
  to: Scalars['String']['output'];
  /** Date time of when the recourse last updated. */
  updatedAt: Scalars['DateTime']['output'];
};


export type EmailAttachmentsArgs = {
  filter?: EmailAttachmentFilter;
  sorting?: Array<EmailAttachmentSort>;
};

export type EmailAttachment = {
  __typename?: 'EmailAttachment';
  /** Date time of when the recourse is created. */
  createdAt: Scalars['DateTime']['output'];
  /** Disposition of the attachment. */
  disposition: EmailAttachmentDisposition;
  /** File of this attachment. */
  file: FileReference;
  /** ID of the file. */
  fileId: Scalars['String']['output'];
  /** Primary key of the recourse. */
  id: Scalars['UUID']['output'];
  /** Date time of when the recourse last updated. */
  updatedAt: Scalars['DateTime']['output'];
};

export type EmailAttachmentCreatedAtFilterComparison = {
  between?: InputMaybe<EmailAttachmentCreatedAtFilterComparisonBetween>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  notBetween?: InputMaybe<EmailAttachmentCreatedAtFilterComparisonBetween>;
};

export type EmailAttachmentCreatedAtFilterComparisonBetween = {
  lower: Scalars['DateTime']['input'];
  upper: Scalars['DateTime']['input'];
};

/** Dispositions the attachment can be. */
export enum EmailAttachmentDisposition {
  Attachment = 'Attachment',
  Inline = 'Inline'
}

export type EmailAttachmentEdge = {
  __typename?: 'EmailAttachmentEdge';
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor']['output'];
  /** The node containing the EmailAttachment */
  node: EmailAttachment;
};

export type EmailAttachmentFilter = {
  and?: InputMaybe<Array<EmailAttachmentFilter>>;
  createdAt?: InputMaybe<EmailAttachmentCreatedAtFilterComparison>;
  id?: InputMaybe<EmailAttachmentIdFilterComparison>;
  or?: InputMaybe<Array<EmailAttachmentFilter>>;
  updatedAt?: InputMaybe<EmailAttachmentUpdatedAtFilterComparison>;
};

export type EmailAttachmentIdFilterComparison = {
  eq?: InputMaybe<Scalars['UUID']['input']>;
  in?: InputMaybe<Array<Scalars['UUID']['input']>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  neq?: InputMaybe<Scalars['UUID']['input']>;
  notIn?: InputMaybe<Array<Scalars['UUID']['input']>>;
};

export type EmailAttachmentSort = {
  direction: SortDirection;
  field: EmailAttachmentSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum EmailAttachmentSortFields {
  CreatedAt = 'createdAt',
  Id = 'id',
  UpdatedAt = 'updatedAt'
}

export type EmailAttachmentUpdatedAtFilterComparison = {
  between?: InputMaybe<EmailAttachmentUpdatedAtFilterComparisonBetween>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  notBetween?: InputMaybe<EmailAttachmentUpdatedAtFilterComparisonBetween>;
};

export type EmailAttachmentUpdatedAtFilterComparisonBetween = {
  lower: Scalars['DateTime']['input'];
  upper: Scalars['DateTime']['input'];
};

/** The direction of an email. */
export enum EmailDirection {
  Incoming = 'Incoming',
  Outgoing = 'Outgoing'
}

export type EmailEdge = {
  __typename?: 'EmailEdge';
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor']['output'];
  /** The node containing the Email */
  node: Email;
};

export type EmailSort = {
  direction: SortDirection;
  field: EmailSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum EmailSortFields {
  CreatedAt = 'createdAt',
  Id = 'id',
  UpdatedAt = 'updatedAt'
}

/** The status of an email. */
export enum EmailStatus {
  Deferred = 'Deferred',
  Delivered = 'Delivered',
  Failed = 'Failed',
  Send = 'Send'
}

export type ExecutableAction = {
  __typename?: 'ExecutableAction';
  /** If `true` files generated by this action will be added as attachment to same step email actions. */
  attachFilesToStepEmailActions: Scalars['Boolean']['output'];
  /** Attachments that will be send with the action (Only for email actions). */
  attachments?: Maybe<Array<WorkflowActionAttachment>>;
  /** Date time of when the recourse is created. */
  createdAt: Scalars['DateTime']['output'];
  /** To who the action should be focused on. */
  direction: WorkflowActionDirection;
  /** Dossier this action belongs to. */
  dossier?: Maybe<Dossier>;
  /** Date the action is executable on. */
  executableOn: Scalars['DateTime']['output'];
  /** Date the action is executed. */
  executedOn?: Maybe<Scalars['DateTime']['output']>;
  /** User who executed this action. */
  executor?: Maybe<UserReference>;
  /** ID of the executor. */
  executorId?: Maybe<Scalars['UUID']['output']>;
  /** Reason why the action failed. */
  failedReason?: Maybe<WorkflowActionFailedReasons>;
  /** Primary key of the recourse. */
  id: Scalars['UUID']['output'];
  /** Invoice this action belongs to. */
  invoice?: Maybe<Invoice>;
  /** Workflow action message. */
  message?: Maybe<Scalars['String']['output']>;
  /** Name of the action. */
  name: Scalars['String']['output'];
  /** Status of the action. */
  status: WorkflowActionStatus;
  /** Workflow action subject. */
  subject?: Maybe<Scalars['String']['output']>;
  /** Type of the action. */
  type: WorkflowActionType;
  /** Date time of when the recourse last updated. */
  updatedAt: Scalars['DateTime']['output'];
};


export type ExecutableActionAttachmentsArgs = {
  sorting?: Array<WorkflowActionAttachmentSort>;
};

export type ExecutableActionEdge = {
  __typename?: 'ExecutableActionEdge';
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor']['output'];
  /** The node containing the ExecutableAction */
  node: ExecutableAction;
};

export type ExecutableDossierAction = {
  __typename?: 'ExecutableDossierAction';
  /** Date time of when the recourse is created. */
  createdAt: Scalars['DateTime']['output'];
  /** To who the action should be focused on. */
  direction: WorkflowActionDirection;
  /** Dossier this action belongs to. */
  dossier: Dossier;
  /** ID of the dossier this action belongs to. */
  dossierId?: Maybe<Scalars['UUID']['output']>;
  /** Date the action is executable on. */
  executableOn: Scalars['DateTime']['output'];
  /** Date the action is executed. */
  executedOn?: Maybe<Scalars['DateTime']['output']>;
  /** User who is assigned to this action. */
  executor?: Maybe<UserReference>;
  /** ID of the executor. */
  executorId?: Maybe<Scalars['UUID']['output']>;
  /** Primary key of the recourse. */
  id: Scalars['UUID']['output'];
  /** Name of the action. */
  name: Scalars['String']['output'];
  /** Status of the action. */
  status: WorkflowActionStatus;
  /** Type of the action. */
  type: WorkflowActionType;
  /** Date time of when the recourse last updated. */
  updatedAt: Scalars['DateTime']['output'];
};

export type ExecutableDossierActionClientIdFilterComparison = {
  eq?: InputMaybe<Scalars['UUID']['input']>;
  in?: InputMaybe<Array<Scalars['UUID']['input']>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  neq?: InputMaybe<Scalars['UUID']['input']>;
  notIn?: InputMaybe<Array<Scalars['UUID']['input']>>;
};

export type ExecutableDossierActionCreatedAtFilterComparison = {
  between?: InputMaybe<ExecutableDossierActionCreatedAtFilterComparisonBetween>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  notBetween?: InputMaybe<ExecutableDossierActionCreatedAtFilterComparisonBetween>;
};

export type ExecutableDossierActionCreatedAtFilterComparisonBetween = {
  lower: Scalars['DateTime']['input'];
  upper: Scalars['DateTime']['input'];
};

export type ExecutableDossierActionDossierTypeFilterComparison = {
  eq?: InputMaybe<DossierType>;
  in?: InputMaybe<Array<DossierType>>;
  neq?: InputMaybe<DossierType>;
  notIn?: InputMaybe<Array<DossierType>>;
};

export type ExecutableDossierActionExecutableOnFilterComparison = {
  between?: InputMaybe<ExecutableDossierActionExecutableOnFilterComparisonBetween>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  notBetween?: InputMaybe<ExecutableDossierActionExecutableOnFilterComparisonBetween>;
};

export type ExecutableDossierActionExecutableOnFilterComparisonBetween = {
  lower: Scalars['DateTime']['input'];
  upper: Scalars['DateTime']['input'];
};

export type ExecutableDossierActionExecutedOnFilterComparison = {
  between?: InputMaybe<ExecutableDossierActionExecutedOnFilterComparisonBetween>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  notBetween?: InputMaybe<ExecutableDossierActionExecutedOnFilterComparisonBetween>;
};

export type ExecutableDossierActionExecutedOnFilterComparisonBetween = {
  lower: Scalars['DateTime']['input'];
  upper: Scalars['DateTime']['input'];
};

export type ExecutableDossierActionExecutorIdFilterComparison = {
  eq?: InputMaybe<Scalars['UUID']['input']>;
  in?: InputMaybe<Array<Scalars['UUID']['input']>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  neq?: InputMaybe<Scalars['UUID']['input']>;
  notIn?: InputMaybe<Array<Scalars['UUID']['input']>>;
};

export type ExecutableDossierActionFilter = {
  and?: InputMaybe<Array<ExecutableDossierActionFilter>>;
  clientId?: InputMaybe<ExecutableDossierActionClientIdFilterComparison>;
  createdAt?: InputMaybe<ExecutableDossierActionCreatedAtFilterComparison>;
  dossierId?: InputMaybe<UuidFilterComparison>;
  dossierType?: InputMaybe<ExecutableDossierActionDossierTypeFilterComparison>;
  executableOn?: InputMaybe<ExecutableDossierActionExecutableOnFilterComparison>;
  executedOn?: InputMaybe<ExecutableDossierActionExecutedOnFilterComparison>;
  executorId?: InputMaybe<ExecutableDossierActionExecutorIdFilterComparison>;
  id?: InputMaybe<ExecutableDossierActionIdFilterComparison>;
  name?: InputMaybe<ExecutableDossierActionNameFilterComparison>;
  or?: InputMaybe<Array<ExecutableDossierActionFilter>>;
  status?: InputMaybe<ExecutableDossierActionStatusFilterComparison>;
  type?: InputMaybe<ExecutableDossierActionTypeFilterComparison>;
  updatedAt?: InputMaybe<ExecutableDossierActionUpdatedAtFilterComparison>;
};

export type ExecutableDossierActionIdFilterComparison = {
  eq?: InputMaybe<Scalars['UUID']['input']>;
  in?: InputMaybe<Array<Scalars['UUID']['input']>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  neq?: InputMaybe<Scalars['UUID']['input']>;
  notIn?: InputMaybe<Array<Scalars['UUID']['input']>>;
};

export type ExecutableDossierActionNameFilterComparison = {
  eq?: InputMaybe<Scalars['String']['input']>;
  like?: InputMaybe<Scalars['String']['input']>;
  neq?: InputMaybe<Scalars['String']['input']>;
  notLike?: InputMaybe<Scalars['String']['input']>;
};

export type ExecutableDossierActionOffsetConnection = {
  __typename?: 'ExecutableDossierActionOffsetConnection';
  /** Array of nodes. */
  nodes: Array<ExecutableDossierAction>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type ExecutableDossierActionSort = {
  direction: SortDirection;
  field: ExecutableDossierActionSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum ExecutableDossierActionSortFields {
  ClientId = 'clientId',
  CreatedAt = 'createdAt',
  DossierId = 'dossierId',
  DossierType = 'dossierType',
  ExecutableOn = 'executableOn',
  ExecutedOn = 'executedOn',
  ExecutorId = 'executorId',
  Id = 'id',
  Name = 'name',
  Status = 'status',
  Type = 'type',
  UpdatedAt = 'updatedAt'
}

export type ExecutableDossierActionStatusFilterComparison = {
  eq?: InputMaybe<WorkflowActionStatus>;
  in?: InputMaybe<Array<WorkflowActionStatus>>;
  neq?: InputMaybe<WorkflowActionStatus>;
  notIn?: InputMaybe<Array<WorkflowActionStatus>>;
};

export type ExecutableDossierActionTypeFilterComparison = {
  eq?: InputMaybe<WorkflowActionType>;
  in?: InputMaybe<Array<WorkflowActionType>>;
  neq?: InputMaybe<WorkflowActionType>;
  notIn?: InputMaybe<Array<WorkflowActionType>>;
};

export type ExecutableDossierActionUpdatedAtFilterComparison = {
  between?: InputMaybe<ExecutableDossierActionUpdatedAtFilterComparisonBetween>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  notBetween?: InputMaybe<ExecutableDossierActionUpdatedAtFilterComparisonBetween>;
};

export type ExecutableDossierActionUpdatedAtFilterComparisonBetween = {
  lower: Scalars['DateTime']['input'];
  upper: Scalars['DateTime']['input'];
};

export type ExecutableInvoiceAction = {
  __typename?: 'ExecutableInvoiceAction';
  /** Date time of when the recourse is created. */
  createdAt: Scalars['DateTime']['output'];
  /** To who the action should be focused on. */
  direction: WorkflowActionDirection;
  /** Date the action is executable on. */
  executableOn: Scalars['DateTime']['output'];
  /** Date the action is executed. */
  executedOn?: Maybe<Scalars['DateTime']['output']>;
  /** User who is assigned to this action. */
  executor?: Maybe<UserReference>;
  /** ID of the executor. */
  executorId?: Maybe<Scalars['UUID']['output']>;
  /** Primary key of the recourse. */
  id: Scalars['UUID']['output'];
  /** Invoice this action belongs to. */
  invoice: Invoice;
  /** Name of the action. */
  name: Scalars['String']['output'];
  /** Status of the action. */
  status: WorkflowActionStatus;
  /** Type of the action. */
  type: WorkflowActionType;
  /** Date time of when the recourse last updated. */
  updatedAt: Scalars['DateTime']['output'];
};

export type ExecutableInvoiceActionCreatedAtFilterComparison = {
  between?: InputMaybe<ExecutableInvoiceActionCreatedAtFilterComparisonBetween>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  notBetween?: InputMaybe<ExecutableInvoiceActionCreatedAtFilterComparisonBetween>;
};

export type ExecutableInvoiceActionCreatedAtFilterComparisonBetween = {
  lower: Scalars['DateTime']['input'];
  upper: Scalars['DateTime']['input'];
};

export type ExecutableInvoiceActionExecutableOnFilterComparison = {
  between?: InputMaybe<ExecutableInvoiceActionExecutableOnFilterComparisonBetween>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  notBetween?: InputMaybe<ExecutableInvoiceActionExecutableOnFilterComparisonBetween>;
};

export type ExecutableInvoiceActionExecutableOnFilterComparisonBetween = {
  lower: Scalars['DateTime']['input'];
  upper: Scalars['DateTime']['input'];
};

export type ExecutableInvoiceActionExecutedOnFilterComparison = {
  between?: InputMaybe<ExecutableInvoiceActionExecutedOnFilterComparisonBetween>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  notBetween?: InputMaybe<ExecutableInvoiceActionExecutedOnFilterComparisonBetween>;
};

export type ExecutableInvoiceActionExecutedOnFilterComparisonBetween = {
  lower: Scalars['DateTime']['input'];
  upper: Scalars['DateTime']['input'];
};

export type ExecutableInvoiceActionExecutorIdFilterComparison = {
  eq?: InputMaybe<Scalars['UUID']['input']>;
  in?: InputMaybe<Array<Scalars['UUID']['input']>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  neq?: InputMaybe<Scalars['UUID']['input']>;
  notIn?: InputMaybe<Array<Scalars['UUID']['input']>>;
};

export type ExecutableInvoiceActionFilter = {
  and?: InputMaybe<Array<ExecutableInvoiceActionFilter>>;
  createdAt?: InputMaybe<ExecutableInvoiceActionCreatedAtFilterComparison>;
  executableOn?: InputMaybe<ExecutableInvoiceActionExecutableOnFilterComparison>;
  executedOn?: InputMaybe<ExecutableInvoiceActionExecutedOnFilterComparison>;
  executorId?: InputMaybe<ExecutableInvoiceActionExecutorIdFilterComparison>;
  id?: InputMaybe<ExecutableInvoiceActionIdFilterComparison>;
  name?: InputMaybe<ExecutableInvoiceActionNameFilterComparison>;
  or?: InputMaybe<Array<ExecutableInvoiceActionFilter>>;
  status?: InputMaybe<ExecutableInvoiceActionStatusFilterComparison>;
  type?: InputMaybe<ExecutableInvoiceActionTypeFilterComparison>;
  updatedAt?: InputMaybe<ExecutableInvoiceActionUpdatedAtFilterComparison>;
};

export type ExecutableInvoiceActionIdFilterComparison = {
  eq?: InputMaybe<Scalars['UUID']['input']>;
  in?: InputMaybe<Array<Scalars['UUID']['input']>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  neq?: InputMaybe<Scalars['UUID']['input']>;
  notIn?: InputMaybe<Array<Scalars['UUID']['input']>>;
};

export type ExecutableInvoiceActionNameFilterComparison = {
  eq?: InputMaybe<Scalars['String']['input']>;
  like?: InputMaybe<Scalars['String']['input']>;
  neq?: InputMaybe<Scalars['String']['input']>;
  notLike?: InputMaybe<Scalars['String']['input']>;
};

export type ExecutableInvoiceActionOffsetConnection = {
  __typename?: 'ExecutableInvoiceActionOffsetConnection';
  /** Array of nodes. */
  nodes: Array<ExecutableInvoiceAction>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type ExecutableInvoiceActionSort = {
  direction: SortDirection;
  field: ExecutableInvoiceActionSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum ExecutableInvoiceActionSortFields {
  CreatedAt = 'createdAt',
  ExecutableOn = 'executableOn',
  ExecutedOn = 'executedOn',
  ExecutorId = 'executorId',
  Id = 'id',
  Name = 'name',
  Status = 'status',
  Type = 'type',
  UpdatedAt = 'updatedAt'
}

export type ExecutableInvoiceActionStatusFilterComparison = {
  eq?: InputMaybe<WorkflowActionStatus>;
  in?: InputMaybe<Array<WorkflowActionStatus>>;
  neq?: InputMaybe<WorkflowActionStatus>;
  notIn?: InputMaybe<Array<WorkflowActionStatus>>;
};

export type ExecutableInvoiceActionTypeFilterComparison = {
  eq?: InputMaybe<WorkflowActionType>;
  in?: InputMaybe<Array<WorkflowActionType>>;
  neq?: InputMaybe<WorkflowActionType>;
  notIn?: InputMaybe<Array<WorkflowActionType>>;
};

export type ExecutableInvoiceActionUpdatedAtFilterComparison = {
  between?: InputMaybe<ExecutableInvoiceActionUpdatedAtFilterComparisonBetween>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  notBetween?: InputMaybe<ExecutableInvoiceActionUpdatedAtFilterComparisonBetween>;
};

export type ExecutableInvoiceActionUpdatedAtFilterComparisonBetween = {
  lower: Scalars['DateTime']['input'];
  upper: Scalars['DateTime']['input'];
};

export type ExecuteManyWorkflowActions = {
  /** Primary key of the recourse. */
  ids: Array<Scalars['UUID']['input']>;
};

export type ExportManyInput = {
  /** Primary key of the recourse. */
  ids: Array<Scalars['UUID']['input']>;
};

export type ExportManyResponse = {
  __typename?: 'ExportManyResponse';
  /** The number of records exported. */
  count: Scalars['Int']['output'];
};

export type File = {
  __typename?: 'File';
  /** Date time of when the recourse is created. */
  createdAt: Scalars['DateTime']['output'];
  /** Description of the file. */
  description?: Maybe<Scalars['String']['output']>;
  /** Extension of the file. */
  extension: Scalars['String']['output'];
  /** Name of file. */
  fileName: Scalars['String']['output'];
  /** Primary key of the recourse. */
  id: Scalars['UUID']['output'];
  /** Mime type of the file. */
  mimeType: Scalars['String']['output'];
  /** Uri to read the file. */
  readUri?: Maybe<Scalars['String']['output']>;
  /** Status of the file. */
  status: FileStatus;
  /** Type of the file. */
  type: FileType;
  /** Date time of when the recourse last updated. */
  updatedAt: Scalars['DateTime']['output'];
  /** Is this file visible for the debtor. */
  visibleForDebtor: Scalars['Boolean']['output'];
};


export type FileReadUriArgs = {
  download?: InputMaybe<Scalars['Boolean']['input']>;
};

export type FileCompanyIdFilterComparison = {
  eq?: InputMaybe<Scalars['UUID']['input']>;
  in?: InputMaybe<Array<Scalars['UUID']['input']>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  neq?: InputMaybe<Scalars['UUID']['input']>;
  notIn?: InputMaybe<Array<Scalars['UUID']['input']>>;
};

export type FileCreatedAtFilterComparison = {
  between?: InputMaybe<FileCreatedAtFilterComparisonBetween>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  notBetween?: InputMaybe<FileCreatedAtFilterComparisonBetween>;
};

export type FileCreatedAtFilterComparisonBetween = {
  lower: Scalars['DateTime']['input'];
  upper: Scalars['DateTime']['input'];
};

export type FileDeleteResponse = {
  __typename?: 'FileDeleteResponse';
  /** Date time of when the recourse is created. */
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  /** Description of the file. */
  description?: Maybe<Scalars['String']['output']>;
  /** Extension of the file. */
  extension?: Maybe<Scalars['String']['output']>;
  /** Name of file. */
  fileName?: Maybe<Scalars['String']['output']>;
  /** Primary key of the recourse. */
  id?: Maybe<Scalars['UUID']['output']>;
  /** Mime type of the file. */
  mimeType?: Maybe<Scalars['String']['output']>;
  /** Uri to read the file. */
  readUri?: Maybe<Scalars['String']['output']>;
  /** Status of the file. */
  status?: Maybe<FileStatus>;
  /** Type of the file. */
  type?: Maybe<FileType>;
  /** Date time of when the recourse last updated. */
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Is this file visible for the debtor. */
  visibleForDebtor?: Maybe<Scalars['Boolean']['output']>;
};

export type FileDossierIdFilterComparison = {
  eq?: InputMaybe<Scalars['UUID']['input']>;
  in?: InputMaybe<Array<Scalars['UUID']['input']>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  neq?: InputMaybe<Scalars['UUID']['input']>;
  notIn?: InputMaybe<Array<Scalars['UUID']['input']>>;
};

export type FileEdge = {
  __typename?: 'FileEdge';
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor']['output'];
  /** The node containing the File */
  node: File;
};

export type FileFileNameFilterComparison = {
  eq?: InputMaybe<Scalars['String']['input']>;
  like?: InputMaybe<Scalars['String']['input']>;
  neq?: InputMaybe<Scalars['String']['input']>;
  notLike?: InputMaybe<Scalars['String']['input']>;
};

export type FileFilter = {
  and?: InputMaybe<Array<FileFilter>>;
  companyId?: InputMaybe<FileCompanyIdFilterComparison>;
  createdAt?: InputMaybe<FileCreatedAtFilterComparison>;
  dossierId?: InputMaybe<FileDossierIdFilterComparison>;
  fileName?: InputMaybe<FileFileNameFilterComparison>;
  id?: InputMaybe<FileIdFilterComparison>;
  invoiceId?: InputMaybe<FileInvoiceIdFilterComparison>;
  jobId?: InputMaybe<FileJobIdFilterComparison>;
  or?: InputMaybe<Array<FileFilter>>;
  status?: InputMaybe<FileStatusFilterComparison>;
  type?: InputMaybe<FileTypeFilterComparison>;
  updatedAt?: InputMaybe<FileUpdatedAtFilterComparison>;
};

export type FileIdFilterComparison = {
  eq?: InputMaybe<Scalars['UUID']['input']>;
  in?: InputMaybe<Array<Scalars['UUID']['input']>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  neq?: InputMaybe<Scalars['UUID']['input']>;
  notIn?: InputMaybe<Array<Scalars['UUID']['input']>>;
};

export type FileInvoiceIdFilterComparison = {
  eq?: InputMaybe<Scalars['UUID']['input']>;
  in?: InputMaybe<Array<Scalars['UUID']['input']>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  neq?: InputMaybe<Scalars['UUID']['input']>;
  notIn?: InputMaybe<Array<Scalars['UUID']['input']>>;
};

export type FileJobIdFilterComparison = {
  eq?: InputMaybe<Scalars['UUID']['input']>;
  in?: InputMaybe<Array<Scalars['UUID']['input']>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  neq?: InputMaybe<Scalars['UUID']['input']>;
  notIn?: InputMaybe<Array<Scalars['UUID']['input']>>;
};

export type FileOffsetConnection = {
  __typename?: 'FileOffsetConnection';
  /** Array of nodes. */
  nodes: Array<File>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type FileReference = {
  __typename?: 'FileReference';
  /** Name of file. */
  fileName: Scalars['String']['output'];
  /** Primary key of the recourse. */
  id: Scalars['UUID']['output'];
  /** Mime type of the file. */
  mimeType: Scalars['String']['output'];
  /** Status of the file. */
  status: FileStatus;
};

export type FileReferenceSort = {
  direction: SortDirection;
  field: FileReferenceSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum FileReferenceSortFields {
  FileName = 'fileName',
  Id = 'id'
}

export type FileSort = {
  direction: SortDirection;
  field: FileSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum FileSortFields {
  CompanyId = 'companyId',
  CreatedAt = 'createdAt',
  DossierId = 'dossierId',
  FileName = 'fileName',
  Id = 'id',
  InvoiceId = 'invoiceId',
  JobId = 'jobId',
  Status = 'status',
  Type = 'type',
  UpdatedAt = 'updatedAt'
}

/** The status of a file. */
export enum FileStatus {
  Generated = 'Generated',
  Malicious = 'Malicious',
  Safe = 'Safe',
  Unscanned = 'Unscanned'
}

export type FileStatusFilterComparison = {
  eq?: InputMaybe<FileStatus>;
  in?: InputMaybe<Array<FileStatus>>;
  neq?: InputMaybe<FileStatus>;
  notIn?: InputMaybe<Array<FileStatus>>;
};

/** The type of a file. */
export enum FileType {
  CompanyLogo = 'CompanyLogo',
  Import = 'Import',
  Invoice = 'Invoice',
  InvoiceSummary = 'InvoiceSummary',
  Other = 'Other',
  Ubl = 'UBL'
}

export type FileTypeFilterComparison = {
  eq?: InputMaybe<FileType>;
  in?: InputMaybe<Array<FileType>>;
  neq?: InputMaybe<FileType>;
  notIn?: InputMaybe<Array<FileType>>;
};

export type FileUpdatedAtFilterComparison = {
  between?: InputMaybe<FileUpdatedAtFilterComparisonBetween>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  notBetween?: InputMaybe<FileUpdatedAtFilterComparisonBetween>;
};

export type FileUpdatedAtFilterComparisonBetween = {
  lower: Scalars['DateTime']['input'];
  upper: Scalars['DateTime']['input'];
};

export type Franchisee = {
  __typename?: 'Franchisee';
  /** Contacts of the company. */
  contacts?: Maybe<Array<CompanyContact>>;
  /** Country code where the company is located (ISO 3166-1 alpha-2). */
  country: Scalars['String']['output'];
  /** Date time of when the recourse is created. */
  createdAt: Scalars['DateTime']['output'];
  /** Currency the company uses. */
  currency: Currency;
  /** Primary key of the recourse. */
  id: Scalars['UUID']['output'];
  /** Additional info of the company. */
  info?: Maybe<CompanyInfo>;
  /** Preferred language of the company. */
  language: Language;
  /** Location number of the company. */
  locationNr?: Maybe<Scalars['String']['output']>;
  /** Master of the franchisee. */
  master: CompanyReference;
  /** Name of the company. */
  name: Scalars['String']['output'];
  /** ID of the master the franchisee belongs to. */
  parentId: Scalars['UUID']['output'];
  /** Slug of the company. */
  slug: Scalars['String']['output'];
  /** Date time of when the recourse last updated. */
  updatedAt: Scalars['DateTime']['output'];
};


export type FranchiseeContactsArgs = {
  filter?: CompanyContactFilter;
  sorting?: Array<CompanyContactSort>;
};

export type FranchiseeConnection = {
  __typename?: 'FranchiseeConnection';
  /** Array of nodes. */
  nodes: Array<Franchisee>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type FranchiseeCreatedAtFilterComparison = {
  between?: InputMaybe<FranchiseeCreatedAtFilterComparisonBetween>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  notBetween?: InputMaybe<FranchiseeCreatedAtFilterComparisonBetween>;
};

export type FranchiseeCreatedAtFilterComparisonBetween = {
  lower: Scalars['DateTime']['input'];
  upper: Scalars['DateTime']['input'];
};

export type FranchiseeFilter = {
  and?: InputMaybe<Array<FranchiseeFilter>>;
  createdAt?: InputMaybe<FranchiseeCreatedAtFilterComparison>;
  id?: InputMaybe<FranchiseeIdFilterComparison>;
  name?: InputMaybe<FranchiseeNameFilterComparison>;
  or?: InputMaybe<Array<FranchiseeFilter>>;
  parentId?: InputMaybe<FranchiseeParentIdFilterComparison>;
  updatedAt?: InputMaybe<FranchiseeUpdatedAtFilterComparison>;
};

export type FranchiseeIdFilterComparison = {
  eq?: InputMaybe<Scalars['UUID']['input']>;
  in?: InputMaybe<Array<Scalars['UUID']['input']>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  neq?: InputMaybe<Scalars['UUID']['input']>;
  notIn?: InputMaybe<Array<Scalars['UUID']['input']>>;
};

export type FranchiseeNameFilterComparison = {
  eq?: InputMaybe<Scalars['String']['input']>;
  like?: InputMaybe<Scalars['String']['input']>;
  neq?: InputMaybe<Scalars['String']['input']>;
  notLike?: InputMaybe<Scalars['String']['input']>;
};

export type FranchiseeParentIdFilterComparison = {
  eq?: InputMaybe<Scalars['UUID']['input']>;
  in?: InputMaybe<Array<Scalars['UUID']['input']>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  neq?: InputMaybe<Scalars['UUID']['input']>;
  notIn?: InputMaybe<Array<Scalars['UUID']['input']>>;
};

export type FranchiseeSort = {
  direction: SortDirection;
  field: FranchiseeSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum FranchiseeSortFields {
  CreatedAt = 'createdAt',
  Id = 'id',
  Name = 'name',
  ParentId = 'parentId',
  UpdatedAt = 'updatedAt'
}

export type FranchiseeUpdatedAtFilterComparison = {
  between?: InputMaybe<FranchiseeUpdatedAtFilterComparisonBetween>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  notBetween?: InputMaybe<FranchiseeUpdatedAtFilterComparisonBetween>;
};

export type FranchiseeUpdatedAtFilterComparisonBetween = {
  lower: Scalars['DateTime']['input'];
  upper: Scalars['DateTime']['input'];
};

/** Genders of a contact */
export enum Gender {
  Man = 'Man',
  Unknown = 'Unknown',
  Woman = 'Woman'
}

/** Group by */
export enum GroupBy {
  Day = 'DAY',
  Month = 'MONTH',
  Week = 'WEEK',
  Year = 'YEAR'
}

export type ImportDossierInvoice = {
  /** Amount in cents of the invoice. */
  centAmount: Scalars['Float']['input'];
  /** Amount in cents of the invoice that have been paid (Only used in debtor management). */
  centAmountPaid?: Scalars['Float']['input'];
  /** Currency of the invoice. */
  currency: Currency;
  /** Date of the invoice. */
  date: Scalars['DateTime']['input'];
  /** ID of the debtor. */
  debtorId: Scalars['UUID']['input'];
  /** ID of associated file. */
  fileId?: InputMaybe<Scalars['UUID']['input']>;
  /** Type of interest. */
  interest?: InputMaybe<DossierInvoiceInterest>;
  /** Date to calculate interest from. (Expire date of the invoice) */
  interestFromDate?: InputMaybe<Scalars['DateTime']['input']>;
  /** Amount of days / work-days. */
  paymentTerm: Scalars['Float']['input'];
  /** Amount of days / work-days. */
  paymentTermType: InvoicePaymentTerm;
  /** Reference of the invoice. This needs to be unique! */
  reference: Scalars['String']['input'];
  /** Status of the invoice. (Only used in debtor management) */
  status?: DossierInvoiceStatus;
};

export type ImportDossierInvoiceInput = {
  /** The record to create */
  invoice: ImportDossierInvoice;
};

export type ImportDossierInvoicesInput = {
  /** Array of records to create */
  invoices: Array<ImportDossierInvoice>;
};

export type ImportManyTransactionInput = {
  /** Array of records to create */
  transactions: Array<ImportTransaction>;
};

export type ImportTransaction = {
  /** Account of the bank (e.g. IBAN). */
  account?: InputMaybe<Scalars['String']['input']>;
  /** Amount in cents of the transaction. */
  centAmount?: InputMaybe<Scalars['Float']['input']>;
  /** Currency of the transaction. */
  currency?: InputMaybe<Currency>;
  /** External ID of the transaction. (e.g. payment provider) */
  externalId: Scalars['String']['input'];
  /** Name of the bank. */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Date time of when transaction took place. */
  receivedOn?: InputMaybe<Scalars['DateTime']['input']>;
  /** Reference of the transaction. */
  reference?: InputMaybe<Scalars['String']['input']>;
  /** Source account of the bank (e.g. IBAN). */
  sourceAccount: Scalars['String']['input'];
};

export type InterestRate = {
  __typename?: 'InterestRate';
  /** Amount in % the tax is. */
  amount: Scalars['Float']['output'];
  /** ID of the company the interest rate belongs to. */
  companyId?: Maybe<Scalars['UUID']['output']>;
  /** Country of the tax (ISO 3166-1 alpha-2). */
  country?: Maybe<Scalars['String']['output']>;
  /** Date time of when the recourse is created. */
  createdAt: Scalars['DateTime']['output'];
  /** ID of the dossier the interest rate belongs to. */
  dossierId?: Maybe<Scalars['UUID']['output']>;
  /** From when the tax is applied. */
  from: Scalars['DateTime']['output'];
  /** Primary key of the recourse. */
  id: Scalars['UUID']['output'];
  /** Until when the tax is applied. */
  until?: Maybe<Scalars['DateTime']['output']>;
  /** Date time of when the recourse last updated. */
  updatedAt: Scalars['DateTime']['output'];
  /** Type of the interest rate. */
  variant: InterestRateType;
};

export type InterestRateCompanyIdFilterComparison = {
  eq?: InputMaybe<Scalars['UUID']['input']>;
  in?: InputMaybe<Array<Scalars['UUID']['input']>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  neq?: InputMaybe<Scalars['UUID']['input']>;
  notIn?: InputMaybe<Array<Scalars['UUID']['input']>>;
};

export type InterestRateConnection = {
  __typename?: 'InterestRateConnection';
  /** Array of nodes. */
  nodes: Array<InterestRate>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type InterestRateCountryFilterComparison = {
  eq?: InputMaybe<Scalars['String']['input']>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  neq?: InputMaybe<Scalars['String']['input']>;
};

export type InterestRateCreatedAtFilterComparison = {
  between?: InputMaybe<InterestRateCreatedAtFilterComparisonBetween>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  notBetween?: InputMaybe<InterestRateCreatedAtFilterComparisonBetween>;
};

export type InterestRateCreatedAtFilterComparisonBetween = {
  lower: Scalars['DateTime']['input'];
  upper: Scalars['DateTime']['input'];
};

export type InterestRateDeleteResponse = {
  __typename?: 'InterestRateDeleteResponse';
  /** Amount in % the tax is. */
  amount?: Maybe<Scalars['Float']['output']>;
  /** ID of the company the interest rate belongs to. */
  companyId?: Maybe<Scalars['UUID']['output']>;
  /** Country of the tax (ISO 3166-1 alpha-2). */
  country?: Maybe<Scalars['String']['output']>;
  /** Date time of when the recourse is created. */
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  /** ID of the dossier the interest rate belongs to. */
  dossierId?: Maybe<Scalars['UUID']['output']>;
  /** From when the tax is applied. */
  from?: Maybe<Scalars['DateTime']['output']>;
  /** Primary key of the recourse. */
  id?: Maybe<Scalars['UUID']['output']>;
  /** Until when the tax is applied. */
  until?: Maybe<Scalars['DateTime']['output']>;
  /** Date time of when the recourse last updated. */
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Type of the interest rate. */
  variant?: Maybe<InterestRateType>;
};

export type InterestRateDossierIdFilterComparison = {
  eq?: InputMaybe<Scalars['UUID']['input']>;
  in?: InputMaybe<Array<Scalars['UUID']['input']>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  neq?: InputMaybe<Scalars['UUID']['input']>;
  notIn?: InputMaybe<Array<Scalars['UUID']['input']>>;
};

export type InterestRateFilter = {
  and?: InputMaybe<Array<InterestRateFilter>>;
  companyId?: InputMaybe<InterestRateCompanyIdFilterComparison>;
  country?: InputMaybe<InterestRateCountryFilterComparison>;
  createdAt?: InputMaybe<InterestRateCreatedAtFilterComparison>;
  dossierId?: InputMaybe<InterestRateDossierIdFilterComparison>;
  from?: InputMaybe<InterestRateFromFilterComparison>;
  id?: InputMaybe<InterestRateIdFilterComparison>;
  or?: InputMaybe<Array<InterestRateFilter>>;
  updatedAt?: InputMaybe<InterestRateUpdatedAtFilterComparison>;
  variant?: InputMaybe<InterestRateVariantFilterComparison>;
};

export type InterestRateFromFilterComparison = {
  between?: InputMaybe<InterestRateFromFilterComparisonBetween>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  notBetween?: InputMaybe<InterestRateFromFilterComparisonBetween>;
};

export type InterestRateFromFilterComparisonBetween = {
  lower: Scalars['DateTime']['input'];
  upper: Scalars['DateTime']['input'];
};

export type InterestRateIdFilterComparison = {
  eq?: InputMaybe<Scalars['UUID']['input']>;
  in?: InputMaybe<Array<Scalars['UUID']['input']>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  neq?: InputMaybe<Scalars['UUID']['input']>;
  notIn?: InputMaybe<Array<Scalars['UUID']['input']>>;
};

export type InterestRateSort = {
  direction: SortDirection;
  field: InterestRateSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum InterestRateSortFields {
  CompanyId = 'companyId',
  Country = 'country',
  CreatedAt = 'createdAt',
  DossierId = 'dossierId',
  From = 'from',
  Id = 'id',
  UpdatedAt = 'updatedAt',
  Variant = 'variant'
}

/** The type of an interest rate. */
export enum InterestRateType {
  Company = 'Company',
  Custom = 'Custom',
  Person = 'Person'
}

export type InterestRateUpdatedAtFilterComparison = {
  between?: InputMaybe<InterestRateUpdatedAtFilterComparisonBetween>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  notBetween?: InputMaybe<InterestRateUpdatedAtFilterComparisonBetween>;
};

export type InterestRateUpdatedAtFilterComparisonBetween = {
  lower: Scalars['DateTime']['input'];
  upper: Scalars['DateTime']['input'];
};

export type InterestRateVariantFilterComparison = {
  eq?: InputMaybe<InterestRateType>;
  in?: InputMaybe<Array<InterestRateType>>;
  neq?: InputMaybe<InterestRateType>;
  notIn?: InputMaybe<Array<InterestRateType>>;
};

export type InviteUser = {
  /** ID of the company to. */
  companyId?: InputMaybe<Scalars['UUID']['input']>;
  /** Email address of the user. */
  email: Scalars['EmailAddress']['input'];
  /** ID of the group. */
  groupId: Scalars['UUID']['input'];
  /** Users preferred language. */
  language?: Language;
};

export type Invoice = {
  __typename?: 'Invoice';
  /** Amount in cents of the invoice. */
  centAmount: Scalars['Float']['output'];
  /** Amount in cents of all the invoice lines. */
  centAmountLines: Scalars['Float']['output'];
  /** Amount in cents open of the invoice. */
  centAmountOpen: Scalars['Float']['output'];
  /** Amount in cents paid of the invoice. */
  centAmountPaid: Scalars['Float']['output'];
  /** Amount in cents of the invoice that are taxes. */
  centAmountTaxes: Scalars['Float']['output'];
  /** Company the invoice belongs to. */
  companyId: Scalars['UUID']['output'];
  /** Date time of when the recourse is created. */
  createdAt: Scalars['DateTime']['output'];
  /** Creator of this this dossier. */
  creator: Creator;
  /** Currency of invoice. */
  currency: Currency;
  /** Date of the invoice. */
  date: Scalars['DateTime']['output'];
  /** Debtor of the invoice. */
  debtor: CompanyReference;
  /** Id of the debtor the invoiced is to. */
  debtorId: Scalars['UUID']['output'];
  /** Primary key of the recourse. */
  id: Scalars['UUID']['output'];
  /** Date the invoice should start collection interest. */
  interestFromDate?: Maybe<Scalars['DateTime']['output']>;
  /** ID of the job that created this invoice. */
  jobId?: Maybe<Scalars['UUID']['output']>;
  /** Number of the invoice. */
  number?: Maybe<Scalars['String']['output']>;
  /** The original invoice this credit is based off. */
  originalInvoice?: Maybe<InvoiceReference>;
  /** ID of the original invoice this credit is based off. */
  originalInvoiceId?: Maybe<Scalars['UUID']['output']>;
  /** Payment term of the invoice. */
  paymentTerm: Scalars['Float']['output'];
  /** Type of the invoice payment term. */
  paymentTermType: InvoicePaymentTerm;
  /** If `true` the invoice will be settled in the invoicing process. */
  settleInInvoicing: Scalars['Boolean']['output'];
  /** Status of the invoice. */
  status: InvoiceStatus;
  /** Subject of the invoice. */
  subject?: Maybe<Scalars['String']['output']>;
  /** Date time of when the recourse last updated. */
  updatedAt: Scalars['DateTime']['output'];
  /** ID of the workflow that is used in this invoice. */
  workflowId: Scalars['UUID']['output'];
};

export type InvoiceAggregateFilter = {
  and?: InputMaybe<Array<InvoiceAggregateFilter>>;
  centAmount?: InputMaybe<InvoiceCentAmountFilterComparison>;
  centAmountLines?: InputMaybe<InvoiceCentAmountLinesFilterComparison>;
  centAmountOpen?: InputMaybe<InvoiceCentAmountOpenFilterComparison>;
  centAmountPaid?: InputMaybe<InvoiceCentAmountPaidFilterComparison>;
  centAmountTaxes?: InputMaybe<InvoiceCentAmountTaxesFilterComparison>;
  companyId?: InputMaybe<InvoiceCompanyIdFilterComparison>;
  createdAt?: InputMaybe<InvoiceCreatedAtFilterComparison>;
  currency?: InputMaybe<InvoiceCurrencyFilterComparison>;
  date?: InputMaybe<InvoiceDateFilterComparison>;
  debtor?: InputMaybe<InvoiceAggregateFilterCompanyReferenceAggregateFilter>;
  debtorId?: InputMaybe<InvoiceDebtorIdFilterComparison>;
  id?: InputMaybe<InvoiceIdFilterComparison>;
  jobId?: InputMaybe<InvoiceJobIdFilterComparison>;
  number?: InputMaybe<InvoiceNumberFilterComparison>;
  or?: InputMaybe<Array<InvoiceAggregateFilter>>;
  status?: InputMaybe<InvoiceStatusFilterComparison>;
  updatedAt?: InputMaybe<InvoiceUpdatedAtFilterComparison>;
};

export type InvoiceAggregateFilterCompanyReferenceAggregateFilter = {
  and?: InputMaybe<Array<InvoiceAggregateFilterCompanyReferenceAggregateFilter>>;
  id?: InputMaybe<CompanyReferenceIdFilterComparison>;
  name?: InputMaybe<CompanyReferenceNameFilterComparison>;
  or?: InputMaybe<Array<InvoiceAggregateFilterCompanyReferenceAggregateFilter>>;
};

export type InvoiceAggregateGroupBy = {
  __typename?: 'InvoiceAggregateGroupBy';
  centAmount?: Maybe<Scalars['Float']['output']>;
  centAmountLines?: Maybe<Scalars['Float']['output']>;
  centAmountOpen?: Maybe<Scalars['Float']['output']>;
  centAmountPaid?: Maybe<Scalars['Float']['output']>;
  centAmountTaxes?: Maybe<Scalars['Float']['output']>;
  companyId?: Maybe<Scalars['UUID']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  currency?: Maybe<Currency>;
  date?: Maybe<Scalars['DateTime']['output']>;
  debtorId?: Maybe<Scalars['UUID']['output']>;
  id?: Maybe<Scalars['UUID']['output']>;
  jobId?: Maybe<Scalars['UUID']['output']>;
  number?: Maybe<Scalars['String']['output']>;
  status?: Maybe<InvoiceStatus>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};


export type InvoiceAggregateGroupByCreatedAtArgs = {
  by?: GroupBy;
};


export type InvoiceAggregateGroupByDateArgs = {
  by?: GroupBy;
};


export type InvoiceAggregateGroupByUpdatedAtArgs = {
  by?: GroupBy;
};

export type InvoiceAggregateResponse = {
  __typename?: 'InvoiceAggregateResponse';
  avg?: Maybe<InvoiceAvgAggregate>;
  count?: Maybe<InvoiceCountAggregate>;
  groupBy?: Maybe<InvoiceAggregateGroupBy>;
  max?: Maybe<InvoiceMaxAggregate>;
  min?: Maybe<InvoiceMinAggregate>;
  sum?: Maybe<InvoiceSumAggregate>;
};

export type InvoiceAvgAggregate = {
  __typename?: 'InvoiceAvgAggregate';
  centAmount?: Maybe<Scalars['Float']['output']>;
  centAmountLines?: Maybe<Scalars['Float']['output']>;
  centAmountOpen?: Maybe<Scalars['Float']['output']>;
  centAmountPaid?: Maybe<Scalars['Float']['output']>;
  centAmountTaxes?: Maybe<Scalars['Float']['output']>;
};

export type InvoiceCentAmountFilterComparison = {
  gt?: InputMaybe<Scalars['Float']['input']>;
  gte?: InputMaybe<Scalars['Float']['input']>;
  lt?: InputMaybe<Scalars['Float']['input']>;
  lte?: InputMaybe<Scalars['Float']['input']>;
};

export type InvoiceCentAmountLinesFilterComparison = {
  gt?: InputMaybe<Scalars['Float']['input']>;
  gte?: InputMaybe<Scalars['Float']['input']>;
  lt?: InputMaybe<Scalars['Float']['input']>;
  lte?: InputMaybe<Scalars['Float']['input']>;
};

export type InvoiceCentAmountOpenFilterComparison = {
  gt?: InputMaybe<Scalars['Float']['input']>;
  gte?: InputMaybe<Scalars['Float']['input']>;
  lt?: InputMaybe<Scalars['Float']['input']>;
  lte?: InputMaybe<Scalars['Float']['input']>;
};

export type InvoiceCentAmountPaidFilterComparison = {
  gt?: InputMaybe<Scalars['Float']['input']>;
  gte?: InputMaybe<Scalars['Float']['input']>;
  lt?: InputMaybe<Scalars['Float']['input']>;
  lte?: InputMaybe<Scalars['Float']['input']>;
};

export type InvoiceCentAmountTaxesFilterComparison = {
  gt?: InputMaybe<Scalars['Float']['input']>;
  gte?: InputMaybe<Scalars['Float']['input']>;
  lt?: InputMaybe<Scalars['Float']['input']>;
  lte?: InputMaybe<Scalars['Float']['input']>;
};

export type InvoiceCompanyIdFilterComparison = {
  eq?: InputMaybe<Scalars['UUID']['input']>;
  in?: InputMaybe<Array<Scalars['UUID']['input']>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  neq?: InputMaybe<Scalars['UUID']['input']>;
  notIn?: InputMaybe<Array<Scalars['UUID']['input']>>;
};

export type InvoiceConnection = {
  __typename?: 'InvoiceConnection';
  /** Array of nodes. */
  nodes: Array<Invoice>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type InvoiceCountAggregate = {
  __typename?: 'InvoiceCountAggregate';
  centAmount?: Maybe<Scalars['Int']['output']>;
  centAmountLines?: Maybe<Scalars['Int']['output']>;
  centAmountOpen?: Maybe<Scalars['Int']['output']>;
  centAmountPaid?: Maybe<Scalars['Int']['output']>;
  centAmountTaxes?: Maybe<Scalars['Int']['output']>;
  companyId?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['Int']['output']>;
  currency?: Maybe<Scalars['Int']['output']>;
  date?: Maybe<Scalars['Int']['output']>;
  debtorId?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  jobId?: Maybe<Scalars['Int']['output']>;
  number?: Maybe<Scalars['Int']['output']>;
  status?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['Int']['output']>;
};

export type InvoiceCreatedAtFilterComparison = {
  between?: InputMaybe<InvoiceCreatedAtFilterComparisonBetween>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  notBetween?: InputMaybe<InvoiceCreatedAtFilterComparisonBetween>;
};

export type InvoiceCreatedAtFilterComparisonBetween = {
  lower: Scalars['DateTime']['input'];
  upper: Scalars['DateTime']['input'];
};

export type InvoiceCurrencyFilterComparison = {
  eq?: InputMaybe<Currency>;
  in?: InputMaybe<Array<Currency>>;
  neq?: InputMaybe<Currency>;
  notIn?: InputMaybe<Array<Currency>>;
};

export type InvoiceDateFilterComparison = {
  between?: InputMaybe<InvoiceDateFilterComparisonBetween>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  notBetween?: InputMaybe<InvoiceDateFilterComparisonBetween>;
};

export type InvoiceDateFilterComparisonBetween = {
  lower: Scalars['DateTime']['input'];
  upper: Scalars['DateTime']['input'];
};

export type InvoiceDebtor = {
  __typename?: 'InvoiceDebtor';
  /** Primary key of the recourse. */
  id: Scalars['UUID']['output'];
  /** Name of the company. */
  name: Scalars['String']['output'];
  /** Company type, "master", "franchisee" or "client". */
  type: CompanyType;
};

export type InvoiceDebtorFilter = {
  and?: InputMaybe<Array<InvoiceDebtorFilter>>;
  name?: InputMaybe<InvoiceDebtorNameFilterComparison>;
  or?: InputMaybe<Array<InvoiceDebtorFilter>>;
};

export type InvoiceDebtorIdFilterComparison = {
  eq?: InputMaybe<Scalars['UUID']['input']>;
  in?: InputMaybe<Array<Scalars['UUID']['input']>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  neq?: InputMaybe<Scalars['UUID']['input']>;
  notIn?: InputMaybe<Array<Scalars['UUID']['input']>>;
};

export type InvoiceDebtorNameFilterComparison = {
  eq?: InputMaybe<Scalars['String']['input']>;
  like?: InputMaybe<Scalars['String']['input']>;
  neq?: InputMaybe<Scalars['String']['input']>;
  notLike?: InputMaybe<Scalars['String']['input']>;
};

export type InvoiceDebtorOffsetConnection = {
  __typename?: 'InvoiceDebtorOffsetConnection';
  /** Array of nodes. */
  nodes: Array<InvoiceDebtor>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type InvoiceDebtorSort = {
  direction: SortDirection;
  field: InvoiceDebtorSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum InvoiceDebtorSortFields {
  Name = 'name'
}

export type InvoiceDeleteResponse = {
  __typename?: 'InvoiceDeleteResponse';
  /** Amount in cents of the invoice. */
  centAmount?: Maybe<Scalars['Float']['output']>;
  /** Amount in cents of all the invoice lines. */
  centAmountLines?: Maybe<Scalars['Float']['output']>;
  /** Amount in cents open of the invoice. */
  centAmountOpen?: Maybe<Scalars['Float']['output']>;
  /** Amount in cents paid of the invoice. */
  centAmountPaid?: Maybe<Scalars['Float']['output']>;
  /** Amount in cents of the invoice that are taxes. */
  centAmountTaxes?: Maybe<Scalars['Float']['output']>;
  /** Company the invoice belongs to. */
  companyId?: Maybe<Scalars['UUID']['output']>;
  /** Date time of when the recourse is created. */
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  /** Currency of invoice. */
  currency?: Maybe<Currency>;
  /** Date of the invoice. */
  date?: Maybe<Scalars['DateTime']['output']>;
  /** Id of the debtor the invoiced is to. */
  debtorId?: Maybe<Scalars['UUID']['output']>;
  /** Primary key of the recourse. */
  id?: Maybe<Scalars['UUID']['output']>;
  /** Date the invoice should start collection interest. */
  interestFromDate?: Maybe<Scalars['DateTime']['output']>;
  /** ID of the job that created this invoice. */
  jobId?: Maybe<Scalars['UUID']['output']>;
  /** Number of the invoice. */
  number?: Maybe<Scalars['String']['output']>;
  /** ID of the original invoice this credit is based off. */
  originalInvoiceId?: Maybe<Scalars['UUID']['output']>;
  /** Payment term of the invoice. */
  paymentTerm?: Maybe<Scalars['Float']['output']>;
  /** Type of the invoice payment term. */
  paymentTermType?: Maybe<InvoicePaymentTerm>;
  /** If `true` the invoice will be settled in the invoicing process. */
  settleInInvoicing?: Maybe<Scalars['Boolean']['output']>;
  /** Status of the invoice. */
  status?: Maybe<InvoiceStatus>;
  /** Subject of the invoice. */
  subject?: Maybe<Scalars['String']['output']>;
  /** Date time of when the recourse last updated. */
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** ID of the workflow that is used in this invoice. */
  workflowId?: Maybe<Scalars['UUID']['output']>;
};

export type InvoiceFilter = {
  and?: InputMaybe<Array<InvoiceFilter>>;
  centAmount?: InputMaybe<InvoiceCentAmountFilterComparison>;
  centAmountLines?: InputMaybe<InvoiceCentAmountLinesFilterComparison>;
  centAmountOpen?: InputMaybe<InvoiceCentAmountOpenFilterComparison>;
  centAmountPaid?: InputMaybe<InvoiceCentAmountPaidFilterComparison>;
  centAmountTaxes?: InputMaybe<InvoiceCentAmountTaxesFilterComparison>;
  companyId?: InputMaybe<InvoiceCompanyIdFilterComparison>;
  createdAt?: InputMaybe<InvoiceCreatedAtFilterComparison>;
  currency?: InputMaybe<InvoiceCurrencyFilterComparison>;
  date?: InputMaybe<InvoiceDateFilterComparison>;
  debtor?: InputMaybe<InvoiceFilterCompanyReferenceFilter>;
  debtorId?: InputMaybe<InvoiceDebtorIdFilterComparison>;
  id?: InputMaybe<InvoiceIdFilterComparison>;
  jobId?: InputMaybe<InvoiceJobIdFilterComparison>;
  number?: InputMaybe<InvoiceNumberFilterComparison>;
  or?: InputMaybe<Array<InvoiceFilter>>;
  status?: InputMaybe<InvoiceStatusFilterComparison>;
  updatedAt?: InputMaybe<InvoiceUpdatedAtFilterComparison>;
};

export type InvoiceFilterCompanyReferenceFilter = {
  and?: InputMaybe<Array<InvoiceFilterCompanyReferenceFilter>>;
  id?: InputMaybe<CompanyReferenceIdFilterComparison>;
  name?: InputMaybe<CompanyReferenceNameFilterComparison>;
  or?: InputMaybe<Array<InvoiceFilterCompanyReferenceFilter>>;
};

export type InvoiceIdFilterComparison = {
  eq?: InputMaybe<Scalars['UUID']['input']>;
  in?: InputMaybe<Array<Scalars['UUID']['input']>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  neq?: InputMaybe<Scalars['UUID']['input']>;
  notIn?: InputMaybe<Array<Scalars['UUID']['input']>>;
};

export type InvoiceJobIdFilterComparison = {
  eq?: InputMaybe<Scalars['UUID']['input']>;
  in?: InputMaybe<Array<Scalars['UUID']['input']>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  neq?: InputMaybe<Scalars['UUID']['input']>;
  notIn?: InputMaybe<Array<Scalars['UUID']['input']>>;
};

export type InvoiceLine = {
  __typename?: 'InvoiceLine';
  /** Cost category this invoice line will fall under. */
  category: CostCategoryReference;
  /** ID of the category of this line item. */
  categoryId?: Maybe<Scalars['UUID']['output']>;
  /** Amount in cents of one line item. */
  centAmount: Scalars['Float']['output'];
  /** Amount in cents of that are taxes of one line item. */
  centAmountTaxes: Scalars['Float']['output'];
  /** Total amount in cents of line item (centAmount * quantity). */
  centAmountTotal: Scalars['Float']['output'];
  /** Date time of when the recourse is created. */
  createdAt: Scalars['DateTime']['output'];
  /** Creator of this invoice line. */
  creator?: Maybe<Creator>;
  /** Currency of line item. */
  currency: Currency;
  /** ID of the debtor the line item is invoiced to. */
  debtorId: Scalars['UUID']['output'];
  /** Description of the line item. */
  description?: Maybe<Scalars['String']['output']>;
  /** Discount percentage of the line item. */
  discountPercentage?: Maybe<Scalars['Float']['output']>;
  /** Primary key of the recourse. */
  id: Scalars['UUID']['output'];
  /** Process line item at next invoicing. */
  inInvoicing: Scalars['Boolean']['output'];
  /** ID of the invoice the line item is invoiced to. */
  invoiceId?: Maybe<Scalars['UUID']['output']>;
  /** Note of the line item. */
  note?: Maybe<Scalars['String']['output']>;
  /** Quantity of line item. */
  quantity: Scalars['Float']['output'];
  /** Source of line item. */
  source: InvoiceLineSource;
  /** Tax rate that will be applied over this line. */
  taxRate?: Maybe<TaxRate>;
  /** Date time of when the recourse last updated. */
  updatedAt: Scalars['DateTime']['output'];
};

export type InvoiceLineCategoryIdFilterComparison = {
  eq?: InputMaybe<Scalars['UUID']['input']>;
  in?: InputMaybe<Array<Scalars['UUID']['input']>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  neq?: InputMaybe<Scalars['UUID']['input']>;
  notIn?: InputMaybe<Array<Scalars['UUID']['input']>>;
};

export type InvoiceLineCentAmountFilterComparison = {
  gt?: InputMaybe<Scalars['Float']['input']>;
  gte?: InputMaybe<Scalars['Float']['input']>;
  lt?: InputMaybe<Scalars['Float']['input']>;
  lte?: InputMaybe<Scalars['Float']['input']>;
};

export type InvoiceLineCentAmountTotalFilterComparison = {
  gt?: InputMaybe<Scalars['Float']['input']>;
  gte?: InputMaybe<Scalars['Float']['input']>;
  lt?: InputMaybe<Scalars['Float']['input']>;
  lte?: InputMaybe<Scalars['Float']['input']>;
};

export type InvoiceLineConnection = {
  __typename?: 'InvoiceLineConnection';
  /** Array of nodes. */
  nodes: Array<InvoiceLine>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type InvoiceLineCreatedAtFilterComparison = {
  between?: InputMaybe<InvoiceLineCreatedAtFilterComparisonBetween>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  notBetween?: InputMaybe<InvoiceLineCreatedAtFilterComparisonBetween>;
};

export type InvoiceLineCreatedAtFilterComparisonBetween = {
  lower: Scalars['DateTime']['input'];
  upper: Scalars['DateTime']['input'];
};

export type InvoiceLineDebtorIdFilterComparison = {
  eq?: InputMaybe<Scalars['UUID']['input']>;
  in?: InputMaybe<Array<Scalars['UUID']['input']>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  neq?: InputMaybe<Scalars['UUID']['input']>;
  notIn?: InputMaybe<Array<Scalars['UUID']['input']>>;
};

export type InvoiceLineDeleteResponse = {
  __typename?: 'InvoiceLineDeleteResponse';
  /** ID of the category of this line item. */
  categoryId?: Maybe<Scalars['UUID']['output']>;
  /** Amount in cents of one line item. */
  centAmount?: Maybe<Scalars['Float']['output']>;
  /** Amount in cents of that are taxes of one line item. */
  centAmountTaxes?: Maybe<Scalars['Float']['output']>;
  /** Total amount in cents of line item (centAmount * quantity). */
  centAmountTotal?: Maybe<Scalars['Float']['output']>;
  /** Date time of when the recourse is created. */
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  /** Currency of line item. */
  currency?: Maybe<Currency>;
  /** ID of the debtor the line item is invoiced to. */
  debtorId?: Maybe<Scalars['UUID']['output']>;
  /** Description of the line item. */
  description?: Maybe<Scalars['String']['output']>;
  /** Discount percentage of the line item. */
  discountPercentage?: Maybe<Scalars['Float']['output']>;
  /** Primary key of the recourse. */
  id?: Maybe<Scalars['UUID']['output']>;
  /** Process line item at next invoicing. */
  inInvoicing?: Maybe<Scalars['Boolean']['output']>;
  /** ID of the invoice the line item is invoiced to. */
  invoiceId?: Maybe<Scalars['UUID']['output']>;
  /** Note of the line item. */
  note?: Maybe<Scalars['String']['output']>;
  /** Quantity of line item. */
  quantity?: Maybe<Scalars['Float']['output']>;
  /** Source of line item. */
  source?: Maybe<InvoiceLineSource>;
  /** Date time of when the recourse last updated. */
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type InvoiceLineFilter = {
  and?: InputMaybe<Array<InvoiceLineFilter>>;
  categoryId?: InputMaybe<InvoiceLineCategoryIdFilterComparison>;
  centAmount?: InputMaybe<InvoiceLineCentAmountFilterComparison>;
  centAmountTotal?: InputMaybe<InvoiceLineCentAmountTotalFilterComparison>;
  createdAt?: InputMaybe<InvoiceLineCreatedAtFilterComparison>;
  debtorId: InvoiceLineDebtorIdFilterComparison;
  id?: InputMaybe<InvoiceLineIdFilterComparison>;
  invoiceId?: InputMaybe<InvoiceLineInvoiceIdFilterComparison>;
  or?: InputMaybe<Array<InvoiceLineFilter>>;
  updatedAt?: InputMaybe<InvoiceLineUpdatedAtFilterComparison>;
};

export type InvoiceLineIdFilterComparison = {
  eq?: InputMaybe<Scalars['UUID']['input']>;
  in?: InputMaybe<Array<Scalars['UUID']['input']>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  neq?: InputMaybe<Scalars['UUID']['input']>;
  notIn?: InputMaybe<Array<Scalars['UUID']['input']>>;
};

export type InvoiceLineInvoiceIdFilterComparison = {
  eq?: InputMaybe<Scalars['UUID']['input']>;
  in?: InputMaybe<Array<Scalars['UUID']['input']>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  neq?: InputMaybe<Scalars['UUID']['input']>;
  notIn?: InputMaybe<Array<Scalars['UUID']['input']>>;
};

export type InvoiceLineSort = {
  direction: SortDirection;
  field: InvoiceLineSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum InvoiceLineSortFields {
  CategoryId = 'categoryId',
  CentAmount = 'centAmount',
  CentAmountTotal = 'centAmountTotal',
  CreatedAt = 'createdAt',
  DebtorId = 'debtorId',
  Id = 'id',
  InvoiceId = 'invoiceId',
  UpdatedAt = 'updatedAt'
}

/** Source of an invoice line item. */
export enum InvoiceLineSource {
  Contract = 'Contract',
  DebtorManagementInvoiceFee = 'DebtorManagementInvoiceFee',
  Manual = 'Manual',
  Repeating = 'Repeating'
}

export type InvoiceLineUpdatedAtFilterComparison = {
  between?: InputMaybe<InvoiceLineUpdatedAtFilterComparisonBetween>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  notBetween?: InputMaybe<InvoiceLineUpdatedAtFilterComparisonBetween>;
};

export type InvoiceLineUpdatedAtFilterComparisonBetween = {
  lower: Scalars['DateTime']['input'];
  upper: Scalars['DateTime']['input'];
};

export type InvoiceMaxAggregate = {
  __typename?: 'InvoiceMaxAggregate';
  centAmount?: Maybe<Scalars['Float']['output']>;
  centAmountLines?: Maybe<Scalars['Float']['output']>;
  centAmountOpen?: Maybe<Scalars['Float']['output']>;
  centAmountPaid?: Maybe<Scalars['Float']['output']>;
  centAmountTaxes?: Maybe<Scalars['Float']['output']>;
  companyId?: Maybe<Scalars['UUID']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  currency?: Maybe<Currency>;
  date?: Maybe<Scalars['DateTime']['output']>;
  debtorId?: Maybe<Scalars['UUID']['output']>;
  id?: Maybe<Scalars['UUID']['output']>;
  jobId?: Maybe<Scalars['UUID']['output']>;
  number?: Maybe<Scalars['String']['output']>;
  status?: Maybe<InvoiceStatus>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type InvoiceMinAggregate = {
  __typename?: 'InvoiceMinAggregate';
  centAmount?: Maybe<Scalars['Float']['output']>;
  centAmountLines?: Maybe<Scalars['Float']['output']>;
  centAmountOpen?: Maybe<Scalars['Float']['output']>;
  centAmountPaid?: Maybe<Scalars['Float']['output']>;
  centAmountTaxes?: Maybe<Scalars['Float']['output']>;
  companyId?: Maybe<Scalars['UUID']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  currency?: Maybe<Currency>;
  date?: Maybe<Scalars['DateTime']['output']>;
  debtorId?: Maybe<Scalars['UUID']['output']>;
  id?: Maybe<Scalars['UUID']['output']>;
  jobId?: Maybe<Scalars['UUID']['output']>;
  number?: Maybe<Scalars['String']['output']>;
  status?: Maybe<InvoiceStatus>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type InvoiceNumberFilterComparison = {
  eq?: InputMaybe<Scalars['String']['input']>;
  like?: InputMaybe<Scalars['String']['input']>;
  neq?: InputMaybe<Scalars['String']['input']>;
  notLike?: InputMaybe<Scalars['String']['input']>;
};

/** Types of invoice terms. */
export enum InvoicePaymentTerm {
  Days = 'Days',
  WorkingDays = 'WorkingDays'
}

export type InvoiceReference = {
  __typename?: 'InvoiceReference';
  /** Date time of when the recourse is created. */
  createdAt: Scalars['DateTime']['output'];
  /** Primary key of the recourse. */
  id: Scalars['UUID']['output'];
  /** Number of the invoice. */
  number: Scalars['String']['output'];
  /** Date time of when the recourse last updated. */
  updatedAt: Scalars['DateTime']['output'];
};

export type InvoiceSort = {
  direction: SortDirection;
  field: InvoiceSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum InvoiceSortFields {
  CentAmount = 'centAmount',
  CentAmountLines = 'centAmountLines',
  CentAmountOpen = 'centAmountOpen',
  CentAmountPaid = 'centAmountPaid',
  CentAmountTaxes = 'centAmountTaxes',
  CompanyId = 'companyId',
  CreatedAt = 'createdAt',
  Currency = 'currency',
  Date = 'date',
  DebtorId = 'debtorId',
  Id = 'id',
  JobId = 'jobId',
  Number = 'number',
  Status = 'status',
  UpdatedAt = 'updatedAt'
}

/** Types the invoice can hold. */
export enum InvoiceStatus {
  Draft = 'Draft',
  Open = 'Open',
  Paid = 'Paid',
  Pending = 'Pending'
}

export type InvoiceStatusFilterComparison = {
  eq?: InputMaybe<InvoiceStatus>;
  in?: InputMaybe<Array<InvoiceStatus>>;
  neq?: InputMaybe<InvoiceStatus>;
  notIn?: InputMaybe<Array<InvoiceStatus>>;
};

export type InvoiceSumAggregate = {
  __typename?: 'InvoiceSumAggregate';
  centAmount?: Maybe<Scalars['Float']['output']>;
  centAmountLines?: Maybe<Scalars['Float']['output']>;
  centAmountOpen?: Maybe<Scalars['Float']['output']>;
  centAmountPaid?: Maybe<Scalars['Float']['output']>;
  centAmountTaxes?: Maybe<Scalars['Float']['output']>;
};

export type InvoiceTransaction = {
  __typename?: 'InvoiceTransaction';
  /** Account of the bank (e.g. IBAN). */
  account?: Maybe<Scalars['String']['output']>;
  /** Amount in cents of the transaction. */
  centAmount: Scalars['Float']['output'];
  /** Date time of when the recourse is created. */
  createdAt: Scalars['DateTime']['output'];
  /** Creator of the transaction. */
  creator?: Maybe<Creator>;
  /** Currency of the transaction. */
  currency: Currency;
  /** Dossier the transaction is connected to. */
  dossier?: Maybe<DossierReference>;
  /** ID of the dossier the transaction is connected to. */
  dossierId?: Maybe<Scalars['UUID']['output']>;
  /** Dossier invoice the transaction is connected to. */
  dossierInvoice?: Maybe<DossierInvoiceReference>;
  /** ID of the dossier invoice the transaction is connected to. */
  dossierInvoiceId?: Maybe<Scalars['UUID']['output']>;
  /** Primary key of the recourse. */
  id: Scalars['UUID']['output'];
  /** Invoice the transaction is connected to. */
  invoice?: Maybe<InvoiceReference>;
  /** ID of the invoice the transaction is connected to. */
  invoiceId: Scalars['UUID']['output'];
  /** ID of the job that created this transaction. */
  jobId?: Maybe<Scalars['UUID']['output']>;
  /** Name of the bank. */
  name?: Maybe<Scalars['String']['output']>;
  /** Is the transaction processed. */
  processed: Scalars['Boolean']['output'];
  /** Date time of when transaction took place. */
  receivedOn: Scalars['DateTime']['output'];
  /** Reference of the transaction. */
  reference?: Maybe<Scalars['String']['output']>;
  /** Source of the transaction. */
  source: TransactionSource;
  /** Subtract the amount of this transaction from the initial dossier amount. */
  subtract: Scalars['Boolean']['output'];
  /** The type of transaction. */
  type: TransactionType;
  /** Date time of when the recourse last updated. */
  updatedAt: Scalars['DateTime']['output'];
};

export type InvoiceTransactionAccountFilterComparison = {
  eq?: InputMaybe<Scalars['String']['input']>;
  like?: InputMaybe<Scalars['String']['input']>;
  neq?: InputMaybe<Scalars['String']['input']>;
  notLike?: InputMaybe<Scalars['String']['input']>;
};

export type InvoiceTransactionCentAmountFilterComparison = {
  gt?: InputMaybe<Scalars['Float']['input']>;
  gte?: InputMaybe<Scalars['Float']['input']>;
  lt?: InputMaybe<Scalars['Float']['input']>;
  lte?: InputMaybe<Scalars['Float']['input']>;
};

export type InvoiceTransactionConnection = {
  __typename?: 'InvoiceTransactionConnection';
  /** Array of nodes. */
  nodes: Array<InvoiceTransaction>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type InvoiceTransactionCreatedAtFilterComparison = {
  between?: InputMaybe<InvoiceTransactionCreatedAtFilterComparisonBetween>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  notBetween?: InputMaybe<InvoiceTransactionCreatedAtFilterComparisonBetween>;
};

export type InvoiceTransactionCreatedAtFilterComparisonBetween = {
  lower: Scalars['DateTime']['input'];
  upper: Scalars['DateTime']['input'];
};

export type InvoiceTransactionCurrencyFilterComparison = {
  eq?: InputMaybe<Currency>;
  in?: InputMaybe<Array<Currency>>;
  neq?: InputMaybe<Currency>;
  notIn?: InputMaybe<Array<Currency>>;
};

export type InvoiceTransactionDossierIdFilterComparison = {
  eq?: InputMaybe<Scalars['UUID']['input']>;
  in?: InputMaybe<Array<Scalars['UUID']['input']>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  neq?: InputMaybe<Scalars['UUID']['input']>;
  notIn?: InputMaybe<Array<Scalars['UUID']['input']>>;
};

export type InvoiceTransactionDossierInvoiceIdFilterComparison = {
  eq?: InputMaybe<Scalars['UUID']['input']>;
  in?: InputMaybe<Array<Scalars['UUID']['input']>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  neq?: InputMaybe<Scalars['UUID']['input']>;
  notIn?: InputMaybe<Array<Scalars['UUID']['input']>>;
};

export type InvoiceTransactionFilter = {
  account?: InputMaybe<InvoiceTransactionAccountFilterComparison>;
  and?: InputMaybe<Array<InvoiceTransactionFilter>>;
  centAmount?: InputMaybe<InvoiceTransactionCentAmountFilterComparison>;
  createdAt?: InputMaybe<InvoiceTransactionCreatedAtFilterComparison>;
  currency?: InputMaybe<InvoiceTransactionCurrencyFilterComparison>;
  dossierId?: InputMaybe<InvoiceTransactionDossierIdFilterComparison>;
  dossierInvoiceId?: InputMaybe<InvoiceTransactionDossierInvoiceIdFilterComparison>;
  id?: InputMaybe<InvoiceTransactionIdFilterComparison>;
  invoiceId: InvoiceTransactionInvoiceIdFilterComparison;
  jobId?: InputMaybe<InvoiceTransactionJobIdFilterComparison>;
  name?: InputMaybe<InvoiceTransactionNameFilterComparison>;
  or?: InputMaybe<Array<InvoiceTransactionFilter>>;
  processed?: InputMaybe<InvoiceTransactionProcessedFilterComparison>;
  receivedOn?: InputMaybe<InvoiceTransactionReceivedOnFilterComparison>;
  reference?: InputMaybe<InvoiceTransactionReferenceFilterComparison>;
  source?: InputMaybe<InvoiceTransactionSourceFilterComparison>;
  type?: InputMaybe<InvoiceTransactionTypeFilterComparison>;
  updatedAt?: InputMaybe<InvoiceTransactionUpdatedAtFilterComparison>;
};

export type InvoiceTransactionIdFilterComparison = {
  eq?: InputMaybe<Scalars['UUID']['input']>;
  in?: InputMaybe<Array<Scalars['UUID']['input']>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  neq?: InputMaybe<Scalars['UUID']['input']>;
  notIn?: InputMaybe<Array<Scalars['UUID']['input']>>;
};

export type InvoiceTransactionInvoiceIdFilterComparison = {
  eq?: InputMaybe<Scalars['UUID']['input']>;
  in?: InputMaybe<Array<Scalars['UUID']['input']>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  neq?: InputMaybe<Scalars['UUID']['input']>;
  notIn?: InputMaybe<Array<Scalars['UUID']['input']>>;
};

export type InvoiceTransactionJobIdFilterComparison = {
  eq?: InputMaybe<Scalars['UUID']['input']>;
  in?: InputMaybe<Array<Scalars['UUID']['input']>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  neq?: InputMaybe<Scalars['UUID']['input']>;
  notIn?: InputMaybe<Array<Scalars['UUID']['input']>>;
};

export type InvoiceTransactionNameFilterComparison = {
  eq?: InputMaybe<Scalars['String']['input']>;
  like?: InputMaybe<Scalars['String']['input']>;
  neq?: InputMaybe<Scalars['String']['input']>;
  notLike?: InputMaybe<Scalars['String']['input']>;
};

export type InvoiceTransactionProcessedFilterComparison = {
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
};

export type InvoiceTransactionReceivedOnFilterComparison = {
  between?: InputMaybe<InvoiceTransactionReceivedOnFilterComparisonBetween>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  notBetween?: InputMaybe<InvoiceTransactionReceivedOnFilterComparisonBetween>;
};

export type InvoiceTransactionReceivedOnFilterComparisonBetween = {
  lower: Scalars['DateTime']['input'];
  upper: Scalars['DateTime']['input'];
};

export type InvoiceTransactionReferenceFilterComparison = {
  eq?: InputMaybe<Scalars['String']['input']>;
  like?: InputMaybe<Scalars['String']['input']>;
  neq?: InputMaybe<Scalars['String']['input']>;
  notLike?: InputMaybe<Scalars['String']['input']>;
};

export type InvoiceTransactionSort = {
  direction: SortDirection;
  field: InvoiceTransactionSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum InvoiceTransactionSortFields {
  Account = 'account',
  CentAmount = 'centAmount',
  CreatedAt = 'createdAt',
  Currency = 'currency',
  DossierId = 'dossierId',
  DossierInvoiceId = 'dossierInvoiceId',
  Id = 'id',
  InvoiceId = 'invoiceId',
  JobId = 'jobId',
  Name = 'name',
  Processed = 'processed',
  ReceivedOn = 'receivedOn',
  Reference = 'reference',
  Source = 'source',
  Type = 'type',
  UpdatedAt = 'updatedAt'
}

export type InvoiceTransactionSourceFilterComparison = {
  eq?: InputMaybe<TransactionSource>;
  in?: InputMaybe<Array<TransactionSource>>;
  neq?: InputMaybe<TransactionSource>;
  notIn?: InputMaybe<Array<TransactionSource>>;
};

export type InvoiceTransactionTypeFilterComparison = {
  eq?: InputMaybe<TransactionType>;
  in?: InputMaybe<Array<TransactionType>>;
  neq?: InputMaybe<TransactionType>;
  notIn?: InputMaybe<Array<TransactionType>>;
};

export type InvoiceTransactionUpdatedAtFilterComparison = {
  between?: InputMaybe<InvoiceTransactionUpdatedAtFilterComparisonBetween>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  notBetween?: InputMaybe<InvoiceTransactionUpdatedAtFilterComparisonBetween>;
};

export type InvoiceTransactionUpdatedAtFilterComparisonBetween = {
  lower: Scalars['DateTime']['input'];
  upper: Scalars['DateTime']['input'];
};

export type InvoiceUpdatedAtFilterComparison = {
  between?: InputMaybe<InvoiceUpdatedAtFilterComparisonBetween>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  notBetween?: InputMaybe<InvoiceUpdatedAtFilterComparisonBetween>;
};

export type InvoiceUpdatedAtFilterComparisonBetween = {
  lower: Scalars['DateTime']['input'];
  upper: Scalars['DateTime']['input'];
};

export type Job = {
  __typename?: 'Job';
  /** Date time of when the recourse is created. */
  createdAt: Scalars['DateTime']['output'];
  /** Creator of the job. */
  creator: Creator;
  /** Failed reason of the job. */
  failedReason?: Maybe<JobFailedReason>;
  /** Files belonging to this job. */
  files: Array<FileReference>;
  /** Primary key of the recourse. */
  id: Scalars['UUID']['output'];
  /** Additional settings of the job. */
  settings?: Maybe<JobSettingsUnion>;
  /** Status of the job. */
  status: JobStatus;
  /** The target company of the job. */
  targetCompany?: Maybe<CompanyReference>;
  /** ID of the target company. */
  targetCompanyId?: Maybe<Scalars['UUID']['output']>;
  /** Type of job. */
  type: JobType;
  /** Date time of when the recourse last updated. */
  updatedAt: Scalars['DateTime']['output'];
};


export type JobFilesArgs = {
  sorting?: Array<FileReferenceSort>;
};

export type JobConnection = {
  __typename?: 'JobConnection';
  /** Array of nodes. */
  nodes: Array<Job>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type JobCreatedAtFilterComparison = {
  between?: InputMaybe<JobCreatedAtFilterComparisonBetween>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  notBetween?: InputMaybe<JobCreatedAtFilterComparisonBetween>;
};

export type JobCreatedAtFilterComparisonBetween = {
  lower: Scalars['DateTime']['input'];
  upper: Scalars['DateTime']['input'];
};

export type JobDossierInvoice = {
  __typename?: 'JobDossierInvoice';
  /** Amount in cents of the invoice. */
  centAmount: Scalars['Float']['output'];
  /** Amount in cents of the invoice that is still open (Only used in debtor management). */
  centAmountOpen: Scalars['Float']['output'];
  /** Amount in cents of the invoice that have been paid (Only used in debtor management). */
  centAmountPaid: Scalars['Float']['output'];
  /** ID of the contractor. */
  contractorId: Scalars['UUID']['output'];
  /** Date time of when the recourse is created. */
  createdAt: Scalars['DateTime']['output'];
  /** Currency of the invoice. */
  currency: Currency;
  /** Date of the invoice. */
  date: Scalars['DateTime']['output'];
  /** Debtor of the dossier. */
  debtor: CompanyReference;
  /** ID of the debtor. */
  debtorId: Scalars['UUID']['output'];
  /** Dossier this invoice belongs to. */
  dossier: DossierReference;
  /** ID of the dossier. */
  dossierId: Scalars['UUID']['output'];
  /** Days until this invoice expires. */
  expiresInDays: Scalars['Float']['output'];
  /** ID of associated file. */
  fileId?: Maybe<Scalars['UUID']['output']>;
  /** Primary key of the recourse. */
  id: Scalars['UUID']['output'];
  /** Type of interest. */
  interest?: Maybe<DossierInvoiceInterest>;
  /** Date to calculate interest from. (Expire date of the invoice) */
  interestFromDate?: Maybe<Scalars['DateTime']['output']>;
  /** ID of the job that created this dossier invoice. */
  jobId?: Maybe<Scalars['String']['output']>;
  /** Amount of days / work-days. */
  paymentTerm: Scalars['Float']['output'];
  /** Amount of days / work-days. */
  paymentTermType: InvoicePaymentTerm;
  /** Reference of the invoice. This needs to be unique! */
  reference: Scalars['String']['output'];
  /** Status of the invoice. (Only used in debtor management) */
  status: DossierInvoiceStatus;
  /** Date time of when the recourse last updated. */
  updatedAt: Scalars['DateTime']['output'];
};

export type JobDossierInvoiceConnection = {
  __typename?: 'JobDossierInvoiceConnection';
  /** Array of nodes. */
  nodes: Array<JobDossierInvoice>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type JobDossierInvoiceFilter = {
  and?: InputMaybe<Array<JobDossierInvoiceFilter>>;
  jobId: JobDossierInvoiceJobIdFilterComparison;
  or?: InputMaybe<Array<JobDossierInvoiceFilter>>;
};

export type JobDossierInvoiceJobIdFilterComparison = {
  eq?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  neq?: InputMaybe<Scalars['String']['input']>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type JobDossierInvoiceSort = {
  direction: SortDirection;
  field: JobDossierInvoiceSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum JobDossierInvoiceSortFields {
  JobId = 'jobId'
}

/** The failed reason of a job. */
export enum JobFailedReason {
  ImportDossiersErrorContainsDuplicates = 'ImportDossiersErrorContainsDuplicates',
  ImportDossiersErrorFailedToParse = 'ImportDossiersErrorFailedToParse',
  ImportDossiersErrorImporting = 'ImportDossiersErrorImporting',
  ImportDossiersErrorInvalidFile = 'ImportDossiersErrorInvalidFile',
  SyncDossiersErrorContainsDuplicates = 'SyncDossiersErrorContainsDuplicates'
}

export type JobFilter = {
  and?: InputMaybe<Array<JobFilter>>;
  createdAt?: InputMaybe<JobCreatedAtFilterComparison>;
  id?: InputMaybe<JobIdFilterComparison>;
  or?: InputMaybe<Array<JobFilter>>;
  status?: InputMaybe<JobStatusFilterComparison>;
  targetCompanyId?: InputMaybe<UuidFilterComparison>;
  type?: InputMaybe<JobTypeFilterComparison>;
  updatedAt?: InputMaybe<JobUpdatedAtFilterComparison>;
};

export type JobIdFilterComparison = {
  eq?: InputMaybe<Scalars['UUID']['input']>;
  in?: InputMaybe<Array<Scalars['UUID']['input']>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  neq?: InputMaybe<Scalars['UUID']['input']>;
  notIn?: InputMaybe<Array<Scalars['UUID']['input']>>;
};

export type JobSettingsImport = {
  __typename?: 'JobSettingsImport';
  provider: DossierImportProvider;
  type: DossierImportType;
};

/** Union of the different job import settings. */
export type JobSettingsUnion = JobSettingsImport;

export type JobSort = {
  direction: SortDirection;
  field: JobSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum JobSortFields {
  CreatedAt = 'createdAt',
  Id = 'id',
  Status = 'status',
  TargetCompanyId = 'targetCompanyId',
  Type = 'type',
  UpdatedAt = 'updatedAt'
}

/** The status of a job. */
export enum JobStatus {
  Done = 'Done',
  Failed = 'Failed',
  Pending = 'Pending',
  Running = 'Running'
}

export type JobStatusFilterComparison = {
  eq?: InputMaybe<JobStatus>;
  in?: InputMaybe<Array<JobStatus>>;
  neq?: InputMaybe<JobStatus>;
  notIn?: InputMaybe<Array<JobStatus>>;
};

/** The type of an job. */
export enum JobType {
  GenerateInvoices = 'GenerateInvoices',
  GenerateSepaXml = 'GenerateSepaXML',
  ImportBankTransactions = 'ImportBankTransactions',
  ImportCollection = 'ImportCollection',
  ImportDebtorManagement = 'ImportDebtorManagement',
  ProcessTransactions = 'ProcessTransactions',
  SyncBankTransactions = 'SyncBankTransactions',
  SyncCollection = 'SyncCollection',
  SyncDebtorManagement = 'SyncDebtorManagement'
}

export type JobTypeFilterComparison = {
  eq?: InputMaybe<JobType>;
  in?: InputMaybe<Array<JobType>>;
  neq?: InputMaybe<JobType>;
  notIn?: InputMaybe<Array<JobType>>;
};

export type JobUpdatedAtFilterComparison = {
  between?: InputMaybe<JobUpdatedAtFilterComparisonBetween>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  notBetween?: InputMaybe<JobUpdatedAtFilterComparisonBetween>;
};

export type JobUpdatedAtFilterComparisonBetween = {
  lower: Scalars['DateTime']['input'];
  upper: Scalars['DateTime']['input'];
};

export type Label = {
  __typename?: 'Label';
  /** Company that the label belongs to. */
  company: CompanyReference;
  /** ID of the company the label belongs to. */
  companyId: Scalars['UUID']['output'];
  /** Date time of when the recourse is created. */
  createdAt: Scalars['DateTime']['output'];
  /** Primary key of the recourse. */
  id: Scalars['UUID']['output'];
  /** Name of the label */
  name: Scalars['String']['output'];
  /** Date time of when the recourse last updated. */
  updatedAt: Scalars['DateTime']['output'];
};

export type LabelConnection = {
  __typename?: 'LabelConnection';
  /** Array of nodes. */
  nodes: Array<Label>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type LabelCreatedAtFilterComparison = {
  between?: InputMaybe<LabelCreatedAtFilterComparisonBetween>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  notBetween?: InputMaybe<LabelCreatedAtFilterComparisonBetween>;
};

export type LabelCreatedAtFilterComparisonBetween = {
  lower: Scalars['DateTime']['input'];
  upper: Scalars['DateTime']['input'];
};

export type LabelDeleteResponse = {
  __typename?: 'LabelDeleteResponse';
  /** ID of the company the label belongs to. */
  companyId?: Maybe<Scalars['UUID']['output']>;
  /** Date time of when the recourse is created. */
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  /** Primary key of the recourse. */
  id?: Maybe<Scalars['UUID']['output']>;
  /** Name of the label */
  name?: Maybe<Scalars['String']['output']>;
  /** Date time of when the recourse last updated. */
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type LabelFilter = {
  and?: InputMaybe<Array<LabelFilter>>;
  createdAt?: InputMaybe<LabelCreatedAtFilterComparison>;
  id?: InputMaybe<LabelIdFilterComparison>;
  name?: InputMaybe<LabelNameFilterComparison>;
  or?: InputMaybe<Array<LabelFilter>>;
  updatedAt?: InputMaybe<LabelUpdatedAtFilterComparison>;
};

export type LabelIdFilterComparison = {
  eq?: InputMaybe<Scalars['UUID']['input']>;
  in?: InputMaybe<Array<Scalars['UUID']['input']>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  neq?: InputMaybe<Scalars['UUID']['input']>;
  notIn?: InputMaybe<Array<Scalars['UUID']['input']>>;
};

export type LabelNameFilterComparison = {
  eq?: InputMaybe<Scalars['String']['input']>;
  like?: InputMaybe<Scalars['String']['input']>;
  neq?: InputMaybe<Scalars['String']['input']>;
  notLike?: InputMaybe<Scalars['String']['input']>;
};

export type LabelSort = {
  direction: SortDirection;
  field: LabelSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum LabelSortFields {
  CreatedAt = 'createdAt',
  Id = 'id',
  Name = 'name',
  UpdatedAt = 'updatedAt'
}

export type LabelUpdatedAtFilterComparison = {
  between?: InputMaybe<LabelUpdatedAtFilterComparisonBetween>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  notBetween?: InputMaybe<LabelUpdatedAtFilterComparisonBetween>;
};

export type LabelUpdatedAtFilterComparisonBetween = {
  lower: Scalars['DateTime']['input'];
  upper: Scalars['DateTime']['input'];
};

/** Languages the app supports. */
export enum Language {
  Da = 'Da',
  De = 'De',
  En = 'En',
  Fr = 'Fr',
  He = 'He',
  Nl = 'Nl',
  Pl = 'Pl',
  Ro = 'Ro',
  Sv = 'Sv'
}

/** The manager of a resource. */
export enum Manager {
  Import = 'Import'
}

export type Master = {
  __typename?: 'Master';
  /** Contacts of the company. */
  contacts?: Maybe<Array<CompanyContact>>;
  /** Country code where the company is located (ISO 3166-1 alpha-2). */
  country: Scalars['String']['output'];
  /** Date time of when the recourse is created. */
  createdAt: Scalars['DateTime']['output'];
  /** Currency the company uses. */
  currency: Currency;
  /** Primary key of the recourse. */
  id: Scalars['UUID']['output'];
  /** Additional info of the company. */
  info?: Maybe<CompanyInfo>;
  /** Preferred language of the company. */
  language: Language;
  /** Location number of the company. */
  locationNr?: Maybe<Scalars['String']['output']>;
  /** Name of the company. */
  name: Scalars['String']['output'];
  /** ID of the master the franchisee belongs to. */
  parentId: Scalars['UUID']['output'];
  /** Slug of the company. */
  slug: Scalars['String']['output'];
  /** Date time of when the recourse last updated. */
  updatedAt: Scalars['DateTime']['output'];
};


export type MasterContactsArgs = {
  filter?: CompanyContactFilter;
  sorting?: Array<CompanyContactSort>;
};

export type MasterConnection = {
  __typename?: 'MasterConnection';
  /** Array of nodes. */
  nodes: Array<Master>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type MasterCreatedAtFilterComparison = {
  between?: InputMaybe<MasterCreatedAtFilterComparisonBetween>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  notBetween?: InputMaybe<MasterCreatedAtFilterComparisonBetween>;
};

export type MasterCreatedAtFilterComparisonBetween = {
  lower: Scalars['DateTime']['input'];
  upper: Scalars['DateTime']['input'];
};

export type MasterFilter = {
  and?: InputMaybe<Array<MasterFilter>>;
  createdAt?: InputMaybe<MasterCreatedAtFilterComparison>;
  id?: InputMaybe<MasterIdFilterComparison>;
  name?: InputMaybe<MasterNameFilterComparison>;
  or?: InputMaybe<Array<MasterFilter>>;
  parentId?: InputMaybe<MasterParentIdFilterComparison>;
  updatedAt?: InputMaybe<MasterUpdatedAtFilterComparison>;
};

export type MasterIdFilterComparison = {
  eq?: InputMaybe<Scalars['UUID']['input']>;
  in?: InputMaybe<Array<Scalars['UUID']['input']>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  neq?: InputMaybe<Scalars['UUID']['input']>;
  notIn?: InputMaybe<Array<Scalars['UUID']['input']>>;
};

export type MasterNameFilterComparison = {
  eq?: InputMaybe<Scalars['String']['input']>;
  like?: InputMaybe<Scalars['String']['input']>;
  neq?: InputMaybe<Scalars['String']['input']>;
  notLike?: InputMaybe<Scalars['String']['input']>;
};

export type MasterParentIdFilterComparison = {
  eq?: InputMaybe<Scalars['UUID']['input']>;
  in?: InputMaybe<Array<Scalars['UUID']['input']>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  neq?: InputMaybe<Scalars['UUID']['input']>;
  notIn?: InputMaybe<Array<Scalars['UUID']['input']>>;
};

export type MasterSort = {
  direction: SortDirection;
  field: MasterSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum MasterSortFields {
  CreatedAt = 'createdAt',
  Id = 'id',
  Name = 'name',
  ParentId = 'parentId',
  UpdatedAt = 'updatedAt'
}

export type MasterUpdatedAtFilterComparison = {
  between?: InputMaybe<MasterUpdatedAtFilterComparisonBetween>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  notBetween?: InputMaybe<MasterUpdatedAtFilterComparisonBetween>;
};

export type MasterUpdatedAtFilterComparisonBetween = {
  lower: Scalars['DateTime']['input'];
  upper: Scalars['DateTime']['input'];
};

export type Me = {
  __typename?: 'Me';
  /** Email address of the user. */
  email: Scalars['String']['output'];
  /** Features for this user. */
  features: UserFeatures;
  /** First name of the user. */
  firstName?: Maybe<Scalars['String']['output']>;
  /** Full name of the user. */
  fullName?: Maybe<Scalars['String']['output']>;
  /** If the user has two factor enabled or not. */
  hasTwoFactor: Scalars['Boolean']['output'];
  /** Primary key of the recourse. */
  id: Scalars['UUID']['output'];
  /** Users preferred language. */
  language: Language;
  /** Last name of the user. */
  lastName?: Maybe<Scalars['String']['output']>;
  /** Scopes of the has for the active company. */
  scopes: Array<Scalars['String']['output']>;
  /** Type of the user. */
  type: UserType;
};

export type Mutation = {
  __typename?: 'Mutation';
  /** Activates a users account. */
  activateAccount: Scalars['Boolean']['output'];
  /** Add labels to one client. */
  addLabelsToClient: Client;
  /** Add labels to one dossier. */
  addLabelsToDossier: Dossier;
  /** Add payment arrangement to a workflow. */
  addPaymentArrangementToWorkflow: Array<WorkflowStep>;
  /** Add payment commitment to a workflow. */
  addPaymentCommitmentToWorkflow: Array<WorkflowStep>;
  /** Add the steps of a workflow to another workflow. */
  addStepsOfWorkflowToWorkflow: Array<WorkflowStep>;
  /** Cancel one contract. */
  cancelOneContract: Contract;
  /** Connect a transaction to an dossier. */
  connectTransactionToDossier: Transaction;
  /** Connect a transaction to an dossier invoice. */
  connectTransactionToDossierInvoice: Transaction;
  /** Updates the type of dossier. */
  convertOneDossierToCollection: Dossier;
  /** Add multiple area codes to an company. */
  createManyCompanyAreaCodes: Array<CompanyAreaCode>;
  /** Create many company contract costs at once. */
  createManyCompanyContractCosts: Array<CompanyContractCost>;
  /** Create many workflow steps, returns updated workflow. */
  createManyWorkflowSteps: Array<WorkflowStep>;
  /** Create one business activity. */
  createOneBusinessActivity: BusinessActivity;
  /** Create one client. */
  createOneClient: Client;
  /** Create one client contact. */
  createOneClientContact: ClientContact;
  /** Add bank account to a company. */
  createOneCompanyBankAccount: CompanyBankAccount;
  /** Create one company contact. */
  createOneCompanyContact: CompanyContact;
  /** Create one company contract. */
  createOneCompanyContract: CompanyContract;
  /** Create one new integration. */
  createOneCompanyIntegration: AuthoriseCompanyIntegration;
  /** Create new contract. */
  createOneContract: Contract;
  /** Create one cost category. */
  createOneCostCategory: CostCategory;
  /** Create one dossier. */
  createOneDossier: Dossier;
  /** Create new costs for a dossier. */
  createOneDossierCost: DossierCost;
  /** Create new dossier invoice. */
  createOneDossierInvoice: DossierInvoice;
  /** Create one franchisee. */
  createOneFranchisee: Franchisee;
  /** Create one interest rate. */
  createOneInterestRate: InterestRate;
  /** Create one new invoice. */
  createOneInvoice: Invoice;
  /** Create one invoice line. */
  createOneInvoiceLine: InvoiceLine;
  /** Create the invoice from an draft. */
  createOneInvoicePdf: Invoice;
  /** Create one label. */
  createOneLabel: Label;
  /** Create one master. */
  createOneMaster: Master;
  /** Create one client. */
  createOneNote: Note;
  /** Create one repeating invoice line. */
  createOneRepeatingInvoiceLine: RepeatingInvoiceLine;
  /** Create one tax rate. */
  createOneTaxRate: TaxRate;
  /** Create one new manual transaction */
  createOneTransaction: Transaction;
  /** Create one user group. */
  createOneUserGroup: UserGroup;
  /** Create one internal number. */
  createOneVoipInternalNumber: VoipInternalNumber;
  /** Create one workflow. */
  createOneWorkflow: Workflow;
  /** Credit one invoice. */
  creditOneInvoice: Invoice;
  /** Deactivates one account. */
  deactivateOneUser: User;
  /** Delete an invoice or postal address of a client. */
  deleteAddressFromClient: Scalars['Boolean']['output'];
  /** Delete an invoice or postal address of a company. */
  deleteAddressFromCompany: Scalars['Boolean']['output'];
  /** Delete all notifications. */
  deleteAllNotifications: Scalars['Boolean']['output'];
  /** Remove multiple area codes from an company. */
  deleteManyCompanyAreaCodes: DeleteManyResponse;
  /** Delete many company contract costs at once. */
  deleteManyCompanyContractCosts: DeleteManyResponse;
  /** Delete one business activity. */
  deleteOneBusinessActivity: BusinessActivityDeleteResponse;
  /** Delete one client contact. */
  deleteOneClientContact: ClientContactDeleteResponse;
  /** Delete a bank account of an company. */
  deleteOneCompanyBankAccount: CompanyBankAccountDeleteResponse;
  /** Delete one company contact. */
  deleteOneCompanyContact: CompanyContactDeleteResponse;
  /** Delete one company integration, */
  deleteOneCompanyIntegration: CompanyIntegrationDeleteResponse;
  /** Delete one contract. */
  deleteOneContract: ContractDeleteResponse;
  /** Delete one cost category. */
  deleteOneCostCategory: CostCategoryDeleteResponse;
  /** Delete one draft dossier. */
  deleteOneDossier: DossierDeleteResponse;
  /** Delete one un-processed costs of an dossier. */
  deleteOneDossierCost: DossierCostDeleteResponse;
  /** Delete one dossier invoice. */
  deleteOneDossierInvoice: DossierInvoiceDeleteResponse;
  /** Delete a file. */
  deleteOneFile: FileDeleteResponse;
  /** Delete one interest rate. */
  deleteOneInterestRate: InterestRateDeleteResponse;
  /** Delete one draft invoice. */
  deleteOneInvoice: InvoiceDeleteResponse;
  /** Delete one manual created invoice line. */
  deleteOneInvoiceLine: InvoiceLineDeleteResponse;
  /** Delete one label. */
  deleteOneLabel: LabelDeleteResponse;
  /** Delete one note. */
  deleteOneNote: NoteDeleteResponse;
  /** Delete a notification. */
  deleteOneNotification: NotificationDeleteResponse;
  /** Delete one repeating invoice line. */
  deleteOneRepeatingInvoiceLine: RepeatingInvoiceLineDeleteResponse;
  /** Delete one tax rate. */
  deleteOneTaxRate: TaxRateDeleteResponse;
  /** Delete one manual transaction. */
  deleteOneTransaction: TransactionDeleteResponse;
  /** Revoke one users access to an company. */
  deleteOneUserToCompany: UserToCompanyDeleteResponse;
  /** Delete one internal number. */
  deleteOneVoipInternalNumber: VoipInternalNumberDeleteResponse;
  /** Delete one workflow. */
  deleteOneWorkflow: WorkflowDeleteResponse;
  /** Delete one workflow step, returns updated workflow if step is part of one. */
  deleteOneWorkflowStep: Array<WorkflowStep>;
  /** Delete payment arrangement of a workflow. */
  deletePaymentArrangementFromWorkflow: Array<WorkflowStep>;
  /** Delete link between payment term workflow step and transaction, re-opens step (and check step after it) if in future. */
  deleteTransactionFromOneWorkflowStep: Array<WorkflowStep>;
  /** Download the dossier. */
  downloadOneDossier: Scalars['Boolean']['output'];
  /** Login the user with 2 factor validation. */
  enableTwoFactor: Scalars['Boolean']['output'];
  /** Schedule multiple workflow actions. */
  executeManyWorkflowActions: UpdateManyResponse;
  /** Execute an workflow action. */
  executeOneWorkflowAction: WorkflowAction;
  /** Exports many invoice lines. */
  exportManyInvoiceLines: ExportManyResponse;
  /** Exports many invoice PDFs. */
  exportManyInvoicePdfs: ExportManyResponse;
  /** Resets a users password. */
  forgotPassword: Scalars['Boolean']['output'];
  /** Import dossier invoices for collection. Will only return the newly created invoices. */
  importManyDossierInvoices: Array<DossierInvoice>;
  /** Import many transactions. */
  importManyTransactions: Job;
  /** Import dossier invoice for collection. Will return null if invoice is already imported. */
  importOneDossierInvoice?: Maybe<DossierInvoice>;
  /** Invite a client to his company. */
  inviteClient: Scalars['Boolean']['output'];
  /** Invite a user to join a company. */
  inviteUser: Scalars['Boolean']['output'];
  /** Pin one note. */
  pinOneNote: Note;
  /** Reactivates one account. */
  reactivateOneUser: User;
  /** Refresh the dossier balance. */
  refreshDossierBalance: Dossier;
  /** Remove labels from one client. */
  removeLabelsFromClient: Client;
  /** Remove labels from one dossier. */
  removeLabelsFromDossier: Dossier;
  /** Renew one new integration. */
  renewOneCompanyIntegration: AuthoriseCompanyIntegration;
  /** Reopens one workflow step so it can be modified again. */
  reopenOneWorkflowStep: WorkflowStep;
  /** Resend a accounts activation link. */
  resendAccountActivationLink: Scalars['Boolean']['output'];
  /** Resets the users password. */
  resetPassword: Scalars['Boolean']['output'];
  /** Reset the users two-factor. */
  resetTwoFactorForOneUser: User;
  /** ID of the job to retry. */
  retryInvoicingJob: Job;
  /** Create one new integration. */
  selectCompanyIntegrationAccount: CompanyIntegration;
  setLabelsOnClient: Client;
  setLabelsOnDossier: Dossier;
  /** Enable/disable settleInInvoicing on one invoice. */
  setSettleInInvoicingOnInvoice: Invoice;
  /** Skip multiple workflow actions. */
  skipManyWorkflowActions: UpdateManyResponse;
  /** Skip an workflow action. */
  skipOneWorkflowAction: WorkflowAction;
  /** Start an invoicing job for the users logged in company. */
  startInvoicingJob: Job;
  /** Sync debtor management dossier invoices. Invoices that are no longer in the list will be marked paid. Will only return the newly created invoices. */
  syncManyDossierInvoices: Array<DossierInvoice>;
  /** Unpin one note. */
  unpinOneNote: Note;
  /** Updates the status of multiple dossiers. */
  updateManyDossierStatus: Array<Dossier>;
  updateMultipleCompanyContractCosts: Array<CompanyContractCost>;
  /** Update one business activity. */
  updateOneBusinessActivity: BusinessActivity;
  /** Update one client */
  updateOneClient: Client;
  /** Update one client contact. */
  updateOneClientContact: ClientContact;
  /** Update the info of a client. */
  updateOneClientInfo: ClientInfo;
  /** Update one bank account. */
  updateOneCompanyBankAccount: CompanyBankAccount;
  /** Update one company contact. */
  updateOneCompanyContact: CompanyContact;
  /** Update one company contract. */
  updateOneCompanyContract: CompanyContract;
  /** Update the info of a company. */
  updateOneCompanyInfo: CompanyInfo;
  /** Update company settings. */
  updateOneCompanySettings: CompanySettings;
  /** Update one cost category. */
  updateOneCostCategory: CostCategory;
  /** Update one dossier. */
  updateOneDossier: Dossier;
  /** Update cost of a dossier. */
  updateOneDossierCost: DossierCost;
  /** Update new dossier invoice. */
  updateOneDossierInvoice: DossierInvoice;
  /** Updates the status of a dossier. */
  updateOneDossierStatus: Dossier;
  /** Update one file. */
  updateOneFile: File;
  /** Update one franchisee. */
  updateOneFranchisee: Franchisee;
  /** Update one interest rate. */
  updateOneInterestRate: InterestRate;
  /** Update one invoice. */
  updateOneInvoice: Invoice;
  /** Update one manual created invoice line. */
  updateOneInvoiceLine: InvoiceLine;
  /** Update one label. */
  updateOneLabel: Label;
  /** Update one master. */
  updateOneMaster: Master;
  /** Update one note. */
  updateOneNote: Note;
  /** Update one repeating invoice line. */
  updateOneRepeatingInvoiceLine: RepeatingInvoiceLine;
  /** Update one tax rate. */
  updateOneTaxRate: TaxRate;
  /** Update one user group. */
  updateOneUserGroup: UserGroup;
  /** Update one users access to an company. */
  updateOneUserToCompany: UserToCompany;
  /** Update one extension. */
  updateOneVoipExtension: VoipExtension;
  /** Update one internal number. */
  updateOneVoipInternalNumber: VoipInternalNumber;
  /** Update one workflow. */
  updateOneWorkflow: Workflow;
  /** Update one workflow action. */
  updateOneWorkflowAction: WorkflowAction;
  /** Update one workflow step, returns updated workflow if step is part of one. */
  updateOneWorkflowStep: Array<WorkflowStep>;
  /** Mark/unmark one workflow step as global. */
  updateOneWorkflowStepGlobalStatus: WorkflowStep;
  /** Update order of a step in the workflow. */
  updateOneWorkflowStepOrder: Array<WorkflowStep>;
  /** Checks if the provided activation token is valid. */
  validateActivationToken: ValidActivationToken;
  /** Checks if the provided password reset token is valid. */
  validateResetPasswordToken: ValidPasswordResetToken;
};


export type MutationActivateAccountArgs = {
  accountInfo: ActivateAccountInfo;
  token: Scalars['String']['input'];
};


export type MutationAddLabelsToClientArgs = {
  input: AddLabelsToClientInput;
};


export type MutationAddLabelsToDossierArgs = {
  input: AddLabelsToDossierInput;
};


export type MutationAddPaymentArrangementToWorkflowArgs = {
  input: CreatePaymentArrangement;
};


export type MutationAddPaymentCommitmentToWorkflowArgs = {
  input: CreatePaymentCommitment;
};


export type MutationAddStepsOfWorkflowToWorkflowArgs = {
  input: AddStepsOfWorkflowToWorkflow;
};


export type MutationCancelOneContractArgs = {
  input: CancelOneContractInput;
};


export type MutationConnectTransactionToDossierArgs = {
  dossierId?: InputMaybe<Scalars['UUID']['input']>;
  dossierInvoiceId?: InputMaybe<Scalars['UUID']['input']>;
  id: Scalars['UUID']['input'];
};


export type MutationConnectTransactionToDossierInvoiceArgs = {
  dossierInvoiceId?: InputMaybe<Scalars['UUID']['input']>;
  id: Scalars['UUID']['input'];
};


export type MutationConvertOneDossierToCollectionArgs = {
  input: ConvertDossierToCollection;
};


export type MutationCreateManyCompanyAreaCodesArgs = {
  input: CreateManyCompanyAreaCodesInput;
};


export type MutationCreateManyCompanyContractCostsArgs = {
  input: CreateManyCompanyContractCostsInput;
};


export type MutationCreateManyWorkflowStepsArgs = {
  input: CreateManyWorkflowSteps;
};


export type MutationCreateOneBusinessActivityArgs = {
  input: CreateOneBusinessActivityInput;
};


export type MutationCreateOneClientArgs = {
  input: CreateOneClientInput;
};


export type MutationCreateOneClientContactArgs = {
  input: CreateOneClientContactInput;
};


export type MutationCreateOneCompanyBankAccountArgs = {
  input: CreateOneCompanyBankAccountInput;
};


export type MutationCreateOneCompanyContactArgs = {
  input: CreateOneCompanyContactInput;
};


export type MutationCreateOneCompanyContractArgs = {
  input: CreateOneCompanyContractInput;
};


export type MutationCreateOneCompanyIntegrationArgs = {
  input: CreateCompanyIntegration;
};


export type MutationCreateOneContractArgs = {
  input: CreateOneContractInput;
};


export type MutationCreateOneCostCategoryArgs = {
  input: CreateOneCostCategoryInput;
};


export type MutationCreateOneDossierArgs = {
  input: CreateOneDossierInput;
};


export type MutationCreateOneDossierCostArgs = {
  input: CreateOneDossierCostInput;
};


export type MutationCreateOneDossierInvoiceArgs = {
  input: CreateOneDossierInvoiceInput;
};


export type MutationCreateOneFranchiseeArgs = {
  input: CreateOneFranchiseeInput;
};


export type MutationCreateOneInterestRateArgs = {
  input: CreateOneInterestRateInput;
};


export type MutationCreateOneInvoiceArgs = {
  input: CreateOneInvoiceInput;
};


export type MutationCreateOneInvoiceLineArgs = {
  input: CreateOneInvoiceLineInput;
};


export type MutationCreateOneInvoicePdfArgs = {
  id: Scalars['UUID']['input'];
};


export type MutationCreateOneLabelArgs = {
  input: CreateOneLabelInput;
};


export type MutationCreateOneMasterArgs = {
  input: CreateOneMasterInput;
};


export type MutationCreateOneNoteArgs = {
  input: CreateOneNoteInput;
};


export type MutationCreateOneRepeatingInvoiceLineArgs = {
  input: CreateOneRepeatingInvoiceLineInput;
};


export type MutationCreateOneTaxRateArgs = {
  input: CreateOneTaxRateInput;
};


export type MutationCreateOneTransactionArgs = {
  input: CreateOneTransactionInput;
};


export type MutationCreateOneUserGroupArgs = {
  input: CreateOneUserGroupInput;
};


export type MutationCreateOneVoipInternalNumberArgs = {
  input: CreateOneVoipInternalNumberInput;
};


export type MutationCreateOneWorkflowArgs = {
  input: CreateOneWorkflowInput;
};


export type MutationCreditOneInvoiceArgs = {
  id: Scalars['UUID']['input'];
};


export type MutationDeactivateOneUserArgs = {
  id: Scalars['UUID']['input'];
};


export type MutationDeleteAddressFromClientArgs = {
  addressId: Scalars['UUID']['input'];
  id: Scalars['UUID']['input'];
};


export type MutationDeleteAddressFromCompanyArgs = {
  addressId: Scalars['UUID']['input'];
  id: Scalars['UUID']['input'];
};


export type MutationDeleteManyCompanyAreaCodesArgs = {
  input: DeleteManyCompanyAreaCodesInput;
};


export type MutationDeleteManyCompanyContractCostsArgs = {
  input: DeleteManyCompanyContractCostsInput;
};


export type MutationDeleteOneBusinessActivityArgs = {
  input: DeleteOneBusinessActivityInput;
};


export type MutationDeleteOneClientContactArgs = {
  input: DeleteOneClientContactInput;
};


export type MutationDeleteOneCompanyBankAccountArgs = {
  input: DeleteOneCompanyBankAccountInput;
};


export type MutationDeleteOneCompanyContactArgs = {
  input: DeleteOneCompanyContactInput;
};


export type MutationDeleteOneCompanyIntegrationArgs = {
  input: DeleteOneCompanyIntegrationInput;
};


export type MutationDeleteOneContractArgs = {
  input: DeleteOneContractInput;
};


export type MutationDeleteOneCostCategoryArgs = {
  input: DeleteOneCostCategoryInput;
};


export type MutationDeleteOneDossierArgs = {
  input: DeleteOneDossierInput;
};


export type MutationDeleteOneDossierCostArgs = {
  input: DeleteOneDossierCostInput;
};


export type MutationDeleteOneDossierInvoiceArgs = {
  input: DeleteOneDossierInvoiceInput;
};


export type MutationDeleteOneFileArgs = {
  input: DeleteOneFileInput;
};


export type MutationDeleteOneInterestRateArgs = {
  input: DeleteOneInterestRateInput;
};


export type MutationDeleteOneInvoiceArgs = {
  input: DeleteOneInvoiceInput;
};


export type MutationDeleteOneInvoiceLineArgs = {
  input: DeleteOneInvoiceLineInput;
};


export type MutationDeleteOneLabelArgs = {
  input: DeleteOneLabelInput;
};


export type MutationDeleteOneNoteArgs = {
  input: DeleteOneNoteInput;
};


export type MutationDeleteOneNotificationArgs = {
  input: DeleteOneNotificationInput;
};


export type MutationDeleteOneRepeatingInvoiceLineArgs = {
  input: DeleteOneRepeatingInvoiceLineInput;
};


export type MutationDeleteOneTaxRateArgs = {
  input: DeleteOneTaxRateInput;
};


export type MutationDeleteOneTransactionArgs = {
  input: DeleteOneTransactionInput;
};


export type MutationDeleteOneUserToCompanyArgs = {
  input: DeleteOneUserToCompanyInput;
};


export type MutationDeleteOneVoipInternalNumberArgs = {
  input: DeleteOneVoipInternalNumberInput;
};


export type MutationDeleteOneWorkflowArgs = {
  input: DeleteOneWorkflowInput;
};


export type MutationDeleteOneWorkflowStepArgs = {
  input: DeleteOneWorkflowStepInput;
};


export type MutationDeletePaymentArrangementFromWorkflowArgs = {
  input: DeletePaymentArrangement;
};


export type MutationDeleteTransactionFromOneWorkflowStepArgs = {
  input: DeleteTransactionFromWorkflowStep;
};


export type MutationDownloadOneDossierArgs = {
  id: Scalars['UUID']['input'];
  input: DownloadOneDossierInput;
};


export type MutationEnableTwoFactorArgs = {
  code: Scalars['String']['input'];
  email: Scalars['EmailAddress']['input'];
  password: Scalars['String']['input'];
  secret: Scalars['String']['input'];
};


export type MutationExecuteManyWorkflowActionsArgs = {
  input: ExecuteManyWorkflowActions;
};


export type MutationExecuteOneWorkflowActionArgs = {
  action?: InputMaybe<UpdateWorkflowAction>;
  id: Scalars['UUID']['input'];
};


export type MutationExportManyInvoiceLinesArgs = {
  input: ExportManyInput;
};


export type MutationExportManyInvoicePdfsArgs = {
  input: ExportManyInput;
};


export type MutationForgotPasswordArgs = {
  email: Scalars['EmailAddress']['input'];
};


export type MutationImportManyDossierInvoicesArgs = {
  input: ImportDossierInvoicesInput;
};


export type MutationImportManyTransactionsArgs = {
  input: ImportManyTransactionInput;
};


export type MutationImportOneDossierInvoiceArgs = {
  input: ImportDossierInvoiceInput;
};


export type MutationInviteClientArgs = {
  input: InviteUser;
};


export type MutationInviteUserArgs = {
  input: InviteUser;
};


export type MutationPinOneNoteArgs = {
  input: PinOneNoteInput;
};


export type MutationReactivateOneUserArgs = {
  id: Scalars['UUID']['input'];
};


export type MutationRefreshDossierBalanceArgs = {
  id: Scalars['UUID']['input'];
};


export type MutationRemoveLabelsFromClientArgs = {
  input: RemoveLabelsFromClientInput;
};


export type MutationRemoveLabelsFromDossierArgs = {
  input: RemoveLabelsFromDossierInput;
};


export type MutationRenewOneCompanyIntegrationArgs = {
  input: RenewCompanyIntegration;
};


export type MutationReopenOneWorkflowStepArgs = {
  input: ReopenWorkflowStep;
};


export type MutationResendAccountActivationLinkArgs = {
  id: Scalars['UUID']['input'];
};


export type MutationResetPasswordArgs = {
  password: Scalars['String']['input'];
  passwordCheck: Scalars['String']['input'];
  token: Scalars['String']['input'];
};


export type MutationResetTwoFactorForOneUserArgs = {
  id: Scalars['UUID']['input'];
};


export type MutationRetryInvoicingJobArgs = {
  id: Scalars['UUID']['input'];
};


export type MutationSelectCompanyIntegrationAccountArgs = {
  input: SelectCompanyIntegrationAccountInput;
};


export type MutationSetLabelsOnClientArgs = {
  input: SetLabelsOnClientInput;
};


export type MutationSetLabelsOnDossierArgs = {
  input: SetLabelsOnDossierInput;
};


export type MutationSetSettleInInvoicingOnInvoiceArgs = {
  id: Scalars['UUID']['input'];
  to: Scalars['Boolean']['input'];
};


export type MutationSkipManyWorkflowActionsArgs = {
  input: SkipManyWorkflowActionsInput;
};


export type MutationSkipOneWorkflowActionArgs = {
  id: Scalars['UUID']['input'];
};


export type MutationSyncManyDossierInvoicesArgs = {
  input: SyncDossierInvoicesInput;
};


export type MutationUnpinOneNoteArgs = {
  input: UnpinOneNoteInput;
};


export type MutationUpdateManyDossierStatusArgs = {
  input: UpdateManyDossierStatus;
};


export type MutationUpdateMultipleCompanyContractCostsArgs = {
  input: Array<UpdateOneCompanyContractCostInput>;
};


export type MutationUpdateOneBusinessActivityArgs = {
  input: UpdateOneBusinessActivityInput;
};


export type MutationUpdateOneClientArgs = {
  input: UpdateOneClientInput;
};


export type MutationUpdateOneClientContactArgs = {
  input: UpdateOneClientContactInput;
};


export type MutationUpdateOneClientInfoArgs = {
  input: UpdateOneClientInfoInput;
};


export type MutationUpdateOneCompanyBankAccountArgs = {
  input: UpdateOneCompanyBankAccountInput;
};


export type MutationUpdateOneCompanyContactArgs = {
  input: UpdateOneCompanyContactInput;
};


export type MutationUpdateOneCompanyContractArgs = {
  input: UpdateOneCompanyContractInput;
};


export type MutationUpdateOneCompanyInfoArgs = {
  input: UpdateOneCompanyInfoInput;
};


export type MutationUpdateOneCompanySettingsArgs = {
  input: UpdateOneCompanySettingsInput;
};


export type MutationUpdateOneCostCategoryArgs = {
  input: UpdateOneCostCategoryInput;
};


export type MutationUpdateOneDossierArgs = {
  input: UpdateOneDossierInput;
};


export type MutationUpdateOneDossierCostArgs = {
  input: UpdateOneDossierCostInput;
};


export type MutationUpdateOneDossierInvoiceArgs = {
  input: UpdateOneDossierInvoiceInput;
};


export type MutationUpdateOneDossierStatusArgs = {
  input: UpdateOneDossierStatus;
};


export type MutationUpdateOneFileArgs = {
  input: UpdateOneFileInput;
};


export type MutationUpdateOneFranchiseeArgs = {
  input: UpdateOneFranchiseeInput;
};


export type MutationUpdateOneInterestRateArgs = {
  input: UpdateOneInterestRateInput;
};


export type MutationUpdateOneInvoiceArgs = {
  input: UpdateOneInvoiceInput;
};


export type MutationUpdateOneInvoiceLineArgs = {
  input: UpdateOneInvoiceLineInput;
};


export type MutationUpdateOneLabelArgs = {
  input: UpdateOneLabelInput;
};


export type MutationUpdateOneMasterArgs = {
  input: UpdateOneMasterInput;
};


export type MutationUpdateOneNoteArgs = {
  input: UpdateOneNoteInput;
};


export type MutationUpdateOneRepeatingInvoiceLineArgs = {
  input: UpdateOneRepeatingInvoiceLineInput;
};


export type MutationUpdateOneTaxRateArgs = {
  input: UpdateOneTaxRateInput;
};


export type MutationUpdateOneUserGroupArgs = {
  input: UpdateOneUserGroupInput;
};


export type MutationUpdateOneUserToCompanyArgs = {
  input: UpdateOneUserToCompanyInput;
};


export type MutationUpdateOneVoipExtensionArgs = {
  input: UpdateOneVoipExtensionInput;
};


export type MutationUpdateOneVoipInternalNumberArgs = {
  input: UpdateOneVoipInternalNumberInput;
};


export type MutationUpdateOneWorkflowArgs = {
  input: UpdateOneWorkflowInput;
};


export type MutationUpdateOneWorkflowActionArgs = {
  input: UpdateOneWorkflowActionInput;
};


export type MutationUpdateOneWorkflowStepArgs = {
  input: UpdateOneWorkflowStep;
};


export type MutationUpdateOneWorkflowStepGlobalStatusArgs = {
  input: UpdateOneWorkflowStepGlobal;
};


export type MutationUpdateOneWorkflowStepOrderArgs = {
  input: UpdateOneWorkflowStepOrder;
};


export type MutationValidateActivationTokenArgs = {
  token: Scalars['String']['input'];
};


export type MutationValidateResetPasswordTokenArgs = {
  token: Scalars['String']['input'];
};

/** The statuses the dossier can be updated to. */
export enum NewDossierStatus {
  AutoOpen = 'AutoOpen',
  Closed = 'Closed',
  Open = 'Open',
  Paid = 'Paid',
  Paused = 'Paused'
}

export type Note = {
  __typename?: 'Note';
  /** Content of the note. */
  content: Scalars['String']['output'];
  /** Date time of when the recourse is created. */
  createdAt: Scalars['DateTime']['output'];
  /** Creator of the note. */
  creator: Creator;
  /** ID of the user who created the note. */
  creatorId: Scalars['UUID']['output'];
  /** Primary key of the recourse. */
  id: Scalars['UUID']['output'];
  /** Is it a sticky note. */
  sticky: Scalars['Boolean']['output'];
  /** Date time of when the recourse last updated. */
  updatedAt: Scalars['DateTime']['output'];
  /** Updater of the note. */
  updater: Creator;
  /** ID of the user who last updated the note. */
  updaterId: Scalars['UUID']['output'];
  /** Is the note visible for the debtor. */
  visibleForDebtor: Scalars['Boolean']['output'];
};

export type NoteAudit = {
  __typename?: 'NoteAudit';
  /** Action that was done on the resource. */
  _action: AuditingAction;
  /** Actor that changed the record. */
  _actor: Actor;
  /** Date time of when the action was executed. */
  _modifiedAt: Scalars['DateTime']['output'];
  /** Primary key of the recourse. */
  _seq: Scalars['UUID']['output'];
  /** Content of the note. */
  content: Scalars['String']['output'];
  /** Date time of when the recourse is created. */
  createdAt: Scalars['DateTime']['output'];
  /** Creator of the note. */
  creator: Creator;
  /** ID of the user who created the note. */
  creatorId: Scalars['UUID']['output'];
  /** Primary key of the recourse. */
  id: Scalars['UUID']['output'];
  /** Is it a sticky note. */
  sticky: Scalars['Boolean']['output'];
  /** Date time of when the recourse last updated. */
  updatedAt: Scalars['DateTime']['output'];
  /** Updater of the note. */
  updater: Creator;
  /** ID of the user who last updated the note. */
  updaterId: Scalars['UUID']['output'];
  /** Is the note visible for the debtor. */
  visibleForDebtor: Scalars['Boolean']['output'];
};

export type NoteAuditCompanyIdFilterComparison = {
  eq?: InputMaybe<Scalars['UUID']['input']>;
  in?: InputMaybe<Array<Scalars['UUID']['input']>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  neq?: InputMaybe<Scalars['UUID']['input']>;
  notIn?: InputMaybe<Array<Scalars['UUID']['input']>>;
};

export type NoteAuditConnection = {
  __typename?: 'NoteAuditConnection';
  /** Array of nodes. */
  nodes: Array<NoteAudit>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type NoteAuditCreatedAtFilterComparison = {
  between?: InputMaybe<NoteAuditCreatedAtFilterComparisonBetween>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  notBetween?: InputMaybe<NoteAuditCreatedAtFilterComparisonBetween>;
};

export type NoteAuditCreatedAtFilterComparisonBetween = {
  lower: Scalars['DateTime']['input'];
  upper: Scalars['DateTime']['input'];
};

export type NoteAuditDossierIdFilterComparison = {
  eq?: InputMaybe<Scalars['UUID']['input']>;
  in?: InputMaybe<Array<Scalars['UUID']['input']>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  neq?: InputMaybe<Scalars['UUID']['input']>;
  notIn?: InputMaybe<Array<Scalars['UUID']['input']>>;
};

export type NoteAuditFilter = {
  _modifiedAt?: InputMaybe<NoteAudit_ModifiedAtFilterComparison>;
  _seq?: InputMaybe<NoteAudit_SeqFilterComparison>;
  and?: InputMaybe<Array<NoteAuditFilter>>;
  companyId?: InputMaybe<NoteAuditCompanyIdFilterComparison>;
  createdAt?: InputMaybe<NoteAuditCreatedAtFilterComparison>;
  dossierId?: InputMaybe<NoteAuditDossierIdFilterComparison>;
  id?: InputMaybe<NoteAuditIdFilterComparison>;
  invoiceId?: InputMaybe<NoteAuditInvoiceIdFilterComparison>;
  or?: InputMaybe<Array<NoteAuditFilter>>;
  sticky?: InputMaybe<NoteAuditStickyFilterComparison>;
  updatedAt?: InputMaybe<NoteAuditUpdatedAtFilterComparison>;
};

export type NoteAuditIdFilterComparison = {
  eq?: InputMaybe<Scalars['UUID']['input']>;
  in?: InputMaybe<Array<Scalars['UUID']['input']>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  neq?: InputMaybe<Scalars['UUID']['input']>;
  notIn?: InputMaybe<Array<Scalars['UUID']['input']>>;
};

export type NoteAuditInvoiceIdFilterComparison = {
  eq?: InputMaybe<Scalars['UUID']['input']>;
  in?: InputMaybe<Array<Scalars['UUID']['input']>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  neq?: InputMaybe<Scalars['UUID']['input']>;
  notIn?: InputMaybe<Array<Scalars['UUID']['input']>>;
};

export type NoteAuditSort = {
  direction: SortDirection;
  field: NoteAuditSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum NoteAuditSortFields {
  ModifiedAt = '_modifiedAt',
  Seq = '_seq',
  CompanyId = 'companyId',
  CreatedAt = 'createdAt',
  DossierId = 'dossierId',
  Id = 'id',
  InvoiceId = 'invoiceId',
  Sticky = 'sticky',
  UpdatedAt = 'updatedAt'
}

export type NoteAuditStickyFilterComparison = {
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
};

export type NoteAuditUpdatedAtFilterComparison = {
  between?: InputMaybe<NoteAuditUpdatedAtFilterComparisonBetween>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  notBetween?: InputMaybe<NoteAuditUpdatedAtFilterComparisonBetween>;
};

export type NoteAuditUpdatedAtFilterComparisonBetween = {
  lower: Scalars['DateTime']['input'];
  upper: Scalars['DateTime']['input'];
};

export type NoteAudit_ModifiedAtFilterComparison = {
  between?: InputMaybe<NoteAudit_ModifiedAtFilterComparisonBetween>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  notBetween?: InputMaybe<NoteAudit_ModifiedAtFilterComparisonBetween>;
};

export type NoteAudit_ModifiedAtFilterComparisonBetween = {
  lower: Scalars['DateTime']['input'];
  upper: Scalars['DateTime']['input'];
};

export type NoteAudit_SeqFilterComparison = {
  eq?: InputMaybe<Scalars['UUID']['input']>;
  in?: InputMaybe<Array<Scalars['UUID']['input']>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  neq?: InputMaybe<Scalars['UUID']['input']>;
  notIn?: InputMaybe<Array<Scalars['UUID']['input']>>;
};

export type NoteCompanyIdFilterComparison = {
  eq?: InputMaybe<Scalars['UUID']['input']>;
  in?: InputMaybe<Array<Scalars['UUID']['input']>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  neq?: InputMaybe<Scalars['UUID']['input']>;
  notIn?: InputMaybe<Array<Scalars['UUID']['input']>>;
};

export type NoteConnection = {
  __typename?: 'NoteConnection';
  /** Array of nodes. */
  nodes: Array<Note>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type NoteCreatedAtFilterComparison = {
  between?: InputMaybe<NoteCreatedAtFilterComparisonBetween>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  notBetween?: InputMaybe<NoteCreatedAtFilterComparisonBetween>;
};

export type NoteCreatedAtFilterComparisonBetween = {
  lower: Scalars['DateTime']['input'];
  upper: Scalars['DateTime']['input'];
};

export type NoteDeleteResponse = {
  __typename?: 'NoteDeleteResponse';
  /** Content of the note. */
  content?: Maybe<Scalars['String']['output']>;
  /** Date time of when the recourse is created. */
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  /** ID of the user who created the note. */
  creatorId?: Maybe<Scalars['UUID']['output']>;
  /** Primary key of the recourse. */
  id?: Maybe<Scalars['UUID']['output']>;
  /** Is it a sticky note. */
  sticky?: Maybe<Scalars['Boolean']['output']>;
  /** Date time of when the recourse last updated. */
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** ID of the user who last updated the note. */
  updaterId?: Maybe<Scalars['UUID']['output']>;
  /** Is the note visible for the debtor. */
  visibleForDebtor?: Maybe<Scalars['Boolean']['output']>;
};

export type NoteDossierIdFilterComparison = {
  eq?: InputMaybe<Scalars['UUID']['input']>;
  in?: InputMaybe<Array<Scalars['UUID']['input']>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  neq?: InputMaybe<Scalars['UUID']['input']>;
  notIn?: InputMaybe<Array<Scalars['UUID']['input']>>;
};

export type NoteFilter = {
  and?: InputMaybe<Array<NoteFilter>>;
  companyId?: InputMaybe<NoteCompanyIdFilterComparison>;
  createdAt?: InputMaybe<NoteCreatedAtFilterComparison>;
  dossierId?: InputMaybe<NoteDossierIdFilterComparison>;
  id?: InputMaybe<NoteIdFilterComparison>;
  invoiceId?: InputMaybe<NoteInvoiceIdFilterComparison>;
  or?: InputMaybe<Array<NoteFilter>>;
  sticky?: InputMaybe<NoteStickyFilterComparison>;
  updatedAt?: InputMaybe<NoteUpdatedAtFilterComparison>;
};

export type NoteIdFilterComparison = {
  eq?: InputMaybe<Scalars['UUID']['input']>;
  in?: InputMaybe<Array<Scalars['UUID']['input']>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  neq?: InputMaybe<Scalars['UUID']['input']>;
  notIn?: InputMaybe<Array<Scalars['UUID']['input']>>;
};

export type NoteInvoiceIdFilterComparison = {
  eq?: InputMaybe<Scalars['UUID']['input']>;
  in?: InputMaybe<Array<Scalars['UUID']['input']>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  neq?: InputMaybe<Scalars['UUID']['input']>;
  notIn?: InputMaybe<Array<Scalars['UUID']['input']>>;
};

export type NoteSort = {
  direction: SortDirection;
  field: NoteSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum NoteSortFields {
  CompanyId = 'companyId',
  CreatedAt = 'createdAt',
  DossierId = 'dossierId',
  Id = 'id',
  InvoiceId = 'invoiceId',
  Sticky = 'sticky',
  UpdatedAt = 'updatedAt'
}

export type NoteStickyFilterComparison = {
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
};

export type NoteUpdatedAtFilterComparison = {
  between?: InputMaybe<NoteUpdatedAtFilterComparisonBetween>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  notBetween?: InputMaybe<NoteUpdatedAtFilterComparisonBetween>;
};

export type NoteUpdatedAtFilterComparisonBetween = {
  lower: Scalars['DateTime']['input'];
  upper: Scalars['DateTime']['input'];
};

export type Notification = {
  __typename?: 'Notification';
  /** Content of the notification. */
  content?: Maybe<Scalars['String']['output']>;
  /** Date time of when the recourse is created. */
  createdAt: Scalars['DateTime']['output'];
  /** Additional data for the notification. */
  data?: Maybe<Scalars['JSONObject']['output']>;
  /** Can the notification be dismissed. */
  dismissible?: Maybe<Scalars['Boolean']['output']>;
  /** Primary key of the recourse. */
  id: Scalars['UUID']['output'];
  /** Severity of the notification. */
  severity: NotificationSeverity;
  /** Title of the notification. */
  title?: Maybe<Scalars['String']['output']>;
  /** Type of the notification. */
  type: NotificationType;
  /** Date time of when the recourse last updated. */
  updatedAt: Scalars['DateTime']['output'];
};

export type NotificationConnection = {
  __typename?: 'NotificationConnection';
  /** Array of nodes. */
  nodes: Array<Notification>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type NotificationCreatedAtFilterComparison = {
  between?: InputMaybe<NotificationCreatedAtFilterComparisonBetween>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  notBetween?: InputMaybe<NotificationCreatedAtFilterComparisonBetween>;
};

export type NotificationCreatedAtFilterComparisonBetween = {
  lower: Scalars['DateTime']['input'];
  upper: Scalars['DateTime']['input'];
};

export type NotificationDeleteResponse = {
  __typename?: 'NotificationDeleteResponse';
  /** Content of the notification. */
  content?: Maybe<Scalars['String']['output']>;
  /** Date time of when the recourse is created. */
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  /** Additional data for the notification. */
  data?: Maybe<Scalars['JSONObject']['output']>;
  /** Can the notification be dismissed. */
  dismissible?: Maybe<Scalars['Boolean']['output']>;
  /** Primary key of the recourse. */
  id?: Maybe<Scalars['UUID']['output']>;
  /** Severity of the notification. */
  severity?: Maybe<NotificationSeverity>;
  /** Title of the notification. */
  title?: Maybe<Scalars['String']['output']>;
  /** Type of the notification. */
  type?: Maybe<NotificationType>;
  /** Date time of when the recourse last updated. */
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type NotificationFilter = {
  and?: InputMaybe<Array<NotificationFilter>>;
  createdAt?: InputMaybe<NotificationCreatedAtFilterComparison>;
  id?: InputMaybe<NotificationIdFilterComparison>;
  or?: InputMaybe<Array<NotificationFilter>>;
  updatedAt?: InputMaybe<NotificationUpdatedAtFilterComparison>;
};

export type NotificationIdFilterComparison = {
  eq?: InputMaybe<Scalars['UUID']['input']>;
  in?: InputMaybe<Array<Scalars['UUID']['input']>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  neq?: InputMaybe<Scalars['UUID']['input']>;
  notIn?: InputMaybe<Array<Scalars['UUID']['input']>>;
};

/** Severity of the notification. */
export enum NotificationSeverity {
  Error = 'Error',
  Info = 'Info',
  Success = 'Success',
  Warning = 'Warning'
}

export type NotificationSort = {
  direction: SortDirection;
  field: NotificationSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum NotificationSortFields {
  CreatedAt = 'createdAt',
  Id = 'id',
  UpdatedAt = 'updatedAt'
}

/** Type of the notification. */
export enum NotificationType {
  AccountingSyncDuplicateInvoice = 'AccountingSyncDuplicateInvoice',
  AddedToCompany = 'AddedToCompany',
  CompanyIntegrationDeleted = 'CompanyIntegrationDeleted',
  CompanyIntegrationExpired = 'CompanyIntegrationExpired',
  DossierDownload = 'DossierDownload',
  DossierImport = 'DossierImport',
  InvoicesAvailable = 'InvoicesAvailable',
  InvoicingInvoicesCreated = 'InvoicingInvoicesCreated',
  InvoicingJobDone = 'InvoicingJobDone',
  InvoicingJobFailedSepa = 'InvoicingJobFailedSEPA',
  InvoicingJobFailedSepaMissingBankInfo = 'InvoicingJobFailedSEPAMissingBankInfo',
  InvoicingJobFailedSepaMissingSettings = 'InvoicingJobFailedSEPAMissingSettings',
  InvoicingJobSepaDone = 'InvoicingJobSEPADone',
  ManualInvoiceCreated = 'ManualInvoiceCreated',
  NewDossierTransaction = 'NewDossierTransaction',
  Normal = 'Normal',
  TransactionsSynced = 'TransactionsSynced'
}

export type NotificationUpdatedAtFilterComparison = {
  between?: InputMaybe<NotificationUpdatedAtFilterComparisonBetween>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  notBetween?: InputMaybe<NotificationUpdatedAtFilterComparisonBetween>;
};

export type NotificationUpdatedAtFilterComparisonBetween = {
  lower: Scalars['DateTime']['input'];
  upper: Scalars['DateTime']['input'];
};

export type NumberFieldComparison = {
  between?: InputMaybe<NumberFieldComparisonBetween>;
  eq?: InputMaybe<Scalars['Float']['input']>;
  gt?: InputMaybe<Scalars['Float']['input']>;
  gte?: InputMaybe<Scalars['Float']['input']>;
  in?: InputMaybe<Array<Scalars['Float']['input']>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  lt?: InputMaybe<Scalars['Float']['input']>;
  lte?: InputMaybe<Scalars['Float']['input']>;
  neq?: InputMaybe<Scalars['Float']['input']>;
  notBetween?: InputMaybe<NumberFieldComparisonBetween>;
  notIn?: InputMaybe<Array<Scalars['Float']['input']>>;
};

export type NumberFieldComparisonBetween = {
  lower: Scalars['Float']['input'];
  upper: Scalars['Float']['input'];
};

export type OffsetPageInfo = {
  __typename?: 'OffsetPageInfo';
  /** true if paging forward and there are more records. */
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  /** true if paging backwards and there are more records. */
  hasPreviousPage?: Maybe<Scalars['Boolean']['output']>;
};

export type OffsetPaging = {
  /** Limit the number of records returned */
  limit?: InputMaybe<Scalars['Int']['input']>;
  /** Offset to start returning records from */
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type PageInfo = {
  __typename?: 'PageInfo';
  /** The cursor of the last returned record. */
  endCursor?: Maybe<Scalars['ConnectionCursor']['output']>;
  /** true if paging forward and there are more records. */
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  /** true if paging backwards and there are more records. */
  hasPreviousPage?: Maybe<Scalars['Boolean']['output']>;
  /** The cursor of the first returned record. */
  startCursor?: Maybe<Scalars['ConnectionCursor']['output']>;
};

export type PinOneNoteInput = {
  /** The id of the record to update */
  id: Scalars['UUID']['input'];
};

export type Query = {
  __typename?: 'Query';
  /** The active company of the token. */
  activeCompany?: Maybe<ActiveCompany>;
  /** Get all business activities. */
  businessActivities: BusinessActivityConnection;
  /** Get one business activity. */
  businessActivity: BusinessActivity;
  /** Get one client. */
  client: Client;
  /** Aggregate clients. */
  clientAggregate: Array<ClientAggregateResponse>;
  /** Get one client contact. */
  clientContact: ClientContact;
  /** Get all client contacts. */
  clientContacts: ClientContactConnection;
  /** Get info for a client. */
  clientInfo?: Maybe<ClientInfo>;
  /** Get all clients. */
  clients: ClientConnection;
  /** Get all companies the user has access to. */
  companies: CompanyOffsetConnection;
  /** Get all area codes of a company. */
  companyAreaCodes: Array<CompanyAreaCode>;
  /** Get one bank account. */
  companyBankAccount: CompanyBankAccount;
  /** Get all audited bank accounts of a company. */
  companyBankAccountAudits: CompanyBankAccountAuditConnection;
  /** Get all bank accounts of a company. */
  companyBankAccounts: CompanyBankAccountConnection;
  /** Get one company contact. */
  companyContact: CompanyContact;
  /** Get all company contacts. */
  companyContacts: CompanyContactConnection;
  /** Get one company contract. */
  companyContract: CompanyContract;
  /** Get all company contracts. */
  companyContracts: CompanyContractConnection;
  /** Get info for a company. */
  companyInfo?: Maybe<CompanyInfo>;
  /** Get all company integrations. */
  companyIntegrations: CompanyIntegrationConnection;
  /** Read one company's settings. */
  companySettings?: Maybe<CompanySettings>;
  /** Get one contract. */
  contract: Contract;
  /** Aggregate contracts. */
  contractAggregate: Array<ContractAggregateResponse>;
  /** Get many contracts. */
  contracts: ContractConnection;
  /** Get all cost categories. */
  costCategories: CostCategoryConnection;
  /** Get one cost category. */
  costCategory: CostCategory;
  /** Get one dossier. */
  dossier: Dossier;
  /** Aggregate dossiers. */
  dossierAggregate: Array<DossierAggregateResponse>;
  /** Get cost of an dossier. */
  dossierCost: DossierCost;
  /** Get all costs for a dossier. */
  dossierCosts: DossierCostConnection;
  /** Get all interest lines of a dossier. */
  dossierInterestLines: Array<DossierInterestLine>;
  /** Get one dossier invoice. */
  dossierInvoice: DossierInvoice;
  /** Aggregate dossier invoices. */
  dossierInvoiceAggregate: Array<DossierInvoiceAggregateResponse>;
  /** Get all invoices of an dossier. */
  dossierInvoices: DossierInvoiceConnection;
  /** Get the timeline of a dossier. */
  dossierTimeline: DossierTimelineItemOffsetConnection;
  /** Get all dossiers. */
  dossiers: DossierConnection;
  /** Get one email. */
  email: Email;
  /** Get one executable action. */
  executableAction: ExecutableAction;
  /** Get all executable actions for dossiers. */
  executableDossierActions: ExecutableDossierActionOffsetConnection;
  /** Get all executable actions for invoices. */
  executableInvoiceActions: ExecutableInvoiceActionOffsetConnection;
  /** Get one file. */
  file: File;
  /** Get all files. */
  files: FileOffsetConnection;
  /** Get one franchisee. */
  franchisee: Franchisee;
  /** Get all franchisees. */
  franchisees: FranchiseeConnection;
  /** Get one interest rate. */
  interestRate: InterestRate;
  /** Get all interest rate. */
  interestRates: InterestRateConnection;
  /** Get one invoice. */
  invoice: Invoice;
  /** Aggregate invoices. */
  invoiceAggregate: Array<InvoiceAggregateResponse>;
  /** Get all possible debtors for invoices. */
  invoiceDebtors: InvoiceDebtorOffsetConnection;
  /** Get one invoice line. */
  invoiceLine: InvoiceLine;
  /** Get all invoice lines. */
  invoiceLines: InvoiceLineConnection;
  /** Get all transactions for an invoice. */
  invoiceTransactions: InvoiceTransactionConnection;
  /** Get all invoices. */
  invoices: InvoiceConnection;
  /** Get one job. */
  job: Job;
  /** Get dossier invoices for one job. */
  jobDossierInvoices: JobDossierInvoiceConnection;
  /** Get all jobs. */
  jobs: JobConnection;
  /** Get one label. */
  label: Label;
  /** Get all labels. */
  labels: LabelConnection;
  /** Get one master. */
  master: Master;
  /** Get all masters. */
  masters: MasterConnection;
  me: Me;
  /** Get one note. */
  note: Note;
  /** Get all audited notes of a company / dossier. */
  noteAudits: NoteAuditConnection;
  /** Get all notes. */
  notes: NoteConnection;
  /** Get users notifications. */
  notifications: NotificationConnection;
  /** Get one repeating invoice line. */
  repeatingInvoiceLine: RepeatingInvoiceLine;
  /** Get all repeating invoice lines. */
  repeatingInvoiceLines: RepeatingInvoiceLineConnection;
  /** Search through the APP. */
  search: SearchItemOffsetConnection;
  /** Get suggested dossier for transaction. */
  suggestDossierForTransaction?: Maybe<DossierReference>;
  /** Get one tax rate. */
  taxRate: TaxRate;
  /** Get all tax rate. */
  taxRates: TaxRateConnection;
  /** Get one transaction. */
  transaction: Transaction;
  /** Aggregate transactions. */
  transactionAggregate: Array<TransactionAggregateResponse>;
  /** Get all transactions. */
  transactions: TransactionConnection;
  /** Gets a secret and an QR code that can be used to activate two factor. */
  twoFactor: TwoFactorPayload;
  /** Get one user. */
  user: User;
  /** Get one user group. */
  userGroup: UserGroup;
  /** Get all users groups. */
  userGroups: UserGroupConnection;
  /** Get all user to companies. */
  userToCompanies: UserToCompanyConnection;
  /** Get all users. */
  users: UserConnection;
  /** Get one call. */
  voipCall: VoipCall;
  /** Get all calls. */
  voipCalls: VoipCallConnection;
  /** Get one extension. */
  voipExtension: VoipExtension;
  /** Get all extensions. */
  voipExtensions: VoipExtensionConnection;
  /** Get one internal number. */
  voipInternalNumber: VoipInternalNumber;
  /** Get all internal numbers. */
  voipInternalNumbers: VoipInternalNumberConnection;
  /** Get all voip numbers. */
  voipNumbers: VoipNumberConnection;
  /** Get one workflow. */
  workflow: Workflow;
  /** Get one workflow action. */
  workflowAction: WorkflowAction;
  /** Get all global workflow actions. */
  workflowActions: WorkflowActionConnection;
  /** Get cost categories that can be added to the provided workflow. */
  workflowCostCategories: Array<CostCategoryReference>;
  /** Get one workflow step. */
  workflowStep: WorkflowStep;
  /** Get all global workflow steps, if workflowId is provided all steps of that workflow. */
  workflowSteps: WorkflowStepOffsetConnection;
  /** Get all workflow. */
  workflows: WorkflowConnection;
};


export type QueryBusinessActivitiesArgs = {
  filter?: BusinessActivityFilter;
  paging?: OffsetPaging;
  sorting?: Array<BusinessActivitySort>;
};


export type QueryBusinessActivityArgs = {
  id: Scalars['UUID']['input'];
};


export type QueryClientArgs = {
  id: Scalars['UUID']['input'];
};


export type QueryClientAggregateArgs = {
  filter?: InputMaybe<ClientAggregateFilter>;
};


export type QueryClientContactArgs = {
  id: Scalars['UUID']['input'];
};


export type QueryClientContactsArgs = {
  filter?: ClientContactFilter;
  paging?: OffsetPaging;
  sorting?: Array<ClientContactSort>;
};


export type QueryClientInfoArgs = {
  id: Scalars['UUID']['input'];
};


export type QueryClientsArgs = {
  filter?: ClientFilter;
  paging?: OffsetPaging;
  sorting?: Array<ClientSort>;
};


export type QueryCompaniesArgs = {
  filter?: CompanyFilter;
  paging?: OffsetPaging;
  sorting?: Array<CompanySort>;
};


export type QueryCompanyAreaCodesArgs = {
  filter: CompanyAreaCodeFilter;
  sorting?: Array<CompanyAreaCodeSort>;
};


export type QueryCompanyBankAccountArgs = {
  id: Scalars['UUID']['input'];
};


export type QueryCompanyBankAccountAuditsArgs = {
  filter: CompanyBankAccountAuditFilter;
  paging?: OffsetPaging;
  sorting?: Array<CompanyBankAccountAuditSort>;
};


export type QueryCompanyBankAccountsArgs = {
  filter: CompanyBankAccountFilter;
  paging?: OffsetPaging;
  sorting?: Array<CompanyBankAccountSort>;
};


export type QueryCompanyContactArgs = {
  id: Scalars['UUID']['input'];
};


export type QueryCompanyContactsArgs = {
  filter?: CompanyContactFilter;
  paging?: OffsetPaging;
  sorting?: Array<CompanyContactSort>;
};


export type QueryCompanyContractArgs = {
  id: Scalars['UUID']['input'];
};


export type QueryCompanyContractsArgs = {
  filter?: CompanyContractFilter;
  paging?: OffsetPaging;
  sorting?: Array<CompanyContractSort>;
};


export type QueryCompanyInfoArgs = {
  id: Scalars['UUID']['input'];
};


export type QueryCompanyIntegrationsArgs = {
  filter: CompanyIntegrationFilter;
  paging?: OffsetPaging;
  sorting?: Array<CompanyIntegrationSort>;
};


export type QueryCompanySettingsArgs = {
  id: Scalars['UUID']['input'];
};


export type QueryContractArgs = {
  id: Scalars['UUID']['input'];
};


export type QueryContractAggregateArgs = {
  filter?: InputMaybe<ContractAggregateFilter>;
};


export type QueryContractsArgs = {
  filter?: ContractFilter;
  paging?: OffsetPaging;
  sorting?: Array<ContractSort>;
};


export type QueryCostCategoriesArgs = {
  filter?: CostCategoryFilter;
  paging?: OffsetPaging;
  sorting?: Array<CostCategorySort>;
};


export type QueryCostCategoryArgs = {
  id: Scalars['UUID']['input'];
};


export type QueryDossierArgs = {
  id: Scalars['UUID']['input'];
};


export type QueryDossierAggregateArgs = {
  filter?: InputMaybe<DossierAggregateFilter>;
};


export type QueryDossierCostArgs = {
  id: Scalars['UUID']['input'];
};


export type QueryDossierCostsArgs = {
  filter: DossierCostFilter;
  paging?: OffsetPaging;
  sorting?: Array<DossierCostSort>;
};


export type QueryDossierInterestLinesArgs = {
  filter: DossierInterestLineFilter;
  sorting?: Array<DossierInterestLineSort>;
};


export type QueryDossierInvoiceArgs = {
  id: Scalars['UUID']['input'];
};


export type QueryDossierInvoiceAggregateArgs = {
  filter?: InputMaybe<DossierInvoiceAggregateFilter>;
};


export type QueryDossierInvoicesArgs = {
  filter?: DossierInvoiceFilter;
  paging?: OffsetPaging;
  sorting?: Array<DossierInvoiceSort>;
};


export type QueryDossierTimelineArgs = {
  id: Scalars['UUID']['input'];
  paging?: OffsetPaging;
};


export type QueryDossiersArgs = {
  filter?: DossierFilter;
  paging?: OffsetPaging;
  sorting?: Array<DossierSort>;
};


export type QueryEmailArgs = {
  id: Scalars['UUID']['input'];
};


export type QueryExecutableActionArgs = {
  id: Scalars['UUID']['input'];
};


export type QueryExecutableDossierActionsArgs = {
  filter?: ExecutableDossierActionFilter;
  paging?: OffsetPaging;
  sorting?: Array<ExecutableDossierActionSort>;
};


export type QueryExecutableInvoiceActionsArgs = {
  filter?: ExecutableInvoiceActionFilter;
  paging?: OffsetPaging;
  sorting?: Array<ExecutableInvoiceActionSort>;
};


export type QueryFileArgs = {
  id: Scalars['UUID']['input'];
};


export type QueryFilesArgs = {
  filter?: FileFilter;
  paging?: OffsetPaging;
  sorting?: Array<FileSort>;
};


export type QueryFranchiseeArgs = {
  id: Scalars['UUID']['input'];
};


export type QueryFranchiseesArgs = {
  filter?: FranchiseeFilter;
  paging?: OffsetPaging;
  sorting?: Array<FranchiseeSort>;
};


export type QueryInterestRateArgs = {
  id: Scalars['UUID']['input'];
};


export type QueryInterestRatesArgs = {
  filter?: InterestRateFilter;
  paging?: OffsetPaging;
  sorting?: Array<InterestRateSort>;
};


export type QueryInvoiceArgs = {
  id: Scalars['UUID']['input'];
};


export type QueryInvoiceAggregateArgs = {
  filter?: InputMaybe<InvoiceAggregateFilter>;
};


export type QueryInvoiceDebtorsArgs = {
  filter?: InvoiceDebtorFilter;
  paging?: OffsetPaging;
  sorting?: Array<InvoiceDebtorSort>;
};


export type QueryInvoiceLineArgs = {
  id: Scalars['UUID']['input'];
};


export type QueryInvoiceLinesArgs = {
  filter: InvoiceLineFilter;
  paging?: OffsetPaging;
  sorting?: Array<InvoiceLineSort>;
};


export type QueryInvoiceTransactionsArgs = {
  filter: InvoiceTransactionFilter;
  paging?: OffsetPaging;
  sorting?: Array<InvoiceTransactionSort>;
};


export type QueryInvoicesArgs = {
  filter?: InvoiceFilter;
  paging?: OffsetPaging;
  sorting?: Array<InvoiceSort>;
};


export type QueryJobArgs = {
  id: Scalars['UUID']['input'];
};


export type QueryJobDossierInvoicesArgs = {
  filter: JobDossierInvoiceFilter;
  paging?: OffsetPaging;
  sorting?: Array<JobDossierInvoiceSort>;
};


export type QueryJobsArgs = {
  filter?: JobFilter;
  paging?: OffsetPaging;
  sorting?: Array<JobSort>;
};


export type QueryLabelArgs = {
  id: Scalars['UUID']['input'];
};


export type QueryLabelsArgs = {
  filter?: LabelFilter;
  paging?: OffsetPaging;
  sorting?: Array<LabelSort>;
};


export type QueryMasterArgs = {
  id: Scalars['UUID']['input'];
};


export type QueryMastersArgs = {
  filter?: MasterFilter;
  paging?: OffsetPaging;
  sorting?: Array<MasterSort>;
};


export type QueryNoteArgs = {
  id: Scalars['UUID']['input'];
};


export type QueryNoteAuditsArgs = {
  filter?: NoteAuditFilter;
  paging?: OffsetPaging;
  sorting?: Array<NoteAuditSort>;
};


export type QueryNotesArgs = {
  filter?: NoteFilter;
  paging?: OffsetPaging;
  sorting?: Array<NoteSort>;
};


export type QueryNotificationsArgs = {
  filter?: NotificationFilter;
  paging?: OffsetPaging;
  sorting?: Array<NotificationSort>;
};


export type QueryRepeatingInvoiceLineArgs = {
  id: Scalars['UUID']['input'];
};


export type QueryRepeatingInvoiceLinesArgs = {
  filter?: RepeatingInvoiceLineFilter;
  paging?: OffsetPaging;
  sorting?: Array<RepeatingInvoiceLineSort>;
};


export type QuerySearchArgs = {
  paging?: OffsetPaging;
  query: Scalars['String']['input'];
};


export type QuerySuggestDossierForTransactionArgs = {
  id: Scalars['UUID']['input'];
};


export type QueryTaxRateArgs = {
  id: Scalars['UUID']['input'];
};


export type QueryTaxRatesArgs = {
  filter?: TaxRateFilter;
  paging?: OffsetPaging;
  sorting?: Array<TaxRateSort>;
};


export type QueryTransactionArgs = {
  id: Scalars['UUID']['input'];
};


export type QueryTransactionAggregateArgs = {
  filter?: InputMaybe<TransactionAggregateFilter>;
};


export type QueryTransactionsArgs = {
  filter?: TransactionFilter;
  paging?: OffsetPaging;
  sorting?: Array<TransactionSort>;
};


export type QueryTwoFactorArgs = {
  accountName: Scalars['String']['input'];
};


export type QueryUserArgs = {
  id: Scalars['UUID']['input'];
};


export type QueryUserGroupArgs = {
  id: Scalars['UUID']['input'];
};


export type QueryUserGroupsArgs = {
  filter?: UserGroupFilter;
  paging?: OffsetPaging;
  sorting?: Array<UserGroupSort>;
};


export type QueryUserToCompaniesArgs = {
  filter?: UserToCompanyFilter;
  paging?: OffsetPaging;
  sorting?: Array<UserToCompanySort>;
};


export type QueryUsersArgs = {
  filter?: UserFilter;
  paging?: OffsetPaging;
  sorting?: Array<UserSort>;
};


export type QueryVoipCallArgs = {
  id: Scalars['UUID']['input'];
};


export type QueryVoipCallsArgs = {
  filter?: VoipCallFilter;
  paging?: OffsetPaging;
  sorting?: Array<VoipCallSort>;
};


export type QueryVoipExtensionArgs = {
  id: Scalars['UUID']['input'];
};


export type QueryVoipExtensionsArgs = {
  filter?: VoipExtensionFilter;
  paging?: OffsetPaging;
  sorting?: Array<VoipExtensionSort>;
};


export type QueryVoipInternalNumberArgs = {
  id: Scalars['UUID']['input'];
};


export type QueryVoipInternalNumbersArgs = {
  filter?: VoipInternalNumberFilter;
  paging?: OffsetPaging;
  sorting?: Array<VoipInternalNumberSort>;
};


export type QueryVoipNumbersArgs = {
  filter?: VoipNumberFilter;
  paging?: OffsetPaging;
  sorting?: Array<VoipNumberSort>;
};


export type QueryWorkflowArgs = {
  id: Scalars['UUID']['input'];
};


export type QueryWorkflowActionArgs = {
  id: Scalars['UUID']['input'];
};


export type QueryWorkflowActionsArgs = {
  filter?: WorkflowActionFilter;
  paging?: OffsetPaging;
  sorting?: Array<WorkflowActionSort>;
};


export type QueryWorkflowCostCategoriesArgs = {
  id: Scalars['UUID']['input'];
};


export type QueryWorkflowStepArgs = {
  id: Scalars['UUID']['input'];
};


export type QueryWorkflowStepsArgs = {
  filter?: WorkflowStepFilter;
  paging?: OffsetPaging;
  sorting?: Array<WorkflowStepSort>;
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
};


export type QueryWorkflowsArgs = {
  filter?: WorkflowFilter;
  paging?: OffsetPaging;
  sorting?: Array<WorkflowSort>;
};

export type RemoveLabelsFromClientInput = {
  /** The id of the record. */
  id: Scalars['UUID']['input'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['UUID']['input']>;
};

export type RemoveLabelsFromDossierInput = {
  /** The id of the record. */
  id: Scalars['UUID']['input'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['UUID']['input']>;
};

export type RenewCompanyIntegration = {
  /** Primary key of the recourse. */
  id: Scalars['UUID']['input'];
  /** URL to redirect the user back to when integration is authorised. */
  redirectUrl: Scalars['String']['input'];
};

export type ReopenWorkflowStep = {
  /** ID of the workflow step to reopen. */
  id: Scalars['UUID']['input'];
};

export type RepeatingInvoiceLine = {
  __typename?: 'RepeatingInvoiceLine';
  /** Cost category the created invoice line will fall under. */
  category: CostCategoryReference;
  /** ID of the category of this line item. */
  categoryId?: Maybe<Scalars['UUID']['output']>;
  /** Amount in cents of one line item. */
  centAmount: Scalars['Float']['output'];
  /** Total amount in cents of line item (centAmount * quantity). */
  centAmountTotal: Scalars['Float']['output'];
  /** Company the repeating invoice line belongs to. */
  companyId: Scalars['UUID']['output'];
  /** Date time of when the recourse is created. */
  createdAt: Scalars['DateTime']['output'];
  /** Creator of this repeating invoice line. */
  creator: Creator;
  /** Currency of line item. */
  currency: Currency;
  /** Debtor of this repeating invoice line. */
  debtor: CompanyReference;
  /** ID of the debtor the line item is invoiced to. */
  debtorId: Scalars['UUID']['output'];
  /** Description of the line item. */
  description?: Maybe<Scalars['String']['output']>;
  /** Primary key of the recourse. */
  id: Scalars['UUID']['output'];
  /** Process line item at next invoicing. */
  inInvoicing: Scalars['Boolean']['output'];
  /** Date the next line will be generated. */
  nextDate: Scalars['DateTime']['output'];
  /** Note of the line item. */
  note?: Maybe<Scalars['String']['output']>;
  /** The amount of (working) days / weeks / months. */
  period: Scalars['Float']['output'];
  /** Type of the period. */
  periodType: RepeatingInvoiceLinePeriodType;
  /** Quantity of line item. */
  quantity: Scalars['Float']['output'];
  /** Tax rate that will be applied over this line. */
  taxRate?: Maybe<TaxRate>;
  /** Date time of when the recourse last updated. */
  updatedAt: Scalars['DateTime']['output'];
};

export type RepeatingInvoiceLineCategoryIdFilterComparison = {
  eq?: InputMaybe<Scalars['UUID']['input']>;
  in?: InputMaybe<Array<Scalars['UUID']['input']>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  neq?: InputMaybe<Scalars['UUID']['input']>;
  notIn?: InputMaybe<Array<Scalars['UUID']['input']>>;
};

export type RepeatingInvoiceLineCentAmountFilterComparison = {
  gt?: InputMaybe<Scalars['Float']['input']>;
  gte?: InputMaybe<Scalars['Float']['input']>;
  lt?: InputMaybe<Scalars['Float']['input']>;
  lte?: InputMaybe<Scalars['Float']['input']>;
};

export type RepeatingInvoiceLineCentAmountTotalFilterComparison = {
  gt?: InputMaybe<Scalars['Float']['input']>;
  gte?: InputMaybe<Scalars['Float']['input']>;
  lt?: InputMaybe<Scalars['Float']['input']>;
  lte?: InputMaybe<Scalars['Float']['input']>;
};

export type RepeatingInvoiceLineCompanyIdFilterComparison = {
  eq?: InputMaybe<Scalars['UUID']['input']>;
  in?: InputMaybe<Array<Scalars['UUID']['input']>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  neq?: InputMaybe<Scalars['UUID']['input']>;
  notIn?: InputMaybe<Array<Scalars['UUID']['input']>>;
};

export type RepeatingInvoiceLineConnection = {
  __typename?: 'RepeatingInvoiceLineConnection';
  /** Array of nodes. */
  nodes: Array<RepeatingInvoiceLine>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type RepeatingInvoiceLineCreatedAtFilterComparison = {
  between?: InputMaybe<RepeatingInvoiceLineCreatedAtFilterComparisonBetween>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  notBetween?: InputMaybe<RepeatingInvoiceLineCreatedAtFilterComparisonBetween>;
};

export type RepeatingInvoiceLineCreatedAtFilterComparisonBetween = {
  lower: Scalars['DateTime']['input'];
  upper: Scalars['DateTime']['input'];
};

export type RepeatingInvoiceLineDebtorIdFilterComparison = {
  eq?: InputMaybe<Scalars['UUID']['input']>;
  in?: InputMaybe<Array<Scalars['UUID']['input']>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  neq?: InputMaybe<Scalars['UUID']['input']>;
  notIn?: InputMaybe<Array<Scalars['UUID']['input']>>;
};

export type RepeatingInvoiceLineDeleteResponse = {
  __typename?: 'RepeatingInvoiceLineDeleteResponse';
  /** ID of the category of this line item. */
  categoryId?: Maybe<Scalars['UUID']['output']>;
  /** Amount in cents of one line item. */
  centAmount?: Maybe<Scalars['Float']['output']>;
  /** Total amount in cents of line item (centAmount * quantity). */
  centAmountTotal?: Maybe<Scalars['Float']['output']>;
  /** Company the repeating invoice line belongs to. */
  companyId?: Maybe<Scalars['UUID']['output']>;
  /** Date time of when the recourse is created. */
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  /** Currency of line item. */
  currency?: Maybe<Currency>;
  /** ID of the debtor the line item is invoiced to. */
  debtorId?: Maybe<Scalars['UUID']['output']>;
  /** Description of the line item. */
  description?: Maybe<Scalars['String']['output']>;
  /** Primary key of the recourse. */
  id?: Maybe<Scalars['UUID']['output']>;
  /** Process line item at next invoicing. */
  inInvoicing?: Maybe<Scalars['Boolean']['output']>;
  /** Date the next line will be generated. */
  nextDate?: Maybe<Scalars['DateTime']['output']>;
  /** Note of the line item. */
  note?: Maybe<Scalars['String']['output']>;
  /** The amount of (working) days / weeks / months. */
  period?: Maybe<Scalars['Float']['output']>;
  /** Type of the period. */
  periodType?: Maybe<RepeatingInvoiceLinePeriodType>;
  /** Quantity of line item. */
  quantity?: Maybe<Scalars['Float']['output']>;
  /** Date time of when the recourse last updated. */
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type RepeatingInvoiceLineFilter = {
  and?: InputMaybe<Array<RepeatingInvoiceLineFilter>>;
  categoryId?: InputMaybe<RepeatingInvoiceLineCategoryIdFilterComparison>;
  centAmount?: InputMaybe<RepeatingInvoiceLineCentAmountFilterComparison>;
  centAmountTotal?: InputMaybe<RepeatingInvoiceLineCentAmountTotalFilterComparison>;
  companyId?: InputMaybe<RepeatingInvoiceLineCompanyIdFilterComparison>;
  createdAt?: InputMaybe<RepeatingInvoiceLineCreatedAtFilterComparison>;
  debtorId?: InputMaybe<RepeatingInvoiceLineDebtorIdFilterComparison>;
  id?: InputMaybe<RepeatingInvoiceLineIdFilterComparison>;
  nextDate?: InputMaybe<DateFieldComparison>;
  or?: InputMaybe<Array<RepeatingInvoiceLineFilter>>;
  updatedAt?: InputMaybe<RepeatingInvoiceLineUpdatedAtFilterComparison>;
};

export type RepeatingInvoiceLineIdFilterComparison = {
  eq?: InputMaybe<Scalars['UUID']['input']>;
  in?: InputMaybe<Array<Scalars['UUID']['input']>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  neq?: InputMaybe<Scalars['UUID']['input']>;
  notIn?: InputMaybe<Array<Scalars['UUID']['input']>>;
};

/** The type of the repeating invoice line period. */
export enum RepeatingInvoiceLinePeriodType {
  Days = 'Days',
  Months = 'Months',
  Weeks = 'Weeks',
  WorkingDays = 'WorkingDays'
}

export type RepeatingInvoiceLineSort = {
  direction: SortDirection;
  field: RepeatingInvoiceLineSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum RepeatingInvoiceLineSortFields {
  CategoryId = 'categoryId',
  CentAmount = 'centAmount',
  CentAmountTotal = 'centAmountTotal',
  CompanyId = 'companyId',
  CreatedAt = 'createdAt',
  DebtorId = 'debtorId',
  Id = 'id',
  NextDate = 'nextDate',
  UpdatedAt = 'updatedAt'
}

export type RepeatingInvoiceLineUpdatedAtFilterComparison = {
  between?: InputMaybe<RepeatingInvoiceLineUpdatedAtFilterComparisonBetween>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  notBetween?: InputMaybe<RepeatingInvoiceLineUpdatedAtFilterComparisonBetween>;
};

export type RepeatingInvoiceLineUpdatedAtFilterComparisonBetween = {
  lower: Scalars['DateTime']['input'];
  upper: Scalars['DateTime']['input'];
};

export type Scope = {
  __typename?: 'Scope';
  /** The action. */
  action: ScopeAction;
  /** The claim. */
  claim: ScopeClaim;
  /** Date time of when the recourse is created. */
  createdAt: Scalars['DateTime']['output'];
  /** Primary key of the recourse. */
  id: Scalars['UUID']['output'];
  /** Date time of when the recourse last updated. */
  updatedAt: Scalars['DateTime']['output'];
};

export enum ScopeAction {
  Manage = 'Manage',
  None = 'None',
  Read = 'Read',
  Write = 'Write'
}

export enum ScopeClaim {
  Admin = 'Admin',
  All = 'All',
  AlphaFeatures = 'AlphaFeatures',
  Audit = 'Audit',
  BankTransaction = 'BankTransaction',
  BetaFeatures = 'BetaFeatures',
  BusinessActivity = 'BusinessActivity',
  Client = 'Client',
  Companies = 'Companies',
  CompanyAreaCode = 'CompanyAreaCode',
  CompanyBankAccount = 'CompanyBankAccount',
  CompanyContact = 'CompanyContact',
  CompanyContract = 'CompanyContract',
  CompanyFeature = 'CompanyFeature',
  CompanyFile = 'CompanyFile',
  CompanyIntegration = 'CompanyIntegration',
  CompanyLabel = 'CompanyLabel',
  CompanyNote = 'CompanyNote',
  CompanyPage = 'CompanyPage',
  CompanySettings = 'CompanySettings',
  Contract = 'Contract',
  CostCategory = 'CostCategory',
  Dossier = 'Dossier',
  DossierCost = 'DossierCost',
  DossierFile = 'DossierFile',
  DossierInvoice = 'DossierInvoice',
  DossierLabel = 'DossierLabel',
  DossierNote = 'DossierNote',
  Franchisee = 'Franchisee',
  InterestRate = 'InterestRate',
  Invoice = 'Invoice',
  InvoiceFile = 'InvoiceFile',
  InvoiceLine = 'InvoiceLine',
  Job = 'Job',
  JobFile = 'JobFile',
  Label = 'Label',
  Master = 'Master',
  Me = 'Me',
  Notification = 'Notification',
  RepeatingInvoiceLine = 'RepeatingInvoiceLine',
  Sessions = 'Sessions',
  TaxRate = 'TaxRate',
  Transaction = 'Transaction',
  User = 'User',
  UserCompany = 'UserCompany',
  UserGroup = 'UserGroup',
  Voip = 'Voip',
  VoipCall = 'VoipCall',
  VoipCallRecording = 'VoipCallRecording',
  VoipExtension = 'VoipExtension',
  VoipInternalNumber = 'VoipInternalNumber',
  VoipNumber = 'VoipNumber',
  Workflow = 'Workflow',
  WorkflowAction = 'WorkflowAction',
  WorkflowPaymentArrangement = 'WorkflowPaymentArrangement',
  WorkflowStep = 'WorkflowStep'
}

export type ScopeEdge = {
  __typename?: 'ScopeEdge';
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor']['output'];
  /** The node containing the Scope */
  node: Scope;
};

export type SearchItem = {
  __typename?: 'SearchItem';
  /** Primary key of the recourse. */
  id: Scalars['UUID']['output'];
  /** Field the match was made on. */
  matchedField?: Maybe<Scalars['String']['output']>;
  /** Result the match made on. */
  matchedOn?: Maybe<Scalars['String']['output']>;
  /** Key identifier of the "type". */
  name: Scalars['String']['output'];
  /** Sub type of the search item. */
  subType?: Maybe<SearchItemSubType>;
  /** Type of the search item. */
  type: SearchItemType;
};

export type SearchItemOffsetConnection = {
  __typename?: 'SearchItemOffsetConnection';
  /** Array of nodes. */
  nodes: Array<SearchItem>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
};

/** The sub-type of a search item. */
export enum SearchItemSubType {
  Collection = 'Collection',
  Contact = 'Contact',
  DebtorManagement = 'DebtorManagement',
  DossierInvoice = 'DossierInvoice'
}

/** The type of a search item. */
export enum SearchItemType {
  Client = 'Client',
  Debtor = 'Debtor',
  Dossier = 'Dossier'
}

export type SelectCompanyIntegrationAccount = {
  /** Settings of the account selected. */
  accountSettings: Scalars['JSONObject']['input'];
};

export type SelectCompanyIntegrationAccountInput = {
  /** The id of the record to update */
  id: Scalars['UUID']['input'];
  /** The update to apply. */
  update: SelectCompanyIntegrationAccount;
};

export type SetLabelsOnClientInput = {
  /** The id of the record. */
  id: Scalars['UUID']['input'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['UUID']['input']>;
};

export type SetLabelsOnDossierInput = {
  /** The id of the record. */
  id: Scalars['UUID']['input'];
  /** The ids of the relations. */
  relationIds: Array<Scalars['UUID']['input']>;
};

export type SkipManyWorkflowActionsInput = {
  /** Primary key of the recourse. */
  ids: Array<Scalars['UUID']['input']>;
};

export type SmsSend = {
  __typename?: 'SmsSend';
  /** Date time of when the recourse is created. */
  createdAt: Scalars['DateTime']['output'];
  /** Reason why the sms failed. */
  failedReason?: Maybe<Scalars['String']['output']>;
  /** Who send the sms. */
  from: Scalars['String']['output'];
  /** Primary key of the recourse. */
  id: Scalars['UUID']['output'];
  /** Message of the sms. */
  message: Scalars['String']['output'];
  /** Status of the sms. */
  status: SmsStatus;
  /** Who received the sms. */
  to: Scalars['String']['output'];
  /** Date time of when the recourse last updated. */
  updatedAt: Scalars['DateTime']['output'];
};

export type SmsSendSort = {
  direction: SortDirection;
  field: SmsSendSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum SmsSendSortFields {
  CreatedAt = 'createdAt',
  Id = 'id',
  UpdatedAt = 'updatedAt'
}

/** The status of an sms. */
export enum SmsStatus {
  Delivered = 'Delivered',
  Failed = 'Failed',
  Send = 'Send'
}

/** Sort Directions */
export enum SortDirection {
  Asc = 'ASC',
  Desc = 'DESC'
}

/** Sort Nulls Options */
export enum SortNulls {
  NullsFirst = 'NULLS_FIRST',
  NullsLast = 'NULLS_LAST'
}

export type StringFieldComparison = {
  eq?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  iLike?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  like?: InputMaybe<Scalars['String']['input']>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  neq?: InputMaybe<Scalars['String']['input']>;
  notILike?: InputMaybe<Scalars['String']['input']>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
  notLike?: InputMaybe<Scalars['String']['input']>;
};

export type SyncDossierInvoice = {
  /** Amount in cents of the invoice. */
  centAmount: Scalars['Float']['input'];
  /** Amount in cents of the invoice that have been paid (Only used in debtor management). */
  centAmountPaid?: Scalars['Float']['input'];
  /** Currency of the invoice. */
  currency: Currency;
  /** Date of the invoice. */
  date: Scalars['DateTime']['input'];
  /** ID of the debtor. */
  debtorId: Scalars['UUID']['input'];
  /** ID of associated file. */
  fileId?: InputMaybe<Scalars['UUID']['input']>;
  /** Type of interest. */
  interest?: InputMaybe<DossierInvoiceInterest>;
  /** Date to calculate interest from. (Expire date of the invoice) */
  interestFromDate?: InputMaybe<Scalars['DateTime']['input']>;
  /** Amount of days / work-days. */
  paymentTerm: Scalars['Float']['input'];
  /** Amount of days / work-days. */
  paymentTermType: InvoicePaymentTerm;
  /** Reference of the invoice. This needs to be unique! */
  reference: Scalars['String']['input'];
  /** Status of the invoice. (Only used in debtor management) */
  status?: DossierInvoiceStatus;
};

export type SyncDossierInvoicesInput = {
  /** Array of records to create */
  invoices: Array<SyncDossierInvoice>;
};

export type TaxRate = {
  __typename?: 'TaxRate';
  /** Amount in % the tax is. */
  amount: Scalars['Float']['output'];
  /** Country of the tax (ISO 3166-1 alpha-2). */
  country: Scalars['String']['output'];
  /** Date time of when the recourse is created. */
  createdAt: Scalars['DateTime']['output'];
  /** From when the tax is applied. */
  from: Scalars['DateTime']['output'];
  /** Primary key of the recourse. */
  id: Scalars['UUID']['output'];
  /** Until when the tax is applied. */
  until?: Maybe<Scalars['DateTime']['output']>;
  /** Date time of when the recourse last updated. */
  updatedAt: Scalars['DateTime']['output'];
};

export type TaxRateConnection = {
  __typename?: 'TaxRateConnection';
  /** Array of nodes. */
  nodes: Array<TaxRate>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type TaxRateCountryFilterComparison = {
  eq?: InputMaybe<Scalars['String']['input']>;
  neq?: InputMaybe<Scalars['String']['input']>;
};

export type TaxRateCreatedAtFilterComparison = {
  between?: InputMaybe<TaxRateCreatedAtFilterComparisonBetween>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  notBetween?: InputMaybe<TaxRateCreatedAtFilterComparisonBetween>;
};

export type TaxRateCreatedAtFilterComparisonBetween = {
  lower: Scalars['DateTime']['input'];
  upper: Scalars['DateTime']['input'];
};

export type TaxRateDeleteResponse = {
  __typename?: 'TaxRateDeleteResponse';
  /** Amount in % the tax is. */
  amount?: Maybe<Scalars['Float']['output']>;
  /** Country of the tax (ISO 3166-1 alpha-2). */
  country?: Maybe<Scalars['String']['output']>;
  /** Date time of when the recourse is created. */
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  /** From when the tax is applied. */
  from?: Maybe<Scalars['DateTime']['output']>;
  /** Primary key of the recourse. */
  id?: Maybe<Scalars['UUID']['output']>;
  /** Until when the tax is applied. */
  until?: Maybe<Scalars['DateTime']['output']>;
  /** Date time of when the recourse last updated. */
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type TaxRateFilter = {
  and?: InputMaybe<Array<TaxRateFilter>>;
  country?: InputMaybe<TaxRateCountryFilterComparison>;
  createdAt?: InputMaybe<TaxRateCreatedAtFilterComparison>;
  from?: InputMaybe<TaxRateFromFilterComparison>;
  id?: InputMaybe<TaxRateIdFilterComparison>;
  or?: InputMaybe<Array<TaxRateFilter>>;
  updatedAt?: InputMaybe<TaxRateUpdatedAtFilterComparison>;
};

export type TaxRateFromFilterComparison = {
  between?: InputMaybe<TaxRateFromFilterComparisonBetween>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  notBetween?: InputMaybe<TaxRateFromFilterComparisonBetween>;
};

export type TaxRateFromFilterComparisonBetween = {
  lower: Scalars['DateTime']['input'];
  upper: Scalars['DateTime']['input'];
};

export type TaxRateIdFilterComparison = {
  eq?: InputMaybe<Scalars['UUID']['input']>;
  in?: InputMaybe<Array<Scalars['UUID']['input']>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  neq?: InputMaybe<Scalars['UUID']['input']>;
  notIn?: InputMaybe<Array<Scalars['UUID']['input']>>;
};

export type TaxRateSort = {
  direction: SortDirection;
  field: TaxRateSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum TaxRateSortFields {
  Country = 'country',
  CreatedAt = 'createdAt',
  From = 'from',
  Id = 'id',
  UpdatedAt = 'updatedAt'
}

export type TaxRateUpdatedAtFilterComparison = {
  between?: InputMaybe<TaxRateUpdatedAtFilterComparisonBetween>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  notBetween?: InputMaybe<TaxRateUpdatedAtFilterComparisonBetween>;
};

export type TaxRateUpdatedAtFilterComparisonBetween = {
  lower: Scalars['DateTime']['input'];
  upper: Scalars['DateTime']['input'];
};

export type Transaction = {
  __typename?: 'Transaction';
  /** Account of the bank (e.g. IBAN). */
  account?: Maybe<Scalars['String']['output']>;
  /** Amount in cents of the transaction. */
  centAmount: Scalars['Float']['output'];
  /** Date time of when the recourse is created. */
  createdAt: Scalars['DateTime']['output'];
  /** Creator of the transaction. */
  creator?: Maybe<Creator>;
  /** Currency of the transaction. */
  currency: Currency;
  /** Dossier the transaction is connected to. */
  dossier?: Maybe<DossierReference>;
  /** ID of the dossier the transaction is connected to. */
  dossierId?: Maybe<Scalars['UUID']['output']>;
  /** Dossier invoice the transaction is connected to. */
  dossierInvoice?: Maybe<DossierInvoiceReference>;
  /** ID of the dossier invoice the transaction is connected to. */
  dossierInvoiceId?: Maybe<Scalars['UUID']['output']>;
  /** Primary key of the recourse. */
  id: Scalars['UUID']['output'];
  /** Invoice the transaction is connected to. */
  invoice?: Maybe<InvoiceReference>;
  /** ID of the invoice the transaction is connected to. */
  invoiceId?: Maybe<Scalars['UUID']['output']>;
  /** ID of the job that created this transaction. */
  jobId?: Maybe<Scalars['UUID']['output']>;
  /** Name of the bank. */
  name?: Maybe<Scalars['String']['output']>;
  /** Is the transaction processed. */
  processed: Scalars['Boolean']['output'];
  /** Date time of when transaction took place. */
  receivedOn: Scalars['DateTime']['output'];
  /** Reference of the transaction. */
  reference?: Maybe<Scalars['String']['output']>;
  /** Source of the transaction. */
  source: TransactionSource;
  /** Subtract the amount of this transaction from the initial dossier amount. */
  subtract: Scalars['Boolean']['output'];
  /** The type of transaction. */
  type: TransactionType;
  /** Date time of when the recourse last updated. */
  updatedAt: Scalars['DateTime']['output'];
};

export type TransactionAccountFilterComparison = {
  eq?: InputMaybe<Scalars['String']['input']>;
  like?: InputMaybe<Scalars['String']['input']>;
  neq?: InputMaybe<Scalars['String']['input']>;
  notLike?: InputMaybe<Scalars['String']['input']>;
};

export type TransactionAggregateFilter = {
  account?: InputMaybe<TransactionAccountFilterComparison>;
  and?: InputMaybe<Array<TransactionAggregateFilter>>;
  centAmount?: InputMaybe<TransactionCentAmountFilterComparison>;
  createdAt?: InputMaybe<TransactionCreatedAtFilterComparison>;
  currency?: InputMaybe<TransactionCurrencyFilterComparison>;
  dossierId?: InputMaybe<TransactionDossierIdFilterComparison>;
  dossierInvoiceId?: InputMaybe<TransactionDossierInvoiceIdFilterComparison>;
  id?: InputMaybe<TransactionIdFilterComparison>;
  invoiceId?: InputMaybe<TransactionInvoiceIdFilterComparison>;
  jobId?: InputMaybe<TransactionJobIdFilterComparison>;
  name?: InputMaybe<TransactionNameFilterComparison>;
  or?: InputMaybe<Array<TransactionAggregateFilter>>;
  processed?: InputMaybe<TransactionProcessedFilterComparison>;
  receivedOn?: InputMaybe<TransactionReceivedOnFilterComparison>;
  reference?: InputMaybe<TransactionReferenceFilterComparison>;
  source?: InputMaybe<TransactionSourceFilterComparison>;
  type?: InputMaybe<TransactionTypeFilterComparison>;
  updatedAt?: InputMaybe<TransactionUpdatedAtFilterComparison>;
};

export type TransactionAggregateGroupBy = {
  __typename?: 'TransactionAggregateGroupBy';
  account?: Maybe<Scalars['String']['output']>;
  centAmount?: Maybe<Scalars['Float']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  currency?: Maybe<Currency>;
  dossierId?: Maybe<Scalars['UUID']['output']>;
  dossierInvoiceId?: Maybe<Scalars['UUID']['output']>;
  id?: Maybe<Scalars['UUID']['output']>;
  invoiceId?: Maybe<Scalars['UUID']['output']>;
  jobId?: Maybe<Scalars['UUID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  processed?: Maybe<Scalars['Boolean']['output']>;
  receivedOn?: Maybe<Scalars['DateTime']['output']>;
  reference?: Maybe<Scalars['String']['output']>;
  source?: Maybe<TransactionSource>;
  type?: Maybe<TransactionType>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};


export type TransactionAggregateGroupByCreatedAtArgs = {
  by?: GroupBy;
};


export type TransactionAggregateGroupByReceivedOnArgs = {
  by?: GroupBy;
};


export type TransactionAggregateGroupByUpdatedAtArgs = {
  by?: GroupBy;
};

export type TransactionAggregateResponse = {
  __typename?: 'TransactionAggregateResponse';
  avg?: Maybe<TransactionAvgAggregate>;
  count?: Maybe<TransactionCountAggregate>;
  groupBy?: Maybe<TransactionAggregateGroupBy>;
  max?: Maybe<TransactionMaxAggregate>;
  min?: Maybe<TransactionMinAggregate>;
  sum?: Maybe<TransactionSumAggregate>;
};

export type TransactionAvgAggregate = {
  __typename?: 'TransactionAvgAggregate';
  centAmount?: Maybe<Scalars['Float']['output']>;
};

export type TransactionCentAmountFilterComparison = {
  gt?: InputMaybe<Scalars['Float']['input']>;
  gte?: InputMaybe<Scalars['Float']['input']>;
  lt?: InputMaybe<Scalars['Float']['input']>;
  lte?: InputMaybe<Scalars['Float']['input']>;
};

export type TransactionConnection = {
  __typename?: 'TransactionConnection';
  /** Array of nodes. */
  nodes: Array<Transaction>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type TransactionCountAggregate = {
  __typename?: 'TransactionCountAggregate';
  account?: Maybe<Scalars['Int']['output']>;
  centAmount?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['Int']['output']>;
  currency?: Maybe<Scalars['Int']['output']>;
  dossierId?: Maybe<Scalars['Int']['output']>;
  dossierInvoiceId?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  invoiceId?: Maybe<Scalars['Int']['output']>;
  jobId?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['Int']['output']>;
  processed?: Maybe<Scalars['Int']['output']>;
  receivedOn?: Maybe<Scalars['Int']['output']>;
  reference?: Maybe<Scalars['Int']['output']>;
  source?: Maybe<Scalars['Int']['output']>;
  type?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['Int']['output']>;
};

export type TransactionCreatedAtFilterComparison = {
  between?: InputMaybe<TransactionCreatedAtFilterComparisonBetween>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  notBetween?: InputMaybe<TransactionCreatedAtFilterComparisonBetween>;
};

export type TransactionCreatedAtFilterComparisonBetween = {
  lower: Scalars['DateTime']['input'];
  upper: Scalars['DateTime']['input'];
};

export type TransactionCurrencyFilterComparison = {
  eq?: InputMaybe<Currency>;
  in?: InputMaybe<Array<Currency>>;
  neq?: InputMaybe<Currency>;
  notIn?: InputMaybe<Array<Currency>>;
};

export type TransactionDeleteResponse = {
  __typename?: 'TransactionDeleteResponse';
  /** Account of the bank (e.g. IBAN). */
  account?: Maybe<Scalars['String']['output']>;
  /** Amount in cents of the transaction. */
  centAmount?: Maybe<Scalars['Float']['output']>;
  /** Date time of when the recourse is created. */
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  /** Currency of the transaction. */
  currency?: Maybe<Currency>;
  /** ID of the dossier the transaction is connected to. */
  dossierId?: Maybe<Scalars['UUID']['output']>;
  /** ID of the dossier invoice the transaction is connected to. */
  dossierInvoiceId?: Maybe<Scalars['UUID']['output']>;
  /** Primary key of the recourse. */
  id?: Maybe<Scalars['UUID']['output']>;
  /** ID of the invoice the transaction is connected to. */
  invoiceId?: Maybe<Scalars['UUID']['output']>;
  /** ID of the job that created this transaction. */
  jobId?: Maybe<Scalars['UUID']['output']>;
  /** Name of the bank. */
  name?: Maybe<Scalars['String']['output']>;
  /** Is the transaction processed. */
  processed?: Maybe<Scalars['Boolean']['output']>;
  /** Date time of when transaction took place. */
  receivedOn?: Maybe<Scalars['DateTime']['output']>;
  /** Reference of the transaction. */
  reference?: Maybe<Scalars['String']['output']>;
  /** Source of the transaction. */
  source?: Maybe<TransactionSource>;
  /** Subtract the amount of this transaction from the initial dossier amount. */
  subtract?: Maybe<Scalars['Boolean']['output']>;
  /** The type of transaction. */
  type?: Maybe<TransactionType>;
  /** Date time of when the recourse last updated. */
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type TransactionDossierIdFilterComparison = {
  eq?: InputMaybe<Scalars['UUID']['input']>;
  in?: InputMaybe<Array<Scalars['UUID']['input']>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  neq?: InputMaybe<Scalars['UUID']['input']>;
  notIn?: InputMaybe<Array<Scalars['UUID']['input']>>;
};

export type TransactionDossierInvoiceIdFilterComparison = {
  eq?: InputMaybe<Scalars['UUID']['input']>;
  in?: InputMaybe<Array<Scalars['UUID']['input']>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  neq?: InputMaybe<Scalars['UUID']['input']>;
  notIn?: InputMaybe<Array<Scalars['UUID']['input']>>;
};

export type TransactionFilter = {
  account?: InputMaybe<TransactionAccountFilterComparison>;
  and?: InputMaybe<Array<TransactionFilter>>;
  centAmount?: InputMaybe<TransactionCentAmountFilterComparison>;
  createdAt?: InputMaybe<TransactionCreatedAtFilterComparison>;
  currency?: InputMaybe<TransactionCurrencyFilterComparison>;
  dossierId?: InputMaybe<TransactionDossierIdFilterComparison>;
  dossierInvoiceId?: InputMaybe<TransactionDossierInvoiceIdFilterComparison>;
  id?: InputMaybe<TransactionIdFilterComparison>;
  invoiceId?: InputMaybe<TransactionInvoiceIdFilterComparison>;
  jobId?: InputMaybe<TransactionJobIdFilterComparison>;
  name?: InputMaybe<TransactionNameFilterComparison>;
  or?: InputMaybe<Array<TransactionFilter>>;
  processed?: InputMaybe<TransactionProcessedFilterComparison>;
  receivedOn?: InputMaybe<TransactionReceivedOnFilterComparison>;
  reference?: InputMaybe<TransactionReferenceFilterComparison>;
  source?: InputMaybe<TransactionSourceFilterComparison>;
  type?: InputMaybe<TransactionTypeFilterComparison>;
  updatedAt?: InputMaybe<TransactionUpdatedAtFilterComparison>;
};

export type TransactionIdFilterComparison = {
  eq?: InputMaybe<Scalars['UUID']['input']>;
  in?: InputMaybe<Array<Scalars['UUID']['input']>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  neq?: InputMaybe<Scalars['UUID']['input']>;
  notIn?: InputMaybe<Array<Scalars['UUID']['input']>>;
};

export type TransactionInvoiceIdFilterComparison = {
  eq?: InputMaybe<Scalars['UUID']['input']>;
  in?: InputMaybe<Array<Scalars['UUID']['input']>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  neq?: InputMaybe<Scalars['UUID']['input']>;
  notIn?: InputMaybe<Array<Scalars['UUID']['input']>>;
};

export type TransactionJobIdFilterComparison = {
  eq?: InputMaybe<Scalars['UUID']['input']>;
  in?: InputMaybe<Array<Scalars['UUID']['input']>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  neq?: InputMaybe<Scalars['UUID']['input']>;
  notIn?: InputMaybe<Array<Scalars['UUID']['input']>>;
};

export type TransactionMaxAggregate = {
  __typename?: 'TransactionMaxAggregate';
  account?: Maybe<Scalars['String']['output']>;
  centAmount?: Maybe<Scalars['Float']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  currency?: Maybe<Currency>;
  dossierId?: Maybe<Scalars['UUID']['output']>;
  dossierInvoiceId?: Maybe<Scalars['UUID']['output']>;
  id?: Maybe<Scalars['UUID']['output']>;
  invoiceId?: Maybe<Scalars['UUID']['output']>;
  jobId?: Maybe<Scalars['UUID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  receivedOn?: Maybe<Scalars['DateTime']['output']>;
  reference?: Maybe<Scalars['String']['output']>;
  source?: Maybe<TransactionSource>;
  type?: Maybe<TransactionType>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type TransactionMinAggregate = {
  __typename?: 'TransactionMinAggregate';
  account?: Maybe<Scalars['String']['output']>;
  centAmount?: Maybe<Scalars['Float']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  currency?: Maybe<Currency>;
  dossierId?: Maybe<Scalars['UUID']['output']>;
  dossierInvoiceId?: Maybe<Scalars['UUID']['output']>;
  id?: Maybe<Scalars['UUID']['output']>;
  invoiceId?: Maybe<Scalars['UUID']['output']>;
  jobId?: Maybe<Scalars['UUID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  receivedOn?: Maybe<Scalars['DateTime']['output']>;
  reference?: Maybe<Scalars['String']['output']>;
  source?: Maybe<TransactionSource>;
  type?: Maybe<TransactionType>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type TransactionNameFilterComparison = {
  eq?: InputMaybe<Scalars['String']['input']>;
  like?: InputMaybe<Scalars['String']['input']>;
  neq?: InputMaybe<Scalars['String']['input']>;
  notLike?: InputMaybe<Scalars['String']['input']>;
};

export type TransactionProcessedFilterComparison = {
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
};

export type TransactionReceivedOnFilterComparison = {
  between?: InputMaybe<TransactionReceivedOnFilterComparisonBetween>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  notBetween?: InputMaybe<TransactionReceivedOnFilterComparisonBetween>;
};

export type TransactionReceivedOnFilterComparisonBetween = {
  lower: Scalars['DateTime']['input'];
  upper: Scalars['DateTime']['input'];
};

export type TransactionReferenceFilterComparison = {
  eq?: InputMaybe<Scalars['String']['input']>;
  like?: InputMaybe<Scalars['String']['input']>;
  neq?: InputMaybe<Scalars['String']['input']>;
  notLike?: InputMaybe<Scalars['String']['input']>;
};

export type TransactionSort = {
  direction: SortDirection;
  field: TransactionSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum TransactionSortFields {
  Account = 'account',
  CentAmount = 'centAmount',
  CreatedAt = 'createdAt',
  Currency = 'currency',
  DossierId = 'dossierId',
  DossierInvoiceId = 'dossierInvoiceId',
  Id = 'id',
  InvoiceId = 'invoiceId',
  JobId = 'jobId',
  Name = 'name',
  Processed = 'processed',
  ReceivedOn = 'receivedOn',
  Reference = 'reference',
  Source = 'source',
  Type = 'type',
  UpdatedAt = 'updatedAt'
}

/** The source of an transaction. */
export enum TransactionSource {
  Bank = 'Bank',
  Import = 'Import',
  Manual = 'Manual',
  PaymentProvider = 'PaymentProvider'
}

export type TransactionSourceFilterComparison = {
  eq?: InputMaybe<TransactionSource>;
  in?: InputMaybe<Array<TransactionSource>>;
  neq?: InputMaybe<TransactionSource>;
  notIn?: InputMaybe<Array<TransactionSource>>;
};

export type TransactionSumAggregate = {
  __typename?: 'TransactionSumAggregate';
  centAmount?: Maybe<Scalars['Float']['output']>;
};

/** The type of an transaction. */
export enum TransactionType {
  Bancontact = 'Bancontact',
  BankTransfer = 'BankTransfer',
  CreditCard = 'CreditCard',
  Ideal = 'Ideal',
  Kbc = 'KBC',
  PayPal = 'PayPal',
  Settlement = 'Settlement'
}

export type TransactionTypeFilterComparison = {
  eq?: InputMaybe<TransactionType>;
  in?: InputMaybe<Array<TransactionType>>;
  neq?: InputMaybe<TransactionType>;
  notIn?: InputMaybe<Array<TransactionType>>;
};

export type TransactionUpdatedAtFilterComparison = {
  between?: InputMaybe<TransactionUpdatedAtFilterComparisonBetween>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  notBetween?: InputMaybe<TransactionUpdatedAtFilterComparisonBetween>;
};

export type TransactionUpdatedAtFilterComparisonBetween = {
  lower: Scalars['DateTime']['input'];
  upper: Scalars['DateTime']['input'];
};

export type TwoFactorPayload = {
  __typename?: 'TwoFactorPayload';
  qrCode: Scalars['String']['output'];
  secret: Scalars['String']['output'];
};

export type UuidFilterComparison = {
  eq?: InputMaybe<Scalars['UUID']['input']>;
  gt?: InputMaybe<Scalars['UUID']['input']>;
  gte?: InputMaybe<Scalars['UUID']['input']>;
  iLike?: InputMaybe<Scalars['UUID']['input']>;
  in?: InputMaybe<Array<Scalars['UUID']['input']>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  like?: InputMaybe<Scalars['UUID']['input']>;
  lt?: InputMaybe<Scalars['UUID']['input']>;
  lte?: InputMaybe<Scalars['UUID']['input']>;
  neq?: InputMaybe<Scalars['UUID']['input']>;
  notILike?: InputMaybe<Scalars['UUID']['input']>;
  notIn?: InputMaybe<Array<Scalars['UUID']['input']>>;
  notLike?: InputMaybe<Scalars['UUID']['input']>;
};

export type UnpinOneNoteInput = {
  /** The id of the record to update */
  id: Scalars['UUID']['input'];
};

export type UpdateBusinessActivity = {
  /** The name of the business activity. */
  name?: InputMaybe<Scalars['String']['input']>;
  /** The values of the business activity. */
  values?: InputMaybe<Array<CreateBusinessActivityValue>>;
};

export type UpdateClient = {
  /** ID of the linked business activity. */
  businessActivityId?: InputMaybe<Scalars['UUID']['input']>;
  /** Country code where the company is located (ISO 3166-1 alpha-2). */
  country?: InputMaybe<Scalars['String']['input']>;
  /** Preferred language of the company. */
  language?: InputMaybe<Language>;
  /** Name of the company. */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Should the area check be skippend for this client. Only accepted by masters! */
  skipAreaCheck?: Scalars['Boolean']['input'];
  /** The variant of the company. */
  variant?: InputMaybe<CompanyVariant>;
};

export type UpdateClientContact = {
  /** Email address of the contact */
  email?: InputMaybe<Scalars['String']['input']>;
  /** First name of the contact. */
  firstName?: InputMaybe<Scalars['String']['input']>;
  /** Gender of the contact. */
  gender?: Gender;
  /** Hide the users last name in salutation. */
  hideLastNameInSalutation?: Scalars['Boolean']['input'];
  /** Initials of the contact. */
  initials?: InputMaybe<Scalars['String']['input']>;
  /** Insertion of the contact. */
  insertion?: InputMaybe<Scalars['String']['input']>;
  /** Preferred language of the contact. */
  language?: Language;
  /** Last name of the contact. */
  lastName: Scalars['String']['input'];
  /** Mobile number of the contact. (E.164 format) */
  mobileNr?: InputMaybe<Scalars['String']['input']>;
  /** Phone number of the contact. (E.164 format) */
  phoneNr?: InputMaybe<Scalars['String']['input']>;
  /** When creating invoices use this contact. */
  useForInvoicing?: Scalars['Boolean']['input'];
};

export type UpdateClientInfo = {
  address?: InputMaybe<UpdateCompanyAddress>;
  /** Email address of the company, used in letters. */
  email?: InputMaybe<Scalars['String']['input']>;
  invoiceAddress?: InputMaybe<UpdateCompanyAddress>;
  /** Is this company subjected to vat. */
  isSubjectedToVat?: Scalars['Boolean']['input'];
  /** Legal name of the company. */
  legalName?: InputMaybe<Scalars['String']['input']>;
  /** Phone number of the company, used in letters. */
  phoneNr?: InputMaybe<Scalars['String']['input']>;
  postalAddress?: InputMaybe<UpdateCompanyAddress>;
  /** Companies registration number, for example the kvk number in NL. */
  registrationNr?: InputMaybe<Scalars['String']['input']>;
  /** Companies vat number, for example the BTW number in NL. */
  taxNr?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateCompanyAddress = {
  /** City. */
  city: Scalars['String']['input'];
  /** Country (ISO 3166-1 alpha-2). */
  country: Scalars['String']['input'];
  /** State. */
  state?: InputMaybe<Scalars['String']['input']>;
  /** Street name + house number. */
  streetName: Scalars['String']['input'];
  /** Zipcode. */
  zipcode: Scalars['String']['input'];
};

export type UpdateCompanyBankAccount = {
  /** Currency of the bank account. */
  currency?: InputMaybe<Currency>;
  /** Use this bank account if currency specifiek does not exist. */
  fallback?: Scalars['Boolean']['input'];
  /** IBAN of the account. */
  iban: Scalars['String']['input'];
  /** Name of the account. */
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateCompanyContact = {
  /** Email address of the contact */
  email?: InputMaybe<Scalars['String']['input']>;
  /** First name of the contact. */
  firstName?: InputMaybe<Scalars['String']['input']>;
  /** Gender of the contact. */
  gender?: Gender;
  /** Hide the users last name in salutation. */
  hideLastNameInSalutation?: Scalars['Boolean']['input'];
  /** Initials of the contact. */
  initials?: InputMaybe<Scalars['String']['input']>;
  /** Insertion of the contact. */
  insertion?: InputMaybe<Scalars['String']['input']>;
  /** Preferred language of the contact. */
  language?: Language;
  /** Last name of the contact. */
  lastName: Scalars['String']['input'];
  /** Mobile number of the contact. (E.164 format) */
  mobileNr?: InputMaybe<Scalars['String']['input']>;
  /** Phone number of the contact. (E.164 format) */
  phoneNr?: InputMaybe<Scalars['String']['input']>;
  /** When creating invoices use this contact. */
  useForInvoicing?: Scalars['Boolean']['input'];
};

export type UpdateCompanyContract = {
  /** Name of the contract. */
  name?: InputMaybe<Scalars['String']['input']>;
  /** The amount of weeks / months. */
  period?: InputMaybe<Scalars['Float']['input']>;
  /** Type of the period. */
  periodType?: InputMaybe<CompanyContractPeriodType>;
};

export type UpdateCompanyContractCost = {
  /** Categories to add to costs. */
  categories?: InputMaybe<Array<Scalars['UUID']['input']>>;
  /** Id of the category the pricing of this rule should be added. */
  categoryId?: InputMaybe<Scalars['UUID']['input']>;
  /** Fixed amount to always apply. */
  centAmount?: InputMaybe<Scalars['Float']['input']>;
  /** Currency of the costs. */
  currency?: InputMaybe<Currency>;
  /** Minimum amount that needs to be paid when using %. */
  minimumCentAmount?: InputMaybe<Scalars['Float']['input']>;
  /** Fixed amount to always apply (when client zipcode is not in area codes list) */
  outerAreaCentAmount?: InputMaybe<Scalars['Float']['input']>;
  /** Percentage to calculate over de connected costs (when client zipcode is not in area codes list). */
  outerAreaPercentage?: InputMaybe<Scalars['Float']['input']>;
  /** Percentage to calculate over de connected costs. */
  percentage?: InputMaybe<Scalars['Float']['input']>;
};

export type UpdateCompanyInfo = {
  address?: InputMaybe<UpdateCompanyAddress>;
  /** Email address of the company, used in letters. */
  email?: InputMaybe<Scalars['String']['input']>;
  invoiceAddress?: InputMaybe<UpdateCompanyAddress>;
  /** Is this company subjected to vat. */
  isSubjectedToVat?: Scalars['Boolean']['input'];
  /** Legal name of the company. */
  legalName?: InputMaybe<Scalars['String']['input']>;
  /** Phone number of the company, used in letters. */
  phoneNr?: InputMaybe<Scalars['String']['input']>;
  postalAddress?: InputMaybe<UpdateCompanyAddress>;
  /** Companies registration number, for example the kvk number in NL. */
  registrationNr?: InputMaybe<Scalars['String']['input']>;
  /** Companies vat number, for example the BTW number in NL. */
  taxNr?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateCompanySettings = {
  /** ID of the workflow that is used when importing collection dossiers and this client is the debtor. */
  collectionDebtorWorkflowId?: InputMaybe<Scalars['UUID']['input']>;
  /** ID of the workflow that is used when importing collection dossiers for this client. */
  collectionWorkflowId?: InputMaybe<Scalars['UUID']['input']>;
  /** When enabled invoice lines generated towards client in debtor management dossier will be billed when billing contract */
  debtorManagementBillWithContract?: InputMaybe<Scalars['Boolean']['input']>;
  /** ID of the file to use as logo for debtor management. */
  debtorManagementCompanyLogoId?: InputMaybe<Scalars['UUID']['input']>;
  /** ID of the workflow that is used when importing debtor management dossiers and this client is the debtor. */
  debtorManagementDebtorWorkflowId?: InputMaybe<Scalars['UUID']['input']>;
  /** ID of the file to use as logo for debtor management emails. */
  debtorManagementEmailLogoId?: InputMaybe<Scalars['UUID']['input']>;
  /** Signature to use in debtor management emails. */
  debtorManagementEmailSignature?: InputMaybe<Scalars['String']['input']>;
  /** Custom email address to use when sending emails in debtor management, setting this will enable friendly mode. */
  debtorManagementFromEmail?: InputMaybe<Scalars['String']['input']>;
  /** ID of the cost category for the fee. */
  debtorManagementInvoiceFeeCategoryId?: InputMaybe<Scalars['UUID']['input']>;
  /** Amount in cents of the fee. */
  debtorManagementInvoiceFeeCentAmount?: InputMaybe<Scalars['Float']['input']>;
  /** Currency of the invoice fee. */
  debtorManagementInvoiceFeeCurrency?: InputMaybe<Currency>;
  /** Should a fee be applied for each imported dossier invoice.. */
  debtorManagementInvoiceFeeEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  /** Type of dossier invoices to apply the fee on. */
  debtorManagementInvoiceFeeType?: InputMaybe<CompanyDebtorManagementInvoiceFeeType>;
  /** When enabled the client info will be used when sending letters and from email when sending emails in debtor management. */
  debtorManagementUseClientInfo?: InputMaybe<Scalars['Boolean']['input']>;
  /** ID of the workflow that is used when importing debtor management dossiers for this client. */
  debtorManagementWorkflowId?: InputMaybe<Scalars['UUID']['input']>;
  /** ID of the category to use for "interestCategory", goes together with "dossierInterestForContractor". */
  dossierInterestCategoryId?: InputMaybe<Scalars['String']['input']>;
  /** When enabled, creating dossiers for this client will default have "interestForContractor" on. */
  dossierInterestForContractor?: InputMaybe<Scalars['Boolean']['input']>;
  /** When enabled, creating dossiers for this client will default have "interestType" set to "CLIENT". */
  dossierInterestTypeClient?: InputMaybe<Scalars['Boolean']['input']>;
  /** When enabled, creating dossiers for this client will default have "payoutOnComplete" on. */
  dossierPayoutOnComplete?: InputMaybe<Scalars['Boolean']['input']>;
  /** ID of the workflow to use for invoices generated by contracts. */
  invoiceContractWorkflowId?: InputMaybe<Scalars['UUID']['input']>;
  /** ID of the workflow to use for invoices generated by the invoicing process. */
  invoiceInvoicingWorkflowId?: InputMaybe<Scalars['UUID']['input']>;
  /** ID of the workflow to use for manual created invoices. */
  invoiceManualWorkflowId?: InputMaybe<Scalars['UUID']['input']>;
  /** Fallback payment term when importing could not determine end invoice end date. */
  paymentTerm?: InputMaybe<Scalars['Float']['input']>;
  /** Fallback payment term type when importing could not determine end invoice end date. */
  paymentTermType?: InputMaybe<InvoicePaymentTerm>;
};

export type UpdateCostCategory = {
  /** Should vat be applied to this cost category. */
  applyVat?: InputMaybe<Scalars['Boolean']['input']>;
  /** Name of the cost category. */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Type of the cost category, determines who can use it. */
  type?: InputMaybe<CostCategoryType>;
};

export type UpdateDossier = {
  /** Reference for the bailiff. */
  bailiffReference?: InputMaybe<Scalars['String']['input']>;
  /** ID of the contractor. */
  contractorId?: InputMaybe<Scalars['UUID']['input']>;
  /** Reference of the debtor. */
  debtorReference?: InputMaybe<Scalars['String']['input']>;
  /** ID of the cost category to use for contractors interest. */
  interestCategoryId?: InputMaybe<Scalars['UUID']['input']>;
  /** Should the collected interest of this dossier be for the contractor. */
  interestForContractor?: InputMaybe<Scalars['Boolean']['input']>;
  /** Type of interest. */
  interestType?: InputMaybe<DossierInterestType>;
  /** Should the dossier be payout when its completed. */
  payoutOnComplete?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UpdateDossierCost = {
  /** ID of the cost category the cost belongs to. */
  categoryId?: InputMaybe<Scalars['UUID']['input']>;
  /** Amount in cents of cost. */
  centAmount?: InputMaybe<Scalars['Float']['input']>;
  /** Currency of the costs. */
  currency?: InputMaybe<Currency>;
  /** Description of the cost. */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Is this costs success based. */
  successBased?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UpdateDossierInvoice = {
  /** Amount in cents of the invoice. */
  centAmount?: InputMaybe<Scalars['Float']['input']>;
  /** Amount in cents of the invoice that have been paid (Only used in debtor management). */
  centAmountPaid?: InputMaybe<Scalars['Float']['input']>;
  /** Currency of the invoice. */
  currency?: InputMaybe<Currency>;
  /** Date of the invoice. */
  date?: InputMaybe<Scalars['DateTime']['input']>;
  /** ID of associated file. */
  fileId?: InputMaybe<Scalars['UUID']['input']>;
  /** Type of interest. */
  interest?: InputMaybe<DossierInvoiceInterest>;
  /** Date to calculate interest from. (Expire date of the invoice) */
  interestFromDate?: InputMaybe<Scalars['DateTime']['input']>;
  /** Amount of days / work-days. */
  paymentTerm?: InputMaybe<Scalars['Float']['input']>;
  /** Amount of days / work-days. */
  paymentTermType?: InputMaybe<InvoicePaymentTerm>;
  /** Reference of the invoice. This needs to be unique! */
  reference?: InputMaybe<Scalars['String']['input']>;
  /** Status of the invoice. (Only used in debtor management) */
  status?: InputMaybe<DossierInvoiceStatus>;
};

export type UpdateFile = {
  /** Description of the file. */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Name of file. */
  fileName?: InputMaybe<Scalars['String']['input']>;
  /** Type of the file. */
  type?: InputMaybe<FileType>;
  /** Is this file visible for the debtor. */
  visibleForDebtor?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UpdateFranchisee = {
  /** Preferred language of the company. */
  language?: InputMaybe<Language>;
  /** Location number of the company. */
  locationNr?: InputMaybe<Scalars['String']['input']>;
  /** Name of the company. */
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateInterestRate = {
  /** Amount in % the tax is. */
  amount?: InputMaybe<Scalars['Float']['input']>;
  /** From when the tax is applied. */
  from?: InputMaybe<Scalars['DateTime']['input']>;
  /** Until when the tax is applied. */
  until?: InputMaybe<Scalars['DateTime']['input']>;
  /** Type of the interest rate. */
  variant?: InputMaybe<InterestRateType>;
};

export type UpdateInvoice = {
  /** Payment term of the invoice. */
  paymentTerm?: InputMaybe<Scalars['Float']['input']>;
  /** Type of the invoice payment term. */
  paymentTermType?: InputMaybe<InvoicePaymentTerm>;
  /** Subject of the invoice. */
  subject?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateInvoiceLine = {
  /** ID of the category of this line item. */
  categoryId?: InputMaybe<Scalars['UUID']['input']>;
  /** Amount in cents of one line item. */
  centAmount?: InputMaybe<Scalars['Float']['input']>;
  /** Currency of line item. */
  currency?: InputMaybe<Currency>;
  /** Description of the line item. */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Process line item at next invoicing. */
  inInvoicing?: InputMaybe<Scalars['Boolean']['input']>;
  /** Note of the line item. */
  note?: InputMaybe<Scalars['String']['input']>;
  /** Quantity of line item. */
  quantity?: InputMaybe<Scalars['Float']['input']>;
};

export type UpdateLabel = {
  /** Name of the label */
  name: Scalars['String']['input'];
};

export type UpdateManyDossierStatus = {
  /** ID of the dossiers to update. */
  ids: Array<Scalars['UUID']['input']>;
  /** The new status for the dossiers. */
  status: NewDossierStatus;
};

export type UpdateManyResponse = {
  __typename?: 'UpdateManyResponse';
  /** The number of records updated. */
  updatedCount: Scalars['Int']['output'];
};

export type UpdateMaster = {
  /** Preferred language of the company. */
  language?: InputMaybe<Language>;
  /** Location number of the company. */
  locationNr?: InputMaybe<Scalars['String']['input']>;
  /** Name of the company. */
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateNote = {
  /** Content of the note. */
  content?: InputMaybe<Scalars['String']['input']>;
  /** Is it a sticky note. */
  sticky?: InputMaybe<Scalars['Boolean']['input']>;
  /** Is the note visible for the debtor. */
  visibleForDebtor?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UpdateOneBusinessActivityInput = {
  /** The id of the record to update */
  id: Scalars['UUID']['input'];
  /** The update to apply. */
  update: UpdateBusinessActivity;
};

export type UpdateOneClientContactInput = {
  /** The id of the record to update */
  id: Scalars['UUID']['input'];
  /** The update to apply. */
  update: UpdateClientContact;
};

export type UpdateOneClientInfoInput = {
  /** The id of the record to update */
  id: Scalars['UUID']['input'];
  /** The update to apply. */
  update: UpdateClientInfo;
};

export type UpdateOneClientInput = {
  /** The id of the record to update */
  id: Scalars['UUID']['input'];
  /** The update to apply. */
  update: UpdateClient;
};

export type UpdateOneCompanyBankAccountInput = {
  /** The id of the record to update */
  id: Scalars['UUID']['input'];
  /** The update to apply. */
  update: UpdateCompanyBankAccount;
};

export type UpdateOneCompanyContactInput = {
  /** The id of the record to update */
  id: Scalars['UUID']['input'];
  /** The update to apply. */
  update: UpdateCompanyContact;
};

export type UpdateOneCompanyContractCostInput = {
  /** The id of the record to update */
  id: Scalars['UUID']['input'];
  /** The update to apply. */
  update: UpdateCompanyContractCost;
};

export type UpdateOneCompanyContractInput = {
  /** The id of the record to update */
  id: Scalars['UUID']['input'];
  /** The update to apply. */
  update: UpdateCompanyContract;
};

export type UpdateOneCompanyInfoInput = {
  /** The id of the record to update */
  id: Scalars['UUID']['input'];
  /** The update to apply. */
  update: UpdateCompanyInfo;
};

export type UpdateOneCompanySettingsInput = {
  /** The id of the record to update */
  id: Scalars['UUID']['input'];
  /** The update to apply. */
  update: UpdateCompanySettings;
};

export type UpdateOneCostCategoryInput = {
  /** The id of the record to update */
  id: Scalars['UUID']['input'];
  /** The update to apply. */
  update: UpdateCostCategory;
};

export type UpdateOneDossierCostInput = {
  /** The id of the record to update */
  id: Scalars['UUID']['input'];
  /** The update to apply. */
  update: UpdateDossierCost;
};

export type UpdateOneDossierInput = {
  /** The id of the record to update */
  id: Scalars['UUID']['input'];
  /** The update to apply. */
  update: UpdateDossier;
};

export type UpdateOneDossierInvoiceInput = {
  /** The id of the record to update */
  id: Scalars['UUID']['input'];
  /** The update to apply. */
  update: UpdateDossierInvoice;
};

export type UpdateOneDossierStatus = {
  /** ID of the dossier to update. */
  id: Scalars['UUID']['input'];
  /** The new status for the dossier. */
  status: NewDossierStatus;
};

export type UpdateOneFileInput = {
  /** The id of the record to update */
  id: Scalars['UUID']['input'];
  /** The update to apply. */
  update: UpdateFile;
};

export type UpdateOneFranchiseeInput = {
  /** The id of the record to update */
  id: Scalars['UUID']['input'];
  /** The update to apply. */
  update: UpdateFranchisee;
};

export type UpdateOneInterestRateInput = {
  /** The id of the record to update */
  id: Scalars['UUID']['input'];
  /** The update to apply. */
  update: UpdateInterestRate;
};

export type UpdateOneInvoiceInput = {
  /** The id of the record to update */
  id: Scalars['UUID']['input'];
  /** The update to apply. */
  update: UpdateInvoice;
};

export type UpdateOneInvoiceLineInput = {
  /** The id of the record to update */
  id: Scalars['UUID']['input'];
  /** The update to apply. */
  update: UpdateInvoiceLine;
};

export type UpdateOneLabelInput = {
  /** The id of the record to update */
  id: Scalars['UUID']['input'];
  /** The update to apply. */
  update: UpdateLabel;
};

export type UpdateOneMasterInput = {
  /** The id of the record to update */
  id: Scalars['UUID']['input'];
  /** The update to apply. */
  update: UpdateMaster;
};

export type UpdateOneNoteInput = {
  /** The id of the record to update */
  id: Scalars['UUID']['input'];
  /** The update to apply. */
  update: UpdateNote;
};

export type UpdateOneRepeatingInvoiceLineInput = {
  /** The id of the record to update */
  id: Scalars['UUID']['input'];
  /** The update to apply. */
  update: UpdateRepeatingInvoiceLine;
};

export type UpdateOneTaxRateInput = {
  /** The id of the record to update */
  id: Scalars['UUID']['input'];
  /** The update to apply. */
  update: UpdateTaxRate;
};

export type UpdateOneUserGroupInput = {
  /** The id of the record to update */
  id: Scalars['UUID']['input'];
  /** The update to apply. */
  update: UpdateUserGroup;
};

export type UpdateOneUserToCompanyInput = {
  /** The id of the record to update */
  id: Scalars['UUID']['input'];
  /** The update to apply. */
  update: UpdateUserToCompany;
};

export type UpdateOneVoipExtensionInput = {
  /** The id of the record to update */
  id: Scalars['UUID']['input'];
  /** The update to apply. */
  update: UpdateVoipExtension;
};

export type UpdateOneVoipInternalNumberInput = {
  /** The id of the record to update */
  id: Scalars['UUID']['input'];
  /** The update to apply. */
  update: UpdateVoipInternalNumber;
};

export type UpdateOneWorkflowActionInput = {
  /** The id of the record to update */
  id: Scalars['UUID']['input'];
  /** The update to apply. */
  update: UpdateWorkflowAction;
};

export type UpdateOneWorkflowInput = {
  /** The id of the record to update */
  id: Scalars['UUID']['input'];
  /** The update to apply. */
  update: UpdateWorkflow;
};

export type UpdateOneWorkflowStep = {
  /** The id of the record to update */
  id: Scalars['UUID']['input'];
  /** The update to apply. */
  update: UpdateWorkflowStep;
};

export type UpdateOneWorkflowStepGlobal = {
  /** The id of the record to update */
  id: Scalars['UUID']['input'];
  /** The update to apply. */
  update: UpdateWorkflowStepGlobal;
};

export type UpdateOneWorkflowStepOrder = {
  /** ID of the workflow step to move this step after. */
  afterStepId?: InputMaybe<Scalars['UUID']['input']>;
  /** ID of the workflow step to move this step before. */
  beforeStepId?: InputMaybe<Scalars['UUID']['input']>;
  /** ID of the workflow step. */
  id: Scalars['UUID']['input'];
  /** ID of the workflow the steps belong to. */
  workflowId: Scalars['UUID']['input'];
};

export type UpdateRepeatingInvoiceLine = {
  /** ID of the category of this line item. */
  categoryId?: InputMaybe<Scalars['UUID']['input']>;
  /** Amount in cents of one line item. */
  centAmount?: InputMaybe<Scalars['Float']['input']>;
  /** Currency of line item. */
  currency?: InputMaybe<Currency>;
  /** Description of the line item. */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Process line item at next invoicing. */
  inInvoicing?: InputMaybe<Scalars['Boolean']['input']>;
  /** Note of the line item. */
  note?: InputMaybe<Scalars['String']['input']>;
  /** The amount of (working) days / weeks / months. */
  period?: InputMaybe<Scalars['Float']['input']>;
  /** Type of the period. */
  periodType?: InputMaybe<RepeatingInvoiceLinePeriodType>;
  /** Quantity of line item. */
  quantity?: InputMaybe<Scalars['Float']['input']>;
};

export type UpdateTaxRate = {
  /** Amount in % the tax is. */
  amount?: InputMaybe<Scalars['Float']['input']>;
  /** From when the tax is applied. */
  from?: InputMaybe<Scalars['DateTime']['input']>;
  /** Until when the tax is applied. */
  until?: InputMaybe<Scalars['DateTime']['input']>;
};

export type UpdateUserGroup = {
  /** Name of the user group. */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Scopes of the user group. */
  scopes?: InputMaybe<Array<CreateScope>>;
};

export type UpdateUserToCompany = {
  /** ID of the group. */
  groupId: Scalars['UUID']['input'];
};

export type UpdateVoipExtension = {
  /** Name of the extension. */
  name?: InputMaybe<Scalars['String']['input']>;
  /** ID of the user this extension belongs to. */
  userId?: InputMaybe<Scalars['UUID']['input']>;
};

export type UpdateVoipInternalNumber = {
  /** The internal number. */
  number: Scalars['String']['input'];
  /** ID of the user this internal number belongs to. */
  userId: Scalars['UUID']['input'];
};

export type UpdateWorkflow = {
  /** Name of the workflow. */
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateWorkflowAction = {
  /** If `true` files generated by this action will be added as attachment to same step email actions. */
  attachFilesToStepEmailActions?: InputMaybe<Scalars['Boolean']['input']>;
  /** ID's of the attachments to send with an email. */
  attachmentIds?: InputMaybe<Array<Scalars['UUID']['input']>>;
  /** Contacts/email addresses to add in the bcc of the email. */
  bcc?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Contacts/email addresses to add in the cc of the email. */
  cc?: InputMaybe<Array<Scalars['String']['input']>>;
  /** ID of the contact to send this action to. Only used when executing action! */
  contactId?: InputMaybe<Scalars['UUID']['input']>;
  /** Contacts to send this action to. */
  contactIds?: InputMaybe<Array<Scalars['String']['input']>>;
  /** To who the action should be focused on. */
  direction?: InputMaybe<WorkflowActionDirection>;
  /** ID of the executor. */
  executorId?: InputMaybe<Scalars['UUID']['input']>;
  /** Workflow action message. Required if no "id" is provided! */
  message?: InputMaybe<Scalars['String']['input']>;
  /** Name of the action. Required if no "id" is provided! */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Workflow action subject. Required if no "id" is provided and type is "email" or "letter"! */
  subject?: InputMaybe<Scalars['String']['input']>;
  /** Type of the action. Required if no "id" is provided! */
  type?: InputMaybe<WorkflowActionType>;
};

export type UpdateWorkflowStep = {
  /** Actions to create for this workflow step. */
  actions?: InputMaybe<Array<CreateWorkflowAction>>;
  /** Id of the client cost category. Required if "clientCosts" are provided! */
  clientCostCategoryId?: InputMaybe<Scalars['UUID']['input']>;
  /** Currency of the client costs. */
  clientCostCurrency?: InputMaybe<Currency>;
  /** Costs for the client */
  clientCosts?: InputMaybe<Array<UpdateWorkflowStepCost>>;
  /** Are the client costs for each invoice. */
  clientCostsForEachInvoice?: Scalars['Boolean']['input'];
  /** Are the client costs success based. */
  clientCostsSuccessBased?: Scalars['Boolean']['input'];
  /** Amount of days / work-days. */
  days?: Scalars['Float']['input'];
  /** Amount of days / work-days. */
  daysType?: WorkflowStepDaysType;
  /** Id of the debtor cost category. Required if "debtorCosts" are provided! */
  debtorCostCategoryId?: InputMaybe<Scalars['UUID']['input']>;
  /** Currency of the debtor costs. */
  debtorCostCurrency?: InputMaybe<Currency>;
  /** Costs for the debtor */
  debtorCosts?: InputMaybe<Array<UpdateWorkflowStepCost>>;
  /** Are the debtor costs for each invoice. */
  debtorCostsForEachInvoice?: Scalars['Boolean']['input'];
  /** Date this step should be executed on. */
  executableOn?: InputMaybe<Scalars['DateTime']['input']>;
  /** Name of the step. Required if no "id" is provided! */
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateWorkflowStepCost = {
  /** Amount in cents. */
  centAmount?: InputMaybe<Scalars['Float']['input']>;
  /** Until what cent amount this line applies, null if always. */
  centAmountUntil?: InputMaybe<Scalars['Float']['input']>;
  /** Currency of the until amount. */
  currencyUntil?: InputMaybe<Currency>;
  /** Primary key of the recourse. */
  id?: InputMaybe<Scalars['UUID']['input']>;
  /** Use percentage instead of centAmount. */
  isPercentage?: InputMaybe<Scalars['Boolean']['input']>;
  /** Amount in percentage. */
  percentage?: InputMaybe<Scalars['Float']['input']>;
};

export type UpdateWorkflowStepGlobal = {
  /** New global value of the step. */
  global: Scalars['Boolean']['input'];
};

export type User = {
  __typename?: 'User';
  /** Date time of when the recourse is created. */
  createdAt: Scalars['DateTime']['output'];
  /** Email address of the user. */
  email: Scalars['String']['output'];
  /** First name of the user. */
  firstName?: Maybe<Scalars['String']['output']>;
  /** Full name of the user. */
  fullName?: Maybe<Scalars['String']['output']>;
  /** If the user has two factor enabled or not. */
  hasTwoFactor: Scalars['Boolean']['output'];
  /** Primary key of the recourse. */
  id: Scalars['UUID']['output'];
  /** Insertion of the user. */
  insertion?: Maybe<Scalars['String']['output']>;
  /** Users preferred language. */
  language: Language;
  /** Date time when the user logged in the last. */
  lastLogin?: Maybe<Scalars['DateTime']['output']>;
  /** Last name of the user. */
  lastName?: Maybe<Scalars['String']['output']>;
  /** Status of the user. */
  status: UserStatus;
  /** Type of the user. */
  type: UserType;
  /** Date time of when the recourse last updated. */
  updatedAt: Scalars['DateTime']['output'];
};

export type UserConnection = {
  __typename?: 'UserConnection';
  /** Array of nodes. */
  nodes: Array<User>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type UserCreatedAtFilterComparison = {
  between?: InputMaybe<UserCreatedAtFilterComparisonBetween>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  notBetween?: InputMaybe<UserCreatedAtFilterComparisonBetween>;
};

export type UserCreatedAtFilterComparisonBetween = {
  lower: Scalars['DateTime']['input'];
  upper: Scalars['DateTime']['input'];
};

export type UserEmailFilterComparison = {
  eq?: InputMaybe<Scalars['String']['input']>;
  like?: InputMaybe<Scalars['String']['input']>;
  neq?: InputMaybe<Scalars['String']['input']>;
  notLike?: InputMaybe<Scalars['String']['input']>;
};

export type UserFeatures = {
  __typename?: 'UserFeatures';
  /** ID of the users preferred voip extension. */
  defaultVoipExtension?: Maybe<Scalars['UUID']['output']>;
  /** Has the user the VoIP feature. */
  voip: Scalars['Boolean']['output'];
};

export type UserFilter = {
  and?: InputMaybe<Array<UserFilter>>;
  companies?: InputMaybe<UserFilterUserToCompanyFilter>;
  createdAt?: InputMaybe<UserCreatedAtFilterComparison>;
  email?: InputMaybe<UserEmailFilterComparison>;
  fullName?: InputMaybe<UserFullNameFilterComparison>;
  id?: InputMaybe<UserIdFilterComparison>;
  lastLogin?: InputMaybe<UserLastLoginFilterComparison>;
  or?: InputMaybe<Array<UserFilter>>;
  status?: InputMaybe<UserStatusFilterComparison>;
  type?: InputMaybe<UserTypeFilterComparison>;
  updatedAt?: InputMaybe<UserUpdatedAtFilterComparison>;
};

export type UserFilterUserToCompanyFilter = {
  and?: InputMaybe<Array<UserFilterUserToCompanyFilter>>;
  companyId?: InputMaybe<UserToCompanyCompanyIdFilterComparison>;
  createdAt?: InputMaybe<UserToCompanyCreatedAtFilterComparison>;
  id?: InputMaybe<UserToCompanyIdFilterComparison>;
  or?: InputMaybe<Array<UserFilterUserToCompanyFilter>>;
  updatedAt?: InputMaybe<UserToCompanyUpdatedAtFilterComparison>;
  userId?: InputMaybe<UserToCompanyUserIdFilterComparison>;
};

export type UserFullNameFilterComparison = {
  eq?: InputMaybe<Scalars['String']['input']>;
  like?: InputMaybe<Scalars['String']['input']>;
  neq?: InputMaybe<Scalars['String']['input']>;
  notLike?: InputMaybe<Scalars['String']['input']>;
};

export type UserGroup = {
  __typename?: 'UserGroup';
  /** Company who created the group. */
  company: CompanyReference;
  /** ID of the company who created the group. */
  companyId: Scalars['String']['output'];
  /** Date time of when the recourse is created. */
  createdAt: Scalars['DateTime']['output'];
  /** Creator of the group. */
  creator: Creator;
  /** Primary key of the recourse. */
  id: Scalars['UUID']['output'];
  /** Name of the user group. */
  name: Scalars['String']['output'];
  /** Scopes the user group. */
  scopes: Array<Scope>;
  /** Date time of when the recourse last updated. */
  updatedAt: Scalars['DateTime']['output'];
};

export type UserGroupConnection = {
  __typename?: 'UserGroupConnection';
  /** Array of nodes. */
  nodes: Array<UserGroup>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type UserGroupCreatedAtFilterComparison = {
  between?: InputMaybe<UserGroupCreatedAtFilterComparisonBetween>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  notBetween?: InputMaybe<UserGroupCreatedAtFilterComparisonBetween>;
};

export type UserGroupCreatedAtFilterComparisonBetween = {
  lower: Scalars['DateTime']['input'];
  upper: Scalars['DateTime']['input'];
};

export type UserGroupFilter = {
  and?: InputMaybe<Array<UserGroupFilter>>;
  createdAt?: InputMaybe<UserGroupCreatedAtFilterComparison>;
  id?: InputMaybe<UserGroupIdFilterComparison>;
  name?: InputMaybe<UserGroupNameFilterComparison>;
  or?: InputMaybe<Array<UserGroupFilter>>;
  updatedAt?: InputMaybe<UserGroupUpdatedAtFilterComparison>;
};

export type UserGroupIdFilterComparison = {
  eq?: InputMaybe<Scalars['UUID']['input']>;
  in?: InputMaybe<Array<Scalars['UUID']['input']>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  neq?: InputMaybe<Scalars['UUID']['input']>;
  notIn?: InputMaybe<Array<Scalars['UUID']['input']>>;
};

export type UserGroupNameFilterComparison = {
  eq?: InputMaybe<Scalars['String']['input']>;
  like?: InputMaybe<Scalars['String']['input']>;
  neq?: InputMaybe<Scalars['String']['input']>;
  notLike?: InputMaybe<Scalars['String']['input']>;
};

export type UserGroupSort = {
  direction: SortDirection;
  field: UserGroupSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum UserGroupSortFields {
  CreatedAt = 'createdAt',
  Id = 'id',
  Name = 'name',
  UpdatedAt = 'updatedAt'
}

export type UserGroupUpdatedAtFilterComparison = {
  between?: InputMaybe<UserGroupUpdatedAtFilterComparisonBetween>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  notBetween?: InputMaybe<UserGroupUpdatedAtFilterComparisonBetween>;
};

export type UserGroupUpdatedAtFilterComparisonBetween = {
  lower: Scalars['DateTime']['input'];
  upper: Scalars['DateTime']['input'];
};

export type UserIdFilterComparison = {
  eq?: InputMaybe<Scalars['UUID']['input']>;
  in?: InputMaybe<Array<Scalars['UUID']['input']>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  neq?: InputMaybe<Scalars['UUID']['input']>;
  notIn?: InputMaybe<Array<Scalars['UUID']['input']>>;
};

export type UserLastLoginFilterComparison = {
  between?: InputMaybe<UserLastLoginFilterComparisonBetween>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  notBetween?: InputMaybe<UserLastLoginFilterComparisonBetween>;
};

export type UserLastLoginFilterComparisonBetween = {
  lower: Scalars['DateTime']['input'];
  upper: Scalars['DateTime']['input'];
};

export type UserReference = {
  __typename?: 'UserReference';
  /** First name of the user. */
  firstName?: Maybe<Scalars['String']['output']>;
  /** Full name of the user. */
  fullName?: Maybe<Scalars['String']['output']>;
  /** Primary key of the recourse. */
  id: Scalars['UUID']['output'];
  /** Last name of the user. */
  lastName?: Maybe<Scalars['String']['output']>;
};

export type UserSort = {
  direction: SortDirection;
  field: UserSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum UserSortFields {
  CreatedAt = 'createdAt',
  Email = 'email',
  FullName = 'fullName',
  Id = 'id',
  LastLogin = 'lastLogin',
  Status = 'status',
  Type = 'type',
  UpdatedAt = 'updatedAt'
}

/** Status of a user. */
export enum UserStatus {
  Active = 'Active',
  Deactivated = 'Deactivated',
  Invited = 'Invited'
}

export type UserStatusFilterComparison = {
  eq?: InputMaybe<UserStatus>;
  in?: InputMaybe<Array<UserStatus>>;
  neq?: InputMaybe<UserStatus>;
  notIn?: InputMaybe<Array<UserStatus>>;
};

export type UserToCompany = {
  __typename?: 'UserToCompany';
  /** Company of the user. */
  company: CompanyReference;
  /** ID of the company the user is linked to. */
  companyId: Scalars['UUID']['output'];
  /** Date time of when the recourse is created. */
  createdAt: Scalars['DateTime']['output'];
  /** Group of the user. */
  group: UserGroup;
  /** ID of the group. */
  groupId: Scalars['UUID']['output'];
  /** Primary key of the recourse. */
  id: Scalars['UUID']['output'];
  /** Date time of when the recourse last updated. */
  updatedAt: Scalars['DateTime']['output'];
  /** ID of the user of this link. */
  userId: Scalars['UUID']['output'];
};

export type UserToCompanyCompanyIdFilterComparison = {
  eq?: InputMaybe<Scalars['UUID']['input']>;
  in?: InputMaybe<Array<Scalars['UUID']['input']>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  neq?: InputMaybe<Scalars['UUID']['input']>;
  notIn?: InputMaybe<Array<Scalars['UUID']['input']>>;
};

export type UserToCompanyConnection = {
  __typename?: 'UserToCompanyConnection';
  /** Array of nodes. */
  nodes: Array<UserToCompany>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type UserToCompanyCreatedAtFilterComparison = {
  between?: InputMaybe<UserToCompanyCreatedAtFilterComparisonBetween>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  notBetween?: InputMaybe<UserToCompanyCreatedAtFilterComparisonBetween>;
};

export type UserToCompanyCreatedAtFilterComparisonBetween = {
  lower: Scalars['DateTime']['input'];
  upper: Scalars['DateTime']['input'];
};

export type UserToCompanyDeleteResponse = {
  __typename?: 'UserToCompanyDeleteResponse';
  /** ID of the company the user is linked to. */
  companyId?: Maybe<Scalars['UUID']['output']>;
  /** Date time of when the recourse is created. */
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  /** ID of the group. */
  groupId?: Maybe<Scalars['UUID']['output']>;
  /** Primary key of the recourse. */
  id?: Maybe<Scalars['UUID']['output']>;
  /** Date time of when the recourse last updated. */
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** ID of the user of this link. */
  userId?: Maybe<Scalars['UUID']['output']>;
};

export type UserToCompanyFilter = {
  and?: InputMaybe<Array<UserToCompanyFilter>>;
  companyId?: InputMaybe<UserToCompanyCompanyIdFilterComparison>;
  createdAt?: InputMaybe<UserToCompanyCreatedAtFilterComparison>;
  id?: InputMaybe<UserToCompanyIdFilterComparison>;
  or?: InputMaybe<Array<UserToCompanyFilter>>;
  updatedAt?: InputMaybe<UserToCompanyUpdatedAtFilterComparison>;
  userId?: InputMaybe<UserToCompanyUserIdFilterComparison>;
};

export type UserToCompanyIdFilterComparison = {
  eq?: InputMaybe<Scalars['UUID']['input']>;
  in?: InputMaybe<Array<Scalars['UUID']['input']>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  neq?: InputMaybe<Scalars['UUID']['input']>;
  notIn?: InputMaybe<Array<Scalars['UUID']['input']>>;
};

export type UserToCompanySort = {
  direction: SortDirection;
  field: UserToCompanySortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum UserToCompanySortFields {
  CompanyId = 'companyId',
  CreatedAt = 'createdAt',
  Id = 'id',
  UpdatedAt = 'updatedAt',
  UserId = 'userId'
}

export type UserToCompanyUpdatedAtFilterComparison = {
  between?: InputMaybe<UserToCompanyUpdatedAtFilterComparisonBetween>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  notBetween?: InputMaybe<UserToCompanyUpdatedAtFilterComparisonBetween>;
};

export type UserToCompanyUpdatedAtFilterComparisonBetween = {
  lower: Scalars['DateTime']['input'];
  upper: Scalars['DateTime']['input'];
};

export type UserToCompanyUserIdFilterComparison = {
  eq?: InputMaybe<Scalars['UUID']['input']>;
  in?: InputMaybe<Array<Scalars['UUID']['input']>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  neq?: InputMaybe<Scalars['UUID']['input']>;
  notIn?: InputMaybe<Array<Scalars['UUID']['input']>>;
};

/** Type of the user. */
export enum UserType {
  Api = 'API',
  Client = 'Client',
  Integration = 'Integration',
  Normal = 'Normal',
  System = 'System'
}

export type UserTypeFilterComparison = {
  eq?: InputMaybe<UserType>;
  in?: InputMaybe<Array<UserType>>;
  neq?: InputMaybe<UserType>;
  notIn?: InputMaybe<Array<UserType>>;
};

export type UserUpdatedAtFilterComparison = {
  between?: InputMaybe<UserUpdatedAtFilterComparisonBetween>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  notBetween?: InputMaybe<UserUpdatedAtFilterComparisonBetween>;
};

export type UserUpdatedAtFilterComparisonBetween = {
  lower: Scalars['DateTime']['input'];
  upper: Scalars['DateTime']['input'];
};

export type ValidActivationToken = {
  __typename?: 'ValidActivationToken';
  email?: Maybe<Scalars['String']['output']>;
  isValid: Scalars['Boolean']['output'];
  language?: Maybe<Language>;
};

export type ValidPasswordResetToken = {
  __typename?: 'ValidPasswordResetToken';
  email?: Maybe<Scalars['String']['output']>;
  isValid: Scalars['Boolean']['output'];
  language?: Maybe<Language>;
};

export type VoipCall = {
  __typename?: 'VoipCall';
  /** Date time of when the recourse is created. */
  createdAt: Scalars['DateTime']['output'];
  /** Duration of the call. */
  duration: Scalars['Float']['output'];
  /** End date time of the call. */
  end?: Maybe<Scalars['DateTime']['output']>;
  /** Initiator of the call. */
  from: Scalars['String']['output'];
  /** Primary key of the recourse. */
  id: Scalars['UUID']['output'];
  /** Internal ID. */
  internalId: Scalars['String']['output'];
  /** Is there a recording available of the call. */
  recordingAvailable: Scalars['Boolean']['output'];
  /** Uri to read the call recording. */
  recordingReadUri?: Maybe<Scalars['String']['output']>;
  /** Start date time of the call. */
  start: Scalars['DateTime']['output'];
  /** Status of the call. */
  status: VoipCallStatus;
  /** Receiver of the call. */
  to: Scalars['String']['output'];
  /** Date time of when the recourse last updated. */
  updatedAt: Scalars['DateTime']['output'];
  /** User who made/answered the call */
  user?: Maybe<UserReference>;
  /** Number this call belongs to. */
  voipNumber: VoipNumber;
};


export type VoipCallRecordingReadUriArgs = {
  download?: InputMaybe<Scalars['Boolean']['input']>;
};

export type VoipCallConnection = {
  __typename?: 'VoipCallConnection';
  /** Array of nodes. */
  nodes: Array<VoipCall>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type VoipCallCreatedAtFilterComparison = {
  between?: InputMaybe<VoipCallCreatedAtFilterComparisonBetween>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  notBetween?: InputMaybe<VoipCallCreatedAtFilterComparisonBetween>;
};

export type VoipCallCreatedAtFilterComparisonBetween = {
  lower: Scalars['DateTime']['input'];
  upper: Scalars['DateTime']['input'];
};

export type VoipCallEndFilterComparison = {
  between?: InputMaybe<VoipCallEndFilterComparisonBetween>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  notBetween?: InputMaybe<VoipCallEndFilterComparisonBetween>;
};

export type VoipCallEndFilterComparisonBetween = {
  lower: Scalars['DateTime']['input'];
  upper: Scalars['DateTime']['input'];
};

export type VoipCallFilter = {
  and?: InputMaybe<Array<VoipCallFilter>>;
  createdAt?: InputMaybe<VoipCallCreatedAtFilterComparison>;
  end?: InputMaybe<VoipCallEndFilterComparison>;
  from?: InputMaybe<VoipCallFromFilterComparison>;
  id?: InputMaybe<VoipCallIdFilterComparison>;
  internalId?: InputMaybe<VoipCallInternalIdFilterComparison>;
  or?: InputMaybe<Array<VoipCallFilter>>;
  start?: InputMaybe<VoipCallStartFilterComparison>;
  to?: InputMaybe<VoipCallToFilterComparison>;
  updatedAt?: InputMaybe<VoipCallUpdatedAtFilterComparison>;
};

export type VoipCallFromFilterComparison = {
  eq?: InputMaybe<Scalars['String']['input']>;
  like?: InputMaybe<Scalars['String']['input']>;
  neq?: InputMaybe<Scalars['String']['input']>;
  notLike?: InputMaybe<Scalars['String']['input']>;
};

export type VoipCallIdFilterComparison = {
  eq?: InputMaybe<Scalars['UUID']['input']>;
  in?: InputMaybe<Array<Scalars['UUID']['input']>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  neq?: InputMaybe<Scalars['UUID']['input']>;
  notIn?: InputMaybe<Array<Scalars['UUID']['input']>>;
};

export type VoipCallInternalIdFilterComparison = {
  eq?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  neq?: InputMaybe<Scalars['String']['input']>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type VoipCallSort = {
  direction: SortDirection;
  field: VoipCallSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum VoipCallSortFields {
  CreatedAt = 'createdAt',
  End = 'end',
  From = 'from',
  Id = 'id',
  InternalId = 'internalId',
  Start = 'start',
  To = 'to',
  UpdatedAt = 'updatedAt'
}

export type VoipCallStartFilterComparison = {
  between?: InputMaybe<VoipCallStartFilterComparisonBetween>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  notBetween?: InputMaybe<VoipCallStartFilterComparisonBetween>;
};

export type VoipCallStartFilterComparisonBetween = {
  lower: Scalars['DateTime']['input'];
  upper: Scalars['DateTime']['input'];
};

/** Status an voip call can have. */
export enum VoipCallStatus {
  Busy = 'Busy',
  Canceled = 'Canceled',
  Completed = 'Completed',
  Failed = 'Failed',
  InProgress = 'InProgress',
  Initiated = 'Initiated',
  NoAnswer = 'NoAnswer',
  Ringing = 'Ringing'
}

export type VoipCallToFilterComparison = {
  eq?: InputMaybe<Scalars['String']['input']>;
  like?: InputMaybe<Scalars['String']['input']>;
  neq?: InputMaybe<Scalars['String']['input']>;
  notLike?: InputMaybe<Scalars['String']['input']>;
};

export type VoipCallUpdatedAtFilterComparison = {
  between?: InputMaybe<VoipCallUpdatedAtFilterComparisonBetween>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  notBetween?: InputMaybe<VoipCallUpdatedAtFilterComparisonBetween>;
};

export type VoipCallUpdatedAtFilterComparisonBetween = {
  lower: Scalars['DateTime']['input'];
  upper: Scalars['DateTime']['input'];
};

export type VoipExtension = {
  __typename?: 'VoipExtension';
  /** Date time of when the recourse is created. */
  createdAt: Scalars['DateTime']['output'];
  /** Primary key of the recourse. */
  id: Scalars['UUID']['output'];
  /** Name of the extension. */
  name: Scalars['String']['output'];
  /** SIP domain of the extension. */
  sipDomain: Scalars['String']['output'];
  /** Date time of when the recourse last updated. */
  updatedAt: Scalars['DateTime']['output'];
  /** User this extensions belongs to. */
  user?: Maybe<UserReference>;
  /** ID of the user this extension belongs to. */
  userId?: Maybe<Scalars['UUID']['output']>;
  /** Username of the extension. */
  username: Scalars['String']['output'];
  /** Number this extension belongs to. */
  voipNumber: VoipNumber;
  /** ID of the number this extension belongs to. */
  voipNumberId: Scalars['UUID']['output'];
};

export type VoipExtensionConnection = {
  __typename?: 'VoipExtensionConnection';
  /** Array of nodes. */
  nodes: Array<VoipExtension>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type VoipExtensionCreatedAtFilterComparison = {
  between?: InputMaybe<VoipExtensionCreatedAtFilterComparisonBetween>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  notBetween?: InputMaybe<VoipExtensionCreatedAtFilterComparisonBetween>;
};

export type VoipExtensionCreatedAtFilterComparisonBetween = {
  lower: Scalars['DateTime']['input'];
  upper: Scalars['DateTime']['input'];
};

export type VoipExtensionFilter = {
  and?: InputMaybe<Array<VoipExtensionFilter>>;
  createdAt?: InputMaybe<VoipExtensionCreatedAtFilterComparison>;
  id?: InputMaybe<VoipExtensionIdFilterComparison>;
  name?: InputMaybe<VoipExtensionNameFilterComparison>;
  or?: InputMaybe<Array<VoipExtensionFilter>>;
  updatedAt?: InputMaybe<VoipExtensionUpdatedAtFilterComparison>;
};

export type VoipExtensionIdFilterComparison = {
  eq?: InputMaybe<Scalars['UUID']['input']>;
  in?: InputMaybe<Array<Scalars['UUID']['input']>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  neq?: InputMaybe<Scalars['UUID']['input']>;
  notIn?: InputMaybe<Array<Scalars['UUID']['input']>>;
};

export type VoipExtensionNameFilterComparison = {
  eq?: InputMaybe<Scalars['String']['input']>;
  like?: InputMaybe<Scalars['String']['input']>;
  neq?: InputMaybe<Scalars['String']['input']>;
  notLike?: InputMaybe<Scalars['String']['input']>;
};

export type VoipExtensionSort = {
  direction: SortDirection;
  field: VoipExtensionSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum VoipExtensionSortFields {
  CreatedAt = 'createdAt',
  Id = 'id',
  Name = 'name',
  UpdatedAt = 'updatedAt'
}

export type VoipExtensionUpdatedAtFilterComparison = {
  between?: InputMaybe<VoipExtensionUpdatedAtFilterComparisonBetween>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  notBetween?: InputMaybe<VoipExtensionUpdatedAtFilterComparisonBetween>;
};

export type VoipExtensionUpdatedAtFilterComparisonBetween = {
  lower: Scalars['DateTime']['input'];
  upper: Scalars['DateTime']['input'];
};

export type VoipInternalNumber = {
  __typename?: 'VoipInternalNumber';
  /** Date time of when the recourse is created. */
  createdAt: Scalars['DateTime']['output'];
  /** Primary key of the recourse. */
  id: Scalars['UUID']['output'];
  /** The internal number. */
  number: Scalars['String']['output'];
  /** Date time of when the recourse last updated. */
  updatedAt: Scalars['DateTime']['output'];
  /** User this internal number belongs to. */
  user?: Maybe<UserReference>;
  /** ID of the user this internal number belongs to. */
  userId?: Maybe<Scalars['UUID']['output']>;
};

export type VoipInternalNumberConnection = {
  __typename?: 'VoipInternalNumberConnection';
  /** Array of nodes. */
  nodes: Array<VoipInternalNumber>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type VoipInternalNumberCreatedAtFilterComparison = {
  between?: InputMaybe<VoipInternalNumberCreatedAtFilterComparisonBetween>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  notBetween?: InputMaybe<VoipInternalNumberCreatedAtFilterComparisonBetween>;
};

export type VoipInternalNumberCreatedAtFilterComparisonBetween = {
  lower: Scalars['DateTime']['input'];
  upper: Scalars['DateTime']['input'];
};

export type VoipInternalNumberDeleteResponse = {
  __typename?: 'VoipInternalNumberDeleteResponse';
  /** Date time of when the recourse is created. */
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  /** Primary key of the recourse. */
  id?: Maybe<Scalars['UUID']['output']>;
  /** The internal number. */
  number?: Maybe<Scalars['String']['output']>;
  /** Date time of when the recourse last updated. */
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  /** User this internal number belongs to. */
  user?: Maybe<UserReference>;
  /** ID of the user this internal number belongs to. */
  userId?: Maybe<Scalars['UUID']['output']>;
};

export type VoipInternalNumberFilter = {
  and?: InputMaybe<Array<VoipInternalNumberFilter>>;
  createdAt?: InputMaybe<VoipInternalNumberCreatedAtFilterComparison>;
  id?: InputMaybe<VoipInternalNumberIdFilterComparison>;
  number?: InputMaybe<VoipInternalNumberNumberFilterComparison>;
  or?: InputMaybe<Array<VoipInternalNumberFilter>>;
  updatedAt?: InputMaybe<VoipInternalNumberUpdatedAtFilterComparison>;
};

export type VoipInternalNumberIdFilterComparison = {
  eq?: InputMaybe<Scalars['UUID']['input']>;
  in?: InputMaybe<Array<Scalars['UUID']['input']>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  neq?: InputMaybe<Scalars['UUID']['input']>;
  notIn?: InputMaybe<Array<Scalars['UUID']['input']>>;
};

export type VoipInternalNumberNumberFilterComparison = {
  eq?: InputMaybe<Scalars['String']['input']>;
  like?: InputMaybe<Scalars['String']['input']>;
  neq?: InputMaybe<Scalars['String']['input']>;
  notLike?: InputMaybe<Scalars['String']['input']>;
};

export type VoipInternalNumberSort = {
  direction: SortDirection;
  field: VoipInternalNumberSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum VoipInternalNumberSortFields {
  CreatedAt = 'createdAt',
  Id = 'id',
  Number = 'number',
  UpdatedAt = 'updatedAt'
}

export type VoipInternalNumberUpdatedAtFilterComparison = {
  between?: InputMaybe<VoipInternalNumberUpdatedAtFilterComparisonBetween>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  notBetween?: InputMaybe<VoipInternalNumberUpdatedAtFilterComparisonBetween>;
};

export type VoipInternalNumberUpdatedAtFilterComparisonBetween = {
  lower: Scalars['DateTime']['input'];
  upper: Scalars['DateTime']['input'];
};

export type VoipNumber = {
  __typename?: 'VoipNumber';
  /** Company this phone number belongs to. */
  company?: Maybe<CompanyReference>;
  /** ID of the company this phone number belongs to. */
  companyId?: Maybe<Scalars['UUID']['output']>;
  /** Country where the phone number is located. */
  country: Scalars['String']['output'];
  /** Date time of when the recourse is created. */
  createdAt: Scalars['DateTime']['output'];
  /** Primary key of the recourse. */
  id: Scalars['UUID']['output'];
  /** Master this phone number belongs to. */
  master?: Maybe<CompanyReference>;
  /** ID of the master this phone number belongs to. */
  masterId?: Maybe<Scalars['UUID']['output']>;
  /** The phone number. */
  number: Scalars['String']['output'];
  /** Provider of the phone number. */
  provider: Scalars['String']['output'];
  /** Are incoming calls recorded. */
  recordIncoming: Scalars['Boolean']['output'];
  /** Are outgoing calls recorded. */
  recordOutgoing: Scalars['Boolean']['output'];
  /** Date time of when the recourse last updated. */
  updatedAt: Scalars['DateTime']['output'];
};

export type VoipNumberConnection = {
  __typename?: 'VoipNumberConnection';
  /** Array of nodes. */
  nodes: Array<VoipNumber>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type VoipNumberCreatedAtFilterComparison = {
  between?: InputMaybe<VoipNumberCreatedAtFilterComparisonBetween>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  notBetween?: InputMaybe<VoipNumberCreatedAtFilterComparisonBetween>;
};

export type VoipNumberCreatedAtFilterComparisonBetween = {
  lower: Scalars['DateTime']['input'];
  upper: Scalars['DateTime']['input'];
};

export type VoipNumberFilter = {
  and?: InputMaybe<Array<VoipNumberFilter>>;
  createdAt?: InputMaybe<VoipNumberCreatedAtFilterComparison>;
  id?: InputMaybe<VoipNumberIdFilterComparison>;
  number?: InputMaybe<VoipNumberNumberFilterComparison>;
  or?: InputMaybe<Array<VoipNumberFilter>>;
  updatedAt?: InputMaybe<VoipNumberUpdatedAtFilterComparison>;
};

export type VoipNumberIdFilterComparison = {
  eq?: InputMaybe<Scalars['UUID']['input']>;
  in?: InputMaybe<Array<Scalars['UUID']['input']>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  neq?: InputMaybe<Scalars['UUID']['input']>;
  notIn?: InputMaybe<Array<Scalars['UUID']['input']>>;
};

export type VoipNumberNumberFilterComparison = {
  eq?: InputMaybe<Scalars['String']['input']>;
  like?: InputMaybe<Scalars['String']['input']>;
  neq?: InputMaybe<Scalars['String']['input']>;
  notLike?: InputMaybe<Scalars['String']['input']>;
};

export type VoipNumberSort = {
  direction: SortDirection;
  field: VoipNumberSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum VoipNumberSortFields {
  CreatedAt = 'createdAt',
  Id = 'id',
  Number = 'number',
  UpdatedAt = 'updatedAt'
}

export type VoipNumberUpdatedAtFilterComparison = {
  between?: InputMaybe<VoipNumberUpdatedAtFilterComparisonBetween>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  notBetween?: InputMaybe<VoipNumberUpdatedAtFilterComparisonBetween>;
};

export type VoipNumberUpdatedAtFilterComparisonBetween = {
  lower: Scalars['DateTime']['input'];
  upper: Scalars['DateTime']['input'];
};

export type Workflow = {
  __typename?: 'Workflow';
  /** Company that created the workflow */
  company: CompanyReference;
  /** ID of the company that created the workflow. */
  companyId: Scalars['UUID']['output'];
  /** Date time of when the recourse is created. */
  createdAt: Scalars['DateTime']['output'];
  /** Primary key of the recourse. */
  id: Scalars['UUID']['output'];
  /** Name of the workflow. */
  name?: Maybe<Scalars['String']['output']>;
  /** `TRUE` if the workflow has reached its end. */
  reachedEnd: Scalars['UUID']['output'];
  /** Workflow variant. */
  type: WorkflowType;
  /** Date time of when the recourse last updated. */
  updatedAt: Scalars['DateTime']['output'];
};

export type WorkflowAction = {
  __typename?: 'WorkflowAction';
  /** If `true` files generated by this action will be added as attachment to same step email actions. */
  attachFilesToStepEmailActions: Scalars['Boolean']['output'];
  /** Attachments that will be send with the action (Only for email actions). */
  attachments?: Maybe<Array<WorkflowActionAttachment>>;
  /** Date time of when the recourse is created. */
  createdAt: Scalars['DateTime']['output'];
  /** To who the action should be focused on. */
  direction: WorkflowActionDirection;
  /** Emails send by this action. */
  emails?: Maybe<Array<Email>>;
  /** Date the action is executed. */
  executedOn?: Maybe<Scalars['DateTime']['output']>;
  /** User who executed this action. */
  executor?: Maybe<UserReference>;
  /** ID of the executor. */
  executorId?: Maybe<Scalars['UUID']['output']>;
  /** Reason why the action failed. */
  failedReason?: Maybe<WorkflowActionFailedReasons>;
  /** Files created by this action. */
  files?: Maybe<Array<FileReference>>;
  /** Is the workflow action globally available. */
  global: Scalars['Boolean']['output'];
  /** Primary key of the recourse. */
  id: Scalars['UUID']['output'];
  /** Workflow action message. */
  message?: Maybe<Scalars['String']['output']>;
  /** Name of the action. */
  name: Scalars['String']['output'];
  /** SMSes send by this action. */
  smses?: Maybe<Array<SmsSend>>;
  /** Status of the action. */
  status: WorkflowActionStatus;
  /** ID of the step this action belongs to. */
  stepId?: Maybe<Scalars['UUID']['output']>;
  /** Workflow action subject. */
  subject?: Maybe<Scalars['String']['output']>;
  /** Type of the action. */
  type: WorkflowActionType;
  /** Date time of when the recourse last updated. */
  updatedAt: Scalars['DateTime']['output'];
};


export type WorkflowActionAttachmentsArgs = {
  filter?: WorkflowActionAttachmentFilter;
  sorting?: Array<WorkflowActionAttachmentSort>;
};


export type WorkflowActionEmailsArgs = {
  sorting?: Array<EmailSort>;
};


export type WorkflowActionFilesArgs = {
  sorting?: Array<FileReferenceSort>;
};


export type WorkflowActionSmsesArgs = {
  sorting?: Array<SmsSendSort>;
};

export type WorkflowActionAttachment = {
  __typename?: 'WorkflowActionAttachment';
  /** Date time of when the recourse is created. */
  createdAt: Scalars['DateTime']['output'];
  /** File of this attachment. */
  file: FileReference;
  /** ID of the file. */
  fileId: Scalars['String']['output'];
  /** Primary key of the recourse. */
  id: Scalars['UUID']['output'];
  /** Date time of when the recourse last updated. */
  updatedAt: Scalars['DateTime']['output'];
};

export type WorkflowActionAttachmentCreatedAtFilterComparison = {
  between?: InputMaybe<WorkflowActionAttachmentCreatedAtFilterComparisonBetween>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  notBetween?: InputMaybe<WorkflowActionAttachmentCreatedAtFilterComparisonBetween>;
};

export type WorkflowActionAttachmentCreatedAtFilterComparisonBetween = {
  lower: Scalars['DateTime']['input'];
  upper: Scalars['DateTime']['input'];
};

export type WorkflowActionAttachmentEdge = {
  __typename?: 'WorkflowActionAttachmentEdge';
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor']['output'];
  /** The node containing the WorkflowActionAttachment */
  node: WorkflowActionAttachment;
};

export type WorkflowActionAttachmentFilter = {
  and?: InputMaybe<Array<WorkflowActionAttachmentFilter>>;
  createdAt?: InputMaybe<WorkflowActionAttachmentCreatedAtFilterComparison>;
  id?: InputMaybe<WorkflowActionAttachmentIdFilterComparison>;
  or?: InputMaybe<Array<WorkflowActionAttachmentFilter>>;
  updatedAt?: InputMaybe<WorkflowActionAttachmentUpdatedAtFilterComparison>;
};

export type WorkflowActionAttachmentIdFilterComparison = {
  eq?: InputMaybe<Scalars['UUID']['input']>;
  in?: InputMaybe<Array<Scalars['UUID']['input']>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  neq?: InputMaybe<Scalars['UUID']['input']>;
  notIn?: InputMaybe<Array<Scalars['UUID']['input']>>;
};

export type WorkflowActionAttachmentSort = {
  direction: SortDirection;
  field: WorkflowActionAttachmentSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum WorkflowActionAttachmentSortFields {
  CreatedAt = 'createdAt',
  Id = 'id',
  UpdatedAt = 'updatedAt'
}

export type WorkflowActionAttachmentUpdatedAtFilterComparison = {
  between?: InputMaybe<WorkflowActionAttachmentUpdatedAtFilterComparisonBetween>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  notBetween?: InputMaybe<WorkflowActionAttachmentUpdatedAtFilterComparisonBetween>;
};

export type WorkflowActionAttachmentUpdatedAtFilterComparisonBetween = {
  lower: Scalars['DateTime']['input'];
  upper: Scalars['DateTime']['input'];
};

export type WorkflowActionClientIdFilterComparison = {
  eq?: InputMaybe<Scalars['UUID']['input']>;
  in?: InputMaybe<Array<Scalars['UUID']['input']>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  neq?: InputMaybe<Scalars['UUID']['input']>;
  notIn?: InputMaybe<Array<Scalars['UUID']['input']>>;
};

export type WorkflowActionConnection = {
  __typename?: 'WorkflowActionConnection';
  /** Array of nodes. */
  nodes: Array<WorkflowAction>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type WorkflowActionCreatedAtFilterComparison = {
  between?: InputMaybe<WorkflowActionCreatedAtFilterComparisonBetween>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  notBetween?: InputMaybe<WorkflowActionCreatedAtFilterComparisonBetween>;
};

export type WorkflowActionCreatedAtFilterComparisonBetween = {
  lower: Scalars['DateTime']['input'];
  upper: Scalars['DateTime']['input'];
};

/** The direction of an workflow action. */
export enum WorkflowActionDirection {
  Client = 'Client',
  Debtor = 'Debtor'
}

export type WorkflowActionExecutedOnFilterComparison = {
  between?: InputMaybe<WorkflowActionExecutedOnFilterComparisonBetween>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  notBetween?: InputMaybe<WorkflowActionExecutedOnFilterComparisonBetween>;
};

export type WorkflowActionExecutedOnFilterComparisonBetween = {
  lower: Scalars['DateTime']['input'];
  upper: Scalars['DateTime']['input'];
};

export type WorkflowActionExecutorIdFilterComparison = {
  eq?: InputMaybe<Scalars['UUID']['input']>;
  in?: InputMaybe<Array<Scalars['UUID']['input']>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  neq?: InputMaybe<Scalars['UUID']['input']>;
  notIn?: InputMaybe<Array<Scalars['UUID']['input']>>;
};

/** The reason why an workflow action failed. */
export enum WorkflowActionFailedReasons {
  AttachmentFailed = 'AttachmentFailed',
  ClientMissingBusinessActivity = 'ClientMissingBusinessActivity',
  EmailDomainAcceptsNoEmail = 'EmailDomainAcceptsNoEmail',
  EmailDuplicateAddress = 'EmailDuplicateAddress',
  IncorrectMobileNumber = 'IncorrectMobileNumber',
  IncorrectPhoneNumber = 'IncorrectPhoneNumber',
  MailingError = 'MailingError',
  MissingContact = 'MissingContact',
  MissingEmail = 'MissingEmail',
  MissingExtension = 'MissingExtension',
  MissingMobileNumber = 'MissingMobileNumber',
  NoContactSelected = 'NoContactSelected',
  SmsMessageToBig = 'SmsMessageToBig',
  TemplatingError = 'TemplatingError',
  Unknown = 'Unknown'
}

export type WorkflowActionFilter = {
  and?: InputMaybe<Array<WorkflowActionFilter>>;
  clientId?: InputMaybe<WorkflowActionClientIdFilterComparison>;
  createdAt?: InputMaybe<WorkflowActionCreatedAtFilterComparison>;
  executedOn?: InputMaybe<WorkflowActionExecutedOnFilterComparison>;
  executorId?: InputMaybe<WorkflowActionExecutorIdFilterComparison>;
  id?: InputMaybe<WorkflowActionIdFilterComparison>;
  name?: InputMaybe<WorkflowActionNameFilterComparison>;
  or?: InputMaybe<Array<WorkflowActionFilter>>;
  status?: InputMaybe<WorkflowActionStatusFilterComparison>;
  type?: InputMaybe<WorkflowActionTypeFilterComparison>;
  updatedAt?: InputMaybe<WorkflowActionUpdatedAtFilterComparison>;
};

export type WorkflowActionIdFilterComparison = {
  eq?: InputMaybe<Scalars['UUID']['input']>;
  in?: InputMaybe<Array<Scalars['UUID']['input']>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  neq?: InputMaybe<Scalars['UUID']['input']>;
  notIn?: InputMaybe<Array<Scalars['UUID']['input']>>;
};

export type WorkflowActionNameFilterComparison = {
  eq?: InputMaybe<Scalars['String']['input']>;
  like?: InputMaybe<Scalars['String']['input']>;
  neq?: InputMaybe<Scalars['String']['input']>;
  notLike?: InputMaybe<Scalars['String']['input']>;
};

export type WorkflowActionSort = {
  direction: SortDirection;
  field: WorkflowActionSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum WorkflowActionSortFields {
  ClientId = 'clientId',
  CreatedAt = 'createdAt',
  ExecutedOn = 'executedOn',
  ExecutorId = 'executorId',
  Id = 'id',
  Name = 'name',
  Status = 'status',
  Type = 'type',
  UpdatedAt = 'updatedAt'
}

/** The status of an workflow action. */
export enum WorkflowActionStatus {
  Executed = 'Executed',
  Failed = 'Failed',
  Open = 'Open',
  Scheduled = 'Scheduled',
  Skipped = 'Skipped'
}

export type WorkflowActionStatusFilterComparison = {
  eq?: InputMaybe<WorkflowActionStatus>;
  in?: InputMaybe<Array<WorkflowActionStatus>>;
  neq?: InputMaybe<WorkflowActionStatus>;
  notIn?: InputMaybe<Array<WorkflowActionStatus>>;
};

/** The type of an workflow action. */
export enum WorkflowActionType {
  Call = 'Call',
  Email = 'Email',
  Letter = 'Letter',
  Other = 'Other',
  Sms = 'Sms'
}

export type WorkflowActionTypeFilterComparison = {
  eq?: InputMaybe<WorkflowActionType>;
  in?: InputMaybe<Array<WorkflowActionType>>;
  neq?: InputMaybe<WorkflowActionType>;
  notIn?: InputMaybe<Array<WorkflowActionType>>;
};

export type WorkflowActionUpdatedAtFilterComparison = {
  between?: InputMaybe<WorkflowActionUpdatedAtFilterComparisonBetween>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  notBetween?: InputMaybe<WorkflowActionUpdatedAtFilterComparisonBetween>;
};

export type WorkflowActionUpdatedAtFilterComparisonBetween = {
  lower: Scalars['DateTime']['input'];
  upper: Scalars['DateTime']['input'];
};

export type WorkflowCompanyIdFilterComparison = {
  eq?: InputMaybe<Scalars['UUID']['input']>;
  in?: InputMaybe<Array<Scalars['UUID']['input']>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  neq?: InputMaybe<Scalars['UUID']['input']>;
  notIn?: InputMaybe<Array<Scalars['UUID']['input']>>;
};

export type WorkflowConnection = {
  __typename?: 'WorkflowConnection';
  /** Array of nodes. */
  nodes: Array<Workflow>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type WorkflowCreatedAtFilterComparison = {
  between?: InputMaybe<WorkflowCreatedAtFilterComparisonBetween>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  notBetween?: InputMaybe<WorkflowCreatedAtFilterComparisonBetween>;
};

export type WorkflowCreatedAtFilterComparisonBetween = {
  lower: Scalars['DateTime']['input'];
  upper: Scalars['DateTime']['input'];
};

export type WorkflowDeleteResponse = {
  __typename?: 'WorkflowDeleteResponse';
  /** ID of the company that created the workflow. */
  companyId?: Maybe<Scalars['UUID']['output']>;
  /** Date time of when the recourse is created. */
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  /** Primary key of the recourse. */
  id?: Maybe<Scalars['UUID']['output']>;
  /** Name of the workflow. */
  name?: Maybe<Scalars['String']['output']>;
  /** `TRUE` if the workflow has reached its end. */
  reachedEnd?: Maybe<Scalars['UUID']['output']>;
  /** Workflow variant. */
  type?: Maybe<WorkflowType>;
  /** Date time of when the recourse last updated. */
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type WorkflowFilter = {
  and?: InputMaybe<Array<WorkflowFilter>>;
  companyId?: InputMaybe<WorkflowCompanyIdFilterComparison>;
  createdAt?: InputMaybe<WorkflowCreatedAtFilterComparison>;
  id?: InputMaybe<WorkflowIdFilterComparison>;
  name?: InputMaybe<WorkflowNameFilterComparison>;
  or?: InputMaybe<Array<WorkflowFilter>>;
  reachedEnd?: InputMaybe<WorkflowReachedEndFilterComparison>;
  type?: InputMaybe<WorkflowTypeFilterComparison>;
  updatedAt?: InputMaybe<WorkflowUpdatedAtFilterComparison>;
};

export type WorkflowIdFilterComparison = {
  eq?: InputMaybe<Scalars['UUID']['input']>;
  in?: InputMaybe<Array<Scalars['UUID']['input']>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  neq?: InputMaybe<Scalars['UUID']['input']>;
  notIn?: InputMaybe<Array<Scalars['UUID']['input']>>;
};

export type WorkflowNameFilterComparison = {
  eq?: InputMaybe<Scalars['String']['input']>;
  like?: InputMaybe<Scalars['String']['input']>;
  neq?: InputMaybe<Scalars['String']['input']>;
  notLike?: InputMaybe<Scalars['String']['input']>;
};

export type WorkflowReachedEndFilterComparison = {
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
};

export type WorkflowReference = {
  __typename?: 'WorkflowReference';
  /** Primary key of the recourse. */
  id: Scalars['UUID']['output'];
  /** Name of the workflow. */
  name?: Maybe<Scalars['String']['output']>;
};

export type WorkflowSort = {
  direction: SortDirection;
  field: WorkflowSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum WorkflowSortFields {
  CompanyId = 'companyId',
  CreatedAt = 'createdAt',
  Id = 'id',
  Name = 'name',
  ReachedEnd = 'reachedEnd',
  Type = 'type',
  UpdatedAt = 'updatedAt'
}

export type WorkflowStep = {
  __typename?: 'WorkflowStep';
  /** Actions of this workflow step. */
  actions: Array<WorkflowAction>;
  /** Amount in cents the debtor is committed to pay when this step executes. */
  centAmountDebtorCommittedToPay: Scalars['Float']['output'];
  /** Cost category for client costs. */
  clientCostCategory?: Maybe<CostCategoryReference>;
  /** Id of the client cost category. */
  clientCostCategoryId?: Maybe<Scalars['UUID']['output']>;
  /** Currency of the client costs. */
  clientCostCurrency?: Maybe<Currency>;
  /** Costs for the client. */
  clientCosts: Array<WorkflowStepCost>;
  /** Are the client costs for each invoice. */
  clientCostsForEachInvoice: Scalars['Boolean']['output'];
  /** Are the client costs success based. */
  clientCostsSuccessBased: Scalars['Boolean']['output'];
  /** Date time of when the recourse is created. */
  createdAt: Scalars['DateTime']['output'];
  /** Currency of the amount the debtor is committed to pay when this step executes. */
  currencyDebtorCommittedToPay: Currency;
  /** Amount of days / work-days. */
  days: Scalars['Float']['output'];
  /** Amount of days / work-days. */
  daysType: WorkflowStepDaysType;
  /** Cost category for debtor costs. */
  debtorCostCategory?: Maybe<CostCategoryReference>;
  /** Id of the debtor cost category. */
  debtorCostCategoryId?: Maybe<Scalars['UUID']['output']>;
  /** Currency of the debtor costs. */
  debtorCostCurrency?: Maybe<Currency>;
  /** Costs for the debtor. */
  debtorCosts: Array<WorkflowStepCost>;
  /** Are the debtor costs for each invoice. */
  debtorCostsForEachInvoice: Scalars['Boolean']['output'];
  /** Date the actions of this step can be executed. */
  executableOn?: Maybe<Scalars['DateTime']['output']>;
  /** Date the step is executed. */
  executedOn?: Maybe<Scalars['DateTime']['output']>;
  /** Is the workflow step globally available. */
  global: Scalars['Boolean']['output'];
  /** Primary key of the recourse. */
  id: Scalars['UUID']['output'];
  /** Name of the step. */
  name?: Maybe<Scalars['String']['output']>;
  /** Transaction that paid this step (Only for arrangement steps). */
  transaction?: Maybe<Transaction>;
  /** Workflow step variant. */
  type: WorkflowStepType;
  /** Date time of when the recourse last updated. */
  updatedAt: Scalars['DateTime']['output'];
  /** Used to sort the steps in correct execution order. */
  weight: Scalars['Float']['output'];
  /** ID of the workflow this step belongs to. */
  workflowId?: Maybe<Scalars['UUID']['output']>;
};


export type WorkflowStepActionsArgs = {
  filter?: WorkflowActionFilter;
  sorting?: Array<WorkflowActionSort>;
};

export type WorkflowStepCost = {
  __typename?: 'WorkflowStepCost';
  /** Amount in cents. */
  centAmount?: Maybe<Scalars['Float']['output']>;
  /** Until what cent amount this line applies, null if always. */
  centAmountUntil?: Maybe<Scalars['Float']['output']>;
  /** Date time of when the recourse is created. */
  createdAt: Scalars['DateTime']['output'];
  /** Currency of the until amount. */
  currencyUntil: Currency;
  /** To who the action should be focused on. */
  direction: WorkflowStepCostsDirection;
  /** Primary key of the recourse. */
  id: Scalars['UUID']['output'];
  /** Use percentage instead of centAmount. */
  isPercentage: Scalars['Boolean']['output'];
  /** Amount in percentage. */
  percentage?: Maybe<Scalars['Float']['output']>;
  /** Date time of when the recourse last updated. */
  updatedAt: Scalars['DateTime']['output'];
};

export type WorkflowStepCostEdge = {
  __typename?: 'WorkflowStepCostEdge';
  /** Cursor for this node. */
  cursor: Scalars['ConnectionCursor']['output'];
  /** The node containing the WorkflowStepCost */
  node: WorkflowStepCost;
};

/** The direction (who needs to pay) of an workflow step cost. */
export enum WorkflowStepCostsDirection {
  Client = 'Client',
  Debtor = 'Debtor'
}

export type WorkflowStepCreatedAtFilterComparison = {
  between?: InputMaybe<WorkflowStepCreatedAtFilterComparisonBetween>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  notBetween?: InputMaybe<WorkflowStepCreatedAtFilterComparisonBetween>;
};

export type WorkflowStepCreatedAtFilterComparisonBetween = {
  lower: Scalars['DateTime']['input'];
  upper: Scalars['DateTime']['input'];
};

/** The type of the step days. */
export enum WorkflowStepDaysType {
  Days = 'Days',
  Months = 'Months',
  WorkingDays = 'WorkingDays'
}

export type WorkflowStepExecutableOnFilterComparison = {
  between?: InputMaybe<WorkflowStepExecutableOnFilterComparisonBetween>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  notBetween?: InputMaybe<WorkflowStepExecutableOnFilterComparisonBetween>;
};

export type WorkflowStepExecutableOnFilterComparisonBetween = {
  lower: Scalars['DateTime']['input'];
  upper: Scalars['DateTime']['input'];
};

export type WorkflowStepFilter = {
  and?: InputMaybe<Array<WorkflowStepFilter>>;
  createdAt?: InputMaybe<WorkflowStepCreatedAtFilterComparison>;
  executableOn?: InputMaybe<WorkflowStepExecutableOnFilterComparison>;
  global?: InputMaybe<WorkflowStepGlobalFilterComparison>;
  id?: InputMaybe<WorkflowStepIdFilterComparison>;
  name?: InputMaybe<WorkflowStepNameFilterComparison>;
  or?: InputMaybe<Array<WorkflowStepFilter>>;
  type?: InputMaybe<WorkflowStepTypeFilterComparison>;
  updatedAt?: InputMaybe<WorkflowStepUpdatedAtFilterComparison>;
  weight?: InputMaybe<WorkflowStepWeightFilterComparison>;
  workflowId?: InputMaybe<WorkflowStepWorkflowIdFilterComparison>;
};

export type WorkflowStepGlobalFilterComparison = {
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
};

export type WorkflowStepIdFilterComparison = {
  eq?: InputMaybe<Scalars['UUID']['input']>;
  in?: InputMaybe<Array<Scalars['UUID']['input']>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  neq?: InputMaybe<Scalars['UUID']['input']>;
  notIn?: InputMaybe<Array<Scalars['UUID']['input']>>;
};

export type WorkflowStepNameFilterComparison = {
  eq?: InputMaybe<Scalars['String']['input']>;
  like?: InputMaybe<Scalars['String']['input']>;
  neq?: InputMaybe<Scalars['String']['input']>;
  notLike?: InputMaybe<Scalars['String']['input']>;
};

export type WorkflowStepOffsetConnection = {
  __typename?: 'WorkflowStepOffsetConnection';
  /** Array of nodes. */
  nodes: Array<WorkflowStep>;
  /** Paging information */
  pageInfo: OffsetPageInfo;
  /** Fetch total count of records */
  totalCount: Scalars['Int']['output'];
};

export type WorkflowStepSort = {
  direction: SortDirection;
  field: WorkflowStepSortFields;
  nulls?: InputMaybe<SortNulls>;
};

export enum WorkflowStepSortFields {
  CreatedAt = 'createdAt',
  ExecutableOn = 'executableOn',
  Global = 'global',
  Id = 'id',
  Name = 'name',
  Type = 'type',
  UpdatedAt = 'updatedAt',
  Weight = 'weight',
  WorkflowId = 'workflowId'
}

/** The type of an workflow step. */
export enum WorkflowStepType {
  Collection = 'Collection',
  DebtorManagement = 'DebtorManagement',
  Invoicing = 'Invoicing',
  PauseEnd = 'PauseEnd',
  PauseStart = 'PauseStart',
  PaymentArrangementCheck = 'PaymentArrangementCheck',
  PaymentArrangementEnd = 'PaymentArrangementEnd',
  PaymentArrangementReminder = 'PaymentArrangementReminder',
  PaymentArrangementStart = 'PaymentArrangementStart',
  PaymentArrangementTerm = 'PaymentArrangementTerm',
  Universal = 'Universal'
}

export type WorkflowStepTypeFilterComparison = {
  eq?: InputMaybe<WorkflowStepType>;
  in?: InputMaybe<Array<WorkflowStepType>>;
  neq?: InputMaybe<WorkflowStepType>;
  notIn?: InputMaybe<Array<WorkflowStepType>>;
};

export type WorkflowStepUpdatedAtFilterComparison = {
  between?: InputMaybe<WorkflowStepUpdatedAtFilterComparisonBetween>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  notBetween?: InputMaybe<WorkflowStepUpdatedAtFilterComparisonBetween>;
};

export type WorkflowStepUpdatedAtFilterComparisonBetween = {
  lower: Scalars['DateTime']['input'];
  upper: Scalars['DateTime']['input'];
};

export type WorkflowStepWeightFilterComparison = {
  eq?: InputMaybe<Scalars['Float']['input']>;
};

export type WorkflowStepWorkflowIdFilterComparison = {
  eq?: InputMaybe<Scalars['UUID']['input']>;
  in?: InputMaybe<Array<Scalars['UUID']['input']>>;
  is?: InputMaybe<Scalars['Boolean']['input']>;
  isNot?: InputMaybe<Scalars['Boolean']['input']>;
  neq?: InputMaybe<Scalars['UUID']['input']>;
  notIn?: InputMaybe<Array<Scalars['UUID']['input']>>;
};

/** The type of an workflow. */
export enum WorkflowType {
  Collection = 'Collection',
  DebtorManagement = 'DebtorManagement',
  Invoicing = 'Invoicing',
  Universal = 'Universal'
}

export type WorkflowTypeFilterComparison = {
  eq?: InputMaybe<WorkflowType>;
  in?: InputMaybe<Array<WorkflowType>>;
  neq?: InputMaybe<WorkflowType>;
  notIn?: InputMaybe<Array<WorkflowType>>;
};

export type WorkflowUpdatedAtFilterComparison = {
  between?: InputMaybe<WorkflowUpdatedAtFilterComparisonBetween>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  notBetween?: InputMaybe<WorkflowUpdatedAtFilterComparisonBetween>;
};

export type WorkflowUpdatedAtFilterComparisonBetween = {
  lower: Scalars['DateTime']['input'];
  upper: Scalars['DateTime']['input'];
};
